import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 499 499">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" >
          <path fill="#2597F0" opacity="1.000000" stroke="none"
            d="
M254.000000,500.000000 
	C252.611008,500.000000 251.222000,500.000000 249.322540,499.607422 
	C246.979767,498.806244 245.165100,498.274445 243.312271,498.009399 
	C233.046555,496.540955 222.771423,495.138519 212.500015,493.709869 
	C211.534729,493.575592 210.576797,493.381317 209.608887,493.274292 
	C204.590103,492.719147 199.417679,492.765717 194.583542,491.508026 
	C186.802979,489.483765 179.085556,486.999817 171.627716,483.998688 
	C157.602219,478.354675 143.335709,473.045258 130.013184,466.000916 
	C119.022949,460.189758 109.169029,452.190521 98.955254,444.957977 
	C77.014900,429.421692 59.897751,409.279816 44.704475,387.382355 
	C40.567036,381.419220 36.917488,375.117554 32.877899,368.742493 
	C32.197075,368.312286 31.682388,368.110870 31.116568,367.896606 
	C31.065435,367.883728 31.122459,367.795044 31.121315,367.503723 
	C31.109426,366.823792 31.098684,366.435242 31.237419,365.936829 
	C31.268429,365.549530 31.149961,365.272003 30.878139,364.672119 
	C28.707525,360.226135 26.306358,356.239868 24.769119,351.944458 
	C22.798342,346.437653 21.522581,340.682068 19.939697,334.709229 
	C19.289286,333.589386 18.646542,332.793945 17.999241,331.605530 
	C16.977894,327.481140 15.961106,323.749756 14.941492,319.719727 
	C14.629163,318.947723 14.319660,318.474274 13.998058,317.754761 
	C13.985958,317.508698 13.962677,317.016479 13.874104,316.792694 
	C13.604231,316.291107 13.360346,316.088684 13.041075,315.567841 
	C13.001986,314.123596 12.975698,313.073181 12.863016,311.797363 
	C12.595304,311.291595 12.348951,311.091156 12.031739,310.600159 
	C12.020282,309.488647 12.014647,308.747589 12.231687,307.858032 
	C12.301003,307.141235 12.147644,306.572968 11.988122,305.758728 
	C11.981958,305.512787 11.973708,305.020813 12.293586,305.055450 
	C13.732567,304.643646 15.004223,308.889771 16.261358,305.147552 
	C17.081856,305.852600 17.651718,306.389740 18.429094,307.174896 
	C18.749472,308.275330 18.862335,309.127716 18.807875,310.074524 
	C18.763205,310.447357 18.885859,310.725830 19.024712,311.395966 
	C19.067669,313.093140 19.094425,314.398621 19.168423,315.985443 
	C19.494680,316.507263 19.773699,316.747742 20.291599,317.164734 
	C20.963570,318.230255 21.396660,319.119171 21.687336,320.125854 
	C21.726322,320.487457 21.907724,320.731384 22.339577,321.149109 
	C23.388590,322.228302 24.187151,323.133728 25.094604,324.259216 
	C25.412653,324.752380 25.685896,324.922760 26.302681,325.160614 
	C28.374943,326.589142 30.167751,327.847473 32.082203,329.312256 
	C32.419441,329.777039 32.693741,329.930450 33.396179,330.043152 
	C36.177525,330.713959 38.589439,331.320526 41.263580,332.232239 
	C41.998077,332.679382 42.467640,332.935455 42.943020,332.946747 
	C54.822590,333.229401 65.232399,326.611908 68.931244,310.986633 
	C70.447166,304.582794 66.795860,298.983032 65.186996,293.097595 
	C65.009781,292.449310 63.197136,292.248108 62.102760,291.481720 
	C61.075836,289.659668 60.403076,287.742523 59.051048,286.805298 
	C50.809681,281.092377 41.649643,280.386078 32.673492,284.105286 
	C24.926172,287.315308 20.043829,293.720856 17.631752,302.024567 
	C16.516544,302.025452 15.773467,302.034729 15.020650,301.636719 
	C14.301438,297.907867 13.852436,294.494965 12.737610,291.315582 
	C12.360429,290.239899 10.298643,289.754913 8.988856,288.755798 
	C8.970999,288.511597 8.952290,288.022186 8.867027,287.796906 
	C8.602896,287.291229 8.359457,287.088226 8.045328,286.528503 
	C8.009680,284.067688 7.980154,282.041077 7.964545,279.676270 
	C7.660810,278.555084 7.343157,277.772125 7.018934,276.593079 
	C6.994430,275.140961 6.976496,274.084900 6.973487,272.559326 
	C6.665462,267.058044 6.342514,262.026276 6.016755,256.528046 
	C6.017446,251.045792 6.020947,246.029999 6.299252,240.743164 
	C6.695372,238.634613 6.816688,236.797119 6.950442,234.525970 
	C6.989086,232.067352 7.015292,230.042358 7.247160,227.899902 
	C7.714218,227.575104 7.866136,227.305954 7.924661,226.540039 
	C7.971186,224.073425 8.001630,222.041748 8.286722,219.796356 
	C8.682643,218.718613 8.823914,217.854614 8.974447,216.564072 
	C9.667488,211.434341 10.351267,206.731155 11.246390,201.908936 
	C11.721807,201.580597 11.893683,201.312241 11.981625,200.571579 
	C12.008958,198.780792 12.028029,197.403259 12.282581,195.845886 
	C12.665421,195.105804 12.812778,194.545563 13.013100,193.601547 
	C14.710970,186.811462 16.355877,180.405167 18.306393,173.839630 
	C19.052650,172.446136 19.493298,171.211853 19.988396,169.605682 
	C21.023979,166.481430 22.005112,163.729050 23.204426,160.875153 
	C23.697790,160.575378 23.863823,160.309082 23.942047,159.581955 
	C28.974962,147.954285 33.978939,136.715973 39.002007,125.486221 
	C40.899677,121.243721 42.841469,117.020958 44.819290,112.787750 
	C44.875435,112.786293 44.753586,112.771065 45.122360,112.810120 
	C46.602173,110.727455 47.713219,108.605728 48.804543,106.363419 
	C48.784817,106.242836 48.953945,106.066444 49.312241,106.020721 
	C52.440845,103.308998 55.211147,100.643005 57.949741,98.353928 
	C54.789001,106.260567 57.083328,110.991539 64.964165,113.438309 
	C65.828545,114.812515 66.473923,116.017578 67.431862,116.872375 
	C74.384407,123.076378 81.369942,129.244400 88.410118,135.348740 
	C91.300659,137.855042 98.729797,137.320908 100.643929,134.616608 
	C103.691475,130.310974 101.476921,122.293633 96.631950,120.051849 
	C95.249397,119.412125 93.888550,118.725502 91.656700,117.642609 
	C93.278404,117.234863 93.734856,117.021317 94.192078,117.019646 
	C104.771858,116.981171 104.711617,116.982788 105.171982,106.466942 
	C105.207420,105.657356 106.926979,104.921501 107.942383,104.501785 
	C108.373375,105.600273 108.536530,106.767517 109.109764,107.026253 
	C112.126419,108.387924 115.274040,110.474152 118.320992,110.386650 
	C120.256622,110.331055 123.466537,107.047058 123.646889,104.994781 
	C123.896851,102.150513 122.668083,98.201378 120.613678,96.309052 
	C113.974213,90.193428 106.855698,84.543770 99.550171,79.224968 
	C94.901360,75.840401 91.613914,70.110100 84.912498,69.601860 
	C86.290459,67.146408 87.646179,65.067566 89.366318,62.985355 
	C90.791992,61.970493 91.853241,60.959003 93.116653,59.695358 
	C94.883530,58.294338 96.448257,57.145477 98.360809,56.053726 
	C100.755653,57.070129 102.802666,58.029419 104.879898,59.440456 
	C104.546310,65.154686 105.220451,70.206329 108.386879,74.596863 
	C110.805016,77.949829 113.757683,80.956207 115.886307,84.471741 
	C122.185608,94.875359 132.328766,95.845497 142.314774,91.760155 
	C153.580627,87.151215 157.764206,72.296722 150.011047,63.609035 
	C146.824585,58.452297 143.817688,53.538837 140.397125,48.932587 
	C139.126221,47.221157 136.886063,46.229496 134.990265,44.559013 
	C130.938217,40.903370 126.563965,40.467510 121.691193,41.857227 
	C120.346420,42.240761 118.653931,41.405094 117.478600,41.081352 
	C121.932259,38.401329 126.028297,35.768799 130.480194,33.100250 
	C132.225861,32.379185 133.615692,31.694134 135.364594,30.963987 
	C138.819214,29.291784 141.914764,27.664673 145.330780,26.069582 
	C146.099091,26.735022 146.546890,27.368439 146.721252,28.146721 
	C146.619995,29.199202 146.792191,30.106815 147.010910,31.345640 
	C147.026215,32.120617 146.994995,32.564381 146.694656,33.243652 
	C147.136841,39.298447 148.613525,44.788822 153.313644,49.166615 
	C153.681396,49.883297 153.826599,50.422943 153.705475,51.195244 
	C155.961914,58.230701 158.380798,65.076340 161.144379,71.779869 
	C161.474625,72.580933 163.680649,72.608696 165.009674,72.999344 
	C165.002106,73.003799 164.997269,72.987747 165.184753,73.270317 
	C168.747025,75.886551 171.362534,73.881584 174.386093,71.992775 
	C183.587753,70.392029 189.867813,65.717949 193.221390,57.234825 
	C194.645523,53.632362 191.547943,42.355709 188.108276,41.298183 
	C184.143173,40.079109 183.239410,38.376175 183.838165,34.204227 
	C184.915131,26.700356 180.732010,21.276728 174.475952,21.132010 
	C173.775375,21.144756 173.376862,21.120451 172.916718,20.714226 
	C171.568039,19.883707 170.241638,19.521629 169.005997,18.959921 
	C167.962891,18.485731 167.025055,17.779966 166.339035,17.006058 
	C167.687683,16.511944 168.737930,16.188465 170.205582,15.899720 
	C177.180450,14.260172 183.737900,12.585890 190.862762,10.766733 
	C187.394714,19.724407 191.093994,26.337511 193.926331,33.528694 
	C195.450592,41.901031 196.592834,49.931568 198.661896,57.715755 
	C199.225784,59.837143 202.386185,61.638885 204.751343,62.720604 
	C205.642654,63.128242 207.656860,61.080624 209.499008,60.068451 
	C210.576797,57.918682 211.968445,55.830807 211.900009,53.791935 
	C211.796585,50.709953 210.680267,47.661049 209.986099,44.599972 
	C207.274261,32.641937 204.559189,20.684637 201.690689,8.045797 
	C205.193359,7.387770 209.739746,6.533667 214.348831,5.667785 
	C213.816620,9.124457 212.902359,11.707733 213.123398,14.189905 
	C214.266174,27.022690 215.720291,39.827496 217.018127,52.646885 
	C217.385376,56.274414 219.365967,57.986958 222.921646,57.992226 
	C228.079926,57.999866 233.284012,58.393322 238.383087,57.837849 
	C243.056686,57.328720 248.520081,57.912075 250.298553,53.683662 
	C253.776245,55.249603 256.753265,56.807804 259.888794,57.918697 
	C261.711853,58.564606 263.783783,58.551811 265.753174,58.732372 
	C270.526276,59.169983 275.328979,59.981647 280.068542,59.718887 
	C281.782990,59.623833 284.247864,57.131607 284.726379,55.303055 
	C285.175598,53.586357 286.957703,49.736324 282.227295,49.403748 
	C282.124451,49.379723 282.009399,49.198975 281.967834,48.792118 
	C280.310272,47.602318 278.766510,46.491611 277.060760,46.115368 
	C274.667389,45.587452 272.017792,46.003475 269.746521,45.228367 
	C268.547333,44.819145 267.477661,42.814159 267.239594,41.381729 
	C267.139221,40.777874 269.300476,39.179924 270.494904,39.112049 
	C272.751923,38.983803 275.043732,39.750835 277.338043,39.939171 
	C282.183411,40.336906 285.873199,37.953526 285.968109,34.508411 
	C286.101898,29.652067 283.053009,26.326342 278.111359,26.043926 
	C276.123108,25.930300 274.056885,26.293619 272.156403,25.863876 
	C271.020325,25.606981 270.136627,24.234062 269.139465,23.362803 
	C270.054749,22.578445 270.904297,21.229280 271.897369,21.113750 
	C275.103302,20.740784 278.456757,21.253395 281.566071,20.571114 
	C283.533264,20.139454 285.166687,18.186634 286.968170,16.953024 
	C286.986542,16.989977 286.912720,16.953077 287.293457,16.910435 
	C289.912384,12.802043 289.297943,10.399017 285.697327,9.970448 
	C285.335785,9.747736 285.213226,9.625774 285.085449,9.367705 
	C285.080261,9.231598 285.031921,8.963511 285.182709,8.629053 
	C286.964935,7.604182 288.764557,6.066344 290.202362,6.351676 
	C308.931152,10.068399 327.499573,14.331846 345.141327,21.994499 
	C369.604126,32.619877 392.159302,46.217812 412.332672,63.721924 
	C413.598511,64.820320 415.441925,65.253098 417.006836,65.997009 
	C416.997009,65.998474 417.006805,65.980080 416.995361,66.346893 
	C421.181274,71.480064 425.337494,76.284111 429.628723,80.964355 
	C430.155426,81.538773 431.404541,81.450867 432.165222,81.747520 
	C432.011597,81.825134 432.332611,81.701225 432.226410,82.081955 
	C432.584045,83.641396 432.823608,84.985008 433.548981,85.971397 
	C437.553497,91.417130 441.784607,96.697701 445.739197,102.178375 
	C459.349823,121.041298 469.826416,141.528290 477.333893,163.546417 
	C479.444733,169.737305 481.745697,175.863388 483.984009,182.256012 
	C484.008545,182.492828 484.060364,182.966461 484.058075,183.263336 
	C484.349945,184.039429 484.644073,184.518661 484.961792,185.322815 
	C485.020477,186.080154 485.055542,186.512558 485.064819,187.285950 
	C485.352234,188.417648 485.665405,189.208344 485.991638,190.373505 
	C486.038147,191.496490 486.071655,192.245010 485.835144,193.189240 
	C486.364410,195.592041 487.163696,197.799149 487.978333,200.337524 
	C488.013214,201.110504 488.032776,201.552216 487.815857,202.152283 
	C488.018982,202.890778 488.458588,203.470917 488.919006,204.461182 
	C488.945221,206.240204 488.950592,207.609100 488.545990,209.000778 
	C487.428467,210.259842 486.221893,211.452698 486.109772,212.740784 
	C485.809784,216.187836 485.961212,219.675720 485.965240,223.147369 
	C485.978729,234.760406 486.006165,246.373444 486.017334,258.403931 
	C486.336823,261.213074 486.667206,263.604767 486.715057,266.257446 
	C485.960480,269.111877 485.058258,271.711578 485.095703,274.297699 
	C485.215851,282.589447 485.620239,290.879791 486.101776,299.159821 
	C486.158752,300.139801 487.372711,301.052460 488.041168,302.448730 
	C488.152618,305.334808 488.273254,307.768524 488.354126,310.209961 
	C488.314331,310.217651 488.354553,310.288025 487.961395,310.229431 
	C487.071381,311.454742 486.574524,312.738647 486.061310,314.455322 
	C486.212372,316.021759 486.379791,317.155334 486.514862,318.288971 
	C486.482544,318.289001 486.419556,318.274414 486.084534,318.297516 
	C485.520752,318.896606 485.291992,319.472595 485.046997,320.283752 
	C485.030792,320.518860 484.978210,320.987305 484.706299,321.225128 
	C483.726685,323.492645 482.940063,325.498657 482.323364,327.555634 
	C475.942749,348.837189 467.740723,369.299072 455.635773,388.069397 
	C452.903168,392.306610 450.705322,396.888733 448.299042,401.297363 
	C448.336761,401.282593 448.339386,401.363525 447.969727,401.269775 
	C446.077667,403.467255 444.555237,405.758514 443.016113,408.025879 
	C442.999420,408.001984 443.051056,408.029053 442.701294,408.042725 
	C439.460205,410.898834 436.395233,413.589355 433.733795,416.632812 
	C431.688812,418.971283 430.152039,421.754150 428.376221,424.325653 
	C428.363159,424.311920 428.326538,424.302032 428.031067,424.309326 
	C427.582825,424.650940 427.430084,424.985260 427.315613,425.281036 
	C427.353912,425.242493 427.337616,425.349915 426.974731,425.259460 
	C425.641632,425.725128 424.565094,426.153259 423.717407,426.856934 
	C413.591278,435.262878 403.469879,443.675201 393.427185,452.180298 
	C391.771393,453.582550 390.499664,455.438293 389.027832,457.047119 
	C389.005188,457.009735 389.079376,457.055878 388.695923,457.032532 
	C387.212524,457.340698 386.015228,457.493469 385.027710,458.031342 
	C374.857330,463.571014 364.708038,469.149994 354.602173,474.806030 
	C353.883911,475.208038 353.553619,476.303314 353.022278,477.042419 
	C353.001648,477.008636 353.069946,477.048737 352.649719,477.023010 
	C350.452545,477.308807 348.611206,477.419037 346.908722,477.963501 
	C332.853851,482.458496 318.895691,487.272186 304.757416,491.482941 
	C297.843353,493.542114 290.665466,494.970459 283.504883,495.882294 
	C274.512970,497.027344 265.412201,497.303223 256.371796,498.105469 
	C255.531769,498.179993 254.788300,499.342590 254.000000,500.000000 
M343.999908,426.041504 
	C343.875732,426.162231 343.751587,426.282928 343.608490,425.746460 
	C343.038940,425.523743 342.469391,425.301025 341.999115,425.042053 
	C341.999115,425.042053 341.925201,425.117584 341.933105,424.410065 
	C339.701050,420.543915 338.262360,421.946777 337.002838,425.053040 
	C337.002838,425.053040 337.118134,425.100128 336.313019,425.037201 
	C335.588501,426.682220 334.341125,428.310272 334.287048,429.977051 
	C334.233307,431.631500 335.380646,433.324890 335.353516,434.707184 
	C334.068420,434.216217 332.783295,433.725281 331.213989,433.196014 
	C331.213989,433.196014 331.011322,432.993103 330.968872,432.171356 
	C330.407562,431.481110 329.941284,430.307068 329.270233,430.175171 
	C322.307098,428.806671 316.368256,431.943390 310.390717,434.770081 
	C310.134064,434.891418 310.192413,435.678802 310.043213,436.051208 
	C310.043213,436.051208 310.148865,436.110291 309.365387,436.096985 
	C308.631287,438.026550 306.833221,440.310425 307.338348,441.827271 
	C310.283539,450.670410 313.600098,459.403168 317.147858,468.026459 
	C318.763580,471.953705 322.614197,471.837494 325.654388,470.336823 
	C328.459198,468.952362 329.846130,466.176178 327.923828,462.800659 
	C327.079041,461.317200 326.517487,459.672455 325.732635,457.885315 
	C327.380920,457.772217 328.331909,457.706970 329.263184,457.773651 
	C329.263184,457.773651 329.353027,457.675049 329.189972,458.392365 
	C331.531281,460.317963 333.693695,462.538269 336.258667,464.095459 
	C339.552979,466.095520 342.790863,464.676636 344.343903,461.703125 
	C346.266907,458.021210 343.473511,455.141388 340.593201,453.569824 
	C336.934662,451.573608 335.933044,449.429382 336.744202,445.266388 
	C337.377625,442.015686 336.310333,438.433594 336.695526,435.049042 
	C337.981873,436.040558 339.245148,437.063812 340.558777,438.017731 
	C344.721405,441.040588 348.902832,444.037537 353.257690,447.152985 
	C353.257690,447.152985 353.270386,447.363312 353.141449,448.151703 
	C354.753143,452.560303 357.809387,454.629547 362.561859,454.032501 
	C368.031952,453.345245 367.361725,449.086212 366.893677,445.685059 
	C365.778168,437.578949 364.011292,429.563965 362.785248,421.470123 
	C362.566528,420.026123 363.683014,418.379852 364.195587,416.906311 
	C364.195587,416.906311 364.156830,416.838928 364.059296,417.636627 
	C365.412933,424.688446 370.438477,429.135895 375.325562,433.818909 
	C375.325562,433.818909 375.460907,433.664185 375.206451,434.405823 
	C375.822876,438.354492 378.303528,440.881622 382.084961,440.741852 
	C384.033295,440.669830 386.070038,438.541107 387.659119,436.937653 
	C387.978699,436.615234 386.457062,434.467987 385.817810,433.019836 
	C385.817810,433.019836 385.827301,433.165649 385.946991,432.408264 
	C384.575134,430.646851 383.203278,428.885406 381.911346,426.995422 
	C381.911346,426.995422 381.868530,427.140594 381.958038,426.387421 
	C380.279968,424.272949 378.601868,422.158508 376.990997,420.000824 
	C376.990997,420.000824 376.949646,420.069183 377.002502,419.313751 
	C375.455841,414.989197 374.034027,410.566284 368.991516,409.009003 
	C368.991516,409.009003 368.950867,409.083771 368.873718,408.342682 
	C366.945099,406.512878 365.190704,405.887512 363.848663,409.226746 
	C363.848663,409.226746 363.626038,409.218292 362.820038,409.297363 
	C361.869385,410.737305 360.918701,412.177246 359.803101,413.866974 
	C358.062683,415.452240 352.664185,410.246552 353.030457,417.039368 
	C353.030457,417.039368 353.111328,417.024811 352.453003,417.529144 
	C352.453003,422.432251 352.453003,427.335358 352.453003,431.820160 
	C349.811218,430.364105 347.223846,428.938019 344.631409,427.425232 
	C344.631409,427.425232 344.581268,427.354279 344.634583,426.773468 
	C344.302826,426.654327 343.971039,426.535156 343.999908,426.041504 
M120.225029,409.398773 
	C120.531509,409.568359 120.837982,409.737915 121.044922,409.894409 
	C121.044922,409.894409 121.125458,409.834442 121.133202,410.601501 
	C123.589745,411.256653 126.046295,411.911804 128.502823,412.566956 
	C128.675613,410.715851 128.848404,408.864746 128.866486,406.653168 
	C128.866486,406.653168 128.868149,406.260864 128.966064,405.476166 
	C128.198532,404.786377 127.431000,404.096588 126.836555,403.431763 
	C126.836555,403.431763 126.672020,403.376068 126.676147,402.665039 
	C125.740273,402.154053 124.804390,401.643066 123.983879,401.147797 
	C123.983879,401.147797 123.867432,401.162079 123.910507,400.437744 
	C121.208809,395.417236 117.209251,392.983612 111.237228,394.623810 
	C111.709854,385.880035 104.699753,382.745392 99.689705,377.351044 
	C98.121933,376.243530 96.633499,374.992523 94.963806,374.069366 
	C93.335533,373.169159 91.521973,372.604065 89.964600,371.260010 
	C90.085655,370.840088 90.206703,370.420135 90.843575,369.628967 
	C92.841057,364.774200 89.225433,363.932343 86.156494,364.185120 
	C83.205482,364.428162 80.378204,366.173584 76.797691,367.522064 
	C77.781509,365.477722 78.745331,363.956482 79.248291,362.295685 
	C79.761459,360.601196 79.821785,358.769562 80.051453,356.328369 
	C80.108376,355.885986 80.165298,355.443634 80.664803,354.558685 
	C80.299324,353.836121 79.933838,353.113556 79.560768,352.272247 
	C79.560768,352.272247 79.487183,352.365753 79.743614,351.648926 
	C77.925903,348.100525 74.868347,349.009796 72.646599,350.436768 
	C65.028847,355.329407 57.602215,360.529968 50.244976,365.811310 
	C48.187450,367.288330 46.683487,369.459137 49.262424,372.266510 
	C49.262424,372.266510 49.188599,372.526886 49.069111,373.349457 
	C50.305790,374.536316 51.912300,376.919495 52.706203,376.674042 
	C54.677643,376.064606 56.278271,374.255676 58.206745,372.746826 
	C58.206745,372.746826 58.459736,372.778748 59.250900,372.906219 
	C61.678497,372.920593 62.111412,373.994202 61.010487,376.044281 
	C60.067722,377.799835 58.621323,379.591492 58.527908,381.424774 
	C58.387669,384.177246 58.295959,387.859802 59.883484,389.554993 
	C62.256062,392.088531 65.790199,391.612213 68.769562,388.915192 
	C70.951553,386.939941 73.820854,385.739594 76.095352,383.847137 
	C79.499435,381.014923 82.650185,377.878204 86.008118,374.944061 
	C86.008118,374.944061 85.891510,374.903870 86.707947,375.013275 
	C87.404999,375.352753 88.721764,375.723877 88.706284,376.026886 
	C88.467918,380.688995 93.766396,386.333679 86.327995,389.829529 
	C85.537262,390.201141 85.320801,391.794647 84.146111,392.939880 
	C81.724747,394.844910 78.788010,396.374756 77.042816,398.772034 
	C75.611076,400.738770 75.023804,403.775818 75.191353,406.262360 
	C75.277321,407.538086 78.251541,409.903046 79.192711,409.589661 
	C81.723495,408.747131 83.862686,406.728363 86.865372,405.059113 
	C87.973564,403.763245 89.081749,402.467346 90.896019,401.073517 
	C93.005585,398.780121 95.115143,396.486694 97.979439,394.093536 
	C101.636200,395.386536 105.292969,396.679535 108.208366,397.710388 
	C105.446205,402.302429 102.787788,406.722015 100.086006,410.994934 
	C100.086006,410.994934 100.165230,411.125793 99.443634,411.086090 
	C97.295334,413.241272 94.427444,415.039642 93.145760,417.624512 
	C90.317169,423.329041 97.025566,430.985229 104.987122,431.873657 
	C104.987122,431.873657 105.157478,431.809845 105.116249,432.517853 
	C108.870537,436.651031 109.194138,436.670166 111.122124,432.701691 
	C111.122124,432.701691 111.340469,432.716858 112.103508,432.709930 
	C115.376968,430.585266 113.854988,428.893890 111.910545,427.301605 
	C111.910545,427.301605 111.609856,427.307068 111.809296,426.565247 
	C110.382103,424.254639 108.954903,421.944031 107.427971,419.471954 
	C109.536255,419.974518 111.357811,420.408722 113.032600,420.944275 
	C113.032600,420.944275 113.146629,420.807129 113.138901,421.545746 
	C115.657173,424.223114 118.284630,425.157715 120.627289,421.495239 
	C122.282104,418.908173 122.951347,416.259979 118.970497,415.028320 
	C118.970497,415.028320 118.910912,415.125397 118.929825,414.392242 
	C119.126297,412.033142 112.771210,410.199371 118.368172,407.497620 
	C118.368172,407.497620 118.442703,407.548950 118.408463,408.211761 
	C118.976212,408.444427 119.543961,408.677063 120.035461,408.979767 
	C120.035461,408.979767 120.063339,408.880035 120.225029,409.398773 
M345.508453,401.749298 
	C345.682587,401.428864 345.856720,401.108459 346.167358,400.621094 
	C346.167358,400.621094 346.382904,400.627594 347.173279,400.830353 
	C347.668152,400.845001 348.317841,401.072845 348.634979,400.842621 
	C354.614563,396.502319 360.588867,392.152130 366.449829,387.654907 
	C367.026764,387.212250 366.858429,385.798309 367.214417,384.766327 
	C367.214417,384.766327 367.377747,384.865845 368.130493,384.677338 
	C368.372955,384.242188 368.615417,383.807037 368.766449,383.214996 
	C368.766449,383.214996 368.827972,383.044128 369.603058,383.039246 
	C370.563416,382.842712 371.920258,382.986603 372.424896,382.398834 
	C380.514008,372.977539 389.113037,363.904449 396.303223,353.829529 
	C402.026398,345.810150 405.892487,336.481323 410.772583,327.840881 
	C418.845337,313.547638 422.518250,297.655396 425.540344,281.938995 
	C427.908417,269.624054 427.425293,256.576935 426.803864,243.924149 
	C426.215515,231.944092 423.863525,220.044479 422.152130,208.128891 
	C421.500000,203.588226 420.472198,199.101532 419.496796,193.964371 
	C422.480530,198.779327 424.419617,203.587128 427.812225,206.911621 
	C431.209961,210.241150 435.797455,212.918945 440.345703,214.354553 
	C444.937439,215.803894 450.606995,216.890640 454.956421,215.494537 
	C469.114197,210.950073 477.970581,201.898621 477.127289,184.005875 
	C476.542450,171.597321 466.999573,160.188232 455.013153,158.539169 
	C432.851501,155.490234 421.317322,167.325485 419.196014,190.619415 
	C417.213226,188.062103 416.027130,186.983521 415.396667,185.643539 
	C412.108185,178.654282 409.053162,171.553970 405.690247,164.601929 
	C404.980316,163.134308 403.322693,162.125137 402.031342,160.183105 
	C401.033356,158.443008 400.035370,156.702911 398.946503,154.288376 
	C397.995758,153.169159 397.045044,152.049927 395.988037,150.358444 
	C395.661377,149.903366 395.334686,149.448288 394.917480,148.369888 
	C394.318817,147.872955 393.720154,147.376007 393.057159,146.189926 
	C392.032349,144.798538 391.007568,143.407135 389.703217,141.364594 
	C387.163269,137.996124 384.865967,134.405136 382.027924,131.310013 
	C377.477478,126.347343 372.634613,121.645119 367.790741,116.961647 
	C363.939484,113.237907 359.902008,109.706764 355.975098,105.401283 
	C355.702148,104.766151 355.429230,104.131020 355.156311,103.495888 
	C354.764984,104.020416 354.373688,104.544945 353.350464,104.774437 
	C350.661682,103.146782 348.006012,101.461212 345.277924,99.902390 
	C337.237854,95.308357 329.400818,90.270149 321.040894,86.351852 
	C313.890411,83.000420 306.265350,80.413086 298.601654,78.465843 
	C288.225098,75.829300 277.692444,73.306519 267.076202,72.254570 
	C256.435181,71.200180 245.529007,71.187798 234.884628,72.220016 
	C221.932617,73.475998 208.882111,75.358101 196.332764,78.694290 
	C181.789688,82.560509 168.185944,89.114227 155.304443,97.212379 
	C142.588425,105.206490 130.697662,114.077812 120.360184,124.964729 
	C119.479134,125.892609 119.407166,127.588715 118.812012,129.162689 
	C118.812012,129.162689 118.558907,129.276535 117.811569,129.143661 
	C115.712219,131.102356 113.450394,132.915146 111.539383,135.042999 
	C97.019135,151.210800 86.945572,169.920090 79.908173,190.391846 
	C78.647209,194.059982 76.650253,197.475113 74.969147,200.136566 
	C74.975037,193.311188 75.060570,186.483810 74.889877,179.662857 
	C74.867577,178.771713 73.529060,177.913513 72.481949,176.581482 
	C72.219177,175.892349 71.956398,175.203201 71.832802,173.752029 
	C71.153595,173.196686 70.474396,172.641342 69.378372,171.790955 
	C69.177765,171.329575 68.977165,170.868179 68.877190,169.640625 
	C67.073334,168.210556 65.269478,166.780472 63.168312,165.364182 
	C63.168312,165.364182 62.928612,165.187744 62.901115,164.454498 
	C58.168667,162.311066 53.526699,159.904388 48.635368,158.223251 
	C47.517536,157.839066 45.539455,159.957779 43.343525,161.082016 
	C42.922611,161.098373 42.501698,161.114731 41.704315,160.570511 
	C40.796692,161.050995 39.889065,161.531479 38.400360,162.213104 
	C37.791744,162.285233 37.183132,162.357376 35.797039,162.211349 
	C31.124990,163.987183 26.385357,165.686768 25.333466,171.798645 
	C25.333466,171.798645 25.173010,172.037582 24.421871,172.086975 
	C23.741194,173.569931 23.060516,175.052887 22.256847,177.263977 
	C21.722122,179.476059 21.187399,181.688156 19.939821,184.183884 
	C17.497730,191.257126 18.717201,197.525375 23.362640,201.219223 
	C23.362640,201.219223 23.305885,201.496140 23.119846,202.301590 
	C24.850948,204.152939 26.582048,206.004303 28.679232,208.036057 
	C28.903069,208.209579 29.051249,208.433228 29.073305,209.467941 
	C31.244944,210.838226 33.416584,212.208496 36.082546,213.534531 
	C36.418987,213.554535 36.734642,213.646149 37.122486,214.605026 
	C43.771027,218.110977 50.557667,217.822296 57.356060,215.182861 
	C58.085632,214.899597 58.478432,213.748932 59.483440,212.792892 
	C59.813194,212.757217 60.142948,212.721542 61.248402,212.845367 
	C62.146675,211.839355 63.044949,210.833344 64.212563,209.670105 
	C64.212563,209.670105 64.523148,209.698212 65.301193,209.843292 
	C66.458603,208.416794 67.616013,206.990295 68.845161,205.060532 
	C69.137627,204.659607 69.430092,204.258682 70.433052,203.913971 
	C71.957588,202.939560 73.482117,201.965149 75.031052,201.855362 
	C74.986702,203.185074 75.191330,204.580490 74.860329,205.834534 
	C70.500450,222.352890 70.077629,239.192673 69.843727,256.166412 
	C69.517372,279.848816 75.443932,302.033478 84.002701,323.731293 
	C85.062355,326.417694 87.211830,328.674255 88.939751,331.886261 
	C91.537193,337.300873 93.943710,342.820251 96.829155,348.076782 
	C97.932457,350.086700 100.131615,351.495056 101.919937,353.917664 
	C102.335266,355.140106 102.427376,356.647614 103.215508,357.541321 
	C111.863419,367.347260 120.285278,377.390411 129.494720,386.646576 
	C133.790131,390.963806 139.668106,393.706482 145.018280,397.816864 
	C148.873886,400.793091 152.577591,404.003113 156.648407,406.648102 
	C158.722443,407.995758 161.456100,408.328247 164.055679,409.810028 
	C164.573959,410.426910 164.970642,411.363495 165.628418,411.613708 
	C180.079849,417.111115 194.290283,423.473755 209.105545,427.722687 
	C226.420120,432.688446 244.437775,432.061554 262.383575,431.106323 
	C277.975677,430.276337 292.899933,426.704346 307.576538,421.866760 
	C313.949188,419.766205 320.098053,416.915527 326.188416,414.063629 
	C328.714691,412.880707 330.821899,410.802826 333.819244,409.039581 
	C335.875885,408.018158 337.932556,406.996765 340.441925,405.785156 
	C340.720581,405.592041 340.919250,405.336670 341.703796,404.997864 
	C342.461456,404.656677 343.219116,404.315521 344.480591,403.676819 
	C344.669800,403.136932 344.859009,402.597015 345.508453,401.749298 
M421.997467,309.750244 
	C421.996979,310.500275 421.996521,311.250275 421.466309,312.479736 
	C421.946930,313.991516 422.427551,315.503326 422.943848,317.681427 
	C422.799896,329.107605 437.736664,341.353699 449.152863,340.763062 
	C454.777405,340.472076 459.671265,338.846985 464.308502,336.720734 
	C469.269287,334.446075 473.919373,330.853241 474.736572,324.224518 
	C474.736572,324.224518 474.928986,323.980804 475.655975,323.910522 
	C480.975922,317.873871 481.154541,303.496155 475.944519,296.516632 
	C473.808472,293.655090 472.119598,289.881592 469.246796,288.259735 
	C461.490082,283.880554 453.471527,279.973907 443.836731,282.924500 
	C432.733673,286.324738 423.401764,291.415802 422.438995,305.476318 
	C422.293884,306.650970 422.148804,307.825653 421.997467,309.750244 
M377.078949,412.556244 
	C378.426239,414.008789 379.773560,415.461304 381.036896,417.006805 
	C381.036896,417.006805 381.088226,416.892670 381.025787,417.637054 
	C382.510895,419.742188 383.896332,421.926971 385.501678,423.936066 
	C388.935791,428.233765 393.583618,430.014862 398.756989,428.423889 
	C404.662170,426.607849 409.263489,423.369354 410.328003,416.243683 
	C410.855164,412.714813 410.130951,410.135223 407.291290,409.582336 
	C405.384033,409.210968 402.024872,410.897827 400.979370,412.654297 
	C398.975800,416.020294 396.644135,417.469971 393.010620,417.031921 
	C393.010620,417.031921 392.930664,417.089355 392.992798,416.343933 
	C391.793579,414.469604 390.714569,412.503998 389.373505,410.737335 
	C384.619141,404.474213 384.660950,404.229706 392.185059,400.768280 
	C395.414673,399.282471 395.641907,396.967010 392.447174,393.862274 
	C392.199402,393.682343 392.032043,393.445526 391.668701,392.442383 
	C387.411194,390.150024 380.224243,392.818146 377.093048,397.853455 
	C372.989288,404.452911 373.035706,408.049683 377.079834,412.003296 
	C377.079834,412.003296 377.241730,411.801147 377.078949,412.556244 
M462.962067,236.090805 
	C462.962067,236.090805 462.855347,236.195831 462.871399,235.467545 
	C460.159485,234.071930 459.121338,234.500641 458.207062,237.939606 
	C456.193970,245.511627 454.788452,248.438660 445.550995,247.143509 
	C443.995239,246.925385 442.204071,248.386230 440.521698,249.071350 
	C441.218140,250.745728 441.697815,252.564804 442.685516,254.044785 
	C443.369476,255.069550 444.661713,255.836502 445.841797,256.355682 
	C450.345551,258.337097 450.633301,261.478638 449.259888,265.761810 
	C448.556183,267.956360 449.194366,270.581207 449.537994,273.196442 
	C449.746155,273.330902 449.909424,273.506989 450.359924,274.363312 
	C452.361786,273.689392 454.682983,273.450195 456.304840,272.258911 
	C460.912109,268.874817 462.748596,267.288330 466.752258,272.037048 
	C467.487976,272.909698 469.633514,272.593689 471.805450,272.887390 
	C472.482635,272.895935 473.159790,272.904480 474.614563,272.849792 
	C474.951508,271.086456 475.986389,269.101166 475.508026,267.597137 
	C473.067566,259.924072 472.975250,260.026886 479.343597,255.247437 
	C480.189941,254.612274 480.313446,253.013931 480.869873,251.231812 
	C480.865021,250.597900 480.860168,249.963974 480.647705,248.542328 
	C478.374176,248.035248 476.084961,247.056519 473.830750,247.131378 
	C470.429901,247.244308 468.435120,246.281113 467.159058,242.940063 
	C466.220154,240.481720 464.303558,238.396774 462.962067,236.090805 
M130.359909,415.064545 
	C128.954880,416.390289 127.278778,417.529999 126.210419,419.086761 
	C124.730629,421.243073 123.729050,423.724365 122.484520,426.046082 
	C120.493126,429.760986 118.038986,433.306824 116.653908,437.232330 
	C116.018845,439.032196 116.833153,442.917908 118.081909,443.494843 
	C121.097755,444.888245 124.877579,445.464844 127.205078,441.517426 
	C127.900734,440.337585 128.752869,439.249969 129.533295,438.120117 
	C130.026505,438.228119 130.519714,438.336151 131.012924,438.444183 
	C131.012924,441.822296 130.873489,445.207733 131.050186,448.576569 
	C131.246567,452.320953 134.014938,453.802521 137.174255,453.797485 
	C140.391724,453.792358 142.368927,451.894348 142.014557,448.271667 
	C141.869446,446.788269 142.095215,445.269501 141.962387,443.783783 
	C141.714859,441.015411 142.461166,439.664185 145.462967,438.432861 
	C153.528564,435.124390 156.385040,423.754150 150.452103,418.285187 
	C146.791092,414.910400 142.022781,412.514771 137.400650,410.474579 
	C133.664886,408.825562 130.857117,410.333954 131.008453,414.995819 
	C131.008453,414.995819 131.075745,415.047150 130.359909,415.064545 
M379.356415,114.710884 
	C383.778900,121.328049 387.730652,128.459473 395.570465,131.747192 
	C404.534485,135.506348 412.991455,130.384613 414.034363,120.779144 
	C414.133575,119.865372 414.500366,118.980652 414.778442,117.950912 
	C420.925110,118.790657 425.919617,117.606613 428.910278,111.784302 
	C432.079498,105.614326 430.375580,100.199272 426.595856,95.065056 
	C424.527527,92.255516 422.413208,89.471642 420.175354,86.796906 
	C416.267761,82.126411 414.054169,81.810356 409.211884,85.529991 
	C400.124969,92.510155 391.014771,99.474808 382.240875,106.835243 
	C380.448456,108.338905 380.093323,111.555870 379.356415,114.710884 
M357.333038,34.223808 
	C352.088135,31.085300 349.652527,31.756304 347.121674,37.057762 
	C346.477814,38.406464 345.907990,39.790600 345.260162,41.137314 
	C341.434753,49.089901 341.178101,49.091351 333.382812,44.865742 
	C330.678802,43.399994 330.348907,41.774616 331.699249,39.234325 
	C333.181030,36.446903 334.500580,33.569439 335.802490,30.691236 
	C337.352142,27.265259 337.442963,24.059347 333.405579,22.350286 
	C329.524109,20.707241 327.172241,22.627237 325.525116,26.147865 
	C322.428101,32.767509 319.145447,39.300011 315.987000,45.891273 
	C313.690552,50.683567 311.187836,55.405903 309.320099,60.363979 
	C308.186401,63.373516 308.198639,66.800987 312.063660,68.381058 
	C315.871887,69.937897 318.038177,67.838982 319.542816,64.651527 
	C320.746094,62.102436 321.982697,59.561165 323.018646,56.942886 
	C324.481628,53.245235 326.382080,52.380302 330.209198,54.401833 
	C337.151001,58.068600 337.269714,57.815670 333.783417,64.938805 
	C332.907745,66.727928 332.192657,68.596291 331.301758,70.377205 
	C329.639832,73.699471 329.893616,76.793968 333.321411,78.502945 
	C336.956451,80.315247 339.636719,78.624947 341.356628,75.051926 
	C346.759613,63.827530 352.349579,52.690918 357.571198,41.383656 
	C358.417999,39.549877 357.758453,37.020481 357.333038,34.223808 
M372.999847,87.663284 
	C367.805450,91.155769 362.020966,89.386505 361.863770,83.944534 
	C361.794952,81.563522 363.089142,78.762192 364.595917,76.785606 
	C369.834625,69.913452 375.437164,63.318649 380.902039,56.618919 
	C383.016968,54.026154 383.052734,51.229424 380.758118,48.973907 
	C378.265747,46.523964 375.306274,46.819698 372.668243,49.074978 
	C371.662872,49.934460 370.761597,50.941875 369.933594,51.978333 
	C364.849121,58.342960 359.266418,64.398911 354.943115,71.247894 
	C352.459320,75.182671 350.616302,80.537460 350.995819,85.041451 
	C351.674408,93.095413 357.844238,97.642952 365.210999,100.072784 
	C371.898743,102.278656 377.361206,99.556984 381.693512,94.620880 
	C387.502930,88.001793 393.072235,81.168106 398.628754,74.332367 
	C401.930420,70.270561 402.053558,66.302925 399.062927,64.251083 
	C394.607391,61.194183 392.070343,64.281075 389.558899,67.392944 
	C384.233154,73.992004 378.874725,80.564659 372.999847,87.663284 
M153.908539,459.656097 
	C156.354248,455.229248 158.799942,450.802399 161.736404,445.487244 
	C162.636719,451.852997 163.126495,457.162659 164.267517,462.328491 
	C164.639999,464.014862 166.603409,466.377136 168.030914,466.520447 
	C169.522430,466.670105 171.965424,464.872223 172.730499,463.308319 
	C174.983902,458.702057 176.582336,453.777985 178.495132,449.001587 
	C179.912903,445.461212 181.763641,442.046417 182.716400,438.391846 
	C183.084213,436.980988 181.702713,433.798981 180.770035,433.653351 
	C179.115372,433.394928 176.773468,434.417328 175.502502,435.687653 
	C174.197433,436.992096 173.838852,439.238403 173.050003,441.065643 
	C172.118881,443.222412 171.170578,445.371796 170.229721,447.524384 
	C168.599030,441.788696 168.220154,436.338165 167.147354,431.027802 
	C166.800613,429.311432 164.773499,426.950867 163.255615,426.738434 
	C161.810211,426.536133 159.237366,428.393951 158.524857,429.964966 
	C155.030975,437.668732 151.902313,445.543915 148.829437,453.430511 
	C147.181473,457.660034 148.279602,459.095642 153.908539,459.656097 
M27.117750,273.547302 
	C29.481865,272.031433 32.126705,270.815338 34.142536,268.927551 
	C36.975349,266.274719 39.198219,266.618958 41.902779,268.991913 
	C44.045330,270.871796 46.546700,272.342743 48.888741,273.995270 
	C49.244308,273.713715 49.599873,273.432129 49.955437,273.150543 
	C49.542763,270.932770 49.263618,268.679962 48.693050,266.503540 
	C46.782032,259.214142 46.780212,259.283051 53.276489,255.053589 
	C54.814075,254.052521 56.002117,252.514557 57.350948,251.223557 
	C57.066483,250.798477 56.782017,250.373383 56.497555,249.948303 
	C54.195026,249.948303 51.851452,249.670731 49.598206,250.003952 
	C44.306465,250.786560 41.485180,248.918777 40.925327,243.392365 
	C40.699375,241.161926 39.447723,239.035385 38.662853,236.861572 
	C38.106720,236.906372 37.550587,236.951172 36.994450,236.995972 
	C36.082108,239.756638 34.793091,242.458359 34.349464,245.292404 
	C33.748089,249.134171 31.735851,250.227127 28.169209,249.983551 
	C25.030937,249.769241 21.866457,249.938721 18.713551,249.938721 
	C18.618568,250.484772 18.523584,251.030807 18.428602,251.576859 
	C20.071728,252.900421 21.554916,254.529953 23.388802,255.488571 
	C28.335384,258.074280 29.171215,261.678528 27.207226,266.670898 
	C26.408686,268.700775 26.637642,271.134888 27.117750,273.547302 
M205.450562,457.737396 
	C203.098236,456.218872 200.633118,454.844543 198.436737,453.126648 
	C197.162048,452.129639 196.319199,450.580566 195.282013,449.279907 
	C196.812302,448.534332 198.300674,447.310822 199.884171,447.171906 
	C201.610519,447.020477 203.507874,448.403168 205.177841,448.141510 
	C206.844406,447.880402 208.321701,446.411224 209.881958,445.471649 
	C208.884125,443.998901 208.182037,441.685760 206.833008,441.211029 
	C202.792465,439.789154 198.530640,438.611694 194.289139,438.328644 
	C192.517441,438.210449 189.423569,440.234253 188.913055,441.893860 
	C186.477005,449.813507 184.540573,457.905518 182.880081,466.028961 
	C182.554916,467.619751 183.809998,470.756744 185.068695,471.229218 
	C189.688965,472.963470 194.578384,474.110382 199.458527,474.931000 
	C200.673096,475.135254 203.190216,473.448120 203.379517,472.336029 
	C203.591492,471.090820 202.161057,468.923523 200.866348,468.214142 
	C198.898727,467.136017 196.356476,467.162720 194.211746,466.326599 
	C193.234024,465.945435 192.085083,464.678894 191.986816,463.714783 
	C191.873901,462.606964 192.574982,460.695740 193.397247,460.383453 
	C194.603561,459.925262 196.230072,460.713135 197.679718,460.725983 
	C200.496323,460.751007 204.128769,463.345367 205.450562,457.737396 
M425.915375,401.434326 
	C427.034149,400.205444 428.603699,399.149078 429.156921,397.703766 
	C429.837708,395.924896 429.671112,393.821686 429.870758,391.858673 
	C427.797089,392.203461 425.498657,392.108704 423.715027,393.020721 
	C422.041321,393.876434 421.120117,396.155579 419.465790,397.115631 
	C418.451843,397.704071 416.412811,397.397644 415.352203,396.702576 
	C414.730316,396.295013 414.655914,394.085419 415.165680,393.129059 
	C416.082062,391.409668 418.033295,390.195770 418.771240,388.442108 
	C419.339752,387.090942 419.317719,384.523987 418.457275,383.792236 
	C417.459930,382.944061 414.982758,382.954315 413.652924,383.612427 
	C412.148712,384.356781 411.440125,386.659973 409.944214,387.485352 
	C408.734070,388.153046 406.715057,388.006470 405.374969,387.450104 
	C404.874176,387.242188 404.846161,384.648651 405.381073,383.507874 
	C406.054321,382.072052 408.007965,381.185944 408.532928,379.743713 
	C409.222198,377.850098 409.057098,375.645508 409.259460,373.574646 
	C407.282684,373.812225 404.743744,373.361755 403.441925,374.425415 
	C400.383575,376.924255 397.485016,379.875397 395.397186,383.194183 
	C394.393951,384.788910 394.434570,388.565521 395.613922,389.751160 
	C401.564636,395.733734 407.984406,401.259491 414.371582,406.793213 
	C416.898285,408.982300 419.583710,408.659027 421.861084,406.126831 
	C423.082062,404.769318 424.243958,403.358612 425.915375,401.434326 
M298.644562,475.100891 
	C301.727356,474.410614 304.936462,474.037933 307.835175,472.884827 
	C309.121826,472.372986 309.781494,470.285065 310.725739,468.912537 
	C309.371216,468.002380 308.062378,466.430817 306.651764,466.333374 
	C304.399963,466.177826 302.067474,467.358643 299.794556,467.275848 
	C298.475830,467.227844 297.206818,465.814850 295.915436,465.016113 
	C296.758423,463.697754 297.316315,461.910889 298.516998,461.180298 
	C299.998779,460.278687 302.217651,460.609589 303.736389,459.740814 
	C304.953613,459.044495 306.586090,456.970856 306.282410,456.187622 
	C305.763031,454.847961 304.005096,453.694733 302.514252,453.123230 
	C301.422119,452.704529 299.914368,453.284607 298.610016,453.520142 
	C293.345673,454.470673 292.983337,454.146332 293.402985,448.413757 
	C295.730865,447.853546 298.383240,447.717316 300.461884,446.555023 
	C302.103638,445.637024 303.039978,443.457397 304.289612,441.838074 
	C302.535950,440.977661 300.705627,439.275879 299.044159,439.427246 
	C295.273468,439.770844 291.601501,441.100403 287.860107,441.890411 
	C284.066711,442.691376 283.127014,444.808807 284.036133,448.468323 
	C285.761353,455.412842 287.282471,462.411377 288.737885,469.418701 
	C290.127197,476.107574 290.921631,476.687805 298.644562,475.100891 
M258.341644,447.957977 
	C253.904343,456.529755 256.917053,463.145691 266.163757,465.141235 
	C267.301392,465.386719 268.622620,465.371582 269.522797,465.976929 
	C270.630127,466.721649 272.312988,468.295624 272.092285,468.967834 
	C271.675079,470.238373 270.199371,471.785309 268.941223,472.018646 
	C266.714630,472.431458 264.292145,471.684601 262.009949,471.941376 
	C260.730011,472.085388 258.952576,473.017273 258.527100,474.056122 
	C258.115601,475.060822 259.008636,477.750031 259.572418,477.823273 
	C264.373108,478.446991 269.314545,479.336609 274.043396,478.756683 
	C278.164001,478.251343 280.132599,474.410797 280.626892,470.312897 
	C281.426453,463.683838 278.297455,460.418793 269.727997,457.916321 
	C267.910980,457.385712 266.506927,455.440887 264.912598,454.147583 
	C266.869781,453.392456 268.814362,452.601227 270.794128,451.910858 
	C271.223053,451.761292 271.891479,452.182526 272.256195,451.984558 
	C274.064362,451.002991 275.807800,449.902100 277.574066,448.843292 
	C276.060852,447.325775 274.749512,444.840759 272.996063,444.474915 
	C268.043243,443.441620 263.023499,443.797821 258.341644,447.957977 
M434.282166,361.617096 
	C431.444641,363.298981 428.694794,365.167389 425.723450,366.564728 
	C424.638153,367.075104 423.026550,366.466309 421.655060,366.368073 
	C421.805115,364.997223 421.581177,363.417999 422.199677,362.308197 
	C422.975983,360.915222 424.836792,360.092499 425.501282,358.680847 
	C426.173126,357.253571 426.670349,354.856201 425.930481,353.896423 
	C425.156158,352.891998 422.203613,352.252045 421.281464,352.926697 
	C418.680450,354.829620 416.208130,357.214111 414.463074,359.912018 
	C411.615143,364.315094 412.551636,369.768616 416.069580,373.155396 
	C419.475128,376.434021 424.339508,376.775208 428.936157,373.935394 
	C430.767487,372.804047 432.333252,371.159637 434.266449,370.309113 
	C435.345703,369.834320 437.241394,370.098022 438.181366,370.807617 
	C438.833374,371.299866 439.002136,373.451599 438.473572,374.277374 
	C437.353302,376.027618 435.180786,377.173737 434.296814,378.985687 
	C433.477325,380.665436 433.773254,382.889374 433.578033,384.873688 
	C435.254089,384.895721 437.457031,385.605042 438.501953,384.803925 
	C441.070679,382.834412 443.677307,380.494049 445.250183,377.724457 
	C450.061249,369.252869 444.751038,361.009338 434.282166,361.617096 
M215.713669,450.024658 
	C220.695236,450.697357 221.476288,453.418243 220.522446,458.062775 
	C219.459366,463.239227 219.409943,468.628571 218.994385,473.931152 
	C218.801758,476.389221 218.996292,478.710907 222.258301,479.038757 
	C225.679321,479.382660 226.523392,477.178375 226.830002,474.453094 
	C227.480026,468.675049 228.235962,462.904755 228.697464,457.111298 
	C228.941071,454.053467 229.525406,452.240509 233.307968,452.366516 
	C234.837402,452.417450 236.440140,450.267853 238.009552,449.119659 
	C236.504181,447.672577 235.184235,445.298828 233.458405,444.953491 
	C228.433411,443.947998 223.255402,443.514832 218.119492,443.313263 
	C213.547882,443.133881 212.566422,445.449097 215.713669,450.024658 
M57.740269,353.133392 
	C61.541878,351.090240 65.332138,349.025574 69.149101,347.011505 
	C71.680145,345.675903 74.288223,343.706024 72.295830,340.962402 
	C71.274857,339.556458 67.248665,338.855621 65.498642,339.712006 
	C57.905602,343.427765 50.541691,347.659912 43.366760,352.140350 
	C41.967327,353.014252 41.779453,355.828308 41.032761,357.747467 
	C43.218430,358.147980 45.699799,359.399414 47.525806,358.764923 
	C50.917000,357.586487 53.925785,355.307587 57.740269,353.133392 
M361.475769,105.048035 
	C361.328125,105.113335 361.156799,105.151314 361.040070,105.252327 
	C360.941589,105.337517 360.825684,105.581650 360.855103,105.609337 
	C361.630798,106.339996 362.316803,107.336929 363.244965,107.686996 
	C364.189178,108.043137 365.374939,107.758865 366.454926,107.755013 
	C365.830322,106.865547 365.351837,105.784630 364.528168,105.155983 
	C364.005798,104.757309 362.969696,105.031769 361.475769,105.048035 
M358.412628,104.482666 
	C358.412628,104.482666 358.523102,104.416924 358.412628,104.482666 
z"/>
          <path fill="#FDFFFF" opacity="1.000000" stroke="none"
            d="
M275.000000,1.000000 
	C349.965424,1.000000 424.930817,1.000000 499.948120,1.000000 
	C499.948120,167.222015 499.948120,333.444061 499.948120,500.000000 
	C426.312225,500.000000 352.623962,500.000000 278.563629,499.756470 
	C278.463196,499.066681 278.734833,498.620453 279.380219,498.102692 
	C281.016113,497.931030 282.278229,497.830872 283.813538,497.770874 
	C284.390472,497.626495 284.694244,497.441925 285.421631,497.165588 
	C299.233398,494.030853 312.779083,491.537231 325.955994,487.757965 
	C335.253052,485.091431 344.049805,480.680725 353.069946,477.048737 
	C353.069946,477.048737 353.001648,477.008636 353.392090,477.009460 
	C365.548157,470.358795 377.313782,463.707336 389.079376,457.055908 
	C389.079376,457.055878 389.005188,457.009735 389.375427,456.999207 
	C392.907013,454.839783 396.320068,452.980194 399.183655,450.489014 
	C408.674927,442.231873 417.968292,433.747223 427.337585,425.349915 
	C427.337616,425.349915 427.353912,425.242493 427.577698,425.218079 
	C427.976532,424.896484 428.151550,424.599274 428.326538,424.302063 
	C428.326538,424.302032 428.363159,424.311920 428.683899,424.228302 
	C433.686737,418.772797 438.368896,413.400909 443.051056,408.029053 
	C443.051056,408.029053 442.999420,408.001984 443.320831,407.886780 
	C445.207947,405.635559 446.773651,403.499542 448.339386,401.363525 
	C448.339386,401.363525 448.336761,401.282593 448.613129,401.190796 
	C454.636261,392.219543 460.972931,383.659851 465.997559,374.389008 
	C474.453430,358.787170 481.189819,342.395660 485.240356,324.671326 
	C485.758911,322.962128 486.052399,321.531677 486.440002,319.799957 
	C486.495941,319.090607 486.457764,318.682495 486.419556,318.274414 
	C486.419556,318.274414 486.482544,318.289001 486.793945,318.084900 
	C487.484283,315.924866 487.863220,313.968903 488.337646,311.730591 
	C488.406982,311.061493 488.380768,310.674744 488.354553,310.288025 
	C488.354553,310.288025 488.314331,310.217651 488.621216,309.972504 
	C489.266785,308.448120 489.687988,307.184448 489.931946,305.887421 
	C494.901672,279.468384 496.611511,252.838699 493.597595,226.113251 
	C492.192810,213.656738 489.234528,201.375397 486.976807,188.665512 
	C486.802856,187.887405 486.632599,187.458435 486.543945,186.761047 
	C486.404449,186.001053 486.183319,185.509491 485.955566,184.677719 
	C485.806702,183.910767 485.664490,183.484024 485.586060,182.778534 
	C485.393097,182.014526 485.136322,181.529236 484.873505,180.645142 
	C479.163147,157.818359 470.106201,136.721909 457.908173,117.171288 
	C450.209595,104.832214 440.912628,93.490372 432.332611,81.701218 
	C432.332611,81.701225 432.011597,81.825134 432.065552,81.432999 
	C427.081940,76.020607 422.044373,71.000336 417.006805,65.980072 
	C417.006805,65.980080 416.997009,65.998474 416.915405,65.671768 
	C400.423767,49.979095 381.879608,37.758156 361.840546,27.657644 
	C338.143921,15.713578 313.105682,8.104322 286.716125,3.605172 
	C285.227203,3.276611 284.002228,3.201601 282.398712,3.151556 
	C281.015869,3.069576 280.011505,2.962629 278.918396,2.706850 
	C278.551575,2.628243 278.273560,2.698468 277.678833,2.797038 
	C276.544189,2.623106 275.726257,2.420830 274.938904,1.914493 
	C274.979645,1.406955 274.989807,1.203478 275.000000,1.000000 
z"/>
          <path fill="#FDFFFF" opacity="1.000000" stroke="none"
            d="
M48.953945,106.066444 
	C48.953945,106.066444 48.784817,106.242836 48.487232,106.522743 
	C47.044292,108.792122 45.898937,110.781593 44.753586,112.771065 
	C44.753586,112.771065 44.875435,112.786293 44.511612,112.906494 
	C28.889320,136.541168 18.321131,162.138245 12.163464,189.349899 
	C8.774323,204.327042 7.506459,219.784180 5.178008,235.395416 
	C5.094217,236.531128 5.114631,237.296371 4.936600,238.408295 
	C4.841913,247.496597 4.945671,256.238190 4.998349,265.424072 
	C5.107545,268.240479 5.267820,270.612640 5.337000,273.421692 
	C6.720635,293.835968 9.902728,313.559021 16.413132,332.501495 
	C20.547342,344.530243 26.168249,356.048096 31.122456,367.795044 
	C31.122459,367.795044 31.065435,367.883728 31.094913,368.145142 
	C31.232595,368.754089 31.460945,368.980408 31.898521,369.433502 
	C41.435600,388.079956 53.553020,404.529053 67.456566,419.606079 
	C88.988266,442.954987 114.234833,461.369110 142.856583,475.207428 
	C164.682938,485.760284 187.788040,492.170685 211.412933,496.973206 
	C217.239609,498.157684 223.135773,499.000275 229.000000,500.000000 
	C153.034805,500.000000 77.069611,500.000000 1.052205,500.000000 
	C1.052205,333.777649 1.052205,167.555283 1.052205,1.000000 
	C75.687767,1.000000 150.376038,1.000000 225.407227,1.368600 
	C222.929596,2.214313 220.109039,2.691425 216.903168,3.107222 
	C215.346634,3.337821 214.175369,3.629735 212.712402,3.996786 
	C212.020691,4.090284 211.620712,4.108646 210.867737,4.071723 
	C209.678345,4.318761 208.841965,4.621083 207.705353,4.993743 
	C206.995361,5.075176 206.585602,5.086273 205.800934,5.046781 
	C204.288300,5.300023 203.150574,5.603855 201.697937,5.990148 
	C200.741364,6.142582 200.099686,6.212555 199.117599,6.207104 
	C198.186295,6.401600 197.595383,6.671521 196.794266,7.011690 
	C196.584045,7.081938 196.140762,7.078776 195.797928,7.035897 
	C194.640244,7.283679 193.825378,7.574339 192.696945,7.970997 
	C191.948807,8.151784 191.514252,8.226575 190.947983,8.034004 
	C190.198914,8.128805 189.581543,8.490969 188.744934,8.926380 
	C188.525711,8.999627 188.064331,9.028800 187.725632,9.001381 
	C186.594711,9.285770 185.802505,9.597577 184.741470,10.009788 
	C184.092834,10.140170 183.713028,10.170149 182.998505,10.144486 
	C182.107361,10.393562 181.550919,10.698279 180.813705,11.074516 
	C180.632950,11.146037 180.244400,11.132298 179.809509,11.079807 
	C174.833710,12.296472 170.224228,13.369286 165.781754,14.920398 
	C163.666916,15.658803 161.870010,17.307718 159.621277,18.695601 
	C158.532013,19.144905 157.750534,19.442795 156.725342,19.512957 
	C155.317871,19.851152 154.154114,20.417080 152.994980,20.991879 
	C152.999603,21.000750 152.982971,20.988190 152.687286,20.991646 
	C151.927383,21.308863 151.463181,21.622623 150.836243,22.037289 
	C150.673523,22.138197 150.291183,22.159773 149.950256,22.115116 
	C149.088928,22.431049 148.568558,22.791639 147.845825,23.376053 
	C146.764206,24.088465 145.884949,24.577053 144.677826,25.062647 
	C143.324509,25.417217 142.299042,25.774782 141.001938,26.155403 
	C140.475876,26.424097 140.221451,26.669733 139.970276,26.954203 
	C139.973541,26.993036 139.913742,26.943043 139.627670,26.958132 
	C138.892014,27.284676 138.442444,27.596128 137.834045,28.002907 
	C137.675247,28.098232 137.308182,28.147915 137.033340,28.170818 
	C136.501175,28.442787 136.243866,28.691853 135.826828,29.030708 
	C135.667114,29.120497 135.302704,29.159105 134.911865,29.108938 
	C133.026855,30.058176 131.532715,31.057579 130.025391,32.025761 
	C130.012207,31.994539 130.062790,32.039867 129.841965,32.072090 
	C129.331039,32.222820 129.121552,32.424881 128.806000,32.844658 
	C128.619354,32.978828 128.175201,33.097523 127.809128,33.065304 
	C126.624985,33.682911 125.806915,34.332737 124.994385,34.991508 
	C124.999924,35.000458 124.983223,34.986767 124.680283,34.995708 
	C123.925690,35.354931 123.474045,35.705212 123.013168,36.027328 
	C123.003944,35.999161 123.053261,36.032070 122.825760,36.085609 
	C122.305595,36.287025 122.102989,36.514954 121.827599,36.934105 
	C121.664749,37.045280 121.280815,37.135418 121.002792,37.176613 
	C120.481781,37.490696 120.238808,37.763584 119.871590,38.123047 
	C119.747353,38.209625 119.444542,38.215187 119.133080,38.243198 
	C118.575760,38.585976 118.329910,38.900742 117.935806,39.400261 
	C117.533478,39.785469 117.246666,39.911297 116.631096,39.984596 
	C115.899147,40.363750 115.463211,40.720818 114.803635,41.326988 
	C110.752724,44.402782 106.925468,47.229473 103.070282,50.045135 
	C103.042366,50.034111 103.061523,50.089897 102.722382,50.138580 
	C98.475647,53.404530 94.568047,56.621788 90.474480,59.898693 
	C90.288513,59.958336 90.027847,60.249229 89.757362,60.308239 
	C89.220505,60.835068 88.954140,61.302887 88.657188,61.761833 
	C88.626602,61.752960 88.563339,61.745655 88.235336,61.810585 
	C84.643394,64.937706 81.379456,67.999901 78.074577,71.047821 
	C78.033646,71.033562 78.060883,71.115868 77.785370,71.216034 
	C77.241982,71.813766 76.974106,72.311325 76.705811,72.774155 
	C76.705383,72.739418 76.636200,72.745766 76.334663,72.826431 
	C74.604752,74.449432 73.176376,75.991768 71.466370,77.666092 
	C70.414207,78.732498 69.643684,79.666916 68.600342,80.724960 
	C68.148956,81.186470 67.970383,81.524353 67.836357,81.834480 
	C67.880905,81.806732 67.777855,81.786774 67.471924,81.883057 
	C66.277817,83.174644 65.389633,84.369942 64.202660,85.706100 
	C58.920559,92.586777 53.937252,99.326614 48.953945,106.066444 
z"/>
          <path fill="#2296F2" opacity="1.000000" stroke="none"
            d="
M242.000000,0.999997 
	C245.362106,1.000000 248.724197,1.000000 252.766937,1.268902 
	C256.115479,2.070114 258.813049,2.487164 261.443695,3.164179 
	C264.663971,3.992938 267.828247,5.039459 270.645630,5.990144 
	C269.532379,5.981473 268.790192,5.974481 267.925995,5.760729 
	C267.598450,5.290803 267.332733,5.122860 266.578918,5.037150 
	C261.740753,4.687925 257.425507,5.168343 256.243256,10.243117 
	C255.054535,15.345724 254.683517,20.642017 254.009964,25.860636 
	C253.155487,32.480995 252.357254,39.108612 251.702271,44.384136 
	C245.486069,44.218185 239.907562,44.381161 234.425781,43.729881 
	C232.813889,43.538380 230.419479,41.183109 230.200928,39.572838 
	C229.381241,33.533920 229.868164,27.294102 228.803558,21.318760 
	C227.789200,15.625533 230.500671,8.573708 223.348862,4.909865 
	C228.591782,4.484586 233.482849,4.255790 238.312820,3.586227 
	C239.635956,3.402807 240.775955,1.898275 242.000000,0.999997 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M268.048035,5.967488 
	C268.790192,5.974481 269.532379,5.981473 271.041931,6.045005 
	C274.874207,6.932987 277.939056,7.764429 280.599365,8.626889 
	C274.915283,8.046474 269.647034,7.293418 264.352356,6.873112 
	C260.788391,6.590195 258.261566,7.995885 257.840424,12.046227 
	C256.552704,24.430494 255.211960,36.809540 253.819489,49.182472 
	C253.312073,53.691250 255.514374,55.560612 259.716034,55.950409 
	C264.837799,56.425564 269.963226,56.929790 275.054047,57.653580 
	C282.309631,58.685150 284.337585,56.475391 282.330139,49.427773 
	C286.957703,49.736324 285.175598,53.586357 284.726379,55.303055 
	C284.247864,57.131607 281.782990,59.623833 280.068542,59.718887 
	C275.328979,59.981647 270.526276,59.169983 265.753174,58.732372 
	C263.783783,58.551811 261.711853,58.564606 259.888794,57.918697 
	C256.753265,56.807804 253.776245,55.249603 250.298553,53.683662 
	C248.520081,57.912075 243.056686,57.328720 238.383087,57.837849 
	C233.284012,58.393322 228.079926,57.999866 222.921646,57.992226 
	C219.365967,57.986958 217.385376,56.274414 217.018127,52.646885 
	C215.720291,39.827496 214.266174,27.022690 213.123398,14.189905 
	C212.902359,11.707733 213.816620,9.124457 214.348831,5.667785 
	C209.739746,6.533667 205.193359,7.387770 201.690689,8.045797 
	C204.559189,20.684637 207.274261,32.641937 209.986099,44.599972 
	C210.680267,47.661049 211.796585,50.709953 211.900009,53.791935 
	C211.968445,55.830807 210.576797,57.918682 209.408356,59.979176 
	C208.977875,59.973728 208.798706,59.793030 208.961853,59.420937 
	C209.409485,56.650932 210.334229,54.120937 209.871353,51.877201 
	C207.421127,40.000111 204.621704,28.195057 201.943817,16.364916 
	C201.149231,12.854619 199.305252,10.407131 195.367981,11.243842 
	C191.427612,12.081215 190.777573,14.992383 191.544876,18.563171 
	C192.556107,23.269129 193.435379,28.003439 194.251251,32.816948 
	C194.131119,32.908333 193.893188,33.094154 193.893188,33.094154 
	C191.093994,26.337511 187.394714,19.724407 190.862762,10.766733 
	C183.737900,12.585890 177.180450,14.260172 170.370895,15.576742 
	C173.494003,13.856789 176.869202,12.494546 180.244400,11.132300 
	C180.244400,11.132298 180.632950,11.146037 181.137085,11.101612 
	C182.205215,10.771500 182.769211,10.485813 183.333206,10.200127 
	C183.713028,10.170149 184.092834,10.140170 185.076141,10.044647 
	C186.474533,9.662336 187.269440,9.345570 188.064331,9.028801 
	C188.064331,9.028800 188.525711,8.999627 189.032425,8.974380 
	C190.052658,8.733212 190.566177,8.517289 191.079697,8.301367 
	C191.514252,8.226575 191.948807,8.151784 193.032257,8.012831 
	C194.501007,7.658704 195.320892,7.368740 196.140762,7.078776 
	C196.140762,7.078776 196.584045,7.081938 197.122055,7.044793 
	C198.259384,6.765943 198.858688,6.524238 199.458008,6.282530 
	C200.099686,6.212555 200.741364,6.142582 202.060211,6.053207 
	C203.883530,5.721660 205.029678,5.409515 206.175842,5.097370 
	C206.585602,5.086273 206.995361,5.075176 208.047211,5.039488 
	C209.533112,4.718934 210.376923,4.422973 211.220734,4.127009 
	C211.620712,4.108646 212.020691,4.090284 213.083939,4.054837 
	C214.927628,3.748012 216.108047,3.458273 217.288467,3.168536 
	C220.109039,2.691425 222.929596,2.214313 225.875092,1.368600 
	C231.022095,1.000000 236.044189,1.000000 241.533142,0.999997 
	C240.775955,1.898275 239.635956,3.402807 238.312820,3.586227 
	C233.482849,4.255790 228.591782,4.484586 223.348862,4.909865 
	C230.500671,8.573708 227.789200,15.625533 228.803558,21.318760 
	C229.868164,27.294102 229.381241,33.533920 230.200928,39.572838 
	C230.419479,41.183109 232.813889,43.538380 234.425781,43.729881 
	C239.907562,44.381161 245.486069,44.218185 251.702271,44.384136 
	C252.357254,39.108612 253.155487,32.480995 254.009964,25.860636 
	C254.683517,20.642017 255.054535,15.345724 256.243256,10.243117 
	C257.425507,5.168343 261.740753,4.687925 266.693298,5.248702 
	C267.446838,5.735837 267.717651,5.900584 268.048035,5.967488 
M241.391068,55.160446 
	C241.889618,55.132420 242.388229,55.078854 242.886688,55.080486 
	C246.265335,55.091541 248.532532,53.392300 248.481628,50.078072 
	C248.425919,46.452000 245.624512,45.633549 242.442917,45.789474 
	C239.617218,45.927952 236.744583,45.704235 233.968994,46.133678 
	C229.501999,46.824814 228.119217,44.943855 227.953873,40.709717 
	C227.597137,31.575529 226.804810,22.459188 226.290207,13.329936 
	C226.072540,9.468630 224.707184,6.792822 220.368225,7.008101 
	C215.629044,7.243235 215.398911,10.732338 215.669678,14.214587 
	C216.623169,26.476450 217.855484,38.718868 218.640015,50.990528 
	C218.952316,55.875622 221.629822,56.778748 225.594467,56.473743 
	C230.569107,56.091042 235.539108,55.648266 241.391068,55.160446 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M229.468414,500.000000 
	C223.135773,499.000275 217.239609,498.157684 211.412933,496.973206 
	C187.788040,492.170685 164.682938,485.760284 142.856583,475.207428 
	C114.234833,461.369110 88.988266,442.954987 67.456566,419.606079 
	C53.553020,404.529053 41.435600,388.079956 32.207230,369.405640 
	C32.632580,369.010315 32.838306,368.990784 33.044037,368.971252 
	C36.917488,375.117554 40.567036,381.419220 44.704475,387.382355 
	C59.897751,409.279816 77.014900,429.421692 98.955254,444.957977 
	C109.169029,452.190521 119.022949,460.189758 130.013184,466.000916 
	C143.335709,473.045258 157.602219,478.354675 171.627716,483.998688 
	C179.085556,486.999817 186.802979,489.483765 194.583542,491.508026 
	C199.417679,492.765717 204.590103,492.719147 209.608887,493.274292 
	C210.576797,493.381317 211.534729,493.575592 212.500015,493.709869 
	C222.771423,495.138519 233.046555,496.540955 243.312271,498.009399 
	C245.165100,498.274445 246.979767,498.806244 248.906036,499.607422 
	C242.645599,500.000000 236.291214,500.000000 229.468414,500.000000 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M281.003906,8.595873 
	C277.939056,7.764429 274.874207,6.932987 271.413025,6.046683 
	C267.828247,5.039459 264.663971,3.992938 261.443695,3.164179 
	C258.813049,2.487164 256.115479,2.070114 253.223785,1.268902 
	C256.361298,1.000000 259.722565,1.000000 263.779236,1.344529 
	C267.952545,1.865558 271.430420,2.042057 274.908325,2.218555 
	C275.726257,2.420830 276.544189,2.623106 277.926208,2.883504 
	C278.662567,2.912978 278.834869,2.884331 279.007172,2.855682 
	C280.011505,2.962629 281.015869,3.069576 282.691101,3.374772 
	C284.568024,3.668255 285.774048,3.763490 286.980072,3.858723 
	C313.105682,8.104322 338.143921,15.713578 361.840546,27.657644 
	C381.879608,37.758156 400.423767,49.979095 416.925232,65.670303 
	C415.441925,65.253098 413.598511,64.820320 412.332672,63.721924 
	C392.159302,46.217812 369.604126,32.619877 345.141327,21.994499 
	C327.499573,14.331846 308.931152,10.068399 290.202362,6.351676 
	C288.764557,6.066344 286.964935,7.604182 284.915039,8.645585 
	C284.318573,9.008752 284.140533,9.020926 283.979065,9.015215 
	C283.995636,8.997329 283.976776,9.041305 283.912964,8.870211 
	C283.574615,8.756768 283.300079,8.814419 282.679230,8.911301 
	C281.889923,8.832314 281.446930,8.714094 281.003906,8.595873 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M283.540375,497.730713 
	C282.278229,497.830872 281.016113,497.931030 278.947083,498.036987 
	C271.426819,498.695190 264.713409,499.347595 258.000000,500.000000 
	C256.933319,500.000000 255.866653,500.000000 254.399994,500.000000 
	C254.788300,499.342590 255.531769,498.179993 256.371796,498.105469 
	C265.412201,497.303223 274.512970,497.027344 283.504883,495.882294 
	C290.665466,494.970459 297.843353,493.542114 304.757416,491.482941 
	C318.895691,487.272186 332.853851,482.458496 346.908722,477.963501 
	C348.611206,477.419037 350.452545,477.308807 352.649719,477.023010 
	C344.049805,480.680725 335.253052,485.091431 325.955994,487.757965 
	C312.779083,491.537231 299.233398,494.030853 285.151581,497.130066 
	C284.152039,497.367737 283.846222,497.549225 283.540375,497.730713 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M258.468384,500.000000 
	C264.713409,499.347595 271.426819,498.695190 278.573364,498.108521 
	C278.734833,498.620453 278.463196,499.066681 278.095764,499.756470 
	C271.645599,500.000000 265.291199,500.000000 258.468384,500.000000 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M274.938904,1.914495 
	C271.430420,2.042057 267.952545,1.865558 264.237305,1.344529 
	C267.361298,1.000000 270.722565,1.000000 274.541931,1.000000 
	C274.989807,1.203478 274.979645,1.406955 274.938904,1.914495 
z"/>
          <path fill="#F3FBFD" opacity="1.000000" stroke="none"
            d="
M379.216034,114.346970 
	C380.093323,111.555870 380.448456,108.338905 382.240875,106.835243 
	C391.014771,99.474808 400.124969,92.510155 409.211884,85.529991 
	C414.054169,81.810356 416.267761,82.126411 420.175354,86.796906 
	C422.413208,89.471642 424.527527,92.255516 426.595856,95.065056 
	C430.375580,100.199272 432.079498,105.614326 428.910278,111.784302 
	C425.919617,117.606613 420.925110,118.790657 414.778442,117.950912 
	C414.500366,118.980652 414.133575,119.865372 414.034363,120.779144 
	C412.991455,130.384613 404.534485,135.506348 395.570465,131.747192 
	C387.730652,128.459473 383.778900,121.328049 379.216034,114.346970 
M401.201111,110.821602 
	C401.201111,110.821602 401.217560,110.710320 401.294800,109.994949 
	C398.454559,105.624191 396.695435,109.849548 394.656891,110.916855 
	C390.909363,112.878891 391.634155,115.449669 393.997711,118.068214 
	C396.489288,120.828598 398.997742,123.917206 403.187622,121.052811 
	C407.052246,118.410767 406.243530,114.362587 401.201111,110.821602 
M415.664551,96.814514 
	C412.919952,97.891151 410.175354,98.967789 406.522552,100.400696 
	C409.226166,103.625732 410.561096,106.200813 412.676880,107.413055 
	C414.209320,108.291054 417.337708,108.075737 418.700623,107.005745 
	C419.734253,106.194252 419.538666,103.160362 419.081604,101.315697 
	C418.719604,99.854500 417.130615,98.697289 415.664551,96.814514 
z"/>
          <path fill="#F3FBFD" opacity="1.000000" stroke="none"
            d="
M172.978333,21.096146 
	C173.376862,21.120451 173.775375,21.144756 174.621994,21.448593 
	C181.990585,26.339380 184.374496,32.300503 179.924026,40.378265 
	C187.059006,41.718327 191.028076,45.364697 191.337982,52.543629 
	C191.658478,59.968197 187.406479,64.208183 181.646408,67.483673 
	C179.221008,68.862892 176.553741,69.816833 173.617706,70.998932 
	C170.491745,71.685097 167.744507,72.336418 164.997269,72.987747 
	C164.997269,72.987747 165.002106,73.003799 164.779541,72.744484 
	C163.344467,70.787354 161.830276,69.227859 160.976471,67.365585 
	C158.499573,61.963028 156.287689,56.438969 153.971802,50.962585 
	C153.826599,50.422943 153.681396,49.883297 153.294708,48.784767 
	C151.023392,43.153301 148.993576,38.080727 146.963776,33.008148 
	C146.994995,32.564381 147.026215,32.120617 147.237762,31.114845 
	C147.674057,29.729507 147.930023,28.906178 148.435059,27.944817 
	C149.443695,27.261604 150.203232,26.716423 151.301697,26.164518 
	C152.208542,25.818846 152.776489,25.479897 153.520142,25.154825 
	C153.695847,25.168705 154.000458,24.991325 154.308395,24.965378 
	C154.925262,24.678555 155.234192,24.417681 155.806732,24.174397 
	C156.392578,24.055653 156.714844,23.919323 157.313629,23.785288 
	C157.884216,23.575052 158.178268,23.362520 158.843109,23.141226 
	C163.802048,22.453690 168.390198,21.774918 172.978333,21.096146 
M161.518250,40.625046 
	C165.503815,42.444004 168.471634,40.289333 170.936905,37.716209 
	C171.756424,36.860828 171.774551,34.411068 171.145691,33.229568 
	C170.529938,32.072689 168.653366,31.002102 167.278732,30.934090 
	C160.984299,30.622669 159.050186,33.633018 161.518250,40.625046 
M178.981461,49.438820 
	C176.004807,49.495972 172.963547,49.212982 170.080048,49.760628 
	C168.734772,50.016125 166.502884,52.278759 166.725723,52.945095 
	C167.628601,55.644897 169.127914,60.006191 170.779953,60.178406 
	C173.580414,60.470341 177.102036,58.565258 179.432999,56.554733 
	C180.521774,55.615623 179.536606,52.272060 178.981461,49.438820 
z"/>
          <path fill="#F2FAFD" opacity="1.000000" stroke="none"
            d="
M357.564117,34.518215 
	C357.758453,37.020481 358.417999,39.549877 357.571198,41.383656 
	C352.349579,52.690918 346.759613,63.827530 341.356628,75.051926 
	C339.636719,78.624947 336.956451,80.315247 333.321411,78.502945 
	C329.893616,76.793968 329.639832,73.699471 331.301758,70.377205 
	C332.192657,68.596291 332.907745,66.727928 333.783417,64.938805 
	C337.269714,57.815670 337.151001,58.068600 330.209198,54.401833 
	C326.382080,52.380302 324.481628,53.245235 323.018646,56.942886 
	C321.982697,59.561165 320.746094,62.102436 319.542816,64.651527 
	C318.038177,67.838982 315.871887,69.937897 312.063660,68.381058 
	C308.198639,66.800987 308.186401,63.373516 309.320099,60.363979 
	C311.187836,55.405903 313.690552,50.683567 315.987000,45.891273 
	C319.145447,39.300011 322.428101,32.767509 325.525116,26.147865 
	C327.172241,22.627237 329.524109,20.707241 333.405579,22.350286 
	C337.442963,24.059347 337.352142,27.265259 335.802490,30.691236 
	C334.500580,33.569439 333.181030,36.446903 331.699249,39.234325 
	C330.348907,41.774616 330.678802,43.399994 333.382812,44.865742 
	C341.178101,49.091351 341.434753,49.089901 345.260162,41.137314 
	C345.907990,39.790600 346.477814,38.406464 347.121674,37.057762 
	C349.652527,31.756304 352.088135,31.085300 357.564117,34.518215 
z"/>
          <path fill="#F2FAFD" opacity="1.000000" stroke="none"
            d="
M373.264771,87.405716 
	C378.874725,80.564659 384.233154,73.992004 389.558899,67.392944 
	C392.070343,64.281075 394.607391,61.194183 399.062927,64.251083 
	C402.053558,66.302925 401.930420,70.270561 398.628754,74.332367 
	C393.072235,81.168106 387.502930,88.001793 381.693512,94.620880 
	C377.361206,99.556984 371.898743,102.278656 365.210999,100.072784 
	C357.844238,97.642952 351.674408,93.095413 350.995819,85.041451 
	C350.616302,80.537460 352.459320,75.182671 354.943115,71.247894 
	C359.266418,64.398911 364.849121,58.342960 369.933594,51.978333 
	C370.761597,50.941875 371.662872,49.934460 372.668243,49.074978 
	C375.306274,46.819698 378.265747,46.523964 380.758118,48.973907 
	C383.052734,51.229424 383.016968,54.026154 380.902039,56.618919 
	C375.437164,63.318649 369.834625,69.913452 364.595917,76.785606 
	C363.089142,78.762192 361.794952,81.563522 361.863770,83.944534 
	C362.020966,89.386505 367.805450,91.155769 373.264771,87.405716 
z"/>
          <path fill="#F3FBFD" opacity="1.000000" stroke="none"
            d="
M280.599365,8.626890 
	C281.446930,8.714094 281.889923,8.832314 282.908020,9.004162 
	C283.483093,9.057791 283.976776,9.041305 283.976776,9.041305 
	C283.976776,9.041305 283.995636,8.997329 284.122375,9.185839 
	C284.249115,9.374350 284.649933,9.568813 284.649933,9.568813 
	C284.649933,9.568813 285.090668,9.503813 285.090668,9.503813 
	C285.213226,9.625774 285.335785,9.747736 285.847992,10.331046 
	C286.462677,12.845955 286.687714,14.899515 286.912720,16.953075 
	C286.912720,16.953077 286.986542,16.989977 286.561096,17.053249 
	C280.299133,17.116524 274.462585,17.116524 268.482452,17.116524 
	C268.137390,18.875723 268.044556,20.206863 267.608704,21.414148 
	C266.148193,25.459732 267.576385,27.361034 271.784637,27.549809 
	C274.104156,27.653862 276.762726,27.344576 278.635773,28.373413 
	C280.774963,29.548435 283.361481,31.825676 283.688904,33.943192 
	C284.208282,37.302261 280.843475,37.955963 278.079437,37.886250 
	C275.441254,37.819714 272.797546,37.420307 270.185852,36.988396 
	C266.524384,36.382896 264.877441,37.889076 264.965637,41.524731 
	C265.004791,43.138180 264.972260,44.753365 264.972260,46.536514 
	C270.949677,47.470631 276.479553,48.334805 282.009399,49.198975 
	C282.009399,49.198975 282.124451,49.379723 282.227295,49.403748 
	C284.337585,56.475391 282.309631,58.685150 275.054047,57.653580 
	C269.963226,56.929790 264.837799,56.425564 259.716034,55.950409 
	C255.514374,55.560612 253.312073,53.691250 253.819489,49.182472 
	C255.211960,36.809540 256.552704,24.430494 257.840424,12.046227 
	C258.261566,7.995885 260.788391,6.590195 264.352356,6.873112 
	C269.647034,7.293418 274.915283,8.046474 280.599365,8.626890 
z"/>
          <path fill="#3F97D3" opacity="1.000000" stroke="none"
            d="
M402.101715,160.904724 
	C403.322693,162.125137 404.980316,163.134308 405.690247,164.601929 
	C409.053162,171.553970 412.108185,178.654282 415.396667,185.643539 
	C416.027130,186.983521 417.213226,188.062103 419.196014,190.619415 
	C421.317322,167.325485 432.851501,155.490234 455.013153,158.539169 
	C466.999573,160.188232 476.542450,171.597321 477.127289,184.005875 
	C477.970581,201.898621 469.114197,210.950073 454.956421,215.494537 
	C450.606995,216.890640 444.937439,215.803894 440.345703,214.354553 
	C435.797455,212.918945 431.209961,210.241150 427.812225,206.911621 
	C424.419617,203.587128 422.480530,198.779327 419.496796,193.964371 
	C420.472198,199.101532 421.500000,203.588226 422.152130,208.128891 
	C423.863525,220.044479 426.215515,231.944092 426.803864,243.924149 
	C427.425293,256.576935 427.908417,269.624054 425.540344,281.938995 
	C422.518250,297.655396 418.845337,313.547638 410.772583,327.840881 
	C405.892487,336.481323 402.026398,345.810150 396.303223,353.829529 
	C389.113037,363.904449 380.514008,372.977539 372.424896,382.398834 
	C371.920258,382.986603 370.563416,382.842712 369.319489,382.725586 
	C369.990509,381.265167 370.945129,380.118469 372.180237,378.863708 
	C372.971130,378.156097 373.481506,377.556580 374.244568,376.903778 
	C374.656036,376.567657 374.814911,376.284882 375.086243,375.897522 
	C375.198761,375.792908 375.188568,375.485870 375.514771,375.362793 
	C377.593079,373.181824 379.345215,371.123901 381.059265,369.032623 
	C381.021149,368.999268 381.068512,369.088715 381.381409,368.970673 
	C383.181458,366.939575 384.668640,365.026550 386.121033,363.079376 
	C386.086243,363.045258 386.082397,363.142639 386.333801,363.048340 
	C386.746002,362.645813 386.906799,362.337616 387.035583,362.018066 
	C387.003540,362.006714 387.033142,362.067902 387.271179,361.973572 
	C387.666260,361.584534 387.823303,361.289795 387.990784,360.998138 
	C388.001221,361.001221 387.991486,360.982178 388.298340,360.852478 
	C390.082336,358.490570 391.559479,356.258392 393.018311,354.012787 
	C393.000000,353.999359 393.022644,354.038513 393.249359,353.936646 
	C393.773560,353.628143 393.920319,353.343781 393.951904,352.984802 
	C393.987427,352.987946 393.963287,352.920807 394.248444,352.764221 
	C395.370819,351.082489 396.208008,349.557373 397.023468,348.015411 
	C397.001709,347.998566 397.035187,348.041595 397.328033,347.885315 
	C398.474976,346.196503 399.329041,344.663940 400.148132,343.098145 
	C400.113190,343.064880 400.100037,343.160461 400.346375,343.063446 
	C400.712982,342.646362 400.833252,342.326294 401.058716,341.882080 
	C401.163879,341.757965 401.144287,341.433167 401.378845,341.257050 
	C401.738251,340.720978 401.863098,340.361023 402.089722,339.888000 
	C402.191498,339.774902 402.150421,339.473419 402.399780,339.301422 
	C402.789398,338.762909 402.929657,338.396423 403.034668,338.020569 
	C402.999481,338.011230 403.052155,338.061584 403.315033,337.849701 
	C404.422394,335.783478 405.266907,333.929169 406.078247,332.033234 
	C406.045135,331.991638 406.078918,332.092438 406.343536,331.917603 
	C406.790161,331.151886 406.972137,330.560944 407.233368,329.860352 
	C407.312622,329.750641 407.192383,329.508179 407.475342,329.274933 
	C408.883392,326.391052 410.008453,323.740417 411.106934,321.041809 
	C411.080353,320.993805 411.094269,321.102631 411.369873,320.915894 
	C412.071808,319.484467 412.498169,318.239777 413.027649,316.819946 
	C413.130737,316.644745 413.103363,316.239166 413.359924,315.980652 
	C414.450958,313.142090 415.285461,310.562042 416.203186,307.866608 
	C416.286438,307.751221 416.173676,307.489960 416.434814,307.200989 
	C422.689209,289.820953 425.712982,272.149872 425.622833,254.109161 
	C425.570892,243.719772 424.003784,233.337952 423.152008,222.615860 
	C423.071594,221.845230 422.957947,221.411453 422.892822,220.588837 
	C422.324615,217.787384 421.707947,215.374786 421.103638,212.573669 
	C420.938049,211.432434 420.760071,210.679703 420.631165,209.690613 
	C420.617310,209.109497 420.429321,208.858200 420.148071,208.517181 
	C420.179901,208.333954 420.009674,208.003281 420.046509,207.602707 
	C417.409698,199.444061 414.736023,191.686005 412.036682,183.960358 
	C412.010956,183.992783 412.089386,183.966339 412.101074,183.658066 
	C411.773315,182.864441 411.433807,182.379089 411.067139,181.936417 
	C411.039917,181.979095 411.134247,181.942383 411.131989,181.588577 
	C410.436401,179.800919 409.743103,178.367065 409.027344,176.961761 
	C409.004913,176.990326 409.076080,176.975616 409.068909,176.656189 
	C408.742401,175.811874 408.423096,175.287018 408.076050,174.496597 
	C407.750519,173.803787 407.452667,173.376541 407.100525,172.633713 
	C406.754974,172.017365 406.463715,171.716599 406.206177,171.158768 
	C406.094574,170.591782 405.949219,170.281860 405.803833,169.716888 
	C405.562744,169.233215 405.321655,169.004562 405.054810,168.554871 
	C405.029083,168.333817 404.774689,167.968628 404.771210,167.730164 
	C404.635498,167.166260 404.396179,166.974228 404.035217,166.956451 
	C404.020660,166.997299 404.087128,166.941559 404.054626,166.612106 
	C403.334991,165.193329 402.647888,164.104019 401.940125,162.726593 
	C401.844574,162.061295 401.769714,161.684128 401.796112,161.205963 
	C401.897400,161.104965 402.101715,160.904724 402.101715,160.904724 
M473.066498,183.397232 
	C472.910004,182.898712 472.753479,182.400177 473.121735,181.528534 
	C473.222137,181.223358 473.442017,180.903107 473.405731,180.615173 
	C471.992950,169.412537 460.753265,160.911804 447.671936,161.130936 
	C436.146576,161.324005 424.154053,171.274185 423.308441,181.546768 
	C423.219971,182.621231 424.419189,183.801697 424.775696,185.466736 
	C424.702332,185.647842 424.628967,185.828934 424.225739,186.477524 
	C424.340912,187.668091 424.456055,188.858673 424.807404,190.653214 
	C424.866486,190.861130 424.925598,191.069046 424.988892,191.532150 
	C424.988892,191.532150 425.012970,192.016708 424.445038,192.485977 
	C424.489227,194.301498 424.021088,196.323639 424.657013,197.900528 
	C430.906250,213.396347 452.535217,218.795212 465.265869,207.866287 
	C471.266327,202.715042 476.134766,196.619629 473.904755,187.108887 
	C473.638062,186.134430 473.371399,185.159988 473.066498,183.397232 
z"/>
          <path fill="#EBF8FD" opacity="1.000000" stroke="none"
            d="
M153.543884,459.767151 
	C148.279602,459.095642 147.181473,457.660034 148.829437,453.430511 
	C151.902313,445.543915 155.030975,437.668732 158.524857,429.964966 
	C159.237366,428.393951 161.810211,426.536133 163.255615,426.738434 
	C164.773499,426.950867 166.800613,429.311432 167.147354,431.027802 
	C168.220154,436.338165 168.599030,441.788696 170.229721,447.524384 
	C171.170578,445.371796 172.118881,443.222412 173.050003,441.065643 
	C173.838852,439.238403 174.197433,436.992096 175.502502,435.687653 
	C176.773468,434.417328 179.115372,433.394928 180.770035,433.653351 
	C181.702713,433.798981 183.084213,436.980988 182.716400,438.391846 
	C181.763641,442.046417 179.912903,445.461212 178.495132,449.001587 
	C176.582336,453.777985 174.983902,458.702057 172.730499,463.308319 
	C171.965424,464.872223 169.522430,466.670105 168.030914,466.520447 
	C166.603409,466.377136 164.639999,464.014862 164.267517,462.328491 
	C163.126495,457.162659 162.636719,451.852997 161.736404,445.487244 
	C158.799942,450.802399 156.354248,455.229248 153.543884,459.767151 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M118.959351,128.927948 
	C119.407166,127.588715 119.479134,125.892609 120.360184,124.964729 
	C130.697662,114.077812 142.588425,105.206490 155.304443,97.212379 
	C168.185944,89.114227 181.789688,82.560509 196.332764,78.694290 
	C208.882111,75.358101 221.932617,73.475998 234.884628,72.220016 
	C245.529007,71.187798 256.435181,71.200180 267.076202,72.254570 
	C277.692444,73.306519 288.225098,75.829300 298.601654,78.465843 
	C306.265350,80.413086 313.890411,83.000420 321.040894,86.351852 
	C329.400818,90.270149 337.237854,95.308357 345.277924,99.902390 
	C348.006012,101.461212 350.661682,103.146782 353.835022,105.158493 
	C354.862091,105.725075 355.404663,105.907593 355.947205,106.090111 
	C359.902008,109.706764 363.939484,113.237907 367.790741,116.961647 
	C372.634613,121.645119 377.477478,126.347343 382.027924,131.310013 
	C384.865967,134.405136 387.163269,137.996124 389.680420,141.851593 
	C389.657623,142.338577 389.332184,142.661057 389.108551,142.479370 
	C388.277130,141.840164 387.669342,141.382645 387.037231,140.964508 
	C387.012939,141.003891 387.081940,140.941849 387.024536,140.611481 
	C385.333649,138.495911 383.700195,136.710724 382.031921,134.952484 
	C381.997101,134.979431 382.081207,134.953812 382.017242,134.693878 
	C381.669983,134.238922 381.386658,134.043884 381.121918,133.894257 
	C381.140472,133.939651 381.175751,133.848129 381.110413,133.527878 
	C379.688080,131.829102 378.331085,130.450577 376.979218,129.037582 
	C376.984314,129.003113 376.915466,129.013901 376.846985,128.784241 
	C376.613281,128.242081 376.354095,128.077637 376.002502,128.028748 
	C376.003967,127.996231 375.938934,127.998947 375.873840,127.761879 
	C375.626404,127.220879 375.359344,127.047249 374.924866,126.765549 
	C374.567230,126.347664 374.292236,126.168167 373.934937,125.750114 
	C373.568390,125.348457 373.284119,125.185349 373.001770,125.009361 
	C373.003662,124.996475 372.977844,125.000381 372.905212,124.759460 
	C372.559387,124.343674 372.286163,124.168800 372.007080,123.996506 
	C372.001221,123.999084 372.005951,123.986862 371.927673,123.749527 
	C371.634583,123.221947 371.346649,123.074913 370.880402,122.841629 
	C370.513672,122.407730 370.252136,122.203300 369.931335,121.749771 
	C369.583588,121.333725 369.295074,121.166779 369.003357,120.999611 
	C369.000153,120.999390 368.999786,120.993309 368.887634,120.688469 
	C367.562988,119.535149 366.350494,118.686668 365.071777,117.854256 
	C365.005585,117.870323 365.138245,117.860481 365.022461,117.547318 
	C362.967010,115.754036 361.027344,114.273918 359.008911,112.581177 
	C358.748383,112.093765 358.492889,111.950867 358.043335,111.706596 
	C357.615570,111.324524 357.308075,111.175728 357.001984,111.010582 
	C357.003357,110.994225 356.971405,111.001503 356.898315,110.779602 
	C356.634857,110.268799 356.367126,110.137398 355.774536,109.923752 
	C330.932892,90.942749 303.515717,79.026268 272.714569,75.053513 
	C237.999405,70.575935 204.699463,75.078140 172.731812,90.183464 
	C172.136871,90.309486 171.931000,90.546814 171.919586,90.903717 
	C171.970398,90.898651 171.884155,90.843933 171.529419,90.883331 
	C167.701324,92.911911 164.227951,94.901093 160.438599,96.926804 
	C159.075790,97.630775 158.028946,98.298218 156.657379,99.002449 
	C155.712448,99.625229 155.092224,100.211212 154.222870,100.774826 
	C153.613007,101.063713 153.252304,101.374977 152.545319,101.764816 
	C149.428726,103.859627 146.658417,105.875862 143.947952,107.897629 
	C144.007797,107.903168 143.894928,107.860832 143.583649,107.901947 
	C142.522858,108.631958 141.773331,109.320854 141.011902,110.003204 
	C141.000000,109.996674 141.003510,110.023422 140.737244,110.076324 
	C140.232590,110.391220 139.994217,110.653214 139.497314,111.005745 
	C138.820129,111.447571 138.401474,111.798874 137.743042,112.177689 
	C137.212555,112.391762 137.042694,112.656021 136.721710,113.082977 
	C135.936035,113.704964 135.422333,114.241974 134.584137,114.856995 
	C132.812073,116.277435 131.364502,117.619873 129.687286,119.022415 
	C129.173538,119.264809 129.002258,119.522171 128.693176,119.951294 
	C128.265991,120.347946 128.089417,120.647949 127.951828,120.967285 
	C127.990814,120.986633 127.945000,120.912628 127.689484,120.988922 
	C127.261612,121.361076 127.089256,121.656937 126.953568,121.971466 
	C126.990227,121.990135 126.950600,121.918015 126.706215,122.006721 
	C126.285599,122.384483 126.109367,122.673546 125.964020,122.979637 
	C125.994896,122.996666 125.963348,122.933571 125.701004,123.003632 
	C125.232300,123.360634 125.025940,123.647575 124.547562,124.055885 
	C123.496429,125.063248 122.717300,125.949234 121.685616,126.931969 
	C121.265968,127.336342 121.098885,127.643967 120.935349,127.949707 
	C120.938896,127.947823 120.912796,127.927162 120.650436,128.021927 
	C119.911835,128.387115 119.435593,128.657532 118.959351,128.927948 
z"/>
          <path fill="#EFFAFD" opacity="1.000000" stroke="none"
            d="
M90.327751,370.000214 
	C90.206703,370.420135 90.085655,370.840088 89.585243,371.691132 
	C88.101089,373.049469 86.996300,373.976654 85.891510,374.903870 
	C85.891510,374.903870 86.008118,374.944061 85.615067,374.959595 
	C79.635872,378.887848 74.178886,383.009003 68.390747,386.595734 
	C66.468170,387.787048 63.695038,387.605774 61.310768,388.052063 
	C61.233128,385.604736 60.467209,382.918304 61.217587,380.758667 
	C62.908207,375.893097 65.349548,371.288361 67.485413,366.577484 
	C67.171692,366.329651 66.857971,366.081818 66.544250,365.833984 
	C63.849411,368.148895 61.154572,370.463837 58.459732,372.778748 
	C58.459736,372.778748 58.206745,372.746826 57.761948,372.884766 
	C54.453850,375.378510 51.747158,375.166870 49.188599,372.526886 
	C49.188599,372.526886 49.262424,372.266510 49.260952,371.807068 
	C50.169006,370.012451 50.808506,368.315369 52.032726,367.401978 
	C58.960529,362.233032 65.912003,357.073395 73.145012,352.354492 
	C74.626160,351.388153 77.341011,352.312775 79.487183,352.365753 
	C79.487183,352.365753 79.560768,352.272247 79.590439,352.663391 
	C79.820816,353.703430 80.021515,354.352325 80.222221,355.001251 
	C80.165298,355.443634 80.108376,355.885986 79.804031,356.892212 
	C77.224777,362.110199 74.892937,366.764374 72.561089,371.418518 
	C73.020218,371.772705 73.479340,372.126892 73.938461,372.481049 
	C76.736328,370.702484 79.551796,368.950897 82.327888,367.139008 
	C86.420036,364.468170 88.605011,365.234253 90.327751,370.000214 
z"/>
          <path fill="#EFFAFD" opacity="1.000000" stroke="none"
            d="
M331.011322,432.993103 
	C331.011322,432.993103 331.213989,433.196014 331.504669,433.555511 
	C336.568817,438.836151 335.957153,444.272369 332.932281,450.066467 
	C335.568115,452.071198 338.360413,453.663879 340.379486,455.941925 
	C341.481812,457.185669 341.274658,459.589996 341.658020,461.470947 
	C339.731079,461.647614 337.578461,462.448364 335.930908,461.852570 
	C333.546539,460.990234 331.527740,459.117035 329.353027,457.675049 
	C329.353027,457.675049 329.263184,457.773651 329.157654,457.396271 
	C327.400970,455.927216 325.749847,454.835571 324.098724,453.743896 
	C323.682861,454.052856 323.266968,454.361816 322.851105,454.670807 
	C323.741913,457.216248 325.138092,459.718048 325.362823,462.321014 
	C325.540833,464.382568 324.931488,467.589355 323.524689,468.484131 
	C320.674530,470.296936 319.219574,467.644989 318.297638,465.131287 
	C315.508240,457.525940 312.561340,449.973511 310.006653,442.290466 
	C309.394623,440.449677 310.067139,438.181763 310.148865,436.110291 
	C310.148865,436.110291 310.043213,436.051208 310.454041,436.080353 
	C317.441803,434.175232 323.719147,430.303833 331.011322,432.993103 
M322.258545,439.841431 
	C320.917603,440.332245 319.576660,440.823029 316.481995,441.955658 
	C319.152985,444.138916 320.968506,446.742798 322.490784,446.581390 
	C324.359619,446.383240 325.983398,443.874481 327.713104,442.364563 
	C326.137878,441.482483 324.562622,440.600433 322.258545,439.841431 
z"/>
          <path fill="#EFFAFD" opacity="1.000000" stroke="none"
            d="
M26.758419,273.466187 
	C26.637642,271.134888 26.408686,268.700775 27.207226,266.670898 
	C29.171215,261.678528 28.335384,258.074280 23.388802,255.488571 
	C21.554916,254.529953 20.071728,252.900421 18.428602,251.576859 
	C18.523584,251.030807 18.618568,250.484772 18.713551,249.938721 
	C21.866457,249.938721 25.030937,249.769241 28.169209,249.983551 
	C31.735851,250.227127 33.748089,249.134171 34.349464,245.292404 
	C34.793091,242.458359 36.082108,239.756638 36.994450,236.995972 
	C37.550587,236.951172 38.106720,236.906372 38.662853,236.861572 
	C39.447723,239.035385 40.699375,241.161926 40.925327,243.392365 
	C41.485180,248.918777 44.306465,250.786560 49.598206,250.003952 
	C51.851452,249.670731 54.195026,249.948303 56.497555,249.948303 
	C56.782017,250.373383 57.066483,250.798477 57.350948,251.223557 
	C56.002117,252.514557 54.814075,254.052521 53.276489,255.053589 
	C46.780212,259.283051 46.782032,259.214142 48.693050,266.503540 
	C49.263618,268.679962 49.542763,270.932770 49.955437,273.150543 
	C49.599873,273.432129 49.244308,273.713715 48.888741,273.995270 
	C46.546700,272.342743 44.045330,270.871796 41.902779,268.991913 
	C39.198219,266.618958 36.975349,266.274719 34.142536,268.927551 
	C32.126705,270.815338 29.481865,272.031433 26.758419,273.466187 
z"/>
          <path fill="#EFFAFD" opacity="1.000000" stroke="none"
            d="
M473.836975,272.913025 
	C473.159790,272.904480 472.482635,272.895935 471.294128,272.569641 
	C469.218140,271.038635 467.487305,269.986511 466.122437,268.579407 
	C463.044708,265.406372 460.292358,265.834564 457.183228,268.607452 
	C455.007446,270.547943 452.427307,272.035065 450.027802,273.724701 
	C449.909424,273.506989 449.746155,273.330902 449.394867,272.724152 
	C450.004547,269.726837 450.418823,267.031616 451.584045,264.713959 
	C453.700378,260.504364 452.065521,257.886505 448.676788,255.537338 
	C446.586731,254.088440 444.669403,252.390396 442.674561,250.804199 
	C442.796356,250.382080 442.918121,249.959946 443.039917,249.537827 
	C444.884155,249.393036 446.726013,249.175507 448.572968,249.115601 
	C458.370941,248.797760 456.110168,250.786240 459.474823,240.886292 
	C460.067871,239.141388 461.702026,237.750320 462.855347,236.195831 
	C462.855347,236.195831 462.962067,236.090805 462.975403,236.481384 
	C463.648285,238.911270 464.585968,240.907364 464.910950,242.998688 
	C465.646393,247.731262 467.916809,249.909927 472.930664,249.199570 
	C475.514191,248.833527 478.210144,249.261093 480.855347,249.330063 
	C480.860168,249.963974 480.865021,250.597900 480.491272,251.618652 
	C472.345917,255.704727 468.818756,261.020721 473.271545,269.534180 
	C473.774567,270.495972 473.663361,271.778992 473.836975,272.913025 
z"/>
          <path fill="#EBF8FD" opacity="1.000000" stroke="none"
            d="
M205.478516,458.082764 
	C204.128769,463.345367 200.496323,460.751007 197.679718,460.725983 
	C196.230072,460.713135 194.603561,459.925262 193.397247,460.383453 
	C192.574982,460.695740 191.873901,462.606964 191.986816,463.714783 
	C192.085083,464.678894 193.234024,465.945435 194.211746,466.326599 
	C196.356476,467.162720 198.898727,467.136017 200.866348,468.214142 
	C202.161057,468.923523 203.591492,471.090820 203.379517,472.336029 
	C203.190216,473.448120 200.673096,475.135254 199.458527,474.931000 
	C194.578384,474.110382 189.688965,472.963470 185.068695,471.229218 
	C183.809998,470.756744 182.554916,467.619751 182.880081,466.028961 
	C184.540573,457.905518 186.477005,449.813507 188.913055,441.893860 
	C189.423569,440.234253 192.517441,438.210449 194.289139,438.328644 
	C198.530640,438.611694 202.792465,439.789154 206.833008,441.211029 
	C208.182037,441.685760 208.884125,443.998901 209.881958,445.471649 
	C208.321701,446.411224 206.844406,447.880402 205.177841,448.141510 
	C203.507874,448.403168 201.610519,447.020477 199.884171,447.171906 
	C198.300674,447.310822 196.812302,448.534332 195.282013,449.279907 
	C196.319199,450.580566 197.162048,452.129639 198.436737,453.126648 
	C200.633118,454.844543 203.098236,456.218872 205.478516,458.082764 
z"/>
          <path fill="#EBF8FD" opacity="1.000000" stroke="none"
            d="
M425.673950,401.703125 
	C424.243958,403.358612 423.082062,404.769318 421.861084,406.126831 
	C419.583710,408.659027 416.898285,408.982300 414.371582,406.793213 
	C407.984406,401.259491 401.564636,395.733734 395.613922,389.751160 
	C394.434570,388.565521 394.393951,384.788910 395.397186,383.194183 
	C397.485016,379.875397 400.383575,376.924255 403.441925,374.425415 
	C404.743744,373.361755 407.282684,373.812225 409.259460,373.574646 
	C409.057098,375.645508 409.222198,377.850098 408.532928,379.743713 
	C408.007965,381.185944 406.054321,382.072052 405.381073,383.507874 
	C404.846161,384.648651 404.874176,387.242188 405.374969,387.450104 
	C406.715057,388.006470 408.734070,388.153046 409.944214,387.485352 
	C411.440125,386.659973 412.148712,384.356781 413.652924,383.612427 
	C414.982758,382.954315 417.459930,382.944061 418.457275,383.792236 
	C419.317719,384.523987 419.339752,387.090942 418.771240,388.442108 
	C418.033295,390.195770 416.082062,391.409668 415.165680,393.129059 
	C414.655914,394.085419 414.730316,396.295013 415.352203,396.702576 
	C416.412811,397.397644 418.451843,397.704071 419.465790,397.115631 
	C421.120117,396.155579 422.041321,393.876434 423.715027,393.020721 
	C425.498657,392.108704 427.797089,392.203461 429.870789,391.858673 
	C429.671112,393.821686 429.837708,395.924896 429.156921,397.703766 
	C428.603699,399.149078 427.034149,400.205444 425.673950,401.703125 
z"/>
          <path fill="#EBF8FD" opacity="1.000000" stroke="none"
            d="
M298.228027,475.155701 
	C290.921631,476.687805 290.127197,476.107574 288.737885,469.418701 
	C287.282471,462.411377 285.761353,455.412842 284.036133,448.468323 
	C283.127014,444.808807 284.066711,442.691376 287.860107,441.890411 
	C291.601501,441.100403 295.273468,439.770844 299.044159,439.427246 
	C300.705627,439.275879 302.535950,440.977661 304.289612,441.838074 
	C303.039978,443.457397 302.103638,445.637024 300.461884,446.555023 
	C298.383240,447.717316 295.730865,447.853546 293.402985,448.413757 
	C292.983337,454.146332 293.345673,454.470673 298.610016,453.520142 
	C299.914368,453.284607 301.422119,452.704529 302.514252,453.123230 
	C304.005096,453.694733 305.763031,454.847961 306.282410,456.187622 
	C306.586090,456.970856 304.953613,459.044495 303.736389,459.740814 
	C302.217651,460.609589 299.998779,460.278687 298.516998,461.180298 
	C297.316315,461.910889 296.758423,463.697754 295.915436,465.016113 
	C297.206818,465.814850 298.475830,467.227844 299.794556,467.275848 
	C302.067474,467.358643 304.399963,466.177826 306.651764,466.333374 
	C308.062378,466.430817 309.371216,468.002380 310.725769,468.912537 
	C309.781494,470.285065 309.121826,472.372986 307.835175,472.884827 
	C304.936462,474.037933 301.727356,474.410614 298.228027,475.155701 
z"/>
          <path fill="#EBF8FD" opacity="1.000000" stroke="none"
            d="
M258.604156,447.683105 
	C263.023499,443.797821 268.043243,443.441620 272.996063,444.474915 
	C274.749512,444.840759 276.060852,447.325775 277.574066,448.843262 
	C275.807800,449.902100 274.064362,451.002991 272.256195,451.984558 
	C271.891479,452.182526 271.223053,451.761292 270.794128,451.910858 
	C268.814362,452.601227 266.869781,453.392456 264.912598,454.147583 
	C266.506927,455.440887 267.910980,457.385712 269.727997,457.916321 
	C278.297455,460.418793 281.426453,463.683838 280.626892,470.312897 
	C280.132599,474.410797 278.164001,478.251343 274.043396,478.756683 
	C269.314545,479.336609 264.373108,478.446991 259.572418,477.823273 
	C259.008636,477.750031 258.115601,475.060822 258.527100,474.056122 
	C258.952576,473.017273 260.730011,472.085388 262.009949,471.941376 
	C264.292145,471.684601 266.714630,472.431458 268.941223,472.018646 
	C270.199371,471.785309 271.675079,470.238373 272.092285,468.967834 
	C272.312988,468.295624 270.630127,466.721649 269.522797,465.976929 
	C268.622620,465.371582 267.301392,465.386719 266.163757,465.141235 
	C256.917053,463.145691 253.904343,456.529755 258.604156,447.683105 
z"/>
          <path fill="#EBF8FD" opacity="1.000000" stroke="none"
            d="
M434.656036,361.500305 
	C444.751038,361.009338 450.061249,369.252869 445.250183,377.724457 
	C443.677307,380.494049 441.070679,382.834412 438.501953,384.803925 
	C437.457031,385.605042 435.254089,384.895721 433.578033,384.873688 
	C433.773254,382.889374 433.477325,380.665436 434.296814,378.985687 
	C435.180786,377.173737 437.353302,376.027618 438.473572,374.277374 
	C439.002136,373.451599 438.833374,371.299866 438.181366,370.807617 
	C437.241394,370.098022 435.345703,369.834320 434.266449,370.309113 
	C432.333252,371.159637 430.767487,372.804047 428.936157,373.935394 
	C424.339508,376.775208 419.475128,376.434021 416.069580,373.155396 
	C412.551636,369.768616 411.615143,364.315094 414.463074,359.912018 
	C416.208130,357.214111 418.680450,354.829620 421.281464,352.926697 
	C422.203613,352.252045 425.156158,352.891998 425.930481,353.896423 
	C426.670349,354.856201 426.173126,357.253571 425.501282,358.680847 
	C424.836792,360.092499 422.975983,360.915222 422.199677,362.308197 
	C421.581177,363.417999 421.805115,364.997223 421.655060,366.368103 
	C423.026550,366.466309 424.638153,367.075104 425.723450,366.564728 
	C428.694794,365.167389 431.444641,363.298981 434.656036,361.500305 
z"/>
          <path fill="#EFFAFD" opacity="1.000000" stroke="none"
            d="
M128.868149,406.260864 
	C128.868149,406.260864 128.866486,406.653168 128.880524,407.222168 
	C127.784676,414.098816 124.011330,410.279419 121.125458,409.834442 
	C121.125458,409.834442 121.044922,409.894409 121.017883,409.639648 
	C120.681671,409.216644 120.372498,409.048340 120.063339,408.880035 
	C120.063339,408.880035 120.035461,408.979767 119.966782,408.670105 
	C119.412979,408.089966 118.927849,407.819458 118.442711,407.548950 
	C118.442703,407.548950 118.368172,407.497620 118.280762,407.178955 
	C116.727745,406.937347 115.262146,407.014465 113.796547,407.091553 
	C113.913216,408.624695 113.503792,410.539368 114.272652,411.599548 
	C115.364090,413.104553 117.322800,413.980591 118.910919,415.125397 
	C118.910912,415.125397 118.970497,415.028320 118.966278,415.398560 
	C120.104790,420.964050 119.038361,421.887970 113.146629,420.807129 
	C113.146629,420.807129 113.032600,420.944275 113.014000,420.569611 
	C112.271896,419.525360 111.576981,418.821289 110.819138,418.193146 
	C109.049126,416.726135 107.331680,414.711609 105.157814,417.756134 
	C103.156380,420.559143 104.569870,422.258087 106.888489,423.819183 
	C108.510010,424.910919 110.039330,426.139587 111.609848,427.307068 
	C111.609856,427.307068 111.910545,427.301605 111.769218,427.674561 
	C111.532089,429.603943 111.436279,431.160400 111.340469,432.716858 
	C111.340469,432.716858 111.122124,432.701691 110.664589,432.818359 
	C108.523857,432.559998 106.840668,432.184937 105.157478,431.809845 
	C105.157478,431.809845 104.987122,431.873657 104.944519,431.546753 
	C102.345764,429.130005 99.794357,427.034363 97.232315,424.951813 
	C93.731903,422.106506 93.232666,419.174042 96.663139,415.809082 
	C98.038483,414.460022 99.009941,412.699188 100.165230,411.125793 
	C100.165230,411.125793 100.086006,410.994934 100.414803,410.932739 
	C102.717873,408.461578 104.642433,406.009705 106.675385,403.651276 
	C113.972946,395.185394 113.986359,395.198029 123.384102,401.085602 
	C123.511246,401.165314 123.704857,401.138916 123.867432,401.162048 
	C123.867432,401.162079 123.983879,401.147797 124.043114,401.439148 
	C124.958908,402.279022 125.815468,402.827545 126.672028,403.376068 
	C126.672020,403.376068 126.836555,403.431763 126.822472,403.709595 
	C127.494980,404.745209 128.181564,405.503052 128.868149,406.260864 
z"/>
          <path fill="#EFFAFD" opacity="1.000000" stroke="none"
            d="
M353.270386,447.363312 
	C353.270386,447.363312 353.257690,447.152985 353.044189,446.778137 
	C348.084625,442.037201 343.425079,437.571442 338.559570,433.342804 
	C335.700867,430.858246 335.066650,428.291992 337.118134,425.100128 
	C337.118134,425.100128 337.002838,425.053040 337.450867,425.085022 
	C339.241028,425.117188 340.583099,425.117401 341.925201,425.117584 
	C341.925201,425.117584 341.999115,425.042053 342.070801,425.323608 
	C342.637451,425.871307 343.132446,426.137451 343.627441,426.403595 
	C343.751587,426.282928 343.875732,426.162231 343.882599,426.464111 
	C343.943848,427.205597 344.215851,427.361450 344.581268,427.354279 
	C344.581268,427.354279 344.631409,427.425232 344.734650,427.764587 
	C348.244232,431.484436 351.650574,434.864899 355.056946,438.245361 
	C355.352997,437.985901 355.649048,437.726440 355.945129,437.466980 
	C355.000519,430.652924 354.055939,423.838867 353.111328,417.024811 
	C353.111328,417.024811 353.030457,417.039368 353.413635,416.916382 
	C358.721100,415.003876 360.100555,415.664764 360.991974,420.577454 
	C362.083130,426.591095 363.040070,432.629791 363.994202,438.667297 
	C364.483215,441.761780 365.175293,444.877380 365.173615,447.983124 
	C365.171387,452.129639 361.774506,453.654724 358.334045,451.269775 
	C356.584290,450.056854 354.954437,448.671021 353.270386,447.363312 
z"/>
          <path fill="#8B3B63" opacity="1.000000" stroke="none"
            d="
M69.722549,203.857758 
	C69.430092,204.258682 69.137627,204.659607 68.486435,205.422821 
	C66.926193,207.089462 65.724670,208.393845 64.523148,209.698212 
	C64.523148,209.698212 64.212563,209.670105 63.732330,209.760223 
	C62.325630,210.795532 61.399166,211.740692 60.472702,212.685852 
	C60.142948,212.721542 59.813194,212.757217 58.859596,212.889343 
	C51.271217,215.943680 44.154816,214.991272 37.029503,213.809372 
	C36.734642,213.646149 36.418987,213.554535 35.694557,213.247604 
	C33.245636,211.542786 31.184704,210.124893 29.123770,208.707001 
	C29.051249,208.433228 28.903069,208.209579 28.425383,207.603027 
	C26.549654,205.278717 24.927773,203.387421 23.305889,201.496140 
	C23.305885,201.496140 23.362640,201.219223 23.287365,200.699478 
	C22.358950,194.753235 21.505812,189.326736 20.652676,183.900238 
	C21.187399,181.688156 21.722122,179.476059 22.631376,176.760101 
	C23.728273,174.850006 24.450642,173.443787 25.173012,172.037582 
	C25.173010,172.037582 25.333466,171.798645 25.643394,171.539642 
	C29.493723,168.330261 33.034119,165.379883 36.574516,162.429504 
	C37.183132,162.357376 37.791744,162.285233 39.031059,162.159607 
	C40.468102,161.781097 41.274445,161.456085 42.080784,161.131088 
	C42.501698,161.114731 42.922611,161.098373 44.101448,161.085785 
	C50.882450,162.455612 56.905529,163.821686 62.928612,165.187744 
	C62.928612,165.187744 63.168312,165.364182 63.440308,165.672241 
	C65.400398,167.455811 67.088486,168.931305 68.776566,170.406799 
	C68.977165,170.868179 69.177765,171.329575 69.632385,172.264893 
	C70.488808,173.330582 71.091209,173.922333 71.693619,174.514069 
	C71.956398,175.203201 72.219177,175.892349 72.625046,177.237762 
	C74.750832,186.900269 75.095001,195.714294 69.722549,203.857758 
M29.127697,202.766830 
	C30.292328,203.749496 31.456957,204.732162 32.802475,206.463226 
	C38.303898,213.980148 51.207226,215.294815 59.001076,209.048660 
	C60.342266,208.112869 61.683456,207.177078 63.639412,205.903778 
	C72.845268,199.628845 74.701813,185.360046 68.161781,174.600769 
	C63.483517,166.904373 51.939583,161.738525 44.223297,163.919678 
	C42.410900,164.519684 40.598503,165.119690 38.053532,165.752625 
	C36.265572,167.071030 34.477612,168.389420 32.306019,169.763794 
	C32.306019,169.763794 31.935883,169.879135 31.260458,169.953583 
	C30.449976,170.931320 29.639496,171.909073 28.246155,173.259277 
	C27.406822,175.384155 26.567488,177.509033 25.042553,179.957321 
	C21.389366,186.214111 22.327511,192.280045 25.811405,198.648865 
	C25.811405,198.648865 25.930458,199.039520 25.990313,199.720551 
	C26.973677,200.535477 27.957043,201.350403 29.127697,202.766830 
z"/>
          <path fill="#EBF8FD" opacity="1.000000" stroke="none"
            d="
M391.945160,393.151855 
	C392.032043,393.445526 392.199402,393.682343 392.687500,394.350098 
	C393.523499,398.359985 391.370087,399.270142 388.485046,400.093658 
	C383.775940,401.437897 382.476288,404.964600 385.332031,408.809235 
	C387.553802,411.800323 390.375549,414.345825 392.930664,417.089355 
	C392.930664,417.089355 393.010620,417.031921 393.097778,417.402618 
	C397.483978,420.865601 399.519379,418.341339 401.681305,414.779785 
	C402.610321,413.249390 404.891785,412.539978 406.558777,411.457581 
	C407.328918,412.770294 408.914490,414.213013 408.729523,415.373505 
	C407.878021,420.716187 404.297485,424.183502 399.499481,426.179932 
	C395.334778,427.912781 391.413269,427.068024 388.134308,423.774017 
	C385.818481,421.447540 383.439178,419.184204 381.088226,416.892670 
	C381.088226,416.892670 381.036896,417.006805 381.017517,416.636475 
	C379.746002,414.777802 378.493866,413.289490 377.241730,411.801147 
	C377.241730,411.801147 377.079834,412.003296 377.151154,411.522583 
	C374.214325,400.601898 381.104980,392.228821 391.945160,393.151855 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M74.993118,201.007050 
	C76.650253,197.475113 78.647209,194.059982 79.908173,190.391846 
	C86.945572,169.920090 97.019135,151.210800 111.539383,135.042999 
	C113.450394,132.915146 115.712219,131.102356 118.043526,129.530731 
	C116.178520,132.602722 114.081558,135.287659 111.989136,137.983948 
	C111.993683,137.995285 111.975983,137.989899 111.749321,138.082001 
	C111.241379,138.382996 111.090340,138.662323 111.036850,139.007767 
	C111.004173,139.003448 111.014771,139.068512 110.800110,139.127258 
	C110.307541,139.374344 110.198227,139.633728 110.052780,140.164703 
	C109.541885,140.843369 109.235741,141.321503 108.687180,141.920807 
	C108.285789,142.358032 108.126801,142.674088 107.696327,143.124115 
	C106.919090,144.104355 106.413345,144.950623 105.641357,145.976990 
	C104.846191,147.072662 104.317276,147.988220 103.573868,149.009186 
	C103.103912,149.340347 102.976173,149.623718 102.685242,150.133820 
	C100.884308,152.849533 99.374313,155.396057 97.638840,158.081329 
	C97.234207,158.707718 97.055061,159.195358 96.648270,159.836792 
	C96.280907,160.321930 96.141205,160.653290 95.998535,160.990356 
	C95.995560,160.996063 95.985985,161.004623 95.695740,161.174149 
	C94.306725,163.566040 93.207962,165.788391 92.003281,168.175568 
	C91.897362,168.340378 91.882866,168.731918 91.604973,168.940308 
	C90.251976,171.435730 89.176872,173.722778 88.004623,176.187836 
	C87.907478,176.365875 87.900085,176.771423 87.633484,176.987549 
	C86.916878,178.470505 86.466873,179.737350 85.926331,181.157257 
	C85.835777,181.310303 85.880386,181.663162 85.665070,181.825439 
	C85.336594,182.318939 85.223442,182.650162 84.992920,183.141388 
	C84.875565,183.301376 84.870697,183.698196 84.628700,183.953583 
	C83.660454,186.143845 82.934204,188.078735 82.095978,190.221313 
	C81.984001,190.428986 81.957649,190.900116 81.715942,191.144730 
	C81.003593,192.927979 80.532959,194.466629 79.898865,196.331238 
	C79.403954,198.071686 79.072510,199.486160 78.505539,201.139847 
	C78.140129,202.258957 78.010246,203.138840 77.691833,204.307312 
	C77.291214,205.711288 77.079117,206.826706 76.667076,208.149475 
	C76.398178,208.910614 76.329231,209.464417 76.137527,210.328995 
	C75.980721,211.081406 75.946671,211.523010 75.689545,212.205078 
	C75.360161,213.300797 75.253860,214.156067 75.024460,215.314560 
	C74.866516,216.051437 74.831673,216.485107 74.562370,217.217484 
	C73.905937,220.673553 73.483963,223.830887 72.968033,227.308197 
	C72.879715,228.070282 72.885368,228.512405 72.678459,229.267929 
	C72.352615,231.378693 72.239326,233.176086 72.029121,235.369751 
	C71.908508,237.391327 71.884819,239.016617 71.609566,240.992554 
	C71.343338,244.532028 71.328674,247.720840 71.241875,251.302628 
	C71.222076,252.489471 71.274429,253.283325 71.234970,254.513550 
	C71.392853,258.316254 71.642548,261.682587 71.861725,265.377441 
	C71.896538,266.138733 71.961845,266.571503 71.962112,267.451080 
	C72.958412,273.282013 74.019775,278.666107 75.036545,284.341370 
	C75.280350,285.083374 75.568748,285.534271 75.947151,286.286194 
	C76.081940,287.210480 76.126717,287.833649 75.992706,288.702271 
	C75.841721,289.645905 75.869530,290.344086 75.890350,291.379181 
	C76.027962,292.138580 76.172562,292.561096 76.273552,293.363831 
	C77.436470,297.619629 78.643005,301.495209 79.814011,305.535583 
	C79.778481,305.700409 79.924210,306.004456 79.910461,306.365845 
	C80.552277,308.262390 81.207840,309.797546 81.862747,311.638672 
	C82.022491,312.315308 82.182892,312.686066 82.331154,313.296936 
	C82.396370,313.874268 82.588821,314.122162 82.895584,314.475677 
	C82.894783,314.670563 83.117599,314.990387 83.129349,315.346069 
	C84.077408,317.813019 85.013725,319.924255 85.971901,322.017761 
	C85.993759,322.000000 85.944595,322.035950 85.934608,322.338989 
	C86.262939,323.117523 86.601265,323.593079 86.958000,324.029297 
	C86.976410,323.989960 86.919014,324.056061 86.948364,324.415649 
	C87.726730,326.791992 88.475739,328.808685 89.132202,330.901031 
	C89.039658,330.976654 88.856300,331.129974 88.856300,331.129974 
	C87.211830,328.674255 85.062355,326.417694 84.002701,323.731293 
	C75.443932,302.033478 69.517372,279.848816 69.843727,256.166412 
	C70.077629,239.192673 70.500450,222.352890 74.860329,205.834534 
	C75.191330,204.580490 74.986702,203.185074 75.015602,201.427567 
	C75.000153,200.999771 74.993126,201.007050 74.993118,201.007050 
z"/>
          <path fill="#EBF8FD" opacity="1.000000" stroke="none"
            d="
M215.413971,449.803284 
	C212.566422,445.449097 213.547882,443.133881 218.119492,443.313263 
	C223.255402,443.514832 228.433411,443.947998 233.458405,444.953491 
	C235.184235,445.298828 236.504181,447.672577 238.009537,449.119659 
	C236.440140,450.267853 234.837402,452.417450 233.307968,452.366516 
	C229.525406,452.240509 228.941071,454.053467 228.697464,457.111298 
	C228.235962,462.904755 227.480026,468.675049 226.830002,474.453094 
	C226.523392,477.178375 225.679321,479.382660 222.258301,479.038757 
	C218.996292,478.710907 218.801758,476.389221 218.994385,473.931152 
	C219.409943,468.628571 219.459366,463.239227 220.522446,458.062775 
	C221.476288,453.418243 220.695236,450.697357 215.413971,449.803284 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M64.889870,113.107010 
	C57.083328,110.991539 54.789001,106.260567 57.955006,98.364731 
	C57.991978,97.998611 57.976791,97.987640 58.325867,97.941116 
	C59.112404,96.922470 59.549866,95.950348 59.994934,94.988419 
	C60.002548,94.998604 59.978226,94.993851 60.153324,94.909645 
	C60.215034,94.542351 60.101643,94.259262 60.104004,93.713753 
	C60.783512,92.951332 61.347267,92.451324 61.935440,92.293533 
	C61.966213,93.092667 61.972572,93.549568 62.022171,94.333389 
	C63.045452,94.775093 64.025497,94.889900 65.251007,94.729492 
	C72.138512,96.317635 78.780556,98.180977 85.592812,100.092072 
	C85.592812,99.520859 85.906891,98.329170 85.543304,97.403709 
	C82.534981,89.746284 76.758148,83.004951 77.665192,73.828918 
	C77.152931,73.363251 76.894562,73.054512 76.636200,72.745766 
	C76.636200,72.745766 76.705383,72.739418 77.006638,72.714890 
	C77.558891,72.165535 77.809891,71.640701 78.060883,71.115868 
	C78.060883,71.115868 78.033646,71.033562 78.459961,71.033295 
	C79.916199,71.022697 80.946129,71.012383 82.211182,70.886856 
	C82.661217,70.499512 82.876122,70.227386 83.391708,69.956940 
	C84.091682,69.965240 84.490967,69.971863 84.890259,69.978485 
	C91.613914,70.110100 94.901360,75.840401 99.550171,79.224968 
	C106.855698,84.543770 113.974213,90.193428 120.613678,96.309052 
	C122.668083,98.201378 123.896851,102.150513 123.646889,104.994781 
	C123.466537,107.047058 120.256622,110.331055 118.320992,110.386650 
	C115.274040,110.474152 112.126419,108.387924 109.109764,107.026253 
	C108.536530,106.767517 108.373375,105.600273 108.021286,104.411362 
	C108.021553,103.970520 108.180206,103.790413 108.504227,103.895111 
	C110.409294,105.210503 111.834389,106.823082 113.616844,107.514732 
	C115.278107,108.159355 117.605347,108.470222 119.136726,107.792358 
	C122.848175,106.149490 122.761894,101.661682 119.000710,98.351608 
	C113.520050,93.528290 107.880333,88.885300 102.293625,84.183029 
	C98.357437,80.869980 94.627380,77.231094 90.343971,74.447578 
	C88.370270,73.164993 84.178322,72.395126 82.984612,73.515541 
	C81.331490,75.067162 80.976921,78.658264 81.151367,81.304535 
	C81.305733,83.646202 82.964073,85.881737 83.923462,88.178734 
	C85.997490,93.144402 88.049110,98.119438 90.426491,103.854362 
	C88.115082,103.414688 86.698097,103.175507 85.294754,102.873055 
	C78.647667,101.440399 72.047066,99.686752 65.332207,98.741417 
	C63.445526,98.475815 60.450912,100.076706 59.405693,101.731674 
	C58.565315,103.062294 59.419521,106.035507 60.399113,107.790062 
	C61.483936,109.733093 63.566166,111.119263 65.133942,112.838547 
	C65.053825,112.929161 64.889870,113.107010 64.889870,113.107010 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M333.119690,409.131958 
	C330.821899,410.802826 328.714691,412.880707 326.188416,414.063629 
	C320.098053,416.915527 313.949188,419.766205 307.576538,421.866760 
	C292.899933,426.704346 277.975677,430.276337 262.383575,431.106323 
	C244.437775,432.061554 226.420120,432.688446 209.105545,427.722687 
	C194.290283,423.473755 180.079849,417.111115 165.628418,411.613708 
	C164.970642,411.363495 164.573959,410.426910 164.060547,409.361450 
	C164.065399,408.912903 164.237335,408.704681 164.586960,408.771057 
	C166.908707,409.624054 168.880829,410.410706 170.910614,411.184814 
	C170.968292,411.172302 170.852692,411.148468 171.030502,411.442383 
	C173.134445,412.500854 175.060593,413.265472 177.155502,414.271118 
	C177.871170,414.702393 178.418060,414.892548 179.180237,415.342163 
	C181.100754,415.728210 182.805984,415.854767 184.530151,415.939209 
	C184.549103,415.897064 184.640915,415.886810 184.738174,416.254028 
	C188.365952,418.239777 191.772522,420.247864 195.446106,421.416718 
	C233.042984,433.379425 270.474396,431.867371 307.675629,419.568512 
	C309.263977,419.043396 310.440704,417.273254 312.208008,416.044006 
	C313.403564,415.973999 314.200317,415.943054 315.361328,415.896118 
	C317.650208,414.982452 319.574860,414.084778 321.620026,413.248962 
	C321.740570,413.310791 321.967529,413.162872 322.301666,413.163940 
	C323.188873,412.818695 323.741943,412.472321 324.492249,412.120361 
	C324.689484,412.114777 325.015167,411.891998 325.283081,411.865875 
	C325.807983,411.598877 326.065002,411.358002 326.633850,411.089966 
	C327.324036,410.890717 327.702362,410.718658 328.284454,410.583282 
	C328.769043,410.509521 328.945892,410.307770 329.009399,410.007385 
	C329.000000,410.000000 329.015381,410.018585 329.349152,409.995392 
	C330.709167,409.548920 331.735382,409.125641 332.850586,408.810211 
	C332.939606,408.918060 333.119690,409.131958 333.119690,409.131958 
z"/>
          <path fill="#649BC6" opacity="1.000000" stroke="none"
            d="
M486.980438,189.014664 
	C489.234528,201.375397 492.192810,213.656738 493.597595,226.113251 
	C496.611511,252.838699 494.901672,279.468384 489.931946,305.887421 
	C489.687988,307.184448 489.266785,308.448120 488.661011,309.964783 
	C488.273254,307.768524 488.152618,305.334808 488.325623,302.163147 
	C489.401428,293.281464 490.183563,285.137665 490.978882,276.594788 
	C490.989929,275.130432 490.987823,274.065216 490.997314,272.603821 
	C491.002411,269.473236 490.995850,266.738861 490.998474,263.566742 
	C491.004700,261.087616 491.001740,259.046265 491.287567,256.726440 
	C491.367584,247.295792 491.158813,238.143631 490.964966,228.627594 
	C490.669525,227.165192 490.359131,226.066635 490.048370,224.542740 
	C490.033630,222.415421 490.019257,220.713409 490.011017,218.622925 
	C489.692535,216.809204 489.367950,215.383957 489.042358,213.544006 
	C489.012878,211.745529 488.984406,210.361771 488.955963,208.977997 
	C488.950592,207.609100 488.945221,206.240204 488.938965,204.160187 
	C488.642792,202.964020 488.347595,202.478989 488.052368,201.993942 
	C488.032776,201.552216 488.013214,201.110504 487.997498,199.949951 
	C487.369263,197.151932 486.737213,195.072739 486.105164,192.993546 
	C486.071655,192.245010 486.038147,191.496490 486.106201,190.159668 
	C486.395203,189.288467 486.652771,189.102890 486.980438,189.014664 
z"/>
          <path fill="#EBF8FD" opacity="1.000000" stroke="none"
            d="
M57.417030,353.310120 
	C53.925785,355.307587 50.917000,357.586487 47.525806,358.764923 
	C45.699799,359.399414 43.218430,358.147980 41.032753,357.747467 
	C41.779453,355.828308 41.967327,353.014252 43.366760,352.140350 
	C50.541691,347.659912 57.905602,343.427765 65.498642,339.712006 
	C67.248665,338.855621 71.274857,339.556458 72.295830,340.962402 
	C74.288223,343.706024 71.680145,345.675903 69.149101,347.011505 
	C65.332138,349.025574 61.541878,351.090240 57.417030,353.310120 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M131.051254,415.068573 
	C130.857117,410.333954 133.664886,408.825562 137.400650,410.474579 
	C142.022781,412.514771 146.791092,414.910400 150.452103,418.285187 
	C156.385040,423.754150 153.528564,435.124390 145.462967,438.432861 
	C142.461166,439.664185 141.714859,441.015411 141.962387,443.783783 
	C142.095215,445.269501 141.869446,446.788269 142.014557,448.271667 
	C142.368927,451.894348 140.391724,453.792358 137.174255,453.797485 
	C134.014938,453.802521 131.246567,452.320953 131.050186,448.576569 
	C130.873489,445.207733 131.012924,441.822296 131.012924,438.444183 
	C130.519714,438.336151 130.026505,438.228119 129.533295,438.120117 
	C128.752869,439.249969 127.900734,440.337585 127.205078,441.517426 
	C124.877579,445.464844 121.097755,444.888245 118.081909,443.494843 
	C116.833153,442.917908 116.018845,439.032196 116.653908,437.232330 
	C118.038986,433.306824 120.493126,429.760986 122.484520,426.046082 
	C123.729050,423.724365 124.730629,421.243073 126.210419,419.086761 
	C127.278778,417.529999 128.954880,416.390289 130.668549,415.405701 
	C130.529236,416.638702 130.136841,417.563934 129.624329,418.416992 
	C126.111702,424.263916 122.352432,429.979950 119.198135,436.012909 
	C118.398270,437.542755 119.499069,440.066406 119.726517,442.133392 
	C121.668037,441.481750 123.900291,441.221252 125.462959,440.059845 
	C126.815727,439.054443 127.281586,436.929749 128.383392,435.485931 
	C129.083405,434.568604 130.229355,433.991608 131.175323,433.261993 
	C131.673264,434.387177 132.523499,435.487793 132.603958,436.642090 
	C132.858459,440.293823 132.438705,444.035370 133.081528,447.597473 
	C133.362885,449.156647 135.588226,450.365021 136.930817,451.732697 
	C138.010056,450.364502 139.800735,449.101624 140.022079,447.606415 
	C140.498856,444.385681 140.168564,441.045502 140.168564,437.837646 
	C140.999924,437.511597 141.440994,437.204773 141.897354,437.180023 
	C147.422745,436.880188 150.279831,433.443237 151.575226,428.568481 
	C152.878555,423.663849 150.408783,420.244141 146.459824,417.672150 
	C144.647858,416.491943 142.673233,415.527710 140.988815,414.192474 
	C137.322311,411.286011 134.068054,411.413910 131.051254,415.068573 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M5.282214,235.024918 
	C7.506459,219.784180 8.774323,204.327042 12.163464,189.349899 
	C18.321131,162.138245 28.889320,136.541168 44.455471,112.907944 
	C42.841469,117.020958 40.899677,121.243721 39.002007,125.486221 
	C33.978939,136.715973 28.974962,147.954285 23.738449,159.698914 
	C23.259344,160.410812 23.083588,160.666824 22.986244,160.976685 
	C22.005112,163.729050 21.023979,166.481430 19.693336,169.773911 
	C18.896147,171.542297 18.448467,172.770584 18.000786,173.998856 
	C16.355877,180.405167 14.710970,186.811462 12.774364,193.777863 
	C12.337476,194.900543 12.192287,195.463135 12.047100,196.025711 
	C12.028029,197.403259 12.008958,198.780792 11.766512,200.686066 
	C11.274787,201.423401 11.105423,201.694794 11.035046,202.027969 
	C10.351267,206.731155 9.667488,211.434341 8.718699,216.776306 
	C8.313149,218.280075 8.172611,219.145081 8.032073,220.010086 
	C8.001630,222.041748 7.971186,224.073425 7.723482,226.660187 
	C7.255458,227.427094 7.100550,227.694443 7.041498,228.017365 
	C7.015292,230.042358 6.989086,232.067352 6.675244,234.540680 
	C6.019144,235.000992 5.650680,235.012955 5.282214,235.024918 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M432.226410,82.081955 
	C440.912628,93.490372 450.209595,104.832214 457.908173,117.171288 
	C470.106201,136.721909 479.163147,157.818359 484.667969,180.750244 
	C484.235046,181.466766 484.065369,181.721771 483.959473,182.019196 
	C481.745697,175.863388 479.444733,169.737305 477.333893,163.546417 
	C469.826416,141.528290 459.349823,121.041298 445.739197,102.178375 
	C441.784607,96.697701 437.553497,91.417130 433.548981,85.971397 
	C432.823608,84.985008 432.584045,83.641396 432.226410,82.081955 
z"/>
          <path fill="#EBF8FD" opacity="1.000000" stroke="none"
            d="
M363.626038,409.218292 
	C363.626038,409.218292 363.848663,409.226746 364.331970,409.092468 
	C366.193817,409.000031 367.572327,409.041901 368.950867,409.083771 
	C368.950867,409.083771 368.991516,409.009003 369.030182,409.365723 
	C371.695770,413.171387 374.322723,416.620270 376.949646,420.069183 
	C376.949646,420.069183 376.990997,420.000824 376.992798,420.365387 
	C378.619232,422.866852 380.243866,425.003723 381.868530,427.140594 
	C381.868530,427.140594 381.911346,426.995422 381.919006,427.396057 
	C383.226837,429.586365 384.527069,431.376007 385.827301,433.165649 
	C385.827301,433.165649 385.817810,433.019836 385.815918,433.470825 
	C384.866089,435.585419 384.263214,438.047913 382.858734,438.654510 
	C381.775818,439.122223 379.597839,437.393402 378.075378,436.386353 
	C377.051666,435.709137 376.322113,434.587158 375.460907,433.664185 
	C375.460907,433.664185 375.325562,433.818909 375.351135,433.380920 
	C371.636780,427.574921 367.896820,422.206909 364.156830,416.838928 
	C364.156830,416.838928 364.195587,416.906311 364.090973,416.492737 
	C363.866241,413.792236 363.746155,411.505249 363.626038,409.218292 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M107.942657,104.060944 
	C106.926979,104.921501 105.207420,105.657356 105.171982,106.466942 
	C104.711617,116.982788 104.771858,116.981171 94.192078,117.019646 
	C93.734856,117.021317 93.278404,117.234863 91.656700,117.642609 
	C93.888550,118.725502 95.249397,119.412125 96.631950,120.051849 
	C101.476921,122.293633 103.691475,130.310974 100.643929,134.616608 
	C98.729797,137.320908 91.300659,137.855042 88.410118,135.348740 
	C81.369942,129.244400 74.384407,123.076378 67.431862,116.872375 
	C66.473923,116.017578 65.828545,114.812515 64.964165,113.438309 
	C64.889870,113.107010 65.053825,112.929161 65.465500,112.920753 
	C66.679733,113.500122 67.515800,114.047577 68.279686,114.681938 
	C75.833862,120.955116 83.375313,127.243599 90.922066,133.525711 
	C93.547218,135.710953 96.194168,135.591766 98.450905,133.144211 
	C100.698158,130.706955 100.764038,128.034378 98.188179,125.671295 
	C96.473076,124.097855 94.586380,122.710899 92.878487,121.130264 
	C90.752731,119.162888 88.732048,117.081985 86.665985,115.050110 
	C88.929665,114.461334 90.349403,114.792274 91.788055,114.944565 
	C93.440285,115.119476 95.108765,115.147568 96.771057,115.219391 
	C102.074150,115.448532 104.290710,112.402618 102.572189,107.236412 
	C101.677795,104.547707 100.753624,101.868896 99.842903,99.185616 
	C100.267723,98.858711 100.692543,98.531815 101.117371,98.204910 
	C103.471649,100.066750 105.825928,101.928581 108.180206,103.790413 
	C108.180206,103.790413 108.021553,103.970520 107.942657,104.060944 
z"/>
          <path fill="#0F2C2B" opacity="1.000000" stroke="none"
            d="
M474.928986,323.980804 
	C474.928986,323.980804 474.736572,324.224518 474.430359,324.524872 
	C472.756775,326.433685 471.566040,328.246948 469.986481,329.609314 
	C466.364807,332.732941 463.167358,336.900940 457.363647,335.408875 
	C456.534302,333.377655 455.769806,331.703217 455.049377,329.648224 
	C458.183075,326.072937 462.810699,325.839111 467.524445,328.730927 
	C468.225098,328.101990 469.325317,327.572510 469.622681,326.752075 
	C470.242218,325.042755 469.919037,322.017273 470.896423,321.581696 
	C475.021454,319.743317 474.204468,317.268494 472.734680,314.298187 
	C472.485352,313.794281 472.399200,312.788605 472.702667,312.494537 
	C478.435089,306.939087 473.387939,302.310699 471.040588,297.904327 
	C469.580139,295.162872 466.362671,293.357361 463.648804,290.977234 
	C462.561401,290.509308 461.758942,290.195953 460.929016,289.549011 
	C459.912048,288.034851 458.922577,286.854340 458.330505,285.633270 
	C467.066040,287.735168 472.297119,293.370941 475.724609,300.915863 
	C479.261871,308.702484 477.451050,316.369171 474.928986,323.980804 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M488.545990,209.000778 
	C488.984406,210.361771 489.012878,211.745529 488.763123,213.783905 
	C488.991547,215.962814 489.498230,217.487122 490.004883,219.011414 
	C490.019257,220.713409 490.033630,222.415421 489.688843,224.613770 
	C486.974792,227.612503 487.637909,228.854919 490.950012,228.991455 
	C491.158813,238.143631 491.367584,247.295792 490.919830,256.729492 
	C488.851624,257.336151 487.439941,257.661316 486.028259,257.986481 
	C486.006165,246.373444 485.978729,234.760406 485.965240,223.147369 
	C485.961212,219.675720 485.809784,216.187836 486.109772,212.740784 
	C486.221893,211.452698 487.428467,210.259842 488.545990,209.000778 
z"/>
          <path fill="#2F87C9" opacity="1.000000" stroke="none"
            d="
M475.292480,323.945679 
	C477.451050,316.369171 479.261871,308.702484 475.724609,300.915863 
	C472.297119,293.370941 467.066040,287.735168 458.230347,285.619202 
	C457.732819,285.645691 457.551025,285.742889 457.271729,285.435669 
	C450.850311,282.685730 444.965210,284.085754 439.280243,286.579559 
	C431.153687,290.144440 426.824615,297.004364 423.705261,305.012390 
	C423.456390,305.013397 422.959045,304.993134 422.959045,304.993134 
	C423.401764,291.415802 432.733673,286.324738 443.836731,282.924500 
	C453.471527,279.973907 461.490082,283.880554 469.246796,288.259735 
	C472.119598,289.881592 473.808472,293.655090 475.944519,296.516632 
	C481.154541,303.496155 480.975922,317.873871 475.292480,323.945679 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M485.015289,324.950073 
	C481.189819,342.395660 474.453430,358.787170 465.997559,374.389008 
	C460.972931,383.659851 454.636261,392.219543 448.575409,401.205566 
	C450.705322,396.888733 452.903168,392.306610 455.635773,388.069397 
	C467.740723,369.299072 475.942749,348.837189 482.323364,327.555634 
	C482.940063,325.498657 483.726685,323.492645 484.715820,321.622681 
	C485.003235,322.838318 485.009247,323.894196 485.015289,324.950073 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M281.967834,48.792118 
	C276.479553,48.334805 270.949677,47.470631 264.972260,46.536514 
	C264.972260,44.753365 265.004791,43.138180 264.965637,41.524731 
	C264.877441,37.889076 266.524384,36.382896 270.185852,36.988396 
	C272.797546,37.420307 275.441254,37.819714 278.079437,37.886250 
	C280.843475,37.955963 284.208282,37.302261 283.688904,33.943192 
	C283.361481,31.825676 280.774963,29.548435 278.635773,28.373413 
	C276.762726,27.344576 274.104156,27.653862 271.784637,27.549809 
	C267.576385,27.361034 266.148193,25.459732 267.608704,21.414148 
	C268.044556,20.206863 268.137390,18.875723 268.482452,17.116524 
	C274.462585,17.116524 280.299133,17.116524 286.542725,17.016296 
	C285.166687,18.186634 283.533264,20.139454 281.566071,20.571114 
	C278.456757,21.253395 275.103302,20.740784 271.897369,21.113750 
	C270.904297,21.229280 270.054749,22.578445 269.139465,23.362803 
	C270.136627,24.234062 271.020325,25.606981 272.156403,25.863876 
	C274.056885,26.293619 276.123108,25.930300 278.111359,26.043926 
	C283.053009,26.326342 286.101898,29.652067 285.968109,34.508411 
	C285.873199,37.953526 282.183411,40.336906 277.338043,39.939171 
	C275.043732,39.750835 272.751923,38.983803 270.494904,39.112049 
	C269.300476,39.179924 267.139221,40.777874 267.239594,41.381729 
	C267.477661,42.814159 268.547333,44.819145 269.746521,45.228367 
	C272.017792,46.003475 274.667389,45.587452 277.060760,46.115368 
	C278.766510,46.491611 280.310272,47.602318 281.967834,48.792118 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M31.121311,367.503723 
	C26.168249,356.048096 20.547342,344.530243 16.413132,332.501495 
	C9.902728,313.559021 6.720635,293.835968 5.592040,273.421387 
	C6.278305,272.999054 6.618433,273.013947 6.958563,273.028809 
	C6.976496,274.084900 6.994430,275.140961 7.009499,276.930725 
	C7.321299,278.447723 7.635963,279.231079 7.950628,280.014435 
	C7.980154,282.041077 8.009680,284.067688 8.127827,286.756042 
	C8.392163,287.703918 8.637444,287.905396 8.952290,288.022186 
	C8.952290,288.022186 8.970999,288.511597 8.982458,289.191040 
	C9.365561,293.884766 9.737205,297.899078 10.098451,302.239990 
	C10.703370,303.380493 11.318688,304.194458 11.943971,305.013367 
	C11.953935,305.018341 11.973708,305.020813 11.973708,305.020813 
	C11.973708,305.020813 11.981958,305.512787 11.986222,306.092346 
	C11.996661,307.116760 12.002837,307.561676 12.009012,308.006561 
	C12.014647,308.747589 12.020282,309.488647 12.118073,310.826172 
	C12.388291,311.706848 12.634685,311.906891 12.949409,312.022766 
	C12.975698,313.073181 13.001986,314.123596 13.124390,315.795654 
	C13.397780,316.703857 13.645170,316.903595 13.962677,317.016479 
	C13.962677,317.016479 13.985958,317.508698 14.010071,318.050842 
	C14.337563,319.068085 14.640942,319.543213 14.944320,320.018341 
	C15.961106,323.749756 16.977894,327.481140 17.999271,331.958069 
	C18.651691,333.480316 19.299524,334.256989 19.947361,335.033691 
	C21.522581,340.682068 22.798342,346.437653 24.769119,351.944458 
	C26.306358,356.239868 28.707525,360.226135 30.877953,364.935913 
	C31.050062,365.696960 31.069002,365.871826 31.087942,366.046661 
	C31.098684,366.435242 31.109426,366.823792 31.121311,367.503723 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M150.026398,63.974262 
	C157.764206,72.296722 153.580627,87.151215 142.314774,91.760155 
	C132.328766,95.845497 122.185608,94.875359 115.886307,84.471741 
	C113.757683,80.956207 110.805016,77.949829 108.386879,74.596863 
	C105.220451,70.206329 104.546310,65.154686 104.929306,59.047180 
	C105.334129,56.154366 105.719765,54.106583 106.455292,52.044670 
	C107.510582,52.016087 108.215965,52.001625 108.831444,52.336121 
	C104.179092,59.463417 105.755692,66.061630 109.685913,72.326927 
	C112.508156,76.825958 115.732742,81.089882 118.987968,85.296066 
	C124.169151,91.990845 131.715240,93.910126 139.482605,90.745544 
	C150.860901,86.109802 154.231400,77.173065 149.503754,64.471260 
	C149.678009,64.305634 150.026398,63.974262 150.026398,63.974262 
z"/>
          <path fill="#3099BD" opacity="1.000000" stroke="none"
            d="
M18.003881,302.032959 
	C20.043829,293.720856 24.926172,287.315308 32.673492,284.105286 
	C41.649643,280.386078 50.809681,281.092377 59.051048,286.805298 
	C60.403076,287.742523 61.075836,289.659668 62.025932,291.572815 
	C61.989330,292.021759 61.837383,292.205414 61.543495,292.168274 
	C60.853958,292.144043 60.458309,292.156891 59.794632,291.937012 
	C57.679916,290.750214 55.833233,289.796173 53.770008,288.589233 
	C50.248569,287.303131 46.943676,286.269897 43.188488,285.145874 
	C31.071972,285.103973 21.893948,293.340332 20.423389,304.837891 
	C19.485584,304.997070 18.862537,305.116333 18.155136,304.837402 
	C18.048483,303.637085 18.026182,302.835022 18.003881,302.032959 
z"/>
          <path fill="#2F87C9" opacity="1.000000" stroke="none"
            d="
M457.428497,335.765656 
	C463.167358,336.900940 466.364807,332.732941 469.986481,329.609314 
	C471.566040,328.246948 472.756775,326.433685 474.433990,324.680115 
	C473.919373,330.853241 469.269287,334.446075 464.308502,336.720734 
	C459.671265,338.846985 454.777405,340.472076 449.152863,340.763062 
	C437.736664,341.353699 422.799896,329.107605 423.175354,317.333344 
	C423.406860,316.985260 423.904510,316.991333 424.179138,317.225403 
	C426.221161,320.705811 427.988617,323.952118 429.843384,327.541351 
	C434.354492,332.501587 438.978577,337.047485 445.731781,337.540680 
	C448.851593,337.768524 452.071045,336.632629 455.614441,336.102234 
	C456.465240,335.981506 456.946869,335.873596 457.428497,335.765656 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M490.965729,276.993896 
	C490.183563,285.137665 489.401428,293.281464 488.334839,301.710785 
	C487.372711,301.052460 486.158752,300.139801 486.101776,299.159821 
	C485.620239,290.879791 485.215851,282.589447 485.095703,274.297699 
	C485.058258,271.711578 485.960480,269.111877 487.044403,266.213684 
	C488.767303,265.274170 489.878296,264.639343 490.989288,264.004517 
	C490.995850,266.738861 491.002411,269.473236 490.617920,272.614563 
	C489.511505,273.721283 488.796143,274.421051 488.080750,275.120789 
	C489.042389,275.745178 490.004059,276.369537 490.965729,276.993896 
z"/>
          <path fill="#3F97D3" opacity="1.000000" stroke="none"
            d="
M173.996429,70.964096 
	C176.553741,69.816833 179.221008,68.862892 181.646408,67.483673 
	C187.406479,64.208183 191.658478,59.968197 191.337982,52.543629 
	C191.028076,45.364697 187.059006,41.718327 179.924026,40.378265 
	C184.374496,32.300503 181.990585,26.339380 174.924042,21.411541 
	C180.732010,21.276728 184.915131,26.700356 183.838165,34.204227 
	C183.239410,38.376175 184.143173,40.079109 188.108276,41.298183 
	C191.547943,42.355709 194.645523,53.632362 193.221390,57.234825 
	C189.867813,65.717949 183.587753,70.392029 174.382095,71.723152 
	C173.998123,71.323067 173.997269,71.143578 173.996429,70.964096 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M123.888969,400.799896 
	C123.704857,401.138916 123.511246,401.165314 123.384102,401.085602 
	C113.986359,395.198029 113.972946,395.185394 106.675385,403.651276 
	C104.642433,406.009705 102.717873,408.461578 100.436485,411.006104 
	C102.787788,406.722015 105.446205,402.302429 108.208366,397.710388 
	C105.292969,396.679535 101.636200,395.386536 97.531464,394.083618 
	C97.083481,394.073669 96.947220,393.948486 97.074379,393.644440 
	C98.797829,391.420959 100.148689,390.774231 102.521469,392.800903 
	C103.821968,393.911682 106.392952,393.535034 108.391045,393.829102 
	C108.017090,391.754761 108.236557,389.270050 107.145172,387.691833 
	C104.901207,384.447052 101.955971,381.687256 99.480148,378.539307 
	C99.780968,378.224548 99.900993,378.098389 100.021019,377.972229 
	C104.699753,382.745392 111.709854,385.880035 111.237228,394.623810 
	C117.209251,392.983612 121.208809,395.417236 123.888969,400.799896 
z"/>
          <path fill="#3099BD" opacity="1.000000" stroke="none"
            d="
M62.066162,291.930664 
	C63.197136,292.248108 65.009781,292.449310 65.186996,293.097595 
	C66.795860,298.983032 70.447166,304.582794 68.931244,310.986633 
	C65.232399,326.611908 54.822590,333.229401 42.943020,332.946747 
	C42.467640,332.935455 41.998077,332.679382 41.547424,331.973999 
	C44.060314,330.942444 46.551579,330.474274 49.395233,329.968140 
	C60.883099,325.584351 67.529564,316.329346 65.874077,304.560547 
	C64.544174,300.170959 63.190777,296.188171 61.837379,292.205414 
	C61.837383,292.205414 61.989330,292.021759 62.066162,291.930664 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M309.757141,436.103638 
	C310.067139,438.181763 309.394623,440.449677 310.006653,442.290466 
	C312.561340,449.973511 315.508240,457.525940 318.297638,465.131287 
	C319.219574,467.644989 320.674530,470.296936 323.524689,468.484131 
	C324.931488,467.589355 325.540833,464.382568 325.362823,462.321014 
	C325.138092,459.718048 323.741913,457.216248 322.851105,454.670807 
	C323.266968,454.361816 323.682861,454.052856 324.098724,453.743896 
	C325.749847,454.835571 327.400970,455.927216 329.167480,457.330322 
	C328.331909,457.706970 327.380920,457.772217 325.732635,457.885315 
	C326.517487,459.672455 327.079041,461.317200 327.923828,462.800659 
	C329.846130,466.176178 328.459198,468.952362 325.654388,470.336823 
	C322.614197,471.837494 318.763580,471.953705 317.147858,468.026459 
	C313.600098,459.403168 310.283539,450.670410 307.338348,441.827271 
	C306.833221,440.310425 308.631287,438.026550 309.757141,436.103638 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M144.828781,397.162842 
	C139.668106,393.706482 133.790131,390.963806 129.494720,386.646576 
	C120.285278,377.390411 111.863419,367.347260 103.215508,357.541321 
	C102.427376,356.647614 102.335266,355.140106 101.961746,353.463013 
	C102.003548,353.008362 102.180931,352.844666 102.449966,352.980042 
	C103.444817,353.784058 104.170631,354.452698 104.925133,355.062927 
	C104.953819,355.004456 104.860573,355.095367 104.898743,355.414551 
	C105.600739,356.539581 106.264565,357.345428 106.976570,358.368683 
	C107.168983,358.871246 107.396965,359.048981 107.791351,359.429932 
	C108.548729,360.533020 109.223434,361.325470 109.920403,362.056885 
	C109.942665,361.995819 109.863220,362.098694 109.928970,362.432617 
	C113.331711,366.497040 116.668701,370.227600 120.001038,373.981293 
	C119.996399,374.004456 120.042450,373.993927 120.165894,374.298828 
	C122.530083,376.722717 124.770821,378.841736 127.109207,381.202393 
	C127.491982,381.628479 127.777115,381.812866 128.136734,382.224487 
	C128.412842,382.723480 128.681122,382.883484 129.126358,383.209656 
	C130.050018,384.028015 130.863388,384.568420 131.820587,385.362030 
	C132.283234,385.825775 132.602036,386.036346 133.003174,386.446899 
	C133.270233,386.893372 133.515930,387.030853 133.935501,387.305176 
	C134.357971,387.718536 134.667557,387.886169 134.987137,388.026733 
	C134.997131,387.999634 134.945740,388.026062 135.036682,388.263489 
	C135.416916,388.653992 135.706238,388.807068 136.186859,389.193024 
	C137.182343,389.974640 137.986542,390.523376 138.914566,391.312866 
	C139.348373,391.721161 139.658356,391.888733 139.982025,392.027710 
	C139.995712,391.999176 139.944397,392.036346 140.026535,392.264893 
	C140.336960,392.764679 140.627182,392.880035 141.124100,392.957947 
	C141.268829,393.076385 141.639923,393.123169 141.790253,393.368652 
	C142.275467,393.780304 142.610367,393.946472 142.956024,394.070374 
	C142.966782,394.028076 142.888580,394.066803 142.978699,394.313660 
	C143.372803,394.711945 143.676773,394.863403 143.989944,395.007446 
	C143.999146,395.000000 143.987244,395.019745 144.081360,395.240387 
	C144.398102,395.721832 144.680862,395.852631 145.080933,396.075256 
	C145.138077,396.297150 145.250244,396.741425 145.145569,396.847473 
	C145.040909,396.953522 144.828781,397.162842 144.828781,397.162842 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M363.223022,409.257812 
	C363.746155,411.505249 363.866241,413.792236 364.084656,416.453094 
	C363.683014,418.379852 362.566528,420.026123 362.785248,421.470123 
	C364.011292,429.563965 365.778168,437.578949 366.893677,445.685059 
	C367.361725,449.086212 368.031952,453.345245 362.561859,454.032501 
	C357.809387,454.629547 354.753143,452.560303 353.205933,447.757507 
	C354.954437,448.671021 356.584290,450.056854 358.334045,451.269775 
	C361.774506,453.654724 365.171387,452.129639 365.173615,447.983124 
	C365.175293,444.877380 364.483215,441.761780 363.994202,438.667297 
	C363.040070,432.629791 362.083130,426.591095 360.991974,420.577454 
	C360.100555,415.664764 358.721100,415.003876 353.441650,416.946472 
	C352.664185,410.246552 358.062683,415.452240 359.803101,413.866974 
	C360.918701,412.177246 361.869385,410.737305 363.223022,409.257812 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M58.855316,372.842468 
	C61.154572,370.463837 63.849411,368.148895 66.544250,365.833984 
	C66.857971,366.081818 67.171692,366.329651 67.485413,366.577484 
	C65.349548,371.288361 62.908207,375.893097 61.217587,380.758667 
	C60.467209,382.918304 61.233128,385.604736 61.310768,388.052063 
	C63.695038,387.605774 66.468170,387.787048 68.390747,386.595734 
	C74.178886,383.009003 79.635872,378.887848 85.565353,374.923065 
	C82.650185,377.878204 79.499435,381.014923 76.095352,383.847137 
	C73.820854,385.739594 70.951553,386.939941 68.769562,388.915192 
	C65.790199,391.612213 62.256062,392.088531 59.883484,389.554993 
	C58.295959,387.859802 58.387669,384.177246 58.527908,381.424774 
	C58.621323,379.591492 60.067722,377.799835 61.010487,376.044281 
	C62.111412,373.994202 61.678497,372.920593 58.855316,372.842468 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M462.863373,235.831696 
	C461.702026,237.750320 460.067871,239.141388 459.474823,240.886292 
	C456.110168,250.786240 458.370941,248.797760 448.572968,249.115601 
	C446.726013,249.175507 444.884155,249.393036 443.039917,249.537827 
	C442.918121,249.959946 442.796356,250.382080 442.674561,250.804199 
	C444.669403,252.390396 446.586731,254.088440 448.676788,255.537338 
	C452.065521,257.886505 453.700378,260.504364 451.584045,264.713959 
	C450.418823,267.031616 450.004547,269.726837 449.238922,272.631714 
	C449.194366,270.581207 448.556183,267.956360 449.259888,265.761810 
	C450.633301,261.478638 450.345551,258.337097 445.841797,256.355682 
	C444.661713,255.836502 443.369476,255.069550 442.685516,254.044785 
	C441.697815,252.564804 441.218140,250.745728 440.521698,249.071335 
	C442.204071,248.386230 443.995239,246.925385 445.550995,247.143509 
	C454.788452,248.438660 456.193970,245.511627 458.207062,237.939606 
	C459.121338,234.500641 460.159485,234.071930 462.863373,235.831696 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M381.057007,417.264862 
	C383.439178,419.184204 385.818481,421.447540 388.134308,423.774017 
	C391.413269,427.068024 395.334778,427.912781 399.499481,426.179932 
	C404.297485,424.183502 407.878021,420.716187 408.729523,415.373505 
	C408.914490,414.213013 407.328918,412.770294 406.558777,411.457581 
	C404.891785,412.539978 402.610321,413.249390 401.681305,414.779785 
	C399.519379,418.341339 397.483978,420.865601 393.053070,417.420593 
	C396.644135,417.469971 398.975800,416.020294 400.979370,412.654297 
	C402.024872,410.897827 405.384033,409.210968 407.291290,409.582336 
	C410.130951,410.135223 410.855164,412.714813 410.328003,416.243683 
	C409.263489,423.369354 404.662170,426.607849 398.756989,428.423889 
	C393.583618,430.014862 388.935791,428.233765 385.501678,423.936066 
	C383.896332,421.926971 382.510895,419.742188 381.057007,417.264862 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M426.974731,425.259430 
	C417.968292,433.747223 408.674927,442.231873 399.183655,450.489014 
	C396.320068,452.980194 392.907013,454.839783 389.398071,457.036560 
	C390.499664,455.438293 391.771393,453.582550 393.427185,452.180298 
	C403.469879,443.675201 413.591278,435.262878 423.717407,426.856934 
	C424.565094,426.153259 425.641632,425.725128 426.974731,425.259430 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M329.271484,458.033691 
	C331.527740,459.117035 333.546539,460.990234 335.930908,461.852570 
	C337.578461,462.448364 339.731079,461.647614 341.658020,461.470947 
	C341.274658,459.589996 341.481812,457.185669 340.379486,455.941925 
	C338.360413,453.663879 335.568115,452.071198 332.932281,450.066467 
	C335.957153,444.272369 336.568817,438.836151 331.646790,433.574646 
	C332.783295,433.725281 334.068420,434.216217 335.676758,434.853577 
	C336.000000,435.000000 335.998779,434.998779 335.998779,434.998779 
	C336.310333,438.433594 337.377625,442.015686 336.744202,445.266388 
	C335.933044,449.429382 336.934662,451.573608 340.593201,453.569824 
	C343.473511,455.141388 346.266907,458.021210 344.343903,461.703125 
	C342.790863,464.676636 339.552979,466.095520 336.258667,464.095459 
	C333.693695,462.538269 331.531281,460.317963 329.271484,458.033691 
z"/>
          <path fill="#266C7C" opacity="1.000000" stroke="none"
            d="
M49.042847,330.006104 
	C46.551579,330.474274 44.060314,330.942444 41.285202,331.668823 
	C38.589439,331.320526 36.177525,330.713959 33.289894,329.821808 
	C32.596176,329.260834 32.311634,329.117340 31.960556,329.105804 
	C30.167751,327.847473 28.374943,326.589142 26.201239,324.934082 
	C25.609579,324.258270 25.331367,324.092224 24.985710,324.039124 
	C24.187151,323.133728 23.388590,322.228302 22.306396,320.898834 
	C21.958429,320.319244 21.894093,320.163696 21.829754,320.008118 
	C21.396660,319.119171 20.963570,318.230255 20.204340,316.907410 
	C19.625862,316.217041 19.373522,315.960571 19.121181,315.704102 
	C19.094425,314.398621 19.067669,313.093140 19.043072,311.139038 
	C19.021889,310.320312 18.998543,310.150208 18.975199,309.980103 
	C18.862335,309.127716 18.749472,308.275330 18.423225,306.892426 
	C18.219725,305.986481 18.229609,305.611053 18.239491,305.235626 
	C18.862537,305.116333 19.485584,304.997070 20.523827,305.143494 
	C20.978260,305.831299 21.017502,306.253448 21.014416,307.130829 
	C22.290485,311.400482 23.608881,315.214935 24.983425,319.358826 
	C26.362423,321.107300 27.685270,322.526306 29.262899,324.006042 
	C29.691841,324.079498 29.866001,324.092133 30.255653,324.281860 
	C30.978758,324.985870 31.486376,325.512756 32.233822,326.065613 
	C32.473648,326.091583 32.955978,326.080383 32.937019,326.078674 
	C32.918056,326.076996 32.897171,326.113953 33.080807,326.423889 
	C38.523911,327.824615 43.783379,328.915344 49.042847,330.006104 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M103.098206,50.056164 
	C106.925468,47.229473 110.752724,44.402782 115.102417,41.307503 
	C116.015076,40.956478 116.405304,40.874050 116.877274,40.875572 
	C116.959000,40.959518 117.120987,41.128838 117.120987,41.128838 
	C118.653931,41.405094 120.346420,42.240761 121.691193,41.857227 
	C126.563965,40.467510 130.938217,40.903370 134.855927,44.700455 
	C134.819214,45.192497 134.551056,45.475914 134.197479,45.364925 
	C129.825989,44.766106 125.466316,43.074345 121.876137,44.093437 
	C117.329865,45.383919 113.409355,48.878872 109.154732,51.062660 
	C108.833366,49.981388 108.576241,49.260551 108.319115,48.539715 
	C107.564865,49.016434 106.810608,49.493149 106.037231,49.965042 
	C106.018097,49.960217 105.985237,49.938366 105.898712,49.775139 
	C105.535454,49.722191 105.258720,49.832478 104.668488,49.971592 
	C103.936058,50.019005 103.517128,50.037586 103.098206,50.056164 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M388.695923,457.032532 
	C377.313782,463.707336 365.548157,470.358795 353.412720,477.043213 
	C353.553619,476.303314 353.883911,475.208038 354.602173,474.806030 
	C364.708038,469.149994 374.857330,463.571014 385.027710,458.031342 
	C386.015228,457.493469 387.212524,457.340698 388.695923,457.032532 
z"/>
          <path fill="#3F97D3" opacity="1.000000" stroke="none"
            d="
M157.037094,23.782990 
	C156.714844,23.919323 156.392578,24.055653 155.493958,24.199236 
	C154.611862,24.468100 154.306168,24.729712 154.000458,24.991325 
	C154.000458,24.991325 153.695847,25.168705 153.164215,25.128143 
	C152.075989,25.448799 151.519394,25.810019 150.962784,26.171240 
	C150.203232,26.716423 149.443695,27.261604 148.137680,27.915802 
	C147.392365,28.017164 147.193527,28.009512 146.994705,28.001858 
	C146.546890,27.368439 146.099091,26.735022 145.328644,25.826405 
	C145.006012,25.551205 145.005676,25.065641 145.005676,25.065641 
	C145.884949,24.577053 146.764206,24.088465 148.165039,23.362457 
	C149.221466,22.803282 149.756332,22.481527 150.291183,22.159771 
	C150.291183,22.159773 150.673523,22.138197 151.134247,22.053066 
	C152.057648,21.641354 152.520309,21.314772 152.982971,20.988190 
	C152.982971,20.988190 152.999603,21.000750 153.344650,20.973621 
	C154.782822,20.544561 155.875946,20.142626 156.969070,19.740688 
	C157.750534,19.442795 158.532013,19.144905 159.964020,18.835854 
	C162.423248,18.275362 164.231934,17.726028 166.040634,17.176697 
	C167.025055,17.779966 167.962891,18.485731 169.005997,18.959921 
	C170.241638,19.521629 171.568039,19.883707 172.916718,20.714226 
	C168.390198,21.774918 163.802048,22.453690 158.563431,23.147511 
	C157.621002,23.369368 157.329041,23.576178 157.037094,23.782990 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M79.615402,352.007324 
	C77.341011,352.312775 74.626160,351.388153 73.145012,352.354492 
	C65.912003,357.073395 58.960529,362.233032 52.032726,367.401978 
	C50.808506,368.315369 50.169006,370.012451 49.191254,371.691101 
	C46.683487,369.459137 48.187450,367.288330 50.244976,365.811310 
	C57.602215,360.529968 65.028847,355.329407 72.646599,350.436768 
	C74.868347,349.009796 77.925903,348.100525 79.615402,352.007324 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M86.299728,374.958557 
	C86.996300,373.976654 88.101089,373.049469 89.497818,372.007080 
	C91.521973,372.604065 93.335533,373.169159 94.963806,374.069366 
	C96.633499,374.992523 98.121933,376.243530 99.855362,377.661621 
	C99.900993,378.098389 99.780968,378.224548 99.184830,378.345703 
	C96.392204,377.771759 94.075676,377.202789 91.759155,376.633850 
	C92.207001,378.750580 92.751671,380.853149 93.063324,382.989746 
	C93.228119,384.119629 93.456139,385.767578 92.846893,386.389954 
	C90.464340,388.823853 87.759270,390.942047 85.089218,393.089264 
	C85.004372,393.000977 84.837921,392.821320 84.837921,392.821320 
	C85.320801,391.794647 85.537262,390.201141 86.327995,389.829529 
	C93.766396,386.333679 88.467918,380.688995 88.706284,376.026886 
	C88.721764,375.723877 87.404999,375.352753 86.299728,374.958557 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M90.585663,369.814575 
	C88.605011,365.234253 86.420036,364.468170 82.327888,367.139008 
	C79.551796,368.950897 76.736328,370.702484 73.938461,372.481049 
	C73.479340,372.126892 73.020218,371.772705 72.561089,371.418518 
	C74.892937,366.764374 77.224777,362.110199 79.818489,357.226990 
	C79.821785,358.769562 79.761459,360.601196 79.248291,362.295685 
	C78.745331,363.956482 77.781509,365.477722 76.797691,367.522064 
	C80.378204,366.173584 83.205482,364.428162 86.156494,364.185120 
	C89.225433,363.932343 92.841057,364.774200 90.585663,369.814575 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M209.068527,60.063004 
	C207.656860,61.080624 205.642654,63.128242 204.751343,62.720604 
	C202.386185,61.638885 199.225784,59.837143 198.661896,57.715755 
	C196.592834,49.931568 195.450592,41.901031 193.926331,33.528694 
	C193.893188,33.094154 194.131119,32.908333 194.472137,33.123341 
	C196.419937,40.401333 197.989304,47.473103 199.645111,54.524574 
	C201.022552,60.390453 203.041458,61.513977 208.798706,59.793030 
	C208.798706,59.793030 208.977875,59.973728 209.068527,60.063004 
z"/>
          <path fill="#2296F2" opacity="1.000000" stroke="none"
            d="
M10.108850,301.913391 
	C9.737205,297.899078 9.365561,293.884766 9.000315,289.435242 
	C10.298643,289.754913 12.360429,290.239899 12.737610,291.315582 
	C13.852436,294.494965 14.301438,297.907867 15.043148,301.948029 
	C15.387165,303.437653 15.698946,304.208649 16.010725,304.979645 
	C15.004223,308.889771 13.732567,304.643646 12.293586,305.055450 
	C11.973708,305.020813 11.953935,305.018341 11.955229,304.665283 
	C11.340632,303.512634 10.724743,302.713013 10.108850,301.913391 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M336.715576,425.068665 
	C335.066650,428.291992 335.700867,430.858246 338.559570,433.342804 
	C343.425079,437.571442 348.084625,442.037201 352.953857,446.723907 
	C348.902832,444.037537 344.721405,441.040588 340.558777,438.017731 
	C339.245148,437.063812 337.981873,436.040558 336.347168,435.023926 
	C335.998779,434.998779 336.000000,435.000000 336.000610,435.000610 
	C335.380646,433.324890 334.233307,431.631500 334.287048,429.977051 
	C334.341125,428.310272 335.588501,426.682220 336.715576,425.068665 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M392.961731,416.716644 
	C390.375549,414.345825 387.553802,411.800323 385.332031,408.809235 
	C382.476288,404.964600 383.775940,401.437897 388.485046,400.093658 
	C391.370087,399.270142 393.523499,398.359985 392.885742,394.446930 
	C395.641907,396.967010 395.414673,399.282471 392.185059,400.768280 
	C384.660950,404.229706 384.619141,404.474213 389.373505,410.737335 
	C390.714569,412.503998 391.793579,414.469604 392.961731,416.716644 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M99.804428,411.105957 
	C99.009941,412.699188 98.038483,414.460022 96.663139,415.809082 
	C93.232666,419.174042 93.731903,422.106506 97.232315,424.951813 
	C99.794357,427.034363 102.345764,429.130005 105.033356,431.528778 
	C97.025566,430.985229 90.317169,423.329041 93.145760,417.624512 
	C94.427444,415.039642 97.295334,413.241272 99.804428,411.105957 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M352.782166,417.276978 
	C354.055939,423.838867 355.000519,430.652924 355.945129,437.466980 
	C355.649048,437.726440 355.352997,437.985901 355.056946,438.245361 
	C351.650574,434.864899 348.244232,431.484436 344.737183,427.807953 
	C347.223846,428.938019 349.811218,430.364105 352.453003,431.820160 
	C352.453003,427.335358 352.453003,422.432251 352.782166,417.276978 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M474.225769,272.881409 
	C473.663361,271.778992 473.774567,270.495972 473.271545,269.534180 
	C468.818756,261.020721 472.345917,255.704727 480.442200,251.933578 
	C480.313446,253.013931 480.189941,254.612274 479.343597,255.247437 
	C472.975250,260.026886 473.067566,259.924072 475.508026,267.597137 
	C475.986389,269.101166 474.951508,271.086456 474.225769,272.881409 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M61.911026,91.951324 
	C61.347267,92.451324 60.783512,92.951332 60.099766,93.968307 
	C59.979259,94.654800 59.978741,94.824326 59.978226,94.993851 
	C59.978226,94.993851 60.002548,94.998604 59.652210,95.046326 
	C58.860180,96.058578 58.418484,97.023109 57.976791,97.987640 
	C57.976791,97.987640 57.991978,97.998611 57.986713,97.987808 
	C55.211147,100.643005 52.440845,103.308998 49.312241,106.020721 
	C53.937252,99.326614 58.920559,92.586777 64.334831,85.821548 
	C64.765793,85.796150 65.030258,86.022469 65.024811,86.274200 
	C65.028427,86.693550 65.037476,86.861168 64.892555,87.314590 
	C63.796070,89.050705 62.853550,90.501015 61.911026,91.951324 
z"/>
          <path fill="#3F97D3" opacity="1.000000" stroke="none"
            d="
M367.036011,384.835205 
	C366.858429,385.798309 367.026764,387.212250 366.449829,387.654907 
	C360.588867,392.152130 354.614563,396.502319 348.634979,400.842621 
	C348.317841,401.072845 347.668152,400.845001 346.979156,400.448700 
	C349.064362,398.161499 351.343689,396.255920 353.857910,394.324280 
	C354.427399,393.969879 354.761932,393.641602 355.384460,393.240845 
	C356.067535,392.762756 356.462646,392.357178 357.138428,391.874207 
	C357.981445,391.285553 358.543793,390.774261 359.424316,390.170776 
	C360.333405,389.450409 360.924347,388.822296 361.669067,388.177246 
	C361.822815,388.160309 362.011292,387.914978 362.252869,387.863647 
	C362.801697,387.635040 362.984680,387.371490 363.029907,387.014801 
	C363.016357,387.007935 363.029572,387.040924 363.356750,386.980286 
	C364.801331,386.224854 365.918671,385.530029 367.036011,384.835205 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M86.157349,405.176575 
	C83.862686,406.728363 81.723495,408.747131 79.192711,409.589661 
	C78.251541,409.903046 75.277321,407.538086 75.191353,406.262360 
	C75.023804,403.775818 75.611076,400.738770 77.042816,398.772034 
	C78.788010,396.374756 81.724747,394.844910 84.492020,392.880615 
	C84.837921,392.821320 85.004372,393.000977 84.963890,393.394135 
	C83.517776,395.192383 82.021675,396.519440 80.727089,398.020294 
	C78.634071,400.446777 74.010231,402.373535 77.290749,406.431427 
	C80.478508,410.374573 83.062202,406.032227 85.885231,404.862915 
	C85.976028,404.967377 86.157349,405.176575 86.157349,405.176575 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M450.193848,274.044006 
	C452.427307,272.035065 455.007446,270.547943 457.183228,268.607452 
	C460.292358,265.834564 463.044708,265.406372 466.122437,268.579407 
	C467.487305,269.986511 469.218140,271.038635 470.955566,272.539124 
	C469.633514,272.593689 467.487976,272.909698 466.752258,272.037048 
	C462.748596,267.288330 460.912109,268.874817 456.304840,272.258911 
	C454.682983,273.450195 452.361786,273.689392 450.193848,274.044006 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M391.806946,392.797119 
	C381.104980,392.228821 374.214325,400.601898 377.247955,411.436554 
	C373.035706,408.049683 372.989288,404.452911 377.093048,397.853455 
	C380.224243,392.818146 387.411194,390.150024 391.806946,392.797119 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M70.077805,203.885864 
	C75.095001,195.714294 74.750832,186.900269 72.784157,177.467041 
	C73.529060,177.913513 74.867577,178.771713 74.889877,179.662857 
	C75.060570,186.483810 74.975037,193.311188 74.981140,200.571808 
	C74.993126,201.007050 75.000153,200.999771 75.003403,200.995255 
	C73.482117,201.965149 71.957588,202.939560 70.077805,203.885864 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M101.917465,353.092957 
	C100.131615,351.495056 97.932457,350.086700 96.829155,348.076782 
	C93.943710,342.820251 91.537193,337.300873 88.898026,331.508118 
	C88.856300,331.129974 89.039658,330.976654 89.403244,331.100403 
	C91.454620,333.956665 93.142410,336.689178 94.824844,339.583893 
	C94.819504,339.746094 95.027138,339.995514 95.049042,340.348694 
	C97.440941,344.749451 99.810936,348.797058 102.180931,352.844666 
	C102.180931,352.844666 102.003548,353.008362 101.917465,353.092957 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M5.049428,264.979797 
	C4.945671,256.238190 4.841913,247.496597 5.178705,238.619873 
	C5.754319,239.327911 5.889384,240.171051 6.024449,241.014206 
	C6.020947,246.029999 6.017446,251.045792 6.005430,256.956604 
	C5.681087,260.227692 5.365257,262.603729 5.049428,264.979797 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M480.751526,248.936188 
	C478.210144,249.261093 475.514191,248.833527 472.930664,249.199570 
	C467.916809,249.909927 465.646393,247.731262 464.910950,242.998688 
	C464.585968,240.907364 463.648285,238.911270 462.905701,236.508698 
	C464.303558,238.396774 466.220154,240.481720 467.159058,242.940063 
	C468.435120,246.281113 470.429901,247.244308 473.830750,247.131378 
	C476.084961,247.056519 478.374176,248.035248 480.751526,248.936188 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M134.953552,45.051052 
	C136.886063,46.229496 139.126221,47.221157 140.397125,48.932587 
	C143.817688,53.538837 146.824585,58.452297 150.011047,63.609035 
	C150.026398,63.974262 149.678009,64.305634 149.292328,64.216003 
	C144.121445,57.909550 139.336243,51.692730 134.551056,45.475914 
	C134.551056,45.475914 134.819214,45.192497 134.953552,45.051052 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M153.705475,51.195240 
	C156.287689,56.438969 158.499573,61.963028 160.976471,67.365585 
	C161.830276,69.227859 163.344467,70.787354 164.787109,72.740036 
	C163.680649,72.608696 161.474625,72.580933 161.144379,71.779869 
	C158.380798,65.076340 155.961914,58.230701 153.705475,51.195240 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M111.709572,426.936157 
	C110.039330,426.139587 108.510010,424.910919 106.888489,423.819183 
	C104.569870,422.258087 103.156380,420.559143 105.157814,417.756134 
	C107.331680,414.711609 109.049126,416.726135 110.819138,418.193146 
	C111.576981,418.821289 112.271896,419.525360 113.087387,420.518921 
	C111.357811,420.408722 109.536255,419.974518 107.427971,419.471954 
	C108.954903,421.944031 110.382103,424.254639 111.709572,426.936157 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M163.978516,409.016235 
	C161.456100,408.328247 158.722443,407.995758 156.648407,406.648102 
	C152.577591,404.003113 148.873886,400.793091 144.923523,397.489868 
	C144.828781,397.162842 145.040909,396.953522 145.432709,396.895569 
	C146.201874,396.926727 146.579208,397.015869 147.098633,397.383636 
	C148.134445,398.145172 149.028168,398.628082 149.956635,399.081238 
	C149.991379,399.051453 149.900085,399.057831 149.998779,399.296600 
	C150.399521,399.681458 150.701553,399.827515 151.001266,399.989197 
	C150.998932,400.004791 151.028519,399.993805 151.101929,400.199066 
	C151.374283,400.666138 151.633728,400.759064 152.156372,400.824280 
	C152.359070,400.965485 152.841461,401.072113 153.003372,401.351318 
	C154.093063,402.115723 155.020844,402.600922 155.975113,403.051025 
	C156.001617,403.015961 155.924622,403.058411 156.120728,403.332001 
	C158.957001,405.305267 161.597168,407.004974 164.237335,408.704681 
	C164.237335,408.704681 164.065399,408.912903 163.978516,409.016235 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M416.995361,66.346886 
	C422.044373,71.000336 427.081940,76.020607 432.219177,81.355392 
	C431.404541,81.450867 430.155426,81.538773 429.628723,80.964355 
	C425.337494,76.284111 421.181274,71.480064 416.995361,66.346886 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M330.990112,432.582214 
	C323.719147,430.303833 317.441803,434.175232 310.484436,436.132690 
	C310.192413,435.678802 310.134064,434.891418 310.390717,434.770081 
	C316.368256,431.943390 322.307098,428.806671 329.270233,430.175171 
	C329.941284,430.307068 330.407562,431.481110 330.990112,432.582214 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M442.701294,408.042725 
	C438.368896,413.400909 433.686737,418.772797 428.696930,424.242035 
	C430.152039,421.754150 431.688812,418.971283 433.733795,416.632812 
	C436.395233,413.589355 439.460205,410.898834 442.701294,408.042725 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M62.914864,164.821121 
	C56.905529,163.821686 50.882450,162.455612 44.408333,161.007050 
	C45.539455,159.957779 47.517536,157.839066 48.635368,158.223251 
	C53.526699,159.904388 58.168667,162.311066 62.914864,164.821121 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M37.075996,214.207199 
	C44.154816,214.991272 51.271217,215.943680 58.631607,212.993225 
	C58.478432,213.748932 58.085632,214.899597 57.356060,215.182861 
	C50.557667,217.822296 43.771027,218.110977 37.075996,214.207199 
z"/>
          <path fill="#2296F2" opacity="1.000000" stroke="none"
            d="
M103.070282,50.045135 
	C103.517128,50.037586 103.936058,50.019005 104.919395,49.982307 
	C105.650955,49.955578 105.818100,49.946972 105.985237,49.938366 
	C105.985237,49.938366 106.018097,49.960217 106.021820,50.313599 
	C106.052170,51.130920 106.078781,51.594856 106.105392,52.058796 
	C105.719765,54.106583 105.334129,56.154366 104.899094,58.595428 
	C102.802666,58.029419 100.755653,57.070129 98.513176,55.747532 
	C99.898987,53.619450 101.480255,51.854671 103.061523,50.089897 
	C103.061523,50.089897 103.042366,50.034111 103.070282,50.045135 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M364.108063,417.237793 
	C367.896820,422.206909 371.636780,427.574921 375.389038,433.285370 
	C370.438477,429.135895 365.412933,424.688446 364.108063,417.237793 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M375.333679,434.035004 
	C376.322113,434.587158 377.051666,435.709137 378.075378,436.386353 
	C379.597839,437.393402 381.775818,439.122223 382.858734,438.654510 
	C384.263214,438.047913 384.866089,435.585419 385.799164,433.541962 
	C386.457062,434.467987 387.978699,436.615234 387.659119,436.937653 
	C386.070038,438.541107 384.033295,440.669830 382.084961,440.741852 
	C378.303528,440.881622 375.822876,438.354492 375.333679,434.035004 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M490.998474,263.566772 
	C489.878296,264.639343 488.767303,265.274170 487.326935,265.952759 
	C486.667206,263.604767 486.336823,261.213074 486.017334,258.403931 
	C487.439941,257.661316 488.851624,257.336151 490.631042,257.007935 
	C491.001740,259.046265 491.004700,261.087616 490.998474,263.566772 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M166.339035,17.006058 
	C164.231934,17.726028 162.423248,18.275362 160.271820,18.684441 
	C161.870010,17.307718 163.666916,15.658803 165.781754,14.920398 
	C170.224228,13.369286 174.833710,12.296472 179.809509,11.079809 
	C176.869202,12.494546 173.494003,13.856789 169.953491,15.542009 
	C168.737930,16.188465 167.687683,16.511944 166.339035,17.006058 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M146.694641,33.243652 
	C148.993576,38.080727 151.023392,43.153301 153.072144,48.607727 
	C148.613525,44.788822 147.136841,39.298447 146.694641,33.243652 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M20.296247,184.042053 
	C21.505812,189.326736 22.358950,194.753235 23.210032,200.581177 
	C18.717201,197.525375 17.497730,191.257126 20.296247,184.042053 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M353.622986,394.350342 
	C351.343689,396.255920 349.064362,398.161499 346.583984,400.347321 
	C346.382904,400.627594 346.167358,400.621094 345.788879,400.725800 
	C344.953613,401.219269 344.496826,401.608032 343.769867,402.063171 
	C343.227173,402.375916 342.954681,402.622284 342.402710,402.879944 
	C341.754517,402.932587 341.385803,402.973877 341.045074,402.666870 
	C344.215424,398.022980 347.701843,394.269318 353.622986,394.350342 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M81.976059,71.002068 
	C80.946129,71.012383 79.916199,71.022697 78.500885,71.047554 
	C81.379456,67.999901 84.643394,64.937706 88.372086,62.112389 
	C88.891853,62.562416 88.946877,62.775574 89.001892,62.988728 
	C87.646179,65.067566 86.290459,67.146408 84.912498,69.601868 
	C84.490967,69.971863 84.091682,69.965240 83.219635,69.802750 
	C82.489944,70.098602 82.233002,70.550331 81.976059,71.002068 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M118.920372,414.758820 
	C117.322800,413.980591 115.364090,413.104553 114.272652,411.599548 
	C113.503792,410.539368 113.913216,408.624695 113.796547,407.091553 
	C115.262146,407.014465 116.727745,406.937347 118.281540,407.133698 
	C112.771210,410.199371 119.126297,412.033142 118.920372,414.758820 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M102.722382,50.138580 
	C101.480255,51.854671 99.898987,53.619450 98.165344,55.690418 
	C96.448257,57.145477 94.883530,58.294338 92.741463,59.701340 
	C91.662895,59.919338 91.161667,59.879196 90.660446,59.839050 
	C94.568047,56.621788 98.475647,53.404530 102.722382,50.138580 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M113.142761,421.176453 
	C119.038361,421.887970 120.104790,420.964050 118.917130,415.427338 
	C122.951347,416.259979 122.282104,418.908173 120.627289,421.495239 
	C118.284630,425.157715 115.657173,424.223114 113.142761,421.176453 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M111.984589,137.972595 
	C114.081558,135.287659 116.178520,132.602722 118.417191,129.597168 
	C118.558907,129.276535 118.812012,129.162689 118.885681,129.045319 
	C119.435593,128.657532 119.911835,128.387115 120.679642,128.393951 
	C119.038857,132.798889 117.549377,137.354431 111.984589,137.972595 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M367.125214,384.800781 
	C365.918671,385.530029 364.801331,386.224854 363.377350,386.620300 
	C364.437775,381.977905 367.489410,379.658661 371.899750,378.971771 
	C370.945129,380.118469 369.990509,381.265167 368.931946,382.728027 
	C368.827972,383.044128 368.766449,383.214996 368.460083,383.422180 
	C367.895050,384.041504 367.636414,384.453674 367.377747,384.865845 
	C367.377747,384.865845 367.214417,384.766327 367.125214,384.800781 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M36.185776,162.320435 
	C33.034119,165.379883 29.493723,168.330261 25.628029,171.396561 
	C26.385357,165.686768 31.124990,163.987183 36.185776,162.320435 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M119.444550,38.215187 
	C119.444542,38.215187 119.747353,38.209625 120.146759,38.078041 
	C120.791046,37.676113 121.035927,37.405762 121.280815,37.135414 
	C121.280815,37.135418 121.664749,37.045280 122.067108,36.905704 
	C122.784737,36.617443 122.979332,36.372757 123.053261,36.032070 
	C123.053261,36.032070 123.003944,35.999161 123.307854,36.003704 
	C124.068916,35.667755 124.526070,35.327263 124.983223,34.986771 
	C124.983223,34.986767 124.999924,35.000458 125.315720,34.972153 
	C126.479408,34.328403 127.327309,33.712963 128.175201,33.097523 
	C128.175201,33.097523 128.619354,32.978828 129.017731,32.879822 
	C129.416107,32.780819 129.843246,32.822624 129.913605,32.900951 
	C129.983978,32.979279 130.124344,33.136265 130.124344,33.136265 
	C126.028297,35.768799 121.932259,38.401329 117.478600,41.081352 
	C117.120987,41.128838 116.959000,40.959518 116.927658,40.671196 
	C116.896317,40.382877 116.927116,39.962502 116.927116,39.962502 
	C117.246666,39.911297 117.533478,39.785469 118.215736,39.316654 
	C118.910797,38.770592 119.177673,38.492889 119.444550,38.215187 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M376.976074,419.691467 
	C374.322723,416.620270 371.695770,413.171387 368.993835,409.387817 
	C374.034027,410.566284 375.455841,414.989197 376.976074,419.691467 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M4.998348,265.424072 
	C5.365257,262.603729 5.681087,260.227692 6.008241,257.423065 
	C6.342514,262.026276 6.665462,267.058044 6.973486,272.559326 
	C6.618433,273.013947 6.278305,272.999054 5.683136,272.984467 
	C5.267820,270.612640 5.107545,268.240479 4.998348,265.424072 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M121.129333,410.217957 
	C124.011330,410.279419 127.784676,414.098816 128.957886,407.402405 
	C128.848404,408.864746 128.675613,410.715851 128.502838,412.566956 
	C126.046295,411.911804 123.589745,411.256653 121.129333,410.217957 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M49.128853,372.938171 
	C51.747158,375.166870 54.453850,375.378510 57.672493,372.975616 
	C56.278271,374.255676 54.677643,376.064606 52.706203,376.674042 
	C51.912300,376.919495 50.305790,374.536316 49.128853,372.938171 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M144.677826,25.062647 
	C145.005676,25.065641 145.006012,25.551205 145.008148,25.794382 
	C141.914764,27.664673 138.819214,29.291784 135.212891,30.800142 
	C134.702103,30.681389 134.397842,30.354483 134.575790,30.175671 
	C134.936737,29.717609 135.119720,29.438360 135.302704,29.159107 
	C135.302704,29.159105 135.667114,29.120497 136.100220,29.007517 
	C136.791595,28.645662 137.049896,28.396788 137.308182,28.147915 
	C137.308182,28.147915 137.675247,28.098232 138.122665,28.008467 
	C139.017975,27.593483 139.465851,27.268265 139.913742,26.943043 
	C139.913742,26.943043 139.973541,26.993036 140.236969,26.922543 
	C140.758118,26.612144 141.015839,26.372242 141.273560,26.132343 
	C142.299042,25.774782 143.324509,25.417217 144.677826,25.062647 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M341.017120,403.015198 
	C341.385803,402.973877 341.754517,402.932587 342.674042,402.995026 
	C343.475525,403.390656 343.726166,403.682495 343.976807,403.974365 
	C343.219116,404.315521 342.461456,404.656677 341.143311,405.103394 
	C340.297791,405.396881 340.099915,405.652374 339.989197,405.975342 
	C337.932556,406.996765 335.875885,408.018158 333.469482,409.085754 
	C333.119690,409.131958 332.939606,408.918060 333.043701,408.567963 
	C334.125397,407.490143 335.103027,406.762451 336.360229,405.969025 
	C337.098724,405.560089 337.557617,405.216858 338.270142,404.842590 
	C338.745789,404.563293 338.967834,404.315033 339.425140,404.022095 
	C339.660400,403.977386 340.044647,403.691376 340.264343,403.685852 
	C340.780792,403.554474 340.957306,403.332794 341.007874,403.008484 
	C341.002197,403.001678 341.017120,403.015198 341.017120,403.015198 
z"/>
          <path fill="#2996EB" opacity="1.000000" stroke="none"
            d="
M361.819885,105.026184 
	C362.969696,105.031769 364.005798,104.757309 364.528168,105.155983 
	C365.351837,105.784630 365.830322,106.865547 366.454926,107.755020 
	C365.374939,107.758865 364.189178,108.043137 363.244965,107.686996 
	C362.316803,107.336929 361.630798,106.339996 360.855103,105.609337 
	C360.825684,105.581650 360.941589,105.337517 361.040070,105.252327 
	C361.156799,105.151314 361.328125,105.113335 361.819885,105.026184 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M97.154099,394.133484 
	C95.115143,396.486694 93.005585,398.780121 90.443893,401.029358 
	C89.991776,400.985229 89.798187,400.794250 89.968307,400.511841 
	C92.408020,398.135773 94.677620,396.042145 96.947220,393.948486 
	C96.947220,393.948486 97.083481,394.073669 97.154099,394.133484 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M447.969727,401.269775 
	C446.773651,403.499542 445.207947,405.635559 443.337524,407.910675 
	C444.555237,405.758514 446.077667,403.467255 447.969727,401.269775 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M173.617706,70.998932 
	C173.997269,71.143578 173.998123,71.323067 174.002960,71.772171 
	C171.362534,73.881584 168.747025,75.886551 165.184753,73.270309 
	C167.744507,72.336418 170.491745,71.685097 173.617706,70.998932 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M287.293457,16.910433 
	C286.687714,14.899515 286.462677,12.845955 286.086975,10.431797 
	C289.297943,10.399017 289.912384,12.802043 287.293457,16.910433 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M29.098537,209.087463 
	C31.184704,210.124893 33.245636,211.542786 35.447395,213.269730 
	C33.416584,212.208496 31.244944,210.838226 29.098537,209.087463 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M23.212868,201.898865 
	C24.927773,203.387421 26.549654,205.278717 28.242342,207.512833 
	C26.582048,206.004303 24.850948,204.152939 23.212868,201.898865 
z"/>
          <path fill="#3F97D3" opacity="1.000000" stroke="none"
            d="
M423.904510,316.991333 
	C423.904510,316.991333 423.406860,316.985260 423.157532,317.000183 
	C422.427551,315.503326 421.946930,313.991516 421.984070,312.240601 
	C422.838562,312.007507 423.175293,312.013550 423.617157,312.424194 
	C423.783051,314.216309 423.843781,315.603821 423.904510,316.991333 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M381.913269,426.764008 
	C380.243866,425.003723 378.619232,422.866852 376.959198,420.386993 
	C378.601868,422.158508 380.279968,424.272949 381.913269,426.764008 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M105.136864,432.163849 
	C106.840668,432.184937 108.523857,432.559998 110.591248,432.899597 
	C109.194138,436.670166 108.870537,436.651031 105.136864,432.163849 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M45.122360,112.810120 
	C45.898937,110.781593 47.044292,108.792122 48.506958,106.643326 
	C47.713219,108.605728 46.602173,110.727455 45.122360,112.810120 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M68.826881,170.023712 
	C67.088486,168.931305 65.400398,167.455811 63.588966,165.665359 
	C65.269478,166.780472 67.073334,168.210556 68.826881,170.023712 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M385.887146,432.786957 
	C384.527069,431.376007 383.226837,429.586365 381.879028,427.460327 
	C383.203278,428.885406 384.575134,430.646851 385.887146,432.786957 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M341.929138,424.763824 
	C340.583099,425.117401 339.241028,425.117188 337.496002,425.127930 
	C338.262360,421.946777 339.701050,420.543915 341.929138,424.763824 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M485.835144,193.189240 
	C486.737213,195.072739 487.369263,197.151932 487.982147,199.618698 
	C487.163696,197.799149 486.364410,195.592041 485.835144,193.189240 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M377.160339,412.178711 
	C378.493866,413.289490 379.746002,414.777802 381.059509,416.589966 
	C379.773560,415.461304 378.426239,414.008789 377.160339,412.178711 
z"/>
          <path fill="#3F97D3" opacity="1.000000" stroke="none"
            d="
M399.037354,154.962830 
	C400.035370,156.702911 401.033356,158.443008 402.066528,160.543915 
	C402.101715,160.904724 401.897400,161.104965 401.583679,161.146057 
	C401.269989,161.187164 400.858826,161.025360 400.822632,160.763611 
	C400.554138,160.249573 400.321899,159.997299 400.057007,159.476105 
	C399.691528,158.801254 399.358643,158.395325 399.001556,157.681641 
	C398.763214,156.796631 398.549103,156.219406 398.510620,155.472382 
	C398.686249,155.302597 399.037354,154.962830 399.037354,154.962830 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M16.261360,305.147552 
	C15.698946,304.208649 15.387165,303.437653 15.052887,302.355347 
	C15.773467,302.034729 16.516544,302.025452 17.631752,302.024567 
	C18.026182,302.835022 18.048483,303.637085 18.155136,304.837402 
	C18.229609,305.611053 18.219725,305.986481 18.215710,306.644379 
	C17.651718,306.389740 17.081856,305.852600 16.261360,305.147552 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M64.912170,209.770752 
	C65.724670,208.393845 66.926193,207.089462 68.450569,205.674438 
	C67.616013,206.990295 66.458603,208.416794 64.912170,209.770752 
z"/>
          <path fill="#649BC6" opacity="1.000000" stroke="none"
            d="
M488.242126,312.012939 
	C487.863220,313.968903 487.484283,315.924866 486.826294,318.084900 
	C486.379791,317.155334 486.212372,316.021759 486.305206,314.279602 
	C487.124329,313.118378 487.683228,312.565674 488.242126,312.012939 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M368.912292,408.713226 
	C367.572327,409.041901 366.193817,409.000031 364.399719,409.004028 
	C365.190704,405.887512 366.945099,406.512878 368.912292,408.713226 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M389.820190,142.177155 
	C391.007568,143.407135 392.032349,144.798538 393.003845,146.611603 
	C392.950500,147.033279 392.776184,147.183716 392.524719,147.020889 
	C391.548767,146.200867 390.824341,145.543671 390.077515,144.947617 
	C390.055176,145.008759 390.130707,144.902603 390.093109,144.604416 
	C389.814392,143.757843 389.573273,143.209457 389.332184,142.661057 
	C389.332184,142.661057 389.657623,142.338577 389.820190,142.177155 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M90.474480,59.898693 
	C91.161667,59.879196 91.662895,59.919338 92.539307,59.953499 
	C91.853241,60.959003 90.791992,61.970493 89.366318,62.985355 
	C88.946877,62.775574 88.891853,62.562416 88.700089,62.047459 
	C88.563339,61.745655 88.626602,61.752960 88.948074,61.729584 
	C89.522316,61.220547 89.775085,60.734890 90.027847,60.249229 
	C90.027847,60.249229 90.288513,59.958336 90.474480,59.898693 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M111.721985,432.713379 
	C111.436279,431.160400 111.532089,429.603943 111.619827,427.657593 
	C113.854988,428.893890 115.376968,430.585266 111.721985,432.713379 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M90.090858,401.078369 
	C89.081749,402.467346 87.973564,403.763245 86.511360,405.117859 
	C86.157349,405.176575 85.976028,404.967377 86.049103,404.594513 
	C87.347511,403.079163 88.572845,401.936707 89.798187,400.794250 
	C89.798187,400.794250 89.991776,400.985229 90.090858,401.078369 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M6.299252,240.743164 
	C5.889384,240.171051 5.754319,239.327911 5.377150,238.273178 
	C5.114631,237.296371 5.094217,236.531128 5.178009,235.395416 
	C5.650680,235.012955 6.019144,235.000992 6.662807,234.974304 
	C6.816688,236.797119 6.695372,238.634613 6.299252,240.743164 
z"/>
          <path fill="#3F97D3" opacity="1.000000" stroke="none"
            d="
M422.699036,305.234741 
	C422.959045,304.993134 423.456390,305.013397 423.723389,305.412689 
	C423.832977,306.866577 423.675598,307.921204 423.266052,308.984558 
	C422.677155,308.995636 422.340393,308.997986 422.003662,309.000305 
	C422.148804,307.825653 422.293884,306.650970 422.699036,305.234741 
z"/>
          <path fill="#3F97D3" opacity="1.000000" stroke="none"
            d="
M396.094330,150.930695 
	C397.045044,152.049927 397.995758,153.169159 398.991943,154.625610 
	C399.037354,154.962830 398.686249,155.302597 398.314331,155.314163 
	C397.645966,155.176025 397.349518,155.026337 397.008789,154.593872 
	C396.618439,153.878540 396.272369,153.445953 395.947327,152.750519 
	C395.980957,152.312302 395.993591,152.136948 396.023438,151.703461 
	C396.058533,151.273773 396.076416,151.102234 396.094330,150.930695 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M24.797441,172.062286 
	C24.450642,173.443787 23.728273,174.850006 22.692871,176.396027 
	C23.060516,175.052887 23.741194,173.569931 24.797441,172.062286 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M146.721252,28.146723 
	C147.193527,28.009512 147.392365,28.017164 147.888596,28.053833 
	C147.930023,28.906178 147.674057,29.729507 147.191254,30.783634 
	C146.792191,30.106815 146.619995,29.199202 146.721252,28.146723 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M134.853806,30.845234 
	C133.615692,31.694134 132.225861,32.379185 130.480194,33.100250 
	C130.124344,33.136265 129.983978,32.979279 129.987823,32.710808 
	C129.991653,32.442337 130.062790,32.039867 130.062790,32.039867 
	C130.062790,32.039867 130.012207,31.994539 130.365387,32.006115 
	C131.944992,31.463289 133.171417,30.908886 134.397842,30.354483 
	C134.397842,30.354483 134.702103,30.681389 134.853806,30.845234 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M60.860554,212.765625 
	C61.399166,211.740692 62.325630,210.795532 63.597660,209.838837 
	C63.044949,210.833344 62.146675,211.839355 60.860554,212.765625 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M488.337646,311.730591 
	C487.683228,312.565674 487.124329,313.118378 486.321533,313.846802 
	C486.574524,312.738647 487.071381,311.454742 487.961365,310.229431 
	C488.380768,310.674744 488.406982,311.061493 488.337646,311.730591 
z"/>
          <path fill="#2F87C9" opacity="1.000000" stroke="none"
            d="
M422.000549,309.375275 
	C422.340393,308.997986 422.677155,308.995636 423.385681,309.356323 
	C423.675659,310.486084 423.593842,311.252838 423.511993,312.019562 
	C423.175293,312.013550 422.838562,312.007507 422.248962,312.000916 
	C421.996521,311.250275 421.996979,310.500275 422.000549,309.375275 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M18.306396,173.839630 
	C18.448467,172.770584 18.896147,171.542297 19.638885,170.145798 
	C19.493298,171.211853 19.052650,172.446136 18.306396,173.839630 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M41.892548,160.850800 
	C41.274445,161.456085 40.468102,161.781097 39.321602,162.059021 
	C39.889065,161.531479 40.796692,161.050995 41.892548,160.850800 
z"/>
          <path fill="#2996EB" opacity="1.000000" stroke="none"
            d="
M355.961151,105.745697 
	C355.404663,105.907593 354.862091,105.725075 354.150940,105.306015 
	C354.373688,104.544945 354.764984,104.020416 355.156311,103.495895 
	C355.429230,104.131020 355.702148,104.766151 355.961151,105.745697 
z"/>
          <path fill="#649BC6" opacity="1.000000" stroke="none"
            d="
M485.240356,324.671326 
	C485.009247,323.894196 485.003235,322.838318 484.987732,321.384888 
	C484.978210,320.987305 485.030792,320.518860 485.367798,320.285461 
	C485.918549,320.068481 486.132233,320.084869 486.345886,320.101227 
	C486.052399,321.531677 485.758911,322.962128 485.240356,324.671326 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M19.939693,334.709229 
	C19.299524,334.256989 18.651691,333.480316 18.003828,332.351074 
	C18.646542,332.793945 19.289286,333.589386 19.939693,334.709229 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M486.976807,188.665512 
	C486.652771,189.102890 486.395203,189.288467 486.093170,189.785217 
	C485.665405,189.208344 485.352234,188.417648 485.292877,187.302414 
	C485.850891,187.013748 486.156097,187.030945 486.462341,187.029480 
	C486.632599,187.458435 486.802856,187.887405 486.976807,188.665512 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M128.917114,405.868530 
	C128.181564,405.503052 127.494980,404.745209 126.735931,403.697083 
	C127.431000,404.096588 128.198532,404.786377 128.917114,405.868530 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M126.674088,403.020569 
	C125.815468,402.827545 124.958908,402.279022 123.985428,401.431274 
	C124.804390,401.643066 125.740273,402.154053 126.674088,403.020569 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M487.815857,202.152283 
	C488.347595,202.478989 488.642792,202.964020 488.918091,203.750061 
	C488.458588,203.470917 488.018982,202.890778 487.815857,202.152283 
z"/>
          <path fill="#649BC6" opacity="1.000000" stroke="none"
            d="
M486.543945,186.761047 
	C486.156097,187.030945 485.850891,187.013748 485.318665,186.961426 
	C485.055542,186.512558 485.020477,186.080154 485.218170,185.322235 
	C485.621368,185.003784 485.791779,185.010849 485.962189,185.017929 
	C486.183319,185.509491 486.404449,186.001053 486.543945,186.761047 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M485.955566,184.677719 
	C485.791779,185.010849 485.621368,185.003784 485.194580,184.997314 
	C484.644073,184.518661 484.349945,184.039429 484.301514,183.275909 
	C484.871429,183.025314 485.196442,183.047211 485.522278,183.057281 
	C485.664490,183.484024 485.806702,183.910767 485.955566,184.677719 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M71.763214,174.133057 
	C71.091209,173.922333 70.488808,173.330582 69.840805,172.412415 
	C70.474396,172.641342 71.153595,173.196686 71.763214,174.133057 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M393.036011,146.956177 
	C393.720154,147.376007 394.318817,147.872955 394.808350,148.831329 
	C394.699188,149.292755 394.390778,149.592438 394.150269,149.411987 
	C393.531921,148.548920 393.154053,147.866318 392.776184,147.183716 
	C392.776184,147.183716 392.950500,147.033279 393.036011,146.956177 
z"/>
          <path fill="#649BC6" opacity="1.000000" stroke="none"
            d="
M485.586060,182.778534 
	C485.196442,183.047211 484.871429,183.025314 484.303802,182.979034 
	C484.060364,182.966461 484.008545,182.492828 483.984009,182.256012 
	C484.065369,181.721771 484.235046,181.466766 484.674011,181.149063 
	C485.136322,181.529236 485.393097,182.014526 485.586060,182.778534 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M396.041199,150.644562 
	C396.076416,151.102234 396.058533,151.273773 395.791809,151.672333 
	C395.542969,151.899353 395.076141,151.874084 395.052429,151.924927 
	C395.028687,151.975784 395.117920,151.907730 395.096954,151.601868 
	C394.847565,150.728149 394.619171,150.160294 394.390778,149.592438 
	C394.390778,149.592438 394.699188,149.292755 394.853577,149.142990 
	C395.334686,149.448288 395.661377,149.903366 396.041199,150.644562 
z"/>
          <path fill="#3F97D3" opacity="1.000000" stroke="none"
            d="
M344.228699,403.825562 
	C343.726166,403.682495 343.475525,403.390656 342.953552,402.983704 
	C342.954681,402.622284 343.227173,402.375916 344.021790,402.077698 
	C344.712006,402.036255 344.880096,402.046692 345.048187,402.057129 
	C344.859009,402.597015 344.669800,403.136932 344.228699,403.825562 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M80.443512,354.779968 
	C80.021515,354.352325 79.820816,353.703430 79.594231,352.722748 
	C79.933838,353.113556 80.299324,353.836121 80.443512,354.779968 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M7.964545,279.676270 
	C7.635963,279.231079 7.321299,278.447723 7.016069,277.326752 
	C7.343157,277.772125 7.660810,278.555084 7.964545,279.676270 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M8.286722,219.796356 
	C8.172611,219.145081 8.313149,218.280075 8.709436,217.202850 
	C8.823914,217.854614 8.682643,218.718613 8.286722,219.796356 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M12.231688,307.858032 
	C12.002837,307.561676 11.996661,307.116760 11.992386,306.338257 
	C12.147644,306.572968 12.301003,307.141235 12.231688,307.858032 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M118.425591,407.880341 
	C118.927849,407.819458 119.412979,408.089966 120.004906,408.635071 
	C119.543961,408.677063 118.976212,408.444427 118.425591,407.880341 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M345.278320,401.903198 
	C344.880096,402.046692 344.712006,402.036255 344.291992,402.011292 
	C344.496826,401.608032 344.953613,401.219269 345.720642,400.809265 
	C345.856720,401.108459 345.682587,401.428864 345.278320,401.903198 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M32.877899,368.742493 
	C32.838306,368.990784 32.632580,369.010315 32.118145,369.057678 
	C31.460945,368.980408 31.232595,368.754089 31.146046,368.158020 
	C31.682388,368.110870 32.197075,368.312286 32.877899,368.742493 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M14.941493,319.719727 
	C14.640942,319.543213 14.337563,319.068085 14.022171,318.296906 
	C14.319660,318.474274 14.629163,318.947723 14.941493,319.719727 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M486.440002,319.799957 
	C486.132233,320.084869 485.918549,320.068481 485.384033,320.050354 
	C485.291992,319.472595 485.520752,318.896606 486.084534,318.297516 
	C486.457764,318.682495 486.495941,319.090607 486.440002,319.799957 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M343.617981,426.075012 
	C343.132446,426.137451 342.637451,425.871307 342.021149,425.341736 
	C342.469391,425.301025 343.038940,425.523743 343.617981,426.075012 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M12.282581,195.845886 
	C12.192287,195.463135 12.337476,194.900543 12.721399,194.161652 
	C12.812778,194.545563 12.665421,195.105804 12.282581,195.845886 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M23.204424,160.875153 
	C23.083588,160.666824 23.259344,160.410812 23.717110,160.091675 
	C23.863823,160.309082 23.697790,160.575378 23.204424,160.875153 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M344.607910,427.063873 
	C344.215851,427.361450 343.943848,427.205597 343.702271,426.651367 
	C343.971039,426.535156 344.302826,426.654327 344.607910,427.063873 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M7.247160,227.899902 
	C7.100550,227.694443 7.255458,227.427094 7.707401,227.095154 
	C7.866136,227.305954 7.714218,227.575104 7.247160,227.899902 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M285.085449,9.367705 
	C285.090668,9.503813 284.649933,9.568813 284.649933,9.568813 
	C284.649933,9.568813 284.249115,9.374350 284.105835,9.203726 
	C284.140533,9.020926 284.318573,9.008752 284.764252,8.980043 
	C285.031921,8.963511 285.080261,9.231598 285.085449,9.367705 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M120.144180,409.139404 
	C120.372498,409.048340 120.681671,409.216644 121.067650,409.646179 
	C120.837982,409.737915 120.531509,409.568359 120.144180,409.139404 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M11.246389,201.908936 
	C11.105423,201.694794 11.274787,201.423401 11.758251,201.099304 
	C11.893683,201.312241 11.721807,201.580597 11.246389,201.908936 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M428.031067,424.309326 
	C428.151550,424.599274 427.976532,424.896484 427.539429,425.256653 
	C427.430084,424.985260 427.582825,424.650940 428.031067,424.309326 
z"/>
          <path fill="#3F97D3" opacity="1.000000" stroke="none"
            d="
M340.215576,405.880249 
	C340.099915,405.652374 340.297791,405.396881 340.810364,405.113983 
	C340.919250,405.336670 340.720581,405.592041 340.215576,405.880249 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M19.168423,315.985443 
	C19.373522,315.960571 19.625862,316.217041 19.965458,316.730835 
	C19.773699,316.747742 19.494680,316.507263 19.168423,315.985443 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M13.874104,316.792694 
	C13.645170,316.903595 13.397780,316.703857 13.137191,316.189453 
	C13.360346,316.088684 13.604231,316.291107 13.874104,316.792694 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M12.863016,311.797363 
	C12.634685,311.906891 12.388291,311.706848 12.123897,311.196655 
	C12.348951,311.091156 12.595304,311.291595 12.863016,311.797363 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M31.237419,365.936829 
	C31.069002,365.871826 31.050062,365.696960 31.031307,365.258301 
	C31.149961,365.272003 31.268429,365.549530 31.237419,365.936829 
z"/>
          <path fill="#3099BD" opacity="1.000000" stroke="none"
            d="
M18.807875,310.074524 
	C18.998543,310.150208 19.021889,310.320312 19.026871,310.747314 
	C18.885859,310.725830 18.763205,310.447357 18.807875,310.074524 
z"/>
          <path fill="#2996EB" opacity="1.000000" stroke="none"
            d="
M358.467865,104.449799 
	C358.523102,104.416924 358.412628,104.482666 358.467865,104.449799 
z"/>
          <path fill="#3099BD" opacity="1.000000" stroke="none"
            d="
M32.082199,329.312256 
	C32.311634,329.117340 32.596176,329.260834 32.920464,329.757599 
	C32.693741,329.930450 32.419441,329.777039 32.082199,329.312256 
z"/>
          <path fill="#3099BD" opacity="1.000000" stroke="none"
            d="
M25.094604,324.259216 
	C25.331367,324.092224 25.609579,324.258270 25.921785,324.763855 
	C25.685896,324.922760 25.412653,324.752380 25.094604,324.259216 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M21.687336,320.125854 
	C21.894093,320.163696 21.958429,320.319244 22.055944,320.725037 
	C21.907724,320.731384 21.726322,320.487457 21.687336,320.125854 
z"/>
          <path fill="#3F97D3" opacity="1.000000" stroke="none"
            d="
M367.754150,384.771606 
	C367.636414,384.453674 367.895050,384.041504 368.505798,383.500610 
	C368.615417,383.807037 368.372955,384.242188 367.754150,384.771606 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M8.867027,287.796906 
	C8.637444,287.905396 8.392163,287.703918 8.133948,287.190186 
	C8.359457,287.088226 8.602896,287.291229 8.867027,287.796906 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M286.716125,3.605173 
	C285.774048,3.763490 284.568024,3.668255 283.069611,3.349806 
	C284.002228,3.201601 285.227203,3.276611 286.716125,3.605173 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M283.813538,497.770874 
	C283.846222,497.549225 284.152039,497.367737 284.727905,497.221802 
	C284.694244,497.441925 284.390472,497.626495 283.813538,497.770874 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M278.918396,2.706850 
	C278.834869,2.884331 278.662567,2.912978 278.242889,2.855160 
	C278.273560,2.698468 278.551575,2.628243 278.918396,2.706850 
z"/>
          <path fill="#2296F2" opacity="1.000000" stroke="none"
            d="
M61.935444,92.293541 
	C62.853550,90.501015 63.796070,89.050705 65.241714,87.302719 
	C66.472519,86.329590 67.200188,85.654137 67.952072,84.578903 
	C67.910149,83.381676 67.844002,82.584221 67.777855,81.786774 
	C67.777855,81.786774 67.880905,81.806732 68.133987,81.748581 
	C68.549095,81.327393 68.711128,80.964363 68.873154,80.601334 
	C69.643684,79.666916 70.414207,78.732498 71.627747,77.977524 
	C72.695160,78.400986 73.319557,78.644997 74.204353,78.755981 
	C74.628891,78.077522 74.793022,77.532089 75.134445,76.734177 
	C76.180855,75.649750 77.049973,74.817795 77.919098,73.985840 
	C76.758148,83.004951 82.534981,89.746284 85.543304,97.403709 
	C85.906891,98.329170 85.592812,99.520859 85.592812,100.092072 
	C78.780556,98.180977 72.138512,96.317635 64.954109,94.602493 
	C63.600811,94.502632 62.789871,94.254555 61.978931,94.006477 
	C61.972572,93.549568 61.966213,93.092667 61.935444,92.293541 
M74.421906,84.057411 
	C73.394814,84.813103 72.367714,85.568802 71.340614,86.324493 
	C73.359047,88.560196 74.625641,91.903297 79.920013,91.521927 
	C78.001053,88.570999 76.579109,86.384361 74.421906,84.057411 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M73.943939,78.889000 
	C73.319557,78.644997 72.695160,78.400986 71.909386,77.845543 
	C73.176376,75.991768 74.604752,74.449432 76.334663,72.826431 
	C76.894562,73.054512 77.152931,73.363251 77.665192,73.828918 
	C77.049973,74.817795 76.180855,75.649750 74.900909,76.896484 
	C74.308044,77.837166 74.125992,78.363083 73.943939,78.889000 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M134.575790,30.175671 
	C133.171417,30.908886 131.944992,31.463289 130.378571,32.037338 
	C131.532715,31.057579 133.026855,30.058176 134.911865,29.108940 
	C135.119720,29.438360 134.936737,29.717609 134.575790,30.175671 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M67.471924,81.883057 
	C67.844002,82.584221 67.910149,83.381676 67.643211,84.608261 
	C66.550171,85.365761 65.790215,85.694115 65.030258,86.022469 
	C65.030258,86.022469 64.765793,85.796150 64.633621,85.680695 
	C65.389633,84.369942 66.277817,83.174644 67.471924,81.883057 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M156.725342,19.512959 
	C155.875946,20.142626 154.782822,20.544561 153.340027,20.964750 
	C154.154114,20.417080 155.317871,19.851152 156.725342,19.512959 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M127.809128,33.065304 
	C127.327309,33.712963 126.479408,34.328403 125.310181,34.963203 
	C125.806915,34.332737 126.624985,33.682911 127.809128,33.065304 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M216.903168,3.107221 
	C216.108047,3.458273 214.927628,3.748012 213.375671,3.979700 
	C214.175369,3.629735 215.346634,3.337821 216.903168,3.107221 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M205.800934,5.046781 
	C205.029678,5.409515 203.883530,5.721660 202.375107,5.970746 
	C203.150574,5.603855 204.288300,5.300023 205.800934,5.046781 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M190.947983,8.034003 
	C190.566177,8.517289 190.052658,8.733212 189.251648,8.901134 
	C189.581543,8.490969 190.198914,8.128805 190.947983,8.034003 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M187.725632,9.001383 
	C187.269440,9.345570 186.474533,9.662336 185.344971,9.944243 
	C185.802505,9.597577 186.594711,9.285770 187.725632,9.001383 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M195.797928,7.035897 
	C195.320892,7.368740 194.501007,7.658704 193.345825,7.906834 
	C193.825378,7.574339 194.640244,7.283679 195.797928,7.035897 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M210.867737,4.071725 
	C210.376923,4.422973 209.533112,4.718934 208.347443,4.969151 
	C208.841965,4.621083 209.678345,4.318761 210.867737,4.071725 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M116.631096,39.984596 
	C116.927116,39.962502 116.896317,40.382877 116.845932,40.587250 
	C116.405304,40.874050 116.015076,40.956478 115.326065,41.058395 
	C115.463211,40.720818 115.899147,40.363750 116.631096,39.984596 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M124.680283,34.995712 
	C124.526070,35.327263 124.068916,35.667755 123.317078,36.031868 
	C123.474045,35.705212 123.925690,35.354931 124.680283,34.995712 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M89.757370,60.308243 
	C89.775085,60.734890 89.522316,61.220547 88.978668,61.738457 
	C88.954140,61.302887 89.220505,60.835068 89.757370,60.308243 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M77.785370,71.216034 
	C77.809891,71.640701 77.558891,72.165535 77.007065,72.749626 
	C76.974106,72.311325 77.241982,71.813766 77.785370,71.216034 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M182.998505,10.144485 
	C182.769211,10.485813 182.205215,10.771500 181.317841,11.030090 
	C181.550919,10.698279 182.107361,10.393562 182.998505,10.144485 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M152.687286,20.991646 
	C152.520309,21.314772 152.057648,21.641354 151.296982,21.952160 
	C151.463181,21.622623 151.927383,21.308863 152.687286,20.991646 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M149.950256,22.115116 
	C149.756332,22.481527 149.221466,22.803282 148.367401,23.138634 
	C148.568558,22.791639 149.088928,22.431049 149.950256,22.115116 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M139.627670,26.958134 
	C139.465851,27.268265 139.017975,27.593483 138.281464,27.913143 
	C138.442444,27.596128 138.892014,27.284676 139.627670,26.958134 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M199.117599,6.207106 
	C198.858688,6.524238 198.259384,6.765943 197.332275,6.974545 
	C197.595383,6.671521 198.186295,6.401600 199.117599,6.207106 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M137.033340,28.170818 
	C137.049896,28.396788 136.791595,28.645662 136.259918,28.917728 
	C136.243866,28.691853 136.501175,28.442787 137.033340,28.170818 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M141.001938,26.155399 
	C141.015839,26.372242 140.758118,26.612144 140.233704,26.883709 
	C140.221451,26.669733 140.475876,26.424097 141.001938,26.155399 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M129.841965,32.072090 
	C130.062790,32.039867 129.991653,32.442337 129.917450,32.632481 
	C129.843246,32.822624 129.416107,32.780819 129.204376,32.745655 
	C129.121552,32.424881 129.331039,32.222820 129.841965,32.072090 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M122.825760,36.085609 
	C122.979332,36.372757 122.784737,36.617443 122.229958,36.794529 
	C122.102989,36.514954 122.305595,36.287025 122.825760,36.085609 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M121.002792,37.176613 
	C121.035927,37.405762 120.791046,37.676113 120.270996,37.991463 
	C120.238808,37.763584 120.481781,37.490696 121.002792,37.176613 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M119.133087,38.243202 
	C119.177673,38.492889 118.910797,38.770592 118.363991,39.131897 
	C118.329910,38.900742 118.575760,38.585976 119.133087,38.243202 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M68.600342,80.724960 
	C68.711128,80.964363 68.549095,81.327393 68.089432,81.776321 
	C67.970383,81.524353 68.148956,81.186470 68.600342,80.724960 
z"/>
          <path fill="#4299DC" opacity="1.000000" stroke="none"
            d="
M267.925995,5.760729 
	C267.717651,5.900584 267.446838,5.735837 267.121185,5.261693 
	C267.332733,5.122860 267.598450,5.290803 267.925995,5.760729 
z"/>
          <path fill="#F2FAFD" opacity="1.000000" stroke="none"
            d="
M240.951096,55.195930 
	C235.539108,55.648266 230.569107,56.091042 225.594467,56.473743 
	C221.629822,56.778748 218.952316,55.875622 218.640015,50.990528 
	C217.855484,38.718868 216.623169,26.476450 215.669678,14.214587 
	C215.398911,10.732338 215.629044,7.243235 220.368225,7.008101 
	C224.707184,6.792822 226.072540,9.468630 226.290207,13.329936 
	C226.804810,22.459188 227.597137,31.575529 227.953873,40.709717 
	C228.119217,44.943855 229.501999,46.824814 233.968994,46.133678 
	C236.744583,45.704235 239.617218,45.927952 242.442917,45.789474 
	C245.624512,45.633549 248.425919,46.452000 248.481628,50.078072 
	C248.532532,53.392300 246.265335,55.091541 242.886688,55.080486 
	C242.388229,55.078854 241.889618,55.132420 240.951096,55.195930 
z"/>
          <path fill="#F2FAFD" opacity="1.000000" stroke="none"
            d="
M208.961853,59.420937 
	C203.041458,61.513977 201.022552,60.390453 199.645111,54.524574 
	C197.989304,47.473103 196.419937,40.401333 194.592255,33.031952 
	C193.435379,28.003439 192.556107,23.269129 191.544876,18.563171 
	C190.777573,14.992383 191.427612,12.081215 195.367981,11.243842 
	C199.305252,10.407131 201.149231,12.854619 201.943817,16.364916 
	C204.621704,28.195057 207.421127,40.000111 209.871353,51.877201 
	C210.334229,54.120937 209.409485,56.650932 208.961853,59.420937 
z"/>
          <path fill="#4999D6" opacity="1.000000" stroke="none"
            d="
M283.912964,8.870211 
	C283.976776,9.041305 283.483093,9.057791 283.254303,8.964931 
	C283.300079,8.814419 283.574615,8.756768 283.912964,8.870211 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M401.312012,110.802742 
	C406.243530,114.362587 407.052246,118.410767 403.187622,121.052811 
	C398.997742,123.917206 396.489288,120.828598 393.997711,118.068214 
	C391.634155,115.449669 390.909363,112.878891 394.656891,110.916855 
	C396.695435,109.849548 398.454559,105.624191 400.852234,110.441849 
	C398.338959,112.001999 396.268219,113.115265 394.197479,114.228531 
	C395.415802,115.905106 396.391724,117.886307 397.954590,119.129913 
	C398.737671,119.753052 400.858276,119.440079 401.867004,118.778053 
	C402.560974,118.322601 402.651581,116.511826 402.530365,115.357391 
	C402.368286,113.813545 401.740082,112.318657 401.312012,110.802742 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M415.875488,97.109802 
	C417.130615,98.697289 418.719604,99.854500 419.081604,101.315697 
	C419.538666,103.160362 419.734253,106.194252 418.700623,107.005745 
	C417.337708,108.075737 414.209320,108.291054 412.676880,107.413055 
	C410.561096,106.200813 409.226166,103.625732 406.522552,100.400696 
	C410.175354,98.967789 412.919952,97.891151 415.875488,97.109802 
M416.495911,101.116417 
	C414.982513,100.657906 413.469086,100.199394 411.955688,99.740883 
	C411.908051,101.237785 411.238770,103.307419 411.962830,104.093391 
	C412.974152,105.191193 415.025665,105.330688 416.632111,105.880249 
	C416.722260,104.508606 416.812408,103.136955 416.495911,101.116417 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M161.347076,40.289650 
	C159.050186,33.633018 160.984299,30.622669 167.278732,30.934090 
	C168.653366,31.002102 170.529938,32.072689 171.145691,33.229568 
	C171.774551,34.411068 171.756424,36.860828 170.936905,37.716209 
	C168.471634,40.289333 165.503815,42.444004 161.347076,40.289650 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M179.236450,49.728561 
	C179.536606,52.272060 180.521774,55.615623 179.432999,56.554733 
	C177.102036,58.565258 173.580414,60.470341 170.779953,60.178406 
	C169.127914,60.006191 167.628601,55.644897 166.725723,52.945095 
	C166.502884,52.278759 168.734772,50.016125 170.080048,49.760628 
	C172.963547,49.212982 176.004807,49.495972 179.236450,49.728561 
M169.200058,54.248341 
	C169.771225,55.246178 170.154984,56.870350 170.953217,57.109173 
	C172.529755,57.580860 174.652512,57.862816 175.958298,57.146175 
	C177.280746,56.420380 178.257263,54.480961 178.634506,52.894588 
	C178.792145,52.231628 176.740799,50.183960 176.160172,50.354416 
	C173.715576,51.072067 171.447037,52.389389 169.200058,54.248341 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M151.301697,26.164518 
	C151.519394,25.810019 152.075989,25.448799 152.988525,25.114262 
	C152.776489,25.479897 152.208542,25.818846 151.301697,26.164518 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M154.308395,24.965378 
	C154.306168,24.729712 154.611862,24.468100 155.230347,24.181648 
	C155.234192,24.417681 154.925262,24.678555 154.308395,24.965378 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M157.313629,23.785286 
	C157.329041,23.576178 157.621002,23.369368 158.192627,23.156273 
	C158.178268,23.362520 157.884216,23.575052 157.313629,23.785286 
z"/>
          <path fill="#FEFFFF" opacity="1.000000" stroke="none"
            d="
M395.926300,153.013367 
	C396.272369,153.445953 396.618439,153.878540 396.991516,154.960175 
	C397.687622,156.402618 398.356689,157.196014 399.025757,157.989410 
	C399.358643,158.395325 399.691528,158.801254 400.094482,159.737122 
	C400.395966,160.519821 400.627380,160.772598 400.858826,161.025360 
	C400.858826,161.025360 401.269989,161.187164 401.482422,161.247055 
	C401.769714,161.684128 401.844574,162.061295 401.699585,162.923813 
	C402.348846,164.586609 403.217987,165.764084 404.087128,166.941559 
	C404.087128,166.941559 404.020660,166.997299 404.084625,167.175629 
	C404.277527,167.640076 404.486237,167.844971 404.774689,167.968628 
	C404.774689,167.968628 405.029083,168.333817 405.090820,168.799332 
	C405.272736,169.589844 405.489838,169.825531 405.803864,169.971924 
	C405.949219,170.281860 406.094574,170.591782 406.237854,171.489197 
	C406.542114,172.367538 406.848480,172.658417 407.154846,172.949280 
	C407.452667,173.376541 407.750519,173.803787 408.055786,174.828125 
	C408.400848,175.942017 408.738464,176.458817 409.076080,176.975616 
	C409.076080,176.975616 409.004913,176.990326 409.004639,177.341461 
	C409.714294,179.109192 410.424286,180.525787 411.134247,181.942383 
	C411.134247,181.942383 411.039917,181.979095 411.049957,182.252960 
	C411.403137,183.006668 411.746246,183.486511 412.089386,183.966339 
	C412.089386,183.966339 412.010956,183.992783 412.008545,184.382538 
	C414.673981,192.515945 417.341827,200.259613 420.009705,208.003265 
	C420.009674,208.003281 420.179901,208.333954 420.145752,208.748779 
	C420.166870,209.480728 420.323730,209.735184 420.582123,209.926971 
	C420.760071,210.679703 420.938049,211.432434 420.821533,212.790802 
	C421.299469,215.923538 422.071869,218.450607 422.844299,220.977692 
	C422.957947,221.411453 423.071594,221.845230 422.866760,222.850830 
	C423.581116,237.194778 424.981567,250.966904 422.563293,264.838470 
	C421.468323,263.322296 421.173370,261.715240 421.014587,260.094849 
	C420.620117,256.068359 420.287781,252.035812 419.789398,247.694214 
	C418.797852,246.776154 417.947388,246.169601 416.735657,245.356964 
	C395.838348,245.096970 375.302368,245.038025 354.766327,245.013062 
	C354.143921,245.012299 353.521057,245.365707 352.439484,245.574600 
	C347.028503,245.577194 342.076477,245.559174 336.995239,245.395294 
	C336.534607,245.360641 336.203186,245.471848 335.448883,245.609375 
	C333.047394,245.623489 331.068756,245.611267 328.753174,245.395264 
	C325.983887,245.148407 323.551514,245.105316 320.800690,245.026825 
	C319.700500,244.666534 318.918823,244.341614 318.051270,243.642578 
	C318.008148,242.506790 318.050873,241.745117 318.371521,240.677948 
	C318.768951,238.557999 318.991211,236.743576 318.992371,234.929016 
	C319.009094,208.955765 318.995911,182.982483 318.976135,156.713135 
	C318.654785,155.946503 318.347229,155.475937 317.960938,154.643768 
	C317.859955,153.544022 317.837769,152.805878 318.099976,151.883575 
	C317.957977,150.140274 317.531586,148.581146 316.985779,146.715866 
	C316.821198,145.974899 316.776123,145.540085 317.020874,144.927948 
	C316.160461,142.866089 315.010162,140.981567 313.893677,139.064041 
	C313.927429,139.031052 313.928253,139.125458 313.861176,138.879272 
	C313.538208,138.419693 313.282318,138.206314 312.805023,137.837616 
	C312.362732,137.199387 312.141846,136.716476 311.864014,135.965881 
	C311.537354,135.466843 311.267609,135.235489 310.998962,135.002060 
	C311.000000,135.000000 310.996002,135.002136 310.898376,134.777100 
	C310.605591,134.270233 310.342133,134.086075 310.004578,134.000427 
	C309.998779,134.001221 310.000336,133.989471 309.900635,133.766998 
	C309.604767,133.266373 309.341949,133.084518 309.005646,133.000092 
	C308.998779,133.001221 309.000977,132.987411 308.893982,132.761673 
	C308.577240,132.254959 308.300354,132.080795 307.728638,131.953415 
	C307.198212,131.740540 307.013550,131.497818 306.859283,130.903870 
	C306.467163,130.455185 306.162689,130.267838 305.903992,130.087585 
	C305.949738,130.094666 305.885223,130.161057 305.870819,129.783997 
	C304.816986,128.976318 303.777527,128.545685 302.523376,128.074188 
	C302.308655,128.033340 301.962006,127.767052 301.810364,127.572433 
	C301.385162,127.159325 301.074066,127.066551 300.521057,127.091820 
	C300.316742,127.084152 299.977722,126.855431 299.828064,126.641815 
	C299.353668,126.320663 299.028931,126.213135 298.515625,126.120209 
	C298.327026,126.134819 297.994232,125.954887 297.833984,125.720634 
	C297.321106,125.369553 296.968414,125.252724 296.454803,125.163147 
	C296.293854,125.190392 296.005127,125.038086 295.874084,124.750595 
	C294.805084,124.376419 293.867157,124.289734 292.601562,124.223366 
	C291.847351,124.153709 291.420776,124.063744 290.698914,123.680603 
	C284.160889,122.927467 277.921204,122.126717 271.674835,122.070869 
	C252.262894,121.897316 232.846161,121.855881 213.436981,122.145111 
	C209.957092,122.196968 206.504318,124.067352 203.028366,125.058075 
	C203.017715,125.018044 203.088898,125.060402 202.896042,125.080795 
	C202.445801,125.175270 202.239639,125.321518 201.825073,125.692886 
	C201.179123,125.943863 200.792847,126.041893 200.121582,126.160904 
	C199.562134,126.427719 199.287643,126.673531 198.875397,127.028244 
	C198.737640,127.137154 198.386444,127.135117 198.107422,127.155762 
	C197.558121,127.415733 197.287827,127.655060 196.884018,128.005371 
	C196.750488,128.116364 196.403946,128.139099 196.112335,128.171967 
	C195.549927,128.470474 195.279129,128.736130 195.004456,129.001190 
	C195.000610,129.000610 194.999359,129.007797 194.796112,129.074310 
	C194.331116,129.281296 194.144440,129.489151 193.832794,129.959854 
	C193.181152,130.477615 192.729507,130.799911 191.885941,131.083832 
	C187.041916,132.036041 184.552429,134.522263 185.141327,139.544403 
	C185.128174,139.724426 184.910233,140.012192 184.698822,140.138107 
	C184.229248,140.501389 184.099594,140.795456 184.103851,141.096771 
	C184.109253,141.047287 184.173706,141.123154 183.776367,141.146500 
	C182.977158,142.593933 182.575287,144.018036 182.224045,145.768326 
	C182.145035,146.737213 182.015381,147.379913 181.628235,148.233185 
	C181.317123,149.987793 181.263504,151.531830 181.238647,153.539017 
	C181.261108,182.252975 181.337799,210.504410 181.119858,238.753586 
	C181.098114,241.572128 179.283997,244.376816 178.270477,246.604431 
	C177.066895,245.419205 175.904648,244.324142 174.720505,244.299927 
	C169.181427,244.186676 163.636337,244.368576 157.685577,244.305786 
	C155.848114,244.086243 154.418472,244.005951 152.713287,243.655151 
	C149.960373,243.578049 147.482986,243.771423 144.601288,244.079987 
	C142.810455,244.310852 141.423935,244.426559 139.584564,244.406677 
	C132.732895,244.384903 126.334068,244.498703 119.508926,244.491394 
	C116.723541,244.395050 114.364464,244.419800 111.571335,244.308777 
	C108.415581,244.257965 105.693886,244.342896 102.511002,244.354492 
	C94.912331,244.246857 87.769402,244.365860 80.639687,244.112717 
	C77.142159,243.988525 76.039711,245.147659 76.117752,248.648483 
	C76.298683,256.764038 76.078239,264.888550 75.847549,273.087341 
	C75.607986,273.271393 75.461365,273.437897 75.488190,273.473450 
	C75.630424,273.661896 75.816254,273.817444 76.099426,274.354950 
	C76.129204,275.487396 76.046547,276.249298 75.592651,276.925690 
	C74.156670,273.561523 73.091919,270.282898 72.027161,267.004242 
	C71.961845,266.571503 71.896538,266.138733 72.167961,265.114685 
	C72.112061,261.041290 71.719421,257.559235 71.326782,254.077179 
	C71.274429,253.283325 71.222076,252.489471 71.470398,250.980164 
	C71.801079,247.057098 71.831100,243.849487 71.861130,240.641891 
	C71.884819,239.016617 71.908508,237.391327 72.289886,235.149780 
	C72.728722,232.673859 72.809868,230.814194 72.891022,228.954544 
	C72.885368,228.512405 72.879715,228.070282 73.248329,227.079727 
	C74.014008,223.327133 74.405418,220.122940 74.796829,216.918762 
	C74.831673,216.485107 74.866516,216.051437 75.340408,215.259216 
	C75.823845,213.921982 75.868233,212.943314 75.912621,211.964630 
	C75.946671,211.523010 75.980721,211.081406 76.415497,210.279541 
	C76.833153,209.260239 76.850090,208.601181 76.867020,207.942123 
	C77.079117,206.826706 77.291214,205.711288 77.992844,204.180145 
	C78.568604,202.809830 78.654839,201.855240 78.741074,200.900635 
	C79.072510,199.486160 79.403954,198.071686 80.265953,196.253204 
	C81.183556,194.199509 81.570602,192.549805 81.957649,190.900116 
	C81.957649,190.900116 81.984001,190.428986 82.441605,190.142746 
	C83.556374,187.803741 84.213539,185.750961 84.870697,183.698196 
	C84.870697,183.698196 84.875565,183.301376 85.215439,182.988617 
	C85.663681,182.338303 85.772041,182.000732 85.880386,181.663162 
	C85.880386,181.663162 85.835777,181.310303 86.294434,181.085358 
	C87.135422,179.497421 87.517746,178.134415 87.900085,176.771423 
	C87.900085,176.771423 87.907478,176.365875 88.348160,176.125443 
	C89.820190,173.500641 90.851524,171.116287 91.882866,168.731918 
	C91.882866,168.731918 91.897362,168.340378 92.360153,168.128815 
	C96.289360,166.716736 96.107201,163.846741 95.985985,161.004623 
	C95.985985,161.004623 95.995560,160.996063 96.241379,160.851257 
	C96.616776,160.365311 96.746353,160.024170 96.875931,159.683014 
	C97.055061,159.195358 97.234207,158.707718 98.005165,158.070251 
	C100.056709,155.268509 101.516441,152.616577 102.976173,149.964661 
	C102.976173,149.623718 103.103912,149.340347 103.952332,148.996704 
	C104.999382,147.851501 105.453491,146.824188 105.907593,145.796890 
	C106.413345,144.950623 106.919090,144.104355 107.939117,143.002914 
	C108.612122,142.431702 108.770851,142.115662 108.929588,141.799637 
	C109.235741,141.321503 109.541885,140.843369 110.225113,140.060547 
	C110.810646,139.569366 110.948174,139.340240 111.014771,139.068512 
	C111.014771,139.068512 111.004173,139.003448 111.248672,138.898361 
	C111.758026,138.587967 111.918961,138.320175 111.975983,137.989899 
	C111.975983,137.989899 111.993683,137.995285 111.989136,137.983948 
	C117.549377,137.354431 119.038857,132.798889 120.942001,128.299194 
	C120.912796,127.927162 120.938896,127.947823 121.183052,127.847565 
	C121.597534,127.443275 121.767853,127.139244 121.938179,126.835220 
	C122.717300,125.949234 123.496429,125.063248 124.808945,123.982597 
	C125.549339,123.503143 125.756340,123.218353 125.963348,122.933571 
	C125.963348,122.933571 125.994896,122.996666 126.206306,122.887268 
	C126.595345,122.491249 126.772972,122.204628 126.950600,121.918015 
	C126.950600,121.918015 126.990227,121.990135 127.197029,121.880249 
	C127.584221,121.484459 127.764603,121.198540 127.944992,120.912628 
	C127.945000,120.912628 127.990814,120.986633 128.201233,120.869705 
	C128.589050,120.453400 128.766418,120.154015 128.943787,119.854630 
	C129.002258,119.522171 129.173538,119.264809 130.064713,119.036667 
	C132.084045,117.586868 133.496338,116.182922 134.908630,114.778976 
	C135.422333,114.241974 135.936035,113.704964 136.933411,113.002274 
	C137.687714,112.675385 137.876282,112.446587 137.982819,112.150169 
	C138.401474,111.798874 138.820129,111.447571 139.765381,110.953461 
	C140.529160,110.548233 140.766342,110.285828 141.003510,110.023422 
	C141.003510,110.023422 141.000000,109.996674 141.371277,110.000458 
	C142.460022,109.289772 143.177475,108.575294 143.894928,107.860825 
	C143.894928,107.860832 144.007797,107.903168 144.334061,107.936195 
	C147.107086,106.645943 149.553848,105.322662 152.054291,104.138092 
	C152.193497,104.326317 152.279007,104.375832 152.364532,104.425346 
	C152.242813,104.283745 152.121109,104.142143 152.121017,103.713043 
	C152.458954,102.845779 152.675278,102.266006 152.891586,101.686241 
	C153.252304,101.374977 153.613007,101.063713 154.598816,100.810684 
	C155.809982,100.234489 156.396042,99.600075 156.982101,98.965668 
	C158.028946,98.298218 159.075790,97.630775 160.835693,96.959877 
	C164.993896,94.918930 168.439026,92.881432 171.884155,90.843933 
	C171.884155,90.843933 171.970398,90.898651 172.152191,90.862457 
	C172.638016,90.695366 172.852432,90.476654 172.977249,90.170128 
	C204.699463,75.078140 237.999405,70.575935 272.714569,75.053513 
	C303.515717,79.026268 330.932892,90.942749 355.878937,110.116394 
	C356.425385,110.785263 356.672211,110.936172 356.971405,111.001503 
	C356.971405,111.001503 357.003357,110.994225 357.125244,111.240410 
	C357.552643,111.637688 357.858154,111.788780 358.163635,111.939873 
	C358.492889,111.950867 358.748383,112.093765 359.006348,112.951096 
	C361.101135,114.975906 363.119690,116.418198 365.138245,117.860489 
	C365.138245,117.860481 365.005585,117.870323 365.056213,118.225594 
	C366.404480,119.385010 367.702118,120.189163 368.999786,120.993317 
	C368.999786,120.993309 369.000153,120.999390 369.101868,121.223190 
	C369.398804,121.726585 369.661163,121.910545 369.990570,121.998871 
	C370.252136,122.203300 370.513672,122.407730 370.991638,123.052567 
	C371.413300,123.755638 371.679291,123.920273 372.005951,123.986862 
	C372.005951,123.986862 372.001221,123.999084 372.099579,124.221909 
	C372.388611,124.727211 372.648590,124.912422 372.977844,125.000381 
	C372.977844,125.000381 373.003662,124.996475 373.105713,125.231308 
	C373.411163,125.743149 373.681000,125.917320 374.017242,125.988663 
	C374.292236,126.168167 374.567230,126.347664 375.014038,126.986176 
	C375.368042,127.723373 375.619080,127.907959 375.938934,127.998947 
	C375.938934,127.998947 376.003967,127.996231 376.096008,128.237457 
	C376.369781,128.739655 376.612244,128.918060 376.915466,129.013901 
	C376.915466,129.013901 376.984314,129.003113 376.987335,129.400696 
	C378.385498,131.148224 379.780609,132.498184 381.175751,133.848129 
	C381.175751,133.848129 381.140472,133.939651 381.188721,134.151337 
	C381.518372,134.559967 381.799805,134.756897 382.081207,134.953812 
	C382.081207,134.953812 381.997101,134.979431 382.009644,135.324585 
	C383.708740,137.427109 385.395325,139.184494 387.081909,140.941864 
	C387.081940,140.941849 387.012939,141.003891 387.016724,141.339523 
	C388.057220,142.750977 389.093964,143.826797 390.130707,144.902618 
	C390.130707,144.902603 390.055176,145.008759 390.046509,145.324997 
	C391.731171,147.730072 393.424530,149.818909 395.117920,151.907745 
	C395.117920,151.907730 395.028687,151.975784 395.109009,152.169968 
	C395.434998,152.580551 395.680664,152.796951 395.926300,153.013367 
z"/>
          <path fill="#FEFFFF" opacity="1.000000" stroke="none"
            d="
M340.044647,403.691376 
	C340.044647,403.691376 339.660400,403.977386 339.177917,404.071259 
	C338.469116,404.401306 338.242828,404.637512 338.016541,404.873657 
	C337.557617,405.216858 337.098724,405.560089 335.968567,405.951599 
	C333.203369,407.339478 331.109375,408.679016 329.015381,410.018555 
	C329.015381,410.018585 329.000000,410.000000 328.826538,410.044983 
	C328.411621,410.178741 328.220825,410.330933 328.080688,410.546570 
	C327.702362,410.718658 327.324036,410.890717 326.363159,411.117706 
	C325.525452,411.412415 325.270294,411.652191 325.015167,411.891998 
	C325.015167,411.891998 324.689484,412.114777 324.142456,412.095337 
	C323.052795,412.438202 322.510162,412.800537 321.967529,413.162872 
	C321.967529,413.162872 321.740570,413.310791 321.145935,413.194031 
	C316.684570,413.570801 315.854828,411.662750 315.926636,408.104309 
	C316.138458,397.609314 316.069611,387.106323 315.925568,376.609009 
	C315.904022,375.038452 314.888672,373.481506 313.987671,371.818298 
	C313.434113,371.599396 313.225983,371.480408 312.845581,371.033539 
	C311.574463,370.471313 310.475830,370.032990 309.376770,370.032013 
	C269.892395,369.997040 230.408005,369.990326 190.923721,370.057892 
	C189.608444,370.060150 188.294540,370.872009 186.603516,371.440308 
	C185.437866,371.702118 184.648682,371.830109 183.475403,372.069885 
	C182.727707,373.871582 182.064728,375.558746 182.049347,377.251831 
	C181.952835,387.877319 181.919510,398.505127 182.056534,409.129730 
	C182.091522,411.842896 181.627579,413.940521 178.964951,415.082703 
	C178.418060,414.892548 177.871170,414.702393 177.103867,413.911285 
	C174.873199,412.589752 172.862946,411.869110 170.852692,411.148468 
	C170.852692,411.148468 170.968292,411.172302 170.917511,410.820892 
	C165.886017,407.999115 160.905319,405.528778 155.924622,403.058411 
	C155.924622,403.058411 156.001617,403.015961 155.939514,402.687256 
	C154.865433,401.929749 153.853455,401.500916 152.841461,401.072113 
	C152.841461,401.072113 152.359070,400.965485 152.044022,400.661011 
	C151.533417,400.162415 151.299942,400.041504 151.028519,399.993805 
	C151.028519,399.993805 150.998932,400.004791 150.885162,399.765259 
	C150.480957,399.369781 150.190521,399.213806 149.900085,399.057861 
	C149.900085,399.057831 149.991379,399.051453 149.957443,398.743042 
	C148.934525,397.991425 147.945541,397.548187 146.956558,397.104980 
	C146.579208,397.015869 146.201874,396.926727 145.537384,396.789520 
	C145.250244,396.741425 145.138077,396.297150 144.960297,395.878723 
	C144.570724,395.217224 144.305634,395.070374 143.987244,395.019745 
	C143.987244,395.019745 143.999146,395.000000 143.882202,394.775391 
	C143.473022,394.389435 143.180801,394.228119 142.888580,394.066803 
	C142.888580,394.066803 142.966782,394.028076 142.831512,393.802429 
	C142.344131,393.425598 141.992020,393.274384 141.639923,393.123169 
	C141.639923,393.123169 141.268829,393.076385 141.006866,392.762207 
	C140.530167,392.209564 140.263336,392.072327 139.944397,392.036346 
	C139.944397,392.036346 139.995712,391.999176 139.868591,391.778839 
	C139.424561,391.396362 139.107666,391.234222 138.790756,391.072113 
	C137.986542,390.523376 137.182343,389.974640 136.077118,388.973145 
	C135.563675,388.247498 135.286896,388.082733 134.945740,388.026062 
	C134.945740,388.026062 134.997131,387.999634 134.874237,387.781067 
	C134.441772,387.394775 134.132202,387.227112 133.822617,387.059418 
	C133.515930,387.030853 133.270233,386.893372 132.905731,386.155884 
	C132.376221,385.479492 132.026489,385.294159 131.676758,385.108826 
	C130.863388,384.568420 130.050018,384.028015 129.024689,383.001465 
	C128.623886,382.253876 128.373734,382.081177 128.062256,381.997253 
	C127.777115,381.812866 127.491982,381.628479 127.106323,380.837402 
	C126.004959,376.677734 124.026459,374.216248 120.042450,373.993927 
	C120.042450,373.993927 119.996399,374.004456 119.999542,373.607910 
	C118.153023,368.110962 116.648720,362.695465 109.863220,362.098694 
	C109.863220,362.098694 109.942665,361.995819 109.951019,361.675171 
	C109.209145,360.609467 108.458916,359.864349 107.708687,359.119263 
	C107.396965,359.048981 107.168983,358.871246 106.999603,357.994049 
	C106.269829,356.633118 105.565201,355.864227 104.860573,355.095367 
	C104.860573,355.095367 104.953819,355.004456 104.958160,354.676575 
	C103.381546,348.366241 100.513924,343.274384 95.027138,339.995514 
	C95.027138,339.995514 94.819504,339.746094 94.873344,339.165924 
	C92.257797,333.742523 89.588402,328.899292 86.919006,324.056061 
	C86.919014,324.056061 86.976410,323.989960 86.964767,323.719788 
	C86.616951,322.978394 86.280769,322.507172 85.944595,322.035950 
	C85.944595,322.035950 85.993759,322.000000 85.996735,321.616760 
	C85.039009,319.152466 84.078308,317.071442 83.117599,314.990387 
	C83.117599,314.990387 82.894783,314.670563 82.886665,314.237732 
	C82.802124,313.482452 82.623703,313.233093 82.343292,313.056793 
	C82.182892,312.686066 82.022491,312.315308 81.907600,311.211517 
	C81.276810,308.987122 80.600510,307.495789 79.924210,306.004456 
	C79.924210,306.004456 79.778481,305.700409 79.861496,305.080170 
	C78.735397,300.634491 77.526283,296.809052 76.317169,292.983612 
	C76.172562,292.561096 76.027962,292.138580 76.161850,291.200256 
	C76.629883,290.078522 76.819420,289.472626 77.441788,288.753357 
	C80.249893,288.732758 82.625153,288.825470 84.947510,289.278992 
	C84.091354,291.752533 82.756996,293.823608 82.587433,295.986023 
	C82.133652,301.772827 84.576881,305.008392 90.966850,304.222198 
	C92.157127,304.075775 93.496086,304.934418 94.733467,305.414185 
	C97.889580,306.637817 98.995468,308.491119 96.810287,311.014801 
	C94.856857,311.014801 93.273758,311.014801 91.690659,311.014801 
	C91.867424,311.969421 91.704811,313.401520 92.273018,313.805359 
	C98.493843,318.226471 99.496887,324.405487 97.698372,331.081848 
	C96.450294,335.714844 99.061844,335.878510 102.085617,335.898621 
	C103.536659,335.908264 104.977791,335.173981 106.447266,335.041840 
	C111.997368,334.542664 114.669785,337.774170 114.198586,343.320374 
	C113.841896,347.518585 112.426582,352.503326 118.289047,354.842041 
	C119.248978,354.075439 120.085258,352.857574 121.055107,352.739838 
	C123.601219,352.430664 126.205528,352.600830 128.786652,352.580048 
	C128.615204,354.639313 128.377213,356.695831 128.290710,358.758636 
	C128.201004,360.897797 127.358521,363.850952 128.420471,365.033417 
	C131.536880,368.503448 130.446289,372.508545 131.197937,376.296509 
	C131.712036,378.887268 131.439514,382.702698 135.719101,382.981354 
	C141.383575,383.350159 147.053940,383.630432 152.716797,384.020813 
	C154.352402,384.133575 155.999435,384.376282 157.592056,384.762329 
	C162.890350,386.046570 163.510239,387.436584 162.671814,392.850159 
	C162.332855,395.038788 162.432907,398.326324 163.752167,399.454590 
	C165.106476,400.612885 168.197571,399.830017 170.521042,399.726990 
	C171.650085,399.676910 172.749512,399.141449 173.884155,398.997437 
	C176.481415,398.667725 176.933716,397.447906 176.557846,394.866302 
	C175.774460,389.486023 175.059860,384.013519 175.203049,378.602325 
	C175.364685,372.494110 178.322769,366.462860 175.151047,360.272827 
	C174.555283,359.110138 175.188370,357.335327 175.195541,355.842712 
	C175.212738,352.260132 175.165070,348.677063 175.203125,345.094818 
	C175.224091,343.121918 175.324509,341.147430 175.462006,339.178772 
	C175.673080,336.156219 176.330551,333.109314 176.106598,330.124634 
	C175.747894,325.344177 177.675278,320.796631 181.083649,320.457520 
	C181.178558,325.250946 181.186234,329.586853 180.889984,334.143402 
	C181.044250,336.573730 181.502426,338.783417 182.049789,341.177917 
	C182.138962,341.362701 182.100861,341.771332 182.061554,342.192505 
	C183.055695,344.714539 184.089142,346.815430 185.088699,348.950256 
	C185.054810,348.984161 185.066254,348.888947 185.126785,349.125061 
	C185.344131,349.664734 185.583542,349.873749 186.012634,350.128113 
	C186.119751,350.268066 186.128189,350.620483 186.169800,350.913574 
	C186.495483,351.461151 186.779556,351.715607 187.283997,352.096771 
	C187.663208,352.519257 187.822021,352.815033 188.079498,353.306671 
	C188.355667,353.747131 188.585999,353.922302 189.127090,354.173920 
	C189.962173,355.119659 190.539337,355.919434 191.109604,357.110046 
	C192.494125,358.293365 193.885559,359.085846 195.485657,359.912415 
	C195.694336,359.946503 196.028809,360.205261 196.156342,360.398254 
	C196.515289,360.820007 196.793518,360.942566 197.368317,361.000122 
	C197.760025,361.132629 197.901962,361.223877 198.163727,361.481628 
	C198.490616,361.844177 198.735901,361.958191 199.295105,362.046387 
	C199.733704,362.195679 199.896576,362.288757 200.184814,362.534668 
	C200.521118,362.863251 200.763611,362.960114 201.019012,362.989563 
	C201.000336,363.001099 201.022903,362.963531 201.073822,363.332458 
	C203.452484,366.594177 206.117401,366.893494 209.563126,364.710205 
	C236.319199,364.705811 262.613556,364.729675 289.119995,365.055054 
	C292.179749,364.402161 295.982727,366.847839 298.215637,362.817505 
	C298.435577,362.712555 298.916199,362.626862 298.916199,362.626862 
	C298.916199,362.626862 299.390594,362.742279 299.773041,362.794922 
	C300.754181,362.205170 301.352905,361.562775 301.975952,360.954742 
	C302.000275,360.989105 301.928253,360.945557 302.242004,360.947083 
	C303.022034,360.593658 303.488281,360.238708 303.965240,359.923645 
	C303.975952,359.963501 303.897217,359.938629 304.139404,359.872070 
	C304.592346,359.554108 304.803131,359.302765 305.005798,359.025696 
	C304.997742,358.999969 305.049622,359.014587 305.253387,358.920776 
	C305.715057,358.652527 305.890656,358.416809 306.163269,357.922485 
	C306.791382,357.449036 307.240265,357.172852 307.985870,356.822083 
	C308.494080,356.414734 308.705597,356.082001 309.025909,355.482941 
	C309.957703,354.425446 310.780701,353.634277 311.994202,352.854248 
	C313.212891,351.144745 314.041046,349.424133 314.894043,347.500122 
	C314.918854,347.296753 315.167786,346.971222 315.380768,346.806427 
	C315.683502,346.298798 315.773254,345.955933 315.883606,345.319031 
	C316.054779,344.663177 316.205353,344.301331 316.524780,343.776154 
	C316.872986,343.333771 316.937012,343.030304 316.865906,342.515778 
	C316.846161,342.329102 317.019897,341.996307 317.279266,341.792053 
	C317.648651,340.043518 317.758698,338.499298 317.830139,336.513794 
	C317.886322,333.710602 317.981140,331.348633 318.355865,328.682129 
	C318.626099,315.086639 318.616425,301.795715 318.429016,288.338959 
	C318.251251,288.173126 318.384888,287.705688 318.764648,287.796356 
	C319.770508,287.570953 320.396606,287.254852 321.443481,286.988403 
	C323.267944,287.152802 324.671661,287.267639 326.403442,287.594177 
	C330.126953,287.678711 333.522400,287.551544 337.276672,287.352417 
	C338.118317,287.330536 338.601135,287.380554 339.381958,287.621338 
	C341.758667,287.682770 343.837341,287.553436 346.367523,287.342896 
	C349.568451,287.303680 352.317902,287.345642 355.410004,287.599426 
	C360.475830,287.673706 365.199005,287.536224 370.313110,287.317993 
	C371.496246,287.294250 372.288483,287.351257 373.425415,287.618042 
	C379.492126,287.673401 385.214081,287.518982 391.289429,287.300537 
	C392.117462,287.284180 392.592041,287.331848 393.409119,287.594055 
	C398.814087,287.651337 403.876556,287.494141 409.380402,287.255859 
	C412.209106,287.230591 414.596436,287.286499 417.051086,287.694275 
	C417.529846,290.498413 418.434326,292.989990 418.242432,295.394104 
	C417.918304,299.455231 416.900055,303.460938 416.173706,307.489960 
	C416.173676,307.489960 416.286438,307.751221 415.864990,308.022888 
	C414.663483,310.942749 413.883423,313.590942 413.103363,316.239166 
	C413.103363,316.239166 413.130737,316.644745 412.670990,316.892273 
	C411.838928,318.460724 411.466614,319.781677 411.094269,321.102631 
	C411.094269,321.102631 411.080353,320.993805 410.739075,321.062317 
	C409.329346,323.923279 408.260864,326.715729 407.192383,329.508179 
	C407.192383,329.508179 407.312622,329.750641 406.952179,330.022827 
	C406.420807,330.894165 406.249847,331.493317 406.078918,332.092438 
	C406.078918,332.092438 406.045135,331.991638 405.691895,332.048950 
	C404.576477,334.091400 403.814301,336.076477 403.052155,338.061584 
	C403.052155,338.061584 402.999481,338.011230 402.769409,338.174316 
	C402.409698,338.716064 402.280060,339.094727 402.150391,339.473419 
	C402.150421,339.473419 402.191498,339.774902 401.844666,340.052246 
	C401.379974,340.697449 401.262146,341.065308 401.144287,341.433167 
	C401.144287,341.433167 401.163879,341.757965 400.838013,342.007202 
	C400.374786,342.557800 400.237427,342.859131 400.100037,343.160461 
	C400.100037,343.160461 400.113190,343.064880 399.763184,343.089478 
	C398.620514,344.756592 397.827850,346.399109 397.035187,348.041595 
	C397.035187,348.041595 397.001709,347.998566 396.654724,348.042236 
	C395.526276,349.697510 394.744781,351.309174 393.963287,352.920807 
	C393.963287,352.920807 393.987427,352.987946 393.740601,353.101105 
	C393.231598,353.428925 393.074554,353.703674 393.022644,354.038513 
	C393.022644,354.038513 393.000000,353.999359 392.662262,354.036713 
	C390.880188,356.376770 389.435822,358.679474 387.991486,360.982178 
	C387.991486,360.982178 388.001221,361.001221 387.765442,361.109070 
	C387.364166,361.500580 387.198639,361.784241 387.033142,362.067902 
	C387.033142,362.067902 387.003540,362.006714 386.791199,362.121338 
	C386.413391,362.538208 386.247894,362.840424 386.082397,363.142639 
	C386.082397,363.142639 386.086243,363.045258 385.754791,363.070496 
	C383.971741,365.093353 382.520111,367.091034 381.068481,369.088715 
	C381.068512,369.088715 381.021149,368.999268 380.701752,369.038177 
	C378.651093,371.213348 376.919830,373.349609 375.188568,375.485870 
	C375.188568,375.485870 375.198761,375.792908 374.868958,375.994324 
	C374.268005,376.385742 374.085602,376.639526 373.991882,376.957092 
	C373.481506,377.556580 372.971130,378.156097 372.180237,378.863708 
	C367.489410,379.658661 364.437775,381.977905 363.050171,386.680908 
	C363.029572,387.040924 363.016357,387.007935 362.803497,387.092926 
	C362.302277,387.348724 362.109161,387.594421 362.011292,387.914978 
	C362.011292,387.914978 361.822815,388.160309 361.278656,388.151794 
	C360.191711,388.849854 359.648926,389.556427 359.106171,390.263000 
	C358.543793,390.774261 357.981445,391.285553 356.834381,391.918579 
	C355.865295,392.464661 355.480896,392.889008 355.096497,393.313354 
	C354.761932,393.641602 354.427399,393.969879 353.857910,394.324280 
	C347.701843,394.269318 344.215424,398.022980 341.045074,402.666870 
	C341.017120,403.015198 341.002197,403.001678 340.809601,403.062866 
	C340.358948,403.245300 340.168152,403.434387 340.044647,403.691376 
M330.606964,291.000000 
	C328.774750,291.010803 326.857788,290.696838 325.139832,291.145294 
	C323.973145,291.449860 322.237946,292.793915 322.205170,293.724121 
	C321.897217,302.461426 321.882294,311.209595 321.824585,319.954895 
	C321.798676,323.882355 322.402893,327.899017 321.770599,331.717834 
	C320.772156,337.747681 325.142273,343.898010 321.177643,349.780609 
	C321.028015,350.002594 321.476501,350.686096 321.724609,351.108490 
	C323.699036,354.469879 323.750061,357.614227 320.991364,360.669128 
	C320.429871,361.290894 320.057220,362.633423 320.331818,363.369873 
	C322.008270,367.865631 318.057739,373.366425 322.841278,377.311096 
	C323.180817,377.591095 323.010376,378.597656 322.938568,379.251160 
	C322.354828,384.562347 321.727509,389.868774 321.135193,395.179047 
	C320.769592,398.456604 323.441010,401.816528 326.429321,401.881073 
	C332.048004,402.002441 336.617371,396.972626 335.918976,391.435120 
	C335.256653,386.183502 336.106079,385.917053 341.649994,385.566071 
	C345.259491,385.337555 349.975891,384.724487 350.803497,379.804321 
	C351.801422,373.871613 354.912415,370.223938 360.622070,368.580078 
	C365.769989,367.097931 368.341614,364.511810 368.418182,358.254974 
	C368.513062,350.503113 373.140472,349.056152 379.323700,353.770508 
	C379.933472,354.235443 380.705261,354.487823 382.505646,355.395111 
	C381.523010,349.815002 386.613312,345.907867 383.490753,340.461029 
	C381.755157,337.433685 383.644501,335.169769 386.989716,335.104370 
	C390.832123,335.029205 394.685822,335.531799 398.267975,335.765991 
	C399.038361,327.850006 399.896759,319.481964 400.600098,311.100891 
	C400.669952,310.268066 399.697815,309.390045 399.376160,308.474396 
	C398.322845,305.475952 399.370483,303.371460 402.541077,303.166534 
	C405.109985,303.000488 407.730774,303.599518 410.325348,303.888153 
	C411.230743,303.988922 412.873474,304.421082 412.917175,304.287811 
	C413.959351,301.110718 415.449310,297.895172 415.543549,294.648468 
	C415.625610,291.822083 413.022980,290.903931 410.097351,290.946564 
	C400.358521,291.088440 390.616333,291.000031 380.875427,291.000000 
	C364.443146,290.999969 348.010834,291.000000 330.606964,291.000000 
z"/>
          <path fill="#E4C344" opacity="1.000000" stroke="none"
            d="
M425.020996,184.932983 
	C424.419189,183.801697 423.219971,182.621231 423.308441,181.546768 
	C424.154053,171.274185 436.146576,161.324005 447.671936,161.130936 
	C460.753265,160.911804 471.992950,169.412537 473.405731,180.615173 
	C473.442017,180.903107 473.222137,181.223358 472.646179,181.501190 
	C470.821960,180.908096 469.473358,180.342361 467.962280,179.698944 
	C467.799866,179.621246 467.442444,179.580444 467.220642,179.274857 
	C455.341522,175.092072 443.786530,176.515411 432.408752,180.038269 
	C429.940125,180.802612 427.942596,183.088531 425.874451,184.759354 
	C426.020813,184.845078 425.724396,184.688889 425.529877,184.696136 
	C425.335358,184.703369 425.020996,184.932968 425.020996,184.932983 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M416.434814,307.200989 
	C416.900055,303.460938 417.918304,299.455231 418.242432,295.394104 
	C418.434326,292.989990 417.529846,290.498413 417.374817,287.569702 
	C418.143677,285.704010 418.656128,284.314819 419.254211,282.624298 
	C419.289520,281.912354 419.239197,281.501709 419.349060,280.711334 
	C419.649658,269.556305 419.790070,258.781006 419.930481,248.005707 
	C420.287781,252.035812 420.620117,256.068359 421.014587,260.094849 
	C421.173370,261.715240 421.468323,263.322296 422.563293,264.838470 
	C424.981567,250.966904 423.581116,237.194778 422.833496,223.187683 
	C424.003784,233.337952 425.570892,243.719772 425.622833,254.109161 
	C425.712982,272.149872 422.689209,289.820953 416.434814,307.200989 
z"/>
          <path fill="#BCBF77" opacity="1.000000" stroke="none"
            d="
M473.848633,187.719070 
	C476.134766,196.619629 471.266327,202.715042 465.265869,207.866287 
	C452.535217,218.795212 430.906250,213.396347 424.657013,197.900528 
	C424.021088,196.323639 424.489227,194.301498 424.991119,192.503098 
	C425.993286,192.635559 426.449371,192.750900 426.969910,193.157349 
	C427.096161,193.837616 427.157990,194.226791 427.094360,194.917175 
	C427.197479,196.147049 427.425964,197.075729 427.762268,198.350555 
	C431.067963,204.757706 436.403137,208.107483 442.617920,210.364059 
	C452.153137,213.826248 467.470947,206.257034 470.187866,196.555313 
	C470.467529,195.556717 469.387970,194.177490 468.961914,192.725082 
	C468.988403,192.307800 468.992065,192.140320 469.259766,191.808075 
	C470.658325,190.514999 471.792847,189.386688 473.169128,188.143051 
	C473.556763,187.924866 473.702698,187.821976 473.848633,187.719070 
z"/>
          <path fill="#1A6F8A" opacity="1.000000" stroke="none"
            d="
M426.905457,192.866272 
	C426.449371,192.750900 425.993286,192.635559 425.275085,192.268463 
	C425.012970,192.016708 424.988892,191.532150 425.131897,191.217834 
	C425.040375,190.618759 424.805786,190.334000 424.571228,190.049240 
	C424.456055,188.858673 424.340912,187.668091 424.712433,186.150238 
	C425.374237,185.444931 425.549316,185.066910 425.724396,184.688889 
	C425.724396,184.688889 426.020813,184.845078 426.251953,184.856812 
	C429.729004,183.633698 432.974884,182.398849 436.163300,181.527740 
	C435.242065,185.552856 434.378296,189.214218 433.388031,192.938904 
	C433.261536,193.002228 432.992920,193.091080 432.713379,193.074982 
	C432.248169,193.078064 432.062500,193.097229 431.535736,193.103012 
	C429.764923,193.015167 428.335205,192.940720 426.905457,192.866272 
z"/>
          <path fill="#1A6F8A" opacity="1.000000" stroke="none"
            d="
M473.876709,187.413971 
	C473.702698,187.821976 473.556763,187.924866 472.813782,188.057388 
	C470.550385,188.469238 468.884094,188.851440 466.907288,188.966187 
	C465.977997,186.653885 465.359253,184.609055 464.676331,182.340134 
	C464.586975,181.804367 464.552368,181.493744 464.658020,181.094223 
	C464.807831,181.004272 465.113739,180.835220 465.469543,180.895111 
	C466.549103,180.995850 467.272888,181.036697 468.185181,181.360855 
	C469.950745,182.491272 471.527710,183.338394 473.104706,184.185532 
	C473.371399,185.159988 473.638062,186.134430 473.876709,187.413971 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M420.046509,207.602692 
	C417.341827,200.259613 414.673981,192.515945 412.034241,184.350098 
	C414.736023,191.686005 417.409698,199.444061 420.046509,207.602692 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M375.514771,375.362793 
	C376.919830,373.349609 378.651093,371.213348 380.739868,369.071533 
	C379.345215,371.123901 377.593079,373.181824 375.514771,375.362793 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M407.475342,329.274933 
	C408.260864,326.715729 409.329346,323.923279 410.765686,321.110291 
	C410.008453,323.740417 408.883392,326.391052 407.475342,329.274933 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M388.298340,360.852478 
	C389.435822,358.679474 390.880188,356.376770 392.680542,354.050140 
	C391.559479,356.258392 390.082336,358.490570 388.298340,360.852478 
z"/>
          <path fill="#4F7855" opacity="1.000000" stroke="none"
            d="
M473.085602,183.791382 
	C471.527710,183.338394 469.950745,182.491272 468.202942,181.033783 
	C468.062988,180.207809 468.093872,179.992218 468.124725,179.776642 
	C469.473358,180.342361 470.821960,180.908096 472.383789,181.687744 
	C472.753479,182.400177 472.910004,182.898712 473.085602,183.791382 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M422.892792,220.588837 
	C422.071869,218.450607 421.299469,215.923538 420.809174,213.179321 
	C421.707947,215.374786 422.324615,217.787384 422.892792,220.588837 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M381.381378,368.970673 
	C382.520111,367.091034 383.971741,365.093353 385.789581,363.104614 
	C384.668640,365.026550 383.181458,366.939575 381.381378,368.970673 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M413.359924,315.980652 
	C413.883423,313.590942 414.663483,310.942749 415.781738,308.138245 
	C415.285461,310.562042 414.450958,313.142090 413.359924,315.980652 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M403.315033,337.849701 
	C403.814301,336.076477 404.576477,334.091400 405.725037,332.090576 
	C405.266907,333.929169 404.422394,335.783478 403.315033,337.849701 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M397.328033,347.885315 
	C397.827850,346.399109 398.620514,344.756592 399.798157,343.122742 
	C399.329041,344.663940 398.474976,346.196503 397.328033,347.885315 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M394.248444,352.764221 
	C394.744781,351.309174 395.526276,349.697510 396.676514,348.059082 
	C396.208008,349.557373 395.370819,351.082489 394.248444,352.764221 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M404.054626,166.612106 
	C403.217987,165.764084 402.348846,164.586609 401.720245,163.211929 
	C402.647888,164.104019 403.334991,165.193329 404.054626,166.612106 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M411.131989,181.588577 
	C410.424286,180.525787 409.714294,179.109192 409.027069,177.312897 
	C409.743103,178.367065 410.436401,179.800919 411.131989,181.588577 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M411.369873,320.915894 
	C411.466614,319.781677 411.838928,318.460724 412.567871,317.067444 
	C412.498169,318.239777 412.071808,319.484467 411.369873,320.915894 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M409.068909,176.656189 
	C408.738464,176.458817 408.400848,175.942017 408.083496,175.093689 
	C408.423096,175.287018 408.742401,175.811874 409.068909,176.656189 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M412.101074,183.658066 
	C411.746246,183.486511 411.403137,183.006668 411.077148,182.210297 
	C411.433807,182.379089 411.773315,182.864441 412.101074,183.658066 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M406.343536,331.917603 
	C406.249847,331.493317 406.420807,330.894165 406.872925,330.132538 
	C406.972137,330.560944 406.790161,331.151886 406.343536,331.917603 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M420.631165,209.690613 
	C420.323730,209.735184 420.166870,209.480728 420.113892,208.932007 
	C420.429321,208.858200 420.617310,209.109497 420.631165,209.690613 
z"/>
          <path fill="#4F7855" opacity="1.000000" stroke="none"
            d="
M425.529877,184.696136 
	C425.549316,185.066910 425.374237,185.444931 424.877380,185.916504 
	C424.628967,185.828934 424.702332,185.647842 424.898346,185.199860 
	C425.020996,184.932968 425.335358,184.703369 425.529877,184.696136 
z"/>
          <path fill="#4F7855" opacity="1.000000" stroke="none"
            d="
M424.689331,190.351227 
	C424.805786,190.334000 425.040375,190.618759 425.129822,191.090240 
	C424.925598,191.069046 424.866486,190.861130 424.689331,190.351227 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M407.100525,172.633698 
	C406.848480,172.658417 406.542114,172.367538 406.204102,171.746246 
	C406.463715,171.716599 406.754974,172.017365 407.100525,172.633698 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M405.803833,169.716888 
	C405.489838,169.825531 405.272736,169.589844 405.116577,169.020386 
	C405.321655,169.004562 405.562744,169.233215 405.803833,169.716888 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M404.771210,167.730164 
	C404.486237,167.844971 404.277527,167.640076 404.099182,167.134766 
	C404.396179,166.974228 404.635498,167.166260 404.771210,167.730164 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M387.271179,361.973572 
	C387.198639,361.784241 387.364166,361.500580 387.755005,361.105988 
	C387.823303,361.289795 387.666260,361.584534 387.271179,361.973572 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M386.333801,363.048340 
	C386.247894,362.840424 386.413391,362.538208 386.823242,362.132690 
	C386.906799,362.337616 386.746002,362.645813 386.333801,363.048340 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M402.399780,339.301422 
	C402.280060,339.094727 402.409698,338.716064 402.804626,338.183655 
	C402.929657,338.396423 402.789398,338.762909 402.399780,339.301422 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M401.378845,341.257050 
	C401.262146,341.065308 401.379974,340.697449 401.742859,340.165314 
	C401.863098,340.361023 401.738251,340.720978 401.378845,341.257050 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M400.346375,343.063446 
	C400.237427,342.859131 400.374786,342.557800 400.732849,342.131348 
	C400.833252,342.326294 400.712982,342.646362 400.346375,343.063446 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M393.249359,353.936646 
	C393.074554,353.703674 393.231598,353.428925 393.705078,353.097961 
	C393.920319,353.343781 393.773560,353.628143 393.249359,353.936646 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M374.244537,376.903748 
	C374.085602,376.639526 374.268005,376.385742 374.756439,376.098907 
	C374.814911,376.284882 374.656036,376.567657 374.244537,376.903748 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M171.529419,90.883331 
	C168.439026,92.881432 164.993896,94.918930 161.151672,96.923347 
	C164.227951,94.901093 167.701324,92.911911 171.529419,90.883331 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M152.000610,103.999390 
	C149.553848,105.322662 147.107086,106.645943 144.274216,107.930656 
	C146.658417,105.875862 149.428726,103.859627 152.545319,101.764816 
	C152.675278,102.266006 152.458954,102.845779 152.121323,103.712769 
	C152.000000,104.000000 152.000610,103.999390 152.000610,103.999390 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M365.022461,117.547318 
	C363.119690,116.418198 361.101135,114.975906 359.085144,113.163712 
	C361.027344,114.273918 362.967010,115.754036 365.022461,117.547318 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M387.024506,140.611481 
	C385.395325,139.184494 383.708740,137.427109 382.044434,135.297638 
	C383.700195,136.710724 385.333649,138.495911 387.024506,140.611481 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M381.110413,133.527878 
	C379.780609,132.498184 378.385498,131.148224 376.982239,129.435165 
	C378.331085,130.450577 379.688080,131.829102 381.110413,133.527878 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M134.584137,114.856987 
	C133.496338,116.182922 132.084045,117.586868 130.294342,118.976562 
	C131.364502,117.619873 132.812073,116.277435 134.584137,114.856987 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M390.093109,144.604431 
	C389.093964,143.826797 388.057220,142.750977 387.041016,141.300140 
	C387.669342,141.382645 388.277130,141.840164 389.108551,142.479370 
	C389.573273,143.209457 389.814392,143.757843 390.093109,144.604431 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M368.887634,120.688477 
	C367.702118,120.189163 366.404480,119.385010 365.122375,118.209526 
	C366.350494,118.686668 367.562988,119.535149 368.887634,120.688477 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M143.583649,107.901947 
	C143.177475,108.575294 142.460022,109.289772 141.383179,110.006996 
	C141.773331,109.320854 142.522858,108.631958 143.583649,107.901947 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M156.657379,99.002457 
	C156.396042,99.600075 155.809982,100.234489 154.847961,100.833054 
	C155.092224,100.211212 155.712448,99.625229 156.657379,99.002457 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M369.931335,121.749771 
	C369.661163,121.910545 369.398804,121.726585 369.105042,121.223404 
	C369.295074,121.166779 369.583588,121.333725 369.931335,121.749771 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M137.743042,112.177689 
	C137.876282,112.446587 137.687714,112.675385 137.205383,112.917282 
	C137.042694,112.656021 137.212555,112.391762 137.743042,112.177689 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M358.043335,111.706596 
	C357.858154,111.788780 357.552643,111.637688 357.123901,111.256767 
	C357.308075,111.175728 357.615570,111.324524 358.043335,111.706596 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M356.898315,110.779602 
	C356.672211,110.936172 356.425385,110.785263 356.126526,110.356148 
	C356.367126,110.137398 356.634857,110.268799 356.898315,110.779602 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M140.737244,110.076324 
	C140.766342,110.285828 140.529160,110.548233 140.023911,110.862930 
	C139.994217,110.653214 140.232590,110.391220 140.737244,110.076324 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M372.905212,124.759460 
	C372.648590,124.912422 372.388611,124.727211 372.105438,124.219330 
	C372.286163,124.168800 372.559387,124.343674 372.905212,124.759460 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M127.689476,120.988922 
	C127.764603,121.198540 127.584221,121.484459 127.160370,121.861588 
	C127.089256,121.656937 127.261612,121.361076 127.689476,120.988922 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M128.693176,119.951294 
	C128.766418,120.154015 128.589050,120.453400 128.162262,120.850365 
	C128.089417,120.647949 128.265991,120.347946 128.693176,119.951294 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M126.706215,122.006721 
	C126.772972,122.204628 126.595345,122.491249 126.175430,122.870239 
	C126.109367,122.673546 126.285599,122.384483 126.706215,122.006721 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M125.701004,123.003632 
	C125.756340,123.218353 125.549339,123.503143 125.080956,123.861221 
	C125.025940,123.647575 125.232300,123.360634 125.701004,123.003632 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M373.934937,125.750114 
	C373.681000,125.917320 373.411163,125.743149 373.103821,125.244202 
	C373.284119,125.185349 373.568390,125.348457 373.934937,125.750114 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M371.927673,123.749527 
	C371.679291,123.920273 371.413300,123.755638 371.096802,123.282043 
	C371.346649,123.074913 371.634583,123.221947 371.927673,123.749527 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M121.685616,126.931969 
	C121.767853,127.139244 121.597534,127.443275 121.179504,127.849449 
	C121.098885,127.643967 121.265968,127.336342 121.685616,126.931969 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M375.873840,127.761879 
	C375.619080,127.907959 375.368042,127.723373 375.096680,127.224564 
	C375.359344,127.047249 375.626404,127.220879 375.873840,127.761879 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M376.846985,128.784241 
	C376.612244,128.918060 376.369781,128.739655 376.094543,128.269958 
	C376.354095,128.077637 376.613281,128.242081 376.846985,128.784241 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M172.731812,90.183464 
	C172.852432,90.476654 172.638016,90.695366 172.101379,90.867523 
	C171.931000,90.546814 172.136871,90.309486 172.731812,90.183464 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M382.017242,134.693878 
	C381.799805,134.756897 381.518372,134.559967 381.170166,134.105942 
	C381.386658,134.043884 381.669983,134.238922 382.017242,134.693878 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M322.622986,439.779907 
	C324.562622,440.600433 326.137878,441.482483 327.713104,442.364563 
	C325.983398,443.874481 324.359619,446.383240 322.490784,446.581390 
	C320.968506,446.742798 319.152985,444.138916 316.481995,441.955658 
	C319.576660,440.823029 320.917603,440.332245 322.622986,439.779907 
z"/>
          <path fill="#CB1519" opacity="1.000000" stroke="none"
            d="
M44.961639,163.917984 
	C51.939583,161.738525 63.483517,166.904373 68.161781,174.600769 
	C74.701813,185.360046 72.845268,199.628845 63.068092,205.771408 
	C62.328629,204.359756 62.393417,202.990448 61.941471,201.820831 
	C61.123482,199.703934 60.011429,197.642334 57.541519,200.711975 
	C56.304325,200.032700 55.414127,199.368652 54.413734,199.035706 
	C52.370815,198.355804 50.268608,197.854065 47.708717,197.145493 
	C46.351665,197.543777 44.122684,198.197952 41.663887,198.646149 
	C40.293430,194.966599 37.633080,197.645782 35.483051,196.889313 
	C33.879742,196.563049 32.576401,196.391876 31.250870,195.836334 
	C33.068031,194.370468 36.389118,193.388657 36.469845,192.188828 
	C36.715359,188.539841 39.313564,184.610031 35.741253,180.999191 
	C33.526752,178.760803 33.406139,176.020248 35.736065,173.406723 
	C36.644760,172.387405 37.114799,170.977051 38.126366,169.116180 
	C35.829098,169.366165 34.259373,169.536987 32.689644,169.707809 
	C34.477612,168.389420 36.265572,167.071030 38.535580,166.126053 
	C42.372112,166.653458 45.726593,166.807449 49.081078,166.961426 
	C49.079727,166.521469 49.078377,166.081512 49.077023,165.641556 
	C47.705227,165.067032 46.333431,164.492508 44.961639,163.917984 
M44.758774,182.585403 
	C40.738861,183.475052 38.804081,186.423065 40.121315,189.907394 
	C41.604404,193.830475 45.305523,192.168655 47.744808,190.710449 
	C51.590736,188.411438 55.378662,185.812729 58.592796,182.719208 
	C61.523743,179.898239 62.563984,175.491745 60.205109,172.205963 
	C58.592442,169.959625 54.903049,168.409058 51.985863,168.119034 
	C49.095528,167.831696 45.841797,169.148712 43.051289,170.425888 
	C39.536427,172.034592 35.447605,174.675446 37.419621,178.993591 
	C39.567516,183.696823 43.756832,180.271652 47.004906,179.351517 
	C49.558952,178.627945 51.999260,177.502884 54.987686,176.367035 
	C55.660007,182.868149 51.901993,185.227936 48.980396,187.277740 
	C47.675209,185.518326 46.580845,184.043106 44.758774,182.585403 
z"/>
          <path fill="#E10408" opacity="1.000000" stroke="none"
            d="
M32.497833,169.735809 
	C34.259373,169.536987 35.829098,169.366165 38.126366,169.116180 
	C37.114799,170.977051 36.644760,172.387405 35.736065,173.406723 
	C33.406139,176.020248 33.526752,178.760803 35.741253,180.999191 
	C39.313564,184.610031 36.715359,188.539841 36.469845,192.188828 
	C36.389118,193.388657 33.068031,194.370468 31.237724,195.816162 
	C31.246767,196.180374 31.203651,196.171143 30.834339,196.157486 
	C28.953501,197.109055 27.441977,198.074280 25.930454,199.039520 
	C25.930458,199.039520 25.811405,198.648865 25.872719,197.991257 
	C25.865408,191.433731 25.796783,185.533829 25.728157,179.633911 
	C26.567488,177.509033 27.406822,175.384155 28.900116,173.084686 
	C30.348013,171.899765 31.141949,170.889450 31.935883,169.879135 
	C31.935883,169.879135 32.306019,169.763794 32.497833,169.735809 
z"/>
          <path fill="#CB1519" opacity="1.000000" stroke="none"
            d="
M58.314293,209.062515 
	C51.207226,215.294815 38.303898,213.980148 33.084557,205.986115 
	C35.612377,205.373459 37.858109,205.237915 40.237343,205.115051 
	C40.370842,205.127701 40.640015,205.140076 40.894714,205.374481 
	C42.365791,205.249344 43.582172,204.889786 44.798550,204.530212 
	C44.709751,204.840958 44.620953,205.151703 44.532154,205.462448 
	C47.764740,205.997177 50.997322,206.531906 54.569698,207.062103 
	C56.044426,207.725876 57.179359,208.394196 58.314293,209.062515 
z"/>
          <path fill="#F39095" opacity="1.000000" stroke="none"
            d="
M40.103844,205.102386 
	C37.858109,205.237915 35.612377,205.373459 32.994114,205.611908 
	C31.456957,204.732162 30.292328,203.749496 29.170937,202.122620 
	C29.877333,199.709320 30.540493,197.940231 31.203651,196.171143 
	C31.203651,196.171143 31.246767,196.180374 31.259914,196.200531 
	C32.576401,196.391876 33.879742,196.563049 35.593273,197.267242 
	C39.171520,199.222824 40.807980,201.505447 40.103844,205.102386 
z"/>
          <path fill="#A44138" opacity="1.000000" stroke="none"
            d="
M58.657684,209.055588 
	C57.179359,208.394196 56.044426,207.725876 54.482864,206.670013 
	C54.933105,205.531754 55.990452,204.908310 56.635189,203.993927 
	C57.257694,203.111038 57.454716,201.928131 57.838646,200.877029 
	C60.011429,197.642334 61.123482,199.703934 61.941471,201.820831 
	C62.393417,202.990448 62.328629,204.359756 62.760712,205.940155 
	C61.683456,207.177078 60.342266,208.112869 58.657684,209.055588 
z"/>
          <path fill="#CB1519" opacity="1.000000" stroke="none"
            d="
M25.385357,179.795624 
	C25.796783,185.533829 25.865408,191.433731 25.868263,197.787109 
	C22.327511,192.280045 21.389366,186.214111 25.385357,179.795624 
z"/>
          <path fill="#E10408" opacity="1.000000" stroke="none"
            d="
M44.592468,163.918823 
	C46.333431,164.492508 47.705227,165.067032 49.077023,165.641556 
	C49.078377,166.081512 49.079727,166.521469 49.081078,166.961426 
	C45.726593,166.807449 42.372112,166.653458 38.901863,166.109589 
	C40.598503,165.119690 42.410900,164.519684 44.592468,163.918823 
z"/>
          <path fill="#CB1519" opacity="1.000000" stroke="none"
            d="
M30.834339,196.157486 
	C30.540493,197.940231 29.877333,199.709320 29.077290,201.821869 
	C27.957043,201.350403 26.973677,200.535477 25.960382,199.380035 
	C27.441977,198.074280 28.953501,197.109055 30.834339,196.157486 
z"/>
          <path fill="#CB1519" opacity="1.000000" stroke="none"
            d="
M31.598171,169.916351 
	C31.141949,170.889450 30.348013,171.899765 29.191545,172.898438 
	C29.639496,171.909073 30.449976,170.931320 31.598171,169.916351 
z"/>
          <path fill="#122218" opacity="1.000000" stroke="none"
            d="
M85.000420,288.918182 
	C82.625153,288.825470 80.249893,288.732758 77.252380,288.609924 
	C76.630142,288.579803 76.171494,288.456848 76.171494,288.456848 
	C76.126717,287.833649 76.081940,287.210480 76.196953,286.164673 
	C76.217667,285.161041 76.078583,284.580078 76.030914,283.568726 
	C76.069511,281.095947 76.016693,279.053589 75.963882,277.011200 
	C76.046547,276.249298 76.129204,275.487396 76.208755,274.114807 
	C76.143929,273.339233 76.082222,273.174347 76.020515,273.009430 
	C76.078239,264.888550 76.298683,256.764038 76.117752,248.648483 
	C76.039711,245.147659 77.142159,243.988525 80.639687,244.112717 
	C87.769402,244.365860 94.912331,244.246857 102.436600,244.611633 
	C102.833557,245.297394 102.843727,245.652710 102.398880,246.001495 
	C94.420395,245.994980 86.896927,245.994980 78.598351,245.994980 
	C81.259819,254.047272 81.742531,261.157440 80.113007,268.454010 
	C79.562737,270.917999 79.416214,275.358307 80.618614,275.993744 
	C85.269455,278.451569 87.552574,284.774597 94.158249,284.220123 
	C95.323288,284.122345 96.719444,286.777802 97.650116,288.306519 
	C95.193924,288.596222 93.096489,288.741180 90.584549,288.757812 
	C88.446838,288.725708 86.723633,288.821960 85.000420,288.918182 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M75.592651,276.925690 
	C76.016693,279.053589 76.069511,281.095947 75.814209,283.544769 
	C75.506104,283.951202 75.081131,284.050201 75.081131,284.050201 
	C74.019775,278.666107 72.958412,273.282013 71.962112,267.451080 
	C73.091919,270.282898 74.156670,273.561523 75.592651,276.925690 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M86.948364,324.415649 
	C89.588402,328.899292 92.257797,333.742523 94.878693,339.003723 
	C93.142410,336.689178 91.454620,333.956665 89.495789,331.024780 
	C88.475739,328.808685 87.726730,326.791992 86.948364,324.415649 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M76.273560,293.363831 
	C77.526283,296.809052 78.735397,300.634491 79.897026,304.915344 
	C78.643005,301.495209 77.436470,297.619629 76.273560,293.363831 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M71.234970,254.513550 
	C71.719421,257.559235 72.112061,261.041290 72.198471,264.786133 
	C71.642548,261.682587 71.392853,258.316254 71.234970,254.513550 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M102.685242,150.133820 
	C101.516441,152.616577 100.056709,155.268509 98.230652,157.931519 
	C99.374313,155.396057 100.884308,152.849533 102.685242,150.133820 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M74.562370,217.217484 
	C74.405418,220.122940 74.014008,223.327133 73.342300,226.759766 
	C73.483963,223.830887 73.905937,220.673553 74.562370,217.217484 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M95.695740,161.174149 
	C96.107201,163.846741 96.289360,166.716736 92.466080,167.964005 
	C93.207962,165.788391 94.306725,163.566040 95.695740,161.174149 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M91.604973,168.940308 
	C90.851524,171.116287 89.820190,173.500641 88.445312,175.947418 
	C89.176872,173.722778 90.251976,171.435730 91.604973,168.940308 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M83.129349,315.346069 
	C84.078308,317.071442 85.039009,319.152466 85.974876,321.634521 
	C85.013725,319.924255 84.077408,317.813019 83.129349,315.346069 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M84.628700,183.953583 
	C84.213539,185.750961 83.556374,187.803741 82.553581,189.935059 
	C82.934204,188.078735 83.660454,186.143845 84.628700,183.953583 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M72.678452,229.267929 
	C72.809868,230.814194 72.728722,232.673859 72.386810,234.753479 
	C72.239326,233.176086 72.352615,231.378693 72.678452,229.267929 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M71.609558,240.992554 
	C71.831100,243.849487 71.801079,247.057098 71.542542,250.587158 
	C71.328674,247.720840 71.343338,244.532028 71.609558,240.992554 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M79.910461,306.365845 
	C80.600510,307.495789 81.276810,308.987122 81.908257,310.905579 
	C81.207840,309.797546 80.552277,308.262390 79.910461,306.365845 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M81.715942,191.144730 
	C81.570602,192.549805 81.183556,194.199509 80.429413,195.927231 
	C80.532959,194.466629 81.003593,192.927979 81.715942,191.144730 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M87.633476,176.987549 
	C87.517746,178.134415 87.135422,179.497421 86.384979,180.932312 
	C86.466873,179.737350 86.916878,178.470505 87.633476,176.987549 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M75.689545,212.205078 
	C75.868233,212.943314 75.823845,213.921982 75.463516,214.955994 
	C75.253860,214.156067 75.360161,213.300797 75.689545,212.205078 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M78.505539,201.139847 
	C78.654839,201.855240 78.568604,202.809830 78.181366,203.891586 
	C78.010246,203.138840 78.140129,202.258957 78.505539,201.139847 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M105.641357,145.976990 
	C105.453491,146.824188 104.999382,147.851501 104.166817,148.891296 
	C104.317276,147.988220 104.846191,147.072662 105.641357,145.976990 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M75.992706,288.702271 
	C76.171494,288.456848 76.630142,288.579803 76.819550,288.723267 
	C76.819420,289.472626 76.629883,290.078522 76.168839,290.863373 
	C75.869530,290.344086 75.841721,289.645905 75.992706,288.702271 
z"/>
          <path fill="#4F7855" opacity="1.000000" stroke="none"
            d="
M75.036545,284.341370 
	C75.081131,284.050201 75.506104,283.951202 75.722801,283.975159 
	C76.078583,284.580078 76.217667,285.161041 76.106949,285.863586 
	C75.568748,285.534271 75.280350,285.083374 75.036545,284.341370 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M76.667084,208.149475 
	C76.850090,208.601181 76.833153,209.260239 76.538246,209.968750 
	C76.329231,209.464417 76.398178,208.910614 76.667084,208.149475 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M85.934601,322.338989 
	C86.280769,322.507172 86.616951,322.978394 86.946358,323.759094 
	C86.601265,323.593079 86.262939,323.117523 85.934601,322.338989 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M85.665070,181.825439 
	C85.772041,182.000732 85.663681,182.338303 85.332802,182.828644 
	C85.223442,182.650162 85.336594,182.318939 85.665070,181.825439 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M96.648270,159.836792 
	C96.746353,160.024170 96.616776,160.365311 96.244354,160.845551 
	C96.141205,160.653290 96.280907,160.321930 96.648270,159.836792 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M82.331154,313.296936 
	C82.623703,313.233093 82.802124,313.482452 82.887466,314.042847 
	C82.588821,314.122162 82.396370,313.874268 82.331154,313.296936 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M111.749321,138.082001 
	C111.918961,138.320175 111.758026,138.587967 111.281349,138.902679 
	C111.090340,138.662323 111.241379,138.382996 111.749321,138.082001 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M110.800110,139.127258 
	C110.948174,139.340240 110.810646,139.569366 110.429855,139.860046 
	C110.198227,139.633728 110.307541,139.374344 110.800110,139.127258 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M108.687180,141.920807 
	C108.770851,142.115662 108.612122,142.431702 108.210602,142.868927 
	C108.126801,142.674088 108.285789,142.358032 108.687180,141.920807 
z"/>
          <path fill="#F3FBFD" opacity="1.000000" stroke="none"
            d="
M108.504227,103.895111 
	C105.825928,101.928581 103.471649,100.066750 101.117371,98.204910 
	C100.692543,98.531815 100.267723,98.858711 99.842903,99.185616 
	C100.753624,101.868896 101.677795,104.547707 102.572189,107.236412 
	C104.290710,112.402618 102.074150,115.448532 96.771057,115.219391 
	C95.108765,115.147568 93.440285,115.119476 91.788055,114.944565 
	C90.349403,114.792274 88.929665,114.461334 86.665985,115.050110 
	C88.732048,117.081985 90.752731,119.162888 92.878487,121.130264 
	C94.586380,122.710899 96.473076,124.097855 98.188179,125.671295 
	C100.764038,128.034378 100.698158,130.706955 98.450905,133.144211 
	C96.194168,135.591766 93.547218,135.710953 90.922066,133.525711 
	C83.375313,127.243599 75.833862,120.955116 68.279686,114.681938 
	C67.515800,114.047577 66.679733,113.500122 65.545609,112.830139 
	C63.566166,111.119263 61.483936,109.733093 60.399113,107.790062 
	C59.419521,106.035507 58.565315,103.062294 59.405693,101.731674 
	C60.450912,100.076706 63.445526,98.475815 65.332207,98.741417 
	C72.047066,99.686752 78.647667,101.440399 85.294754,102.873055 
	C86.698097,103.175507 88.115082,103.414688 90.426491,103.854362 
	C88.049110,98.119438 85.997490,93.144402 83.923462,88.178734 
	C82.964073,85.881737 81.305733,83.646202 81.151367,81.304535 
	C80.976921,78.658264 81.331490,75.067162 82.984612,73.515541 
	C84.178322,72.395126 88.370270,73.164993 90.343971,74.447578 
	C94.627380,77.231094 98.357437,80.869980 102.293625,84.183029 
	C107.880333,88.885300 113.520050,93.528290 119.000710,98.351608 
	C122.761894,101.661682 122.848175,106.149490 119.136726,107.792358 
	C117.605347,108.470222 115.278107,108.159355 113.616844,107.514732 
	C111.834389,106.823082 110.409294,105.210503 108.504227,103.895111 
z"/>
          <path fill="#2597F0" opacity="1.000000" stroke="none"
            d="
M58.325867,97.941116 
	C58.418484,97.023109 58.860180,96.058578 59.644600,95.036140 
	C59.549866,95.950348 59.112404,96.922470 58.325867,97.941116 
z"/>
          <path fill="#2597F0" opacity="1.000000" stroke="none"
            d="
M62.022171,94.333389 
	C62.789871,94.254555 63.600811,94.502632 64.708649,94.877701 
	C64.025497,94.889900 63.045452,94.775093 62.022171,94.333389 
z"/>
          <path fill="#2597F0" opacity="1.000000" stroke="none"
            d="
M82.211182,70.886856 
	C82.233002,70.550331 82.489944,70.098602 82.918953,69.801071 
	C82.876122,70.227386 82.661217,70.499512 82.211182,70.886856 
z"/>
          <path fill="#2597F0" opacity="1.000000" stroke="none"
            d="
M60.153324,94.909645 
	C59.978741,94.824326 59.979259,94.654800 59.984013,94.230728 
	C60.101643,94.259262 60.215034,94.542351 60.153324,94.909645 
z"/>
          <path fill="#F3FBFD" opacity="1.000000" stroke="none"
            d="
M311.809143,416.083069 
	C310.440704,417.273254 309.263977,419.043396 307.675629,419.568512 
	C270.474396,431.867371 233.042984,433.379425 195.446106,421.416718 
	C191.772522,420.247864 188.365952,418.239777 185.150696,416.259949 
	C186.006775,415.837311 186.547577,415.775940 187.490585,415.812683 
	C189.533524,415.838013 191.174255,415.765259 193.289688,415.767273 
	C208.196381,415.791626 222.628372,415.741211 237.449173,415.766418 
	C238.891006,415.807098 239.944016,415.772186 241.245392,415.831482 
	C241.661499,415.868927 241.829224,415.812164 242.457489,415.822021 
	C247.294815,415.859772 251.671616,415.830872 256.505005,415.898804 
	C275.244110,416.024780 293.526611,416.053925 311.809143,416.083069 
z"/>
          <path fill="#338393" opacity="1.000000" stroke="none"
            d="
M237.060364,415.690826 
	C222.628372,415.741211 208.196381,415.791626 193.034332,415.561157 
	C190.565628,415.425049 188.827011,415.569824 187.088379,415.714600 
	C186.547577,415.775940 186.006775,415.837311 185.053436,415.892731 
	C184.640915,415.886810 184.549103,415.897064 184.318420,415.508698 
	C184.011658,400.732910 183.935577,386.345490 183.859512,371.958069 
	C184.648682,371.830109 185.437866,371.702118 187.052216,371.587982 
	C215.473282,371.639435 243.069199,371.721252 270.665009,371.696198 
	C284.782715,371.683350 298.900238,371.479126 313.017822,371.361389 
	C313.225983,371.480408 313.434113,371.599396 313.988037,372.294434 
	C314.554932,387.217712 314.776001,401.564941 314.997070,415.912140 
	C314.200317,415.943054 313.403564,415.973999 312.207977,416.044006 
	C293.526611,416.053925 275.244110,416.024780 256.802216,415.653656 
	C259.425842,414.887482 262.208832,414.463287 265.461121,414.027344 
	C279.262482,414.012421 292.594604,414.000732 305.926697,414.011810 
	C309.318481,414.014618 312.807220,413.343475 312.869659,409.374786 
	C313.051819,397.798004 312.715576,386.213074 312.575378,374.006805 
	C303.962219,373.664215 296.467834,373.366089 288.532349,373.051483 
	C285.724670,373.040131 283.358185,373.045288 280.549072,373.034210 
	C277.741119,373.022827 275.375824,373.027710 272.542603,373.022644 
	C266.673676,373.350922 261.272705,373.689178 255.579651,374.025085 
	C254.899948,374.027405 254.512314,374.032074 253.820282,373.742249 
	C249.341370,373.309418 245.166855,373.171082 240.524780,373.020782 
	C229.704193,373.016388 219.351120,373.023956 208.554993,373.025635 
	C205.755447,373.035675 203.398972,373.051666 200.601868,373.048035 
	C196.217056,373.357941 192.201721,373.360352 188.367325,374.194519 
	C187.026321,374.486267 185.184296,376.826172 185.184219,378.230835 
	C185.183624,387.989807 185.990051,397.758026 185.754608,407.504333 
	C185.609802,413.498230 188.318604,414.255035 193.263901,414.098541 
	C202.554886,413.804504 211.863449,414.117950 221.161285,413.967102 
	C225.791412,413.891968 230.413940,413.348663 235.361069,413.056580 
	C236.141602,413.960968 236.600983,414.825897 237.060364,415.690826 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M315.361328,415.896118 
	C314.776001,401.564941 314.554932,387.217712 314.333466,372.394379 
	C314.888672,373.481506 315.904022,375.038452 315.925568,376.609009 
	C316.069611,387.106323 316.138458,397.609314 315.926636,408.104309 
	C315.854828,411.662750 316.684570,413.570801 321.025391,413.132202 
	C319.574860,414.084778 317.650208,414.982452 315.361328,415.896118 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M183.475403,372.069885 
	C183.935577,386.345490 184.011658,400.732910 184.299469,415.550842 
	C182.805984,415.854767 181.100754,415.728210 179.180237,415.342163 
	C181.627579,413.940521 182.091522,411.842896 182.056534,409.129730 
	C181.919510,398.505127 181.952835,387.877319 182.049347,377.251831 
	C182.064728,375.558746 182.727707,373.871582 183.475403,372.069885 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M156.120728,403.332031 
	C160.905319,405.528778 165.886017,407.999115 170.859833,410.833435 
	C168.880829,410.410706 166.908707,409.624054 164.586960,408.771057 
	C161.597168,407.004974 158.957001,405.305267 156.120728,403.332031 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M329.349152,409.995361 
	C331.109375,408.679016 333.203369,407.339478 335.689026,406.017334 
	C335.103027,406.762451 334.125397,407.490143 332.954681,408.460114 
	C331.735382,409.125641 330.709167,409.548920 329.349152,409.995361 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M171.030502,411.442383 
	C172.862946,411.869110 174.873199,412.589752 176.935089,413.670227 
	C175.060593,413.265472 173.134445,412.500854 171.030502,411.442383 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M322.301666,413.163940 
	C322.510162,412.800537 323.052795,412.438202 323.945221,412.100922 
	C323.741943,412.472321 323.188873,412.818695 322.301666,413.163940 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M325.283081,411.865875 
	C325.270294,411.652191 325.525452,411.412415 326.051300,411.144897 
	C326.065002,411.358002 325.807983,411.598877 325.283081,411.865875 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M328.284454,410.583282 
	C328.220825,410.330933 328.411621,410.178741 328.835907,410.052338 
	C328.945892,410.307770 328.769043,410.509521 328.284454,410.583282 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M490.964996,228.627594 
	C487.637909,228.854919 486.974792,227.612503 489.689209,225.039093 
	C490.359131,226.066635 490.669525,227.165192 490.964996,228.627594 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M490.978882,276.594788 
	C490.004059,276.369537 489.042389,275.745178 488.080750,275.120789 
	C488.796143,274.421051 489.511505,273.721283 490.606293,273.010773 
	C490.987823,274.065216 490.989929,275.130432 490.978882,276.594788 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M490.011017,218.622925 
	C489.498230,217.487122 488.991547,215.962814 488.764099,214.198608 
	C489.367950,215.383957 489.692535,216.809204 490.011017,218.622925 
z"/>
          <path fill="#EFFAFD" opacity="1.000000" stroke="none"
            d="
M131.029846,415.032196 
	C134.068054,411.413910 137.322311,411.286011 140.988815,414.192474 
	C142.673233,415.527710 144.647858,416.491943 146.459824,417.672150 
	C150.408783,420.244141 152.878555,423.663849 151.575226,428.568481 
	C150.279831,433.443237 147.422745,436.880188 141.897354,437.180023 
	C141.440994,437.204773 140.999924,437.511597 140.168564,437.837646 
	C140.168564,441.045502 140.498856,444.385681 140.022079,447.606415 
	C139.800735,449.101624 138.010056,450.364502 136.930817,451.732697 
	C135.588226,450.365021 133.362885,449.156647 133.081528,447.597473 
	C132.438705,444.035370 132.858459,440.293823 132.603958,436.642090 
	C132.523499,435.487793 131.673264,434.387177 131.175323,433.261993 
	C130.229355,433.991608 129.083405,434.568604 128.383392,435.485931 
	C127.281586,436.929749 126.815727,439.054443 125.462959,440.059845 
	C123.900291,441.221252 121.668037,441.481750 119.726517,442.133392 
	C119.499069,440.066406 118.398270,437.542755 119.198135,436.012909 
	C122.352432,429.979950 126.111702,424.263916 129.624329,418.416992 
	C130.136841,417.563934 130.529236,416.638702 131.026474,415.397003 
	C131.075745,415.047150 131.008453,414.995819 131.029846,415.032196 
M139.129211,429.349884 
	C140.705948,428.471588 142.282684,427.593292 143.859390,426.714966 
	C142.337418,424.944061 141.047226,422.135010 139.220917,421.727264 
	C137.731598,421.394745 135.601257,423.933380 132.905716,425.781281 
	C135.709763,427.412872 137.095901,428.219421 139.129211,429.349884 
z"/>
          <path fill="#14250F" opacity="1.000000" stroke="none"
            d="
M457.551025,285.742889 
	C457.551025,285.742889 457.732819,285.645691 457.832947,285.659760 
	C458.922577,286.854340 459.912048,288.034851 460.673889,289.460449 
	C459.948517,289.498138 459.450714,289.290802 458.571320,288.936188 
	C450.392761,286.557861 443.784973,288.536652 440.991943,294.595490 
	C439.819580,297.138702 438.912262,300.823883 439.901001,303.122559 
	C441.729767,307.374329 445.043884,310.987213 448.059113,315.326508 
	C446.094025,317.766449 443.371674,321.146698 440.630493,324.550323 
	C446.192017,328.339539 449.758423,326.195312 452.955353,322.110138 
	C454.482300,320.158997 456.482086,318.556488 457.876190,316.527100 
	C461.233429,311.639893 466.445435,307.798431 466.872742,300.768951 
	C465.936096,297.291199 464.934937,294.211517 463.933746,291.131805 
	C466.362671,293.357361 469.580139,295.162872 471.040588,297.904327 
	C473.387939,302.310699 478.435089,306.939087 472.702667,312.494537 
	C472.399200,312.788605 472.485352,313.794281 472.734680,314.298187 
	C474.204468,317.268494 475.021454,319.743317 470.896423,321.581696 
	C469.919037,322.017273 470.242218,325.042755 469.622681,326.752075 
	C469.325317,327.572510 468.225098,328.101990 467.524445,328.730927 
	C462.810699,325.839111 458.183075,326.072937 454.697052,329.626709 
	C448.557617,329.148743 442.814545,328.311768 436.896790,327.121033 
	C435.494476,326.534546 434.266876,326.301819 432.731750,325.910522 
	C430.610687,322.406738 428.157288,319.246155 427.126648,315.674957 
	C425.378326,309.616760 427.238190,304.286774 430.560730,298.641327 
	C435.521698,290.211914 442.453796,286.657410 451.588165,285.990417 
	C453.571228,285.845642 455.563171,285.822205 457.551025,285.742889 
z"/>
          <path fill="#5B808C" opacity="1.000000" stroke="none"
            d="
M437.071503,327.474762 
	C442.814545,328.311768 448.557617,329.148743 454.653015,330.007233 
	C455.769806,331.703217 456.534302,333.377655 457.363647,335.408875 
	C456.946869,335.873596 456.465240,335.981506 455.266541,336.021759 
	C450.913574,335.319000 447.296356,334.527069 443.636780,334.091248 
	C439.672302,333.619110 436.523071,330.630066 437.071503,327.474762 
z"/>
          <path fill="#543F2E" opacity="1.000000" stroke="none"
            d="
M463.648804,290.977234 
	C464.934937,294.211517 465.936096,297.291199 466.474823,300.659119 
	C464.102661,301.053009 462.192902,301.158661 460.191589,300.874573 
	C459.078827,298.374268 458.057648,296.263672 457.143982,293.827820 
	C457.818634,292.029480 458.385773,290.556458 458.952942,289.083435 
	C459.450714,289.290802 459.948517,289.498138 460.701385,289.794067 
	C461.758942,290.195953 462.561401,290.509308 463.648804,290.977234 
z"/>
          <path fill="#0F2C2B" opacity="1.000000" stroke="none"
            d="
M457.271729,285.435669 
	C455.563171,285.822205 453.571228,285.845642 451.588165,285.990417 
	C442.453796,286.657410 435.521698,290.211914 430.560730,298.641327 
	C427.238190,304.286774 425.378326,309.616760 427.126648,315.674957 
	C428.157288,319.246155 430.610687,322.406738 432.489990,326.173340 
	C431.622528,326.795929 430.689301,326.997162 429.756042,327.198425 
	C427.988617,323.952118 426.221161,320.705811 424.179138,317.225403 
	C423.843781,315.603821 423.783051,314.216309 423.617157,312.424194 
	C423.593842,311.252838 423.675659,310.486084 423.637878,309.347595 
	C423.675598,307.921204 423.832977,306.866577 423.972229,305.411682 
	C426.824615,297.004364 431.153687,290.144440 439.280243,286.579559 
	C444.965210,284.085754 450.850311,282.685730 457.271729,285.435669 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M10.098454,302.239990 
	C10.724743,302.713013 11.340632,303.512634 11.945265,304.660339 
	C11.318688,304.194458 10.703370,303.380493 10.098454,302.239990 
z"/>
          <path fill="#F3FBFD" opacity="1.000000" stroke="none"
            d="
M134.197479,45.364925 
	C139.336243,51.692730 144.121445,57.909550 149.118057,64.381622 
	C154.231400,77.173065 150.860901,86.109802 139.482605,90.745544 
	C131.715240,93.910126 124.169151,91.990845 118.987968,85.296066 
	C115.732742,81.089882 112.508156,76.825958 109.685913,72.326927 
	C105.755692,66.061630 104.179092,59.463417 108.952148,52.219482 
	C109.162766,51.753891 109.218971,51.423096 109.218971,51.423096 
	C113.409355,48.878872 117.329865,45.383919 121.876137,44.093437 
	C125.466316,43.074345 129.825989,44.766106 134.197479,45.364925 
M139.923737,69.929199 
	C136.838531,65.618233 133.874847,61.213291 130.633942,57.022739 
	C127.392166,52.831047 124.164513,52.296879 120.415619,54.923065 
	C116.596436,57.598499 115.744003,61.602814 118.407211,65.774200 
	C120.991081,69.821320 123.768837,73.751465 126.600021,77.631859 
	C129.138885,81.111618 132.411469,83.450455 136.799622,81.189545 
	C141.179306,78.932968 142.160507,75.156914 139.923737,69.929199 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M109.154732,51.062660 
	C109.218971,51.423096 109.162766,51.753891 109.042053,51.870529 
	C108.215965,52.001625 107.510582,52.016087 106.455292,52.044670 
	C106.078781,51.594856 106.052170,51.130920 106.040955,50.318424 
	C106.810608,49.493149 107.564865,49.016434 108.319115,48.539715 
	C108.576241,49.260551 108.833366,49.981388 109.154732,51.062660 
z"/>
          <path fill="#59B365" opacity="1.000000" stroke="none"
            d="
M21.056744,306.675598 
	C21.017502,306.253448 20.978260,305.831299 20.838581,305.103577 
	C21.893948,293.340332 31.071972,285.103973 43.047737,285.492126 
	C44.467014,287.461853 45.299648,289.867767 46.749752,290.327942 
	C49.075405,291.065979 52.249310,292.419312 53.986557,288.842102 
	C55.833233,289.796173 57.679916,290.750214 59.797096,292.299744 
	C60.378105,294.589874 60.688618,296.284485 60.695015,298.140930 
	C59.823898,299.011169 59.256893,299.719604 58.363262,300.262421 
	C52.604797,296.676544 49.569916,297.331696 45.193016,303.620941 
	C44.125977,301.096985 43.515839,298.937408 42.336304,297.154572 
	C41.711067,296.209534 40.096775,295.918884 38.928978,295.332855 
	C38.640583,296.509613 38.352196,297.686401 37.945572,299.178223 
	C37.582561,300.537415 37.337791,301.581573 36.767078,302.670288 
	C35.955231,303.134705 35.469322,303.554596 34.718391,303.988525 
	C34.102699,304.031342 33.752037,304.060120 33.177025,303.767670 
	C28.512369,301.813263 27.911072,305.360657 26.330750,308.063721 
	C24.328272,307.588989 22.692507,307.132294 21.056744,306.675598 
z"/>
          <path fill="#BEE6BC" opacity="1.000000" stroke="none"
            d="
M53.770012,288.589233 
	C52.249310,292.419312 49.075405,291.065979 46.749752,290.327942 
	C45.299648,289.867767 44.467014,287.461853 43.498032,285.582886 
	C46.943676,286.269897 50.248569,287.303131 53.770012,288.589233 
z"/>
          <path fill="#26784B" opacity="1.000000" stroke="none"
            d="
M60.999130,297.979095 
	C60.688618,296.284485 60.378105,294.589874 60.065132,292.532501 
	C60.458309,292.156891 60.853958,292.144043 61.543491,292.168274 
	C63.190777,296.188171 64.544174,300.170959 65.568382,304.505676 
	C64.521461,303.872498 63.803741,302.887329 63.035667,301.580139 
	C62.323254,300.165100 61.661194,299.072083 60.999130,297.979095 
z"/>
          <path fill="#144A2E" opacity="1.000000" stroke="none"
            d="
M429.843384,327.541351 
	C430.689301,326.997162 431.622528,326.795929 432.797546,326.331848 
	C434.266876,326.301819 435.494476,326.534546 436.896790,327.121033 
	C436.523071,330.630066 439.672302,333.619110 443.636780,334.091248 
	C447.296356,334.527069 450.913574,335.319000 454.897369,336.034546 
	C452.071045,336.632629 448.851593,337.768524 445.731781,337.540680 
	C438.978577,337.047485 434.354492,332.501587 429.843384,327.541351 
z"/>
          <path fill="#EBF8FD" opacity="1.000000" stroke="none"
            d="
M89.968300,400.511841 
	C88.572845,401.936707 87.347511,403.079163 85.958305,404.490051 
	C83.062202,406.032227 80.478508,410.374573 77.290749,406.431427 
	C74.010231,402.373535 78.634071,400.446777 80.727089,398.020294 
	C82.021675,396.519440 83.517776,395.192383 85.048737,393.482422 
	C87.759270,390.942047 90.464340,388.823853 92.846893,386.389954 
	C93.456139,385.767578 93.228119,384.119629 93.063324,382.989746 
	C92.751671,380.853149 92.207001,378.750580 91.759155,376.633850 
	C94.075676,377.202789 96.392204,377.771759 99.004044,378.534302 
	C101.955971,381.687256 104.901207,384.447052 107.145172,387.691833 
	C108.236557,389.270050 108.017090,391.754761 108.391045,393.829102 
	C106.392952,393.535034 103.821968,393.911682 102.521469,392.800903 
	C100.148689,390.774231 98.797829,391.420959 97.074387,393.644440 
	C94.677620,396.042145 92.408020,398.135773 89.968300,400.511841 
z"/>
          <path fill="#59B365" opacity="1.000000" stroke="none"
            d="
M63.086014,301.902191 
	C63.803741,302.887329 64.521461,303.872498 65.544884,304.912506 
	C67.529564,316.329346 60.883099,325.584351 49.395237,329.968109 
	C43.783379,328.915344 38.523911,327.824615 33.497437,326.384338 
	C34.558872,326.089661 35.568829,325.855469 36.187576,326.244141 
	C42.171040,330.002411 47.998589,327.560028 53.271156,325.089996 
	C57.339687,323.183929 61.666256,320.705719 61.200184,314.405426 
	C60.896973,310.306610 62.394970,306.074554 63.086014,301.902191 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M95.049042,340.348694 
	C100.513924,343.274384 103.381546,348.366241 104.929474,354.735046 
	C104.170631,354.452698 103.444817,353.784058 102.449966,352.980042 
	C99.810936,348.797058 97.440941,344.749451 95.049042,340.348694 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M109.928970,362.432617 
	C116.648720,362.695465 118.153023,368.110962 120.004181,373.584717 
	C116.668701,370.227600 113.331711,366.497040 109.928970,362.432617 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M120.165894,374.298828 
	C124.026459,374.216248 126.004959,376.677734 127.008675,380.595764 
	C124.770821,378.841736 122.530083,376.722717 120.165894,374.298828 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M104.898743,355.414551 
	C105.565201,355.864227 106.269829,356.633118 106.951431,357.776611 
	C106.264565,357.345428 105.600739,356.539581 104.898743,355.414551 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M107.791351,359.429932 
	C108.458916,359.864349 109.209145,360.609467 109.928757,361.736237 
	C109.223434,361.325470 108.548729,360.533020 107.791351,359.429932 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M131.820587,385.362030 
	C132.026489,385.294159 132.376221,385.479492 132.823395,385.955872 
	C132.602036,386.036346 132.283234,385.825775 131.820587,385.362030 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M133.935501,387.305176 
	C134.132202,387.227112 134.441772,387.394775 134.864258,387.808167 
	C134.667557,387.886169 134.357971,387.718536 133.935501,387.305176 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M135.036682,388.263489 
	C135.286896,388.082733 135.563675,388.247498 135.885834,388.740234 
	C135.706238,388.807068 135.416916,388.653992 135.036682,388.263489 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M138.914566,391.312866 
	C139.107666,391.234222 139.424561,391.396362 139.854889,391.807373 
	C139.658356,391.888733 139.348373,391.721161 138.914566,391.312866 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M140.026535,392.264893 
	C140.263336,392.072327 140.530167,392.209564 140.862152,392.643799 
	C140.627182,392.880035 140.336960,392.764679 140.026535,392.264893 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M141.790237,393.368652 
	C141.992020,393.274384 142.344131,393.425598 142.820755,393.844727 
	C142.610367,393.946472 142.275467,393.780304 141.790237,393.368652 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M142.978699,394.313660 
	C143.180801,394.228119 143.473022,394.389435 143.872986,394.782806 
	C143.676773,394.863403 143.372803,394.711945 142.978699,394.313660 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M144.081360,395.240387 
	C144.305634,395.070374 144.570724,395.217224 144.903137,395.656830 
	C144.680862,395.852631 144.398102,395.721832 144.081360,395.240387 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M128.136749,382.224487 
	C128.373734,382.081177 128.623886,382.253876 128.914398,382.723572 
	C128.681122,382.883484 128.412842,382.723480 128.136749,382.224487 
z"/>
          <path fill="#58A250" opacity="1.000000" stroke="none"
            d="
M63.035664,301.580139 
	C62.394970,306.074554 60.896973,310.306610 61.200184,314.405426 
	C61.666256,320.705719 57.339687,323.183929 53.271156,325.089996 
	C47.998589,327.560028 42.171040,330.002411 36.187576,326.244141 
	C35.568829,325.855469 34.558872,326.089661 33.313805,326.074402 
	C32.897171,326.113953 32.918056,326.076996 32.869270,325.893188 
	C32.544991,325.819458 32.269493,325.929535 31.993996,326.039642 
	C31.486376,325.512756 30.978758,324.985870 30.234974,324.020447 
	C29.861313,323.292145 29.723822,323.002411 29.586334,322.712677 
	C29.393600,323.123535 29.200861,323.534424 29.008121,323.945282 
	C27.685270,322.526306 26.362423,321.107300 25.017300,318.963257 
	C25.859243,316.661407 26.723465,315.084656 27.820671,313.750610 
	C30.708662,317.457520 33.982513,317.434479 37.504375,315.279358 
	C37.818192,317.440094 38.073597,319.198639 38.329002,320.957214 
	C38.882954,320.946869 39.436901,320.936523 39.990852,320.926178 
	C40.314159,318.623047 40.637463,316.319916 41.252808,313.993896 
	C42.011288,313.665955 42.477734,313.360840 43.337341,313.096680 
	C46.251965,309.900543 49.063778,310.645294 52.633553,311.550934 
	C55.862686,312.370209 58.717430,309.950806 59.200928,306.273132 
	C59.449829,304.379913 58.886299,302.379822 58.689884,300.428040 
	C59.256893,299.719604 59.823898,299.011169 60.695019,298.140930 
	C61.661194,299.072083 62.323254,300.165100 63.035664,301.580139 
z"/>
          <path fill="#26784B" opacity="1.000000" stroke="none"
            d="
M27.587685,313.507874 
	C26.723465,315.084656 25.859243,316.661407 24.961149,318.633789 
	C23.608881,315.214935 22.290485,311.400482 21.014416,307.130829 
	C22.692507,307.132294 24.328272,307.588989 26.396452,308.457214 
	C27.081806,310.415131 27.334745,311.961517 27.587685,313.507874 
z"/>
          <path fill="#59B365" opacity="1.000000" stroke="none"
            d="
M29.262901,324.006042 
	C29.200861,323.534424 29.393600,323.123535 29.586334,322.712677 
	C29.723822,323.002411 29.861313,323.292145 30.019485,323.843323 
	C29.866001,324.092133 29.691841,324.079498 29.262901,324.006042 
z"/>
          <path fill="#59B365" opacity="1.000000" stroke="none"
            d="
M32.233822,326.065613 
	C32.269493,325.929535 32.544991,325.819458 32.888233,325.894867 
	C32.955978,326.080383 32.473648,326.091583 32.233822,326.065613 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M105.898712,49.775139 
	C105.818100,49.946972 105.650955,49.955578 105.232895,49.953476 
	C105.258720,49.832478 105.535454,49.722191 105.898712,49.775139 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M65.024811,86.274200 
	C65.790215,85.694115 66.550171,85.365761 67.618988,85.008041 
	C67.200188,85.654137 66.472519,86.329590 65.395691,87.016907 
	C65.037476,86.861168 65.028427,86.693550 65.024811,86.274200 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M359.424316,390.170776 
	C359.648926,389.556427 360.191711,388.849854 361.124908,388.168762 
	C360.924347,388.822296 360.333405,389.450409 359.424316,390.170776 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M355.384460,393.240845 
	C355.480896,392.889008 355.865295,392.464661 356.553741,391.995972 
	C356.462646,392.357178 356.067535,392.762756 355.384460,393.240845 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M362.252869,387.863647 
	C362.109161,387.594421 362.302277,387.348724 362.817017,387.099792 
	C362.984680,387.371490 362.801697,387.635040 362.252869,387.863647 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M147.098633,397.383636 
	C147.945541,397.548187 148.934525,397.991425 149.922699,398.772827 
	C149.028168,398.628082 148.134445,398.145172 147.098633,397.383636 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M153.003372,401.351318 
	C153.853455,401.500916 154.865433,401.929749 155.913025,402.722351 
	C155.020844,402.600922 154.093063,402.115723 153.003372,401.351318 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M151.101929,400.199066 
	C151.299942,400.041504 151.533417,400.162415 151.841324,400.519836 
	C151.633728,400.759064 151.374283,400.666138 151.101929,400.199066 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M149.998779,399.296631 
	C150.190521,399.213806 150.480957,399.369781 150.887497,399.749664 
	C150.701553,399.827515 150.399521,399.681458 149.998779,399.296631 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M338.270142,404.842621 
	C338.242828,404.637512 338.469116,404.401306 338.942627,404.115967 
	C338.967834,404.315033 338.745789,404.563293 338.270142,404.842621 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M340.264343,403.685852 
	C340.168152,403.434387 340.358948,403.245300 340.815308,403.069672 
	C340.957306,403.332794 340.780792,403.554474 340.264343,403.685852 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M399.001556,157.681641 
	C398.356689,157.196014 397.687622,156.402618 397.035797,155.242920 
	C397.349518,155.026337 397.645966,155.176025 398.138702,155.483948 
	C398.549103,156.219406 398.763214,156.796631 399.001556,157.681641 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M400.822632,160.763611 
	C400.627380,160.772598 400.395966,160.519821 400.127075,160.006042 
	C400.321899,159.997299 400.554138,160.249573 400.822632,160.763611 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M395.096954,151.601868 
	C393.424530,149.818909 391.731171,147.730072 390.068848,145.263855 
	C390.824341,145.543671 391.548767,146.200867 392.524719,147.020889 
	C393.154053,147.866318 393.531921,148.548920 394.150269,149.411987 
	C394.619171,150.160294 394.847565,150.728149 395.096954,151.601868 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M395.947327,152.750519 
	C395.680664,152.796951 395.434998,152.580551 395.132751,152.119110 
	C395.076141,151.874084 395.542969,151.899353 395.774597,151.930481 
	C395.993591,152.136948 395.980957,152.312302 395.947327,152.750519 
z"/>
          <path fill="#2597F0" opacity="1.000000" stroke="none"
            d="
M74.789536,84.127563 
	C76.579109,86.384361 78.001053,88.570999 79.920013,91.521927 
	C74.625641,91.903297 73.359047,88.560196 71.340614,86.324493 
	C72.367714,85.568802 73.394814,84.813103 74.789536,84.127563 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M74.204346,78.755981 
	C74.125992,78.363083 74.308044,77.837166 74.723618,77.148956 
	C74.793022,77.532089 74.628891,78.077522 74.204346,78.755981 
z"/>
          <path fill="#2597F0" opacity="1.000000" stroke="none"
            d="
M401.256561,110.812172 
	C401.740082,112.318657 402.368286,113.813545 402.530365,115.357391 
	C402.651581,116.511826 402.560974,118.322601 401.867004,118.778053 
	C400.858276,119.440079 398.737671,119.753052 397.954590,119.129913 
	C396.391724,117.886307 395.415802,115.905106 394.197479,114.228531 
	C396.268219,113.115265 398.338959,112.001999 400.813629,110.799530 
	C401.217560,110.710320 401.201111,110.821602 401.256561,110.812172 
z"/>
          <path fill="#2996EB" opacity="1.000000" stroke="none"
            d="
M416.699219,101.440865 
	C416.812408,103.136955 416.722260,104.508606 416.632111,105.880249 
	C415.025665,105.330688 412.974152,105.191193 411.962830,104.093391 
	C411.238770,103.307419 411.908051,101.237785 411.955688,99.740875 
	C413.469086,100.199394 414.982513,100.657906 416.699219,101.440865 
z"/>
          <path fill="#2597F0" opacity="1.000000" stroke="none"
            d="
M169.157074,53.867805 
	C171.447037,52.389389 173.715576,51.072067 176.160172,50.354416 
	C176.740799,50.183960 178.792145,52.231628 178.634506,52.894588 
	C178.257263,54.480961 177.280746,56.420380 175.958298,57.146175 
	C174.652512,57.862816 172.529755,57.580860 170.953217,57.109173 
	C170.154984,56.870350 169.771225,55.246178 169.157074,53.867805 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M316.355927,343.939514 
	C316.205353,344.301331 316.054779,344.663177 315.680084,345.495117 
	C315.359894,346.300537 315.263855,346.635895 315.167786,346.971222 
	C315.167786,346.971222 314.918854,347.296753 314.617920,347.705750 
	C313.412537,349.690857 312.508118,351.266968 311.603699,352.843109 
	C310.780701,353.634277 309.957703,354.425446 308.763611,355.605713 
	C308.158081,356.295441 307.923615,356.596039 307.689148,356.896667 
	C307.240265,357.172852 306.791382,357.449036 305.928528,357.967590 
	C305.224548,358.400116 305.069580,358.668335 305.049622,359.014587 
	C305.049622,359.014587 304.997742,358.999969 304.764404,359.096375 
	C304.319794,359.441406 304.108521,359.690002 303.897217,359.938629 
	C303.897217,359.938629 303.975952,359.963501 303.660950,359.932251 
	C302.873383,360.249207 302.400818,360.597382 301.928253,360.945557 
	C301.928253,360.945557 302.000275,360.989105 301.644653,360.994202 
	C300.656189,361.580292 300.023376,362.161285 299.390594,362.742279 
	C299.390594,362.742279 298.916199,362.626862 298.916199,362.626862 
	C298.916199,362.626862 298.435577,362.712555 297.817505,362.830963 
	C294.435608,363.550781 291.671753,364.152161 288.907928,364.753540 
	C262.613556,364.729675 236.319199,364.705811 209.254395,364.499695 
	C205.996933,363.866119 203.509918,363.414825 201.022903,362.963531 
	C201.022903,362.963531 201.000336,363.001099 200.924133,362.793701 
	C200.634842,362.326111 200.372025,362.257996 200.059448,362.381866 
	C199.896576,362.288757 199.733704,362.195679 199.208923,361.841980 
	C198.633682,361.328674 198.365967,361.239929 198.043915,361.315155 
	C197.901962,361.223877 197.760025,361.132629 197.252258,360.791504 
	C196.658585,360.281525 196.372711,360.169403 196.028809,360.205261 
	C196.028809,360.205261 195.694336,359.946503 195.316681,359.654541 
	C194.244705,358.630157 193.550369,357.897736 192.940323,357.074402 
	C193.024612,356.983459 193.195312,356.803558 193.561157,356.869812 
	C194.744095,357.065277 195.763153,356.925629 196.347580,357.364624 
	C203.602417,362.813416 212.043320,363.009979 220.551666,363.004578 
	C241.692978,362.991180 262.834717,363.073822 283.975433,362.962555 
	C297.112457,362.893433 306.348206,356.441315 313.025391,345.309113 
	C317.418488,337.984894 314.745819,330.115692 315.609894,322.578827 
	C316.646027,313.540680 315.052582,304.230621 315.083221,295.039948 
	C315.118561,284.428162 315.802582,273.819885 315.955872,263.206482 
	C316.012817,259.260590 315.069977,255.301804 315.106812,251.353775 
	C315.163879,245.235367 315.669922,239.121155 315.998505,232.536163 
	C316.000458,221.709656 315.989441,211.352173 315.989685,200.527527 
	C315.996429,194.708374 315.991974,189.356369 316.000305,183.575409 
	C316.005646,181.429657 315.998230,179.712860 315.999176,177.535782 
	C316.002930,173.384796 315.998322,169.694107 316.005371,165.586243 
	C316.009644,163.778198 316.002289,162.387299 316.001678,160.563507 
	C315.686066,157.700348 315.184845,155.278427 315.071136,152.838470 
	C314.459442,139.713425 302.102386,127.375717 288.704285,126.634567 
	C286.922089,126.223137 285.442596,125.987206 283.962891,125.986008 
	C264.308380,125.970055 244.653885,125.987030 224.532166,125.986397 
	C213.665314,125.960632 203.354874,125.357796 194.403564,132.804794 
	C189.006271,137.295044 184.711166,142.379471 184.175125,149.071152 
	C183.174728,161.559479 183.014038,174.141571 183.065460,186.682343 
	C183.189056,216.831741 183.724716,246.979309 183.898605,277.128754 
	C183.947372,285.583405 183.260559,294.040436 183.026093,302.499542 
	C182.931305,305.919189 183.137253,309.347473 183.218597,312.771576 
	C183.419388,321.221405 183.044281,329.741333 184.050339,338.094604 
	C184.656555,343.128052 187.415833,347.902222 189.121338,352.879211 
	C189.035065,352.965546 188.862885,353.138550 188.642670,353.122162 
	C188.422455,353.105774 187.980835,353.110840 187.980835,353.110840 
	C187.822021,352.815033 187.663208,352.519257 187.218140,351.819275 
	C186.664017,351.150238 186.396103,350.885345 186.128189,350.620483 
	C186.128189,350.620483 186.119751,350.268066 185.980591,349.877228 
	C185.583038,349.287262 185.324646,349.088104 185.066254,348.888947 
	C185.066254,348.888947 185.054810,348.984161 185.033783,348.598358 
	C184.042130,346.065491 183.071487,343.918396 182.100861,341.771332 
	C182.100861,341.771332 182.138962,341.362701 182.079071,340.767517 
	C181.744095,338.089172 181.469009,336.005951 181.193909,333.922760 
	C181.186234,329.586853 181.178558,325.250946 181.170517,319.995361 
	C180.989944,309.659485 180.809753,300.243286 180.672913,290.363342 
	C180.062332,289.422821 179.408386,288.946014 178.464447,288.242462 
	C178.405624,287.155029 178.636765,286.294342 179.353943,285.584900 
	C180.268250,285.272064 181.060471,284.816406 181.071411,284.342682 
	C181.323883,273.417145 181.540268,262.489716 181.553162,251.562378 
	C181.553925,250.907318 179.802475,250.250198 178.627441,249.505096 
	C178.359558,248.673416 178.330643,247.930679 178.301727,247.187927 
	C179.283997,244.376816 181.098114,241.572128 181.119858,238.753586 
	C181.337799,210.504410 181.261108,182.252975 181.449539,153.238632 
	C181.716339,150.990936 181.801025,149.506775 181.885712,148.022614 
	C182.015381,147.379913 182.145035,146.737213 182.479965,145.524475 
	C183.181396,143.677353 183.677551,142.400253 184.173706,141.123154 
	C184.173706,141.123154 184.109253,141.047287 184.338181,140.994934 
	C184.681488,140.632462 184.795868,140.322327 184.910233,140.012192 
	C184.910233,140.012192 185.128174,139.724426 185.413513,139.326675 
	C187.891876,136.326691 190.084885,133.724457 192.277878,131.122223 
	C192.729507,130.799911 193.181152,130.477615 194.054230,129.933411 
	C194.748505,129.550110 194.923065,129.315536 194.999359,129.007797 
	C194.999359,129.007797 195.000610,129.000610 195.287430,128.953766 
	C195.850815,128.650970 196.127380,128.395035 196.403946,128.139099 
	C196.403946,128.139099 196.750488,128.116364 197.155609,127.973129 
	C197.835983,127.598305 198.111206,127.366707 198.386444,127.135117 
	C198.386444,127.135117 198.737640,127.137154 199.151703,126.993263 
	C199.846024,126.612885 200.126297,126.376404 200.406555,126.139923 
	C200.792847,126.041893 201.179123,125.943863 202.033020,125.740829 
	C202.810272,125.560257 203.006363,125.368454 203.088898,125.060402 
	C203.088898,125.060402 203.017715,125.018044 203.453400,125.069336 
	C208.175186,124.749718 212.459900,124.077301 216.747620,124.057671 
	C241.496094,123.944298 266.245300,123.985161 290.994232,123.973778 
	C291.420776,124.063744 291.847351,124.153709 292.850616,124.437851 
	C294.286591,124.767380 295.145874,124.902733 296.005127,125.038086 
	C296.005127,125.038086 296.293854,125.190392 296.621033,125.396317 
	C297.296875,125.719788 297.645538,125.837341 297.994232,125.954887 
	C297.994232,125.954887 298.327026,126.134819 298.670715,126.331863 
	C299.335541,126.637749 299.656616,126.746590 299.977722,126.855423 
	C299.977722,126.855431 300.316742,127.084152 300.673401,127.290756 
	C301.299896,127.728447 301.610535,127.818344 301.962006,127.767052 
	C301.962006,127.767052 302.308655,128.033340 302.713623,128.335480 
	C304.040802,129.145432 304.963013,129.653244 305.885223,130.161057 
	C305.885223,130.161057 305.949738,130.094666 306.000549,130.341446 
	C306.349884,130.780563 306.648407,130.972900 306.946960,131.165237 
	C307.013550,131.497818 307.198212,131.740540 307.824615,132.188889 
	C308.432495,132.652069 308.716736,132.819733 309.000977,132.987396 
	C309.000977,132.987411 308.998779,133.001221 309.081665,133.240265 
	C309.443115,133.649368 309.721741,133.819412 310.000336,133.989471 
	C310.000336,133.989471 309.998779,134.001221 310.080444,134.241653 
	C310.440063,134.655426 310.718048,134.828781 310.996002,135.002136 
	C310.996002,135.002136 311.000000,135.000000 311.074188,135.258514 
	C311.405884,135.755875 311.663422,135.994736 311.920929,136.233582 
	C312.141846,136.716476 312.362732,137.199387 312.849518,138.097870 
	C313.386353,138.717453 313.657318,138.921448 313.928253,139.125458 
	C313.928253,139.125458 313.927429,139.031052 313.953217,139.399353 
	C314.896362,141.546860 315.813690,143.326065 316.731018,145.105270 
	C316.776123,145.540085 316.821198,145.974899 316.946045,147.089264 
	C317.289001,149.201782 317.552277,150.634766 317.815552,152.067749 
	C317.837769,152.805878 317.859955,153.544022 317.881653,154.972290 
	C317.915833,156.110031 317.950470,156.557632 317.916748,157.469833 
	C317.930115,185.617432 318.011841,213.300430 318.093567,240.983429 
	C318.050873,241.745117 318.008148,242.506790 317.970764,244.104309 
	C317.927032,249.827927 317.594269,254.736908 317.957275,259.593933 
	C318.120880,261.783478 319.671387,263.869385 320.624146,266.178589 
	C320.465088,272.739777 320.274567,279.124664 319.648010,285.481812 
	C318.936249,286.204590 318.660553,286.955139 318.384888,287.705688 
	C318.384888,287.705688 318.251251,288.173126 318.150238,288.695618 
	C318.058136,302.474304 318.067047,315.730499 318.075958,328.986694 
	C317.981140,331.348633 317.886322,333.710602 317.614197,336.810303 
	C317.297882,339.030823 317.158905,340.513550 317.019897,341.996307 
	C317.019897,341.996307 316.846161,342.329102 316.677673,342.673035 
	C316.282349,343.295380 316.231262,343.602905 316.355927,343.939514 
z"/>
          <path fill="#122218" opacity="1.000000" stroke="none"
            d="
M178.270477,246.604431 
	C178.330643,247.930679 178.359558,248.673416 178.342743,249.842422 
	C178.487289,261.990356 178.677612,273.712006 178.867920,285.433685 
	C178.636765,286.294342 178.405624,287.155029 178.061310,288.064026 
	C174.851624,288.253967 171.755096,288.395538 168.727676,288.196716 
	C168.139542,287.270599 167.482315,286.684875 167.129547,286.062866 
	C167.637085,286.002960 167.840134,285.979309 168.245636,285.862061 
	C168.701294,285.588715 168.868851,285.349121 168.967224,284.642303 
	C169.330261,282.655579 169.640686,281.067169 170.030487,279.498505 
	C171.005157,275.576111 172.243103,271.702026 172.931000,267.731598 
	C173.378891,265.146515 172.373566,262.190796 173.203964,259.815247 
	C175.002579,254.669769 172.893814,250.543671 171.084274,246.040833 
	C169.229889,246.040833 167.574615,246.040833 165.502167,246.039368 
	C162.754456,245.506943 160.423920,244.975967 158.093384,244.445007 
	C163.636337,244.368576 169.181427,244.186676 174.720505,244.299927 
	C175.904648,244.324142 177.066895,245.419205 178.270477,246.604431 
z"/>
          <path fill="#21613F" opacity="1.000000" stroke="none"
            d="
M157.685577,244.305786 
	C160.423920,244.975967 162.754456,245.506943 165.282837,246.293945 
	C164.287064,246.823410 163.093445,247.096802 162.478119,247.237747 
	C166.575317,250.997681 166.239456,256.195435 166.704544,260.895691 
	C165.963226,261.495514 165.487366,262.193024 165.001160,263.315613 
	C164.343399,266.907135 163.695953,270.073608 163.048782,273.535217 
	C162.657288,273.952820 162.179062,274.279053 161.891174,274.156891 
	C161.225266,273.874268 160.651535,273.374512 159.967102,272.631989 
	C158.939163,271.209991 157.984558,270.118805 157.373383,268.965454 
	C159.234604,266.838806 160.752380,264.774292 161.722382,263.454895 
	C160.518845,260.864105 159.762344,259.072266 158.872971,257.348938 
	C154.640976,249.148743 154.732635,245.622238 143.482544,246.001328 
	C142.327362,246.040253 141.169327,245.994019 140.013733,245.746307 
	C140.046707,245.184860 140.054230,244.863846 140.037415,244.542267 
	C141.423935,244.426559 142.810455,244.310852 145.035339,244.184586 
	C148.245422,244.091217 150.617126,244.008438 152.988831,243.925659 
	C154.418472,244.005951 155.848114,244.086243 157.685577,244.305786 
z"/>
          <path fill="#286191" opacity="1.000000" stroke="none"
            d="
M419.789429,247.694214 
	C419.790070,258.781006 419.649658,269.556305 419.044739,280.471863 
	C418.385864,279.073944 418.191498,277.535736 418.002563,275.529297 
	C418.005768,268.373566 418.003479,261.686096 418.009094,254.572693 
	C417.467499,251.578613 419.685120,247.826828 414.509033,247.030914 
	C405.366180,247.022888 396.690460,247.021820 387.636261,247.009979 
	C386.500610,247.006027 385.743469,247.012863 384.658203,247.017197 
	C383.892639,247.017883 383.455170,247.021088 382.547241,247.012451 
	C374.728485,247.317795 367.380157,247.634964 359.607727,247.945541 
	C357.241791,247.628830 355.301208,247.060852 353.357910,247.051422 
	C342.615936,246.999252 331.873352,247.083160 321.064453,246.865631 
	C321.032593,246.438675 321.067200,246.266556 321.543915,246.065582 
	C324.354065,245.890823 326.722107,245.744919 329.090118,245.599030 
	C331.068756,245.611267 333.047394,245.623489 335.765808,245.707336 
	C336.711853,245.699661 336.918152,245.620392 337.124420,245.541138 
	C342.076477,245.559174 347.028503,245.577194 352.893738,245.726593 
	C363.614838,245.887497 373.423096,245.979660 383.230621,245.930130 
	C394.519714,245.873108 405.808197,245.690872 417.096924,245.563049 
	C417.947388,246.169601 418.797852,246.776154 419.789429,247.694214 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M290.698914,123.680603 
	C266.245300,123.985161 241.496094,123.944298 216.747620,124.057671 
	C212.459900,124.077301 208.175186,124.749718 203.464050,125.109360 
	C206.504318,124.067352 209.957092,122.196968 213.436981,122.145111 
	C232.846161,121.855881 252.262894,121.897316 271.674835,122.070869 
	C277.921204,122.126717 284.160889,122.927467 290.698914,123.680603 
z"/>
          <path fill="#26784B" opacity="1.000000" stroke="none"
            d="
M139.584564,244.406677 
	C140.054230,244.863846 140.046707,245.184860 140.004013,245.756104 
	C139.993179,246.006897 139.974594,246.025787 139.512772,246.021149 
	C135.939423,246.016556 132.819031,245.865555 129.718140,246.047104 
	C122.558517,246.466248 118.130379,249.683533 116.792656,255.305374 
	C114.757866,256.054047 112.955643,256.539459 111.104797,256.638062 
	C111.654602,250.570297 115.436134,247.317673 119.935242,244.612503 
	C126.334068,244.498703 132.732895,244.384903 139.584564,244.406677 
z"/>
          <path fill="#144A2E" opacity="1.000000" stroke="none"
            d="
M119.508926,244.491394 
	C115.436134,247.317673 111.654602,250.570297 111.060989,256.864471 
	C111.065796,257.477692 111.015587,257.935638 110.631409,257.953217 
	C109.226265,257.921967 108.205292,257.873138 107.078148,257.528748 
	C106.300682,256.809631 105.629379,256.386108 104.964066,255.514526 
	C104.963539,252.126480 104.957031,249.186523 105.363937,246.189636 
	C107.853371,245.569992 109.929382,245.007263 112.005394,244.444550 
	C114.364464,244.419800 116.723541,244.395050 119.508926,244.491394 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M318.371521,240.677948 
	C318.011841,213.300430 317.930115,185.617432 318.167999,157.468597 
	C318.655029,157.004898 318.822479,157.007065 318.989960,157.009216 
	C318.995911,182.982483 319.009094,208.955765 318.992371,234.929016 
	C318.991211,236.743576 318.768951,238.557999 318.371521,240.677948 
z"/>
          <path fill="#A1C2DE" opacity="1.000000" stroke="none"
            d="
M416.735657,245.356964 
	C405.808197,245.690872 394.519714,245.873108 383.230621,245.930130 
	C373.423096,245.979660 363.614838,245.887497 353.352661,245.705978 
	C353.521057,245.365707 354.143921,245.012299 354.766327,245.013062 
	C375.302368,245.038025 395.838348,245.096970 416.735657,245.356964 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M321.101776,246.094437 
	C321.067200,246.266556 321.032593,246.438675 320.974365,247.166534 
	C320.985046,248.139175 321.019318,248.556061 320.946716,249.429810 
	C320.757416,255.258545 320.675018,260.630402 320.592651,266.002289 
	C319.671387,263.869385 318.120880,261.783478 317.957275,259.593933 
	C317.594269,254.736908 317.927032,249.827927 318.056641,244.478394 
	C318.918823,244.341614 319.700500,244.666534 320.771912,245.284317 
	C321.075043,245.749603 321.088409,245.922028 321.101776,246.094437 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M191.885941,131.083832 
	C190.084885,133.724457 187.891876,136.326691 185.426666,139.146667 
	C184.552429,134.522263 187.041916,132.036041 191.885941,131.083832 
z"/>
          <path fill="#26784B" opacity="1.000000" stroke="none"
            d="
M111.571335,244.308777 
	C109.929382,245.007263 107.853371,245.569992 105.017403,246.131790 
	C103.789597,246.089920 103.321747,246.048965 102.853897,246.007996 
	C102.843727,245.652710 102.833557,245.297394 102.897797,244.684967 
	C105.693886,244.342896 108.415581,244.257965 111.571335,244.308777 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M317.020874,144.927948 
	C315.813690,143.326065 314.896362,141.546860 313.919434,139.432343 
	C315.010162,140.981567 316.160461,142.866089 317.020874,144.927948 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M318.099945,151.883575 
	C317.552277,150.634766 317.289001,149.201782 317.065491,147.395416 
	C317.531586,148.581146 317.957977,150.140274 318.099945,151.883575 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M181.628235,148.233185 
	C181.801025,149.506775 181.716339,150.990936 181.420761,152.775482 
	C181.263504,151.531830 181.317123,149.987793 181.628235,148.233185 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M152.713287,243.655167 
	C150.617126,244.008438 148.245422,244.091217 145.439667,244.069397 
	C147.482986,243.771423 149.960373,243.578049 152.713287,243.655167 
z"/>
          <path fill="#A1C2DE" opacity="1.000000" stroke="none"
            d="
M321.543915,246.065582 
	C321.088409,245.922028 321.075043,245.749603 321.090393,245.319702 
	C323.551514,245.105316 325.983887,245.148407 328.753174,245.395264 
	C326.722107,245.744919 324.354065,245.890823 321.543915,246.065582 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M183.776367,141.146500 
	C183.677551,142.400253 183.181396,143.677353 182.429321,145.198303 
	C182.575287,144.018036 182.977158,142.593933 183.776367,141.146500 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M295.874084,124.750595 
	C295.145874,124.902733 294.286591,124.767380 293.178284,124.417542 
	C293.867157,124.289734 294.805084,124.376419 295.874084,124.750595 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M305.870819,129.783997 
	C304.963013,129.653244 304.040802,129.145432 302.928345,128.376343 
	C303.777527,128.545685 304.816986,128.976318 305.870819,129.783997 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M318.976135,156.713135 
	C318.822479,157.007065 318.655029,157.004898 318.236328,157.003998 
	C317.950470,156.557632 317.915833,156.110031 317.960449,155.333893 
	C318.347229,155.475937 318.654785,155.946503 318.976135,156.713135 
z"/>
          <path fill="#A1C2DE" opacity="1.000000" stroke="none"
            d="
M336.995239,245.395294 
	C336.918152,245.620392 336.711853,245.699661 336.188660,245.681000 
	C336.203186,245.471848 336.534607,245.360641 336.995239,245.395294 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M202.896042,125.080795 
	C203.006363,125.368454 202.810272,125.560257 202.292664,125.587868 
	C202.239639,125.321518 202.445801,125.175270 202.896042,125.080795 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M297.833984,125.720634 
	C297.645538,125.837341 297.296875,125.719788 296.781982,125.369064 
	C296.968414,125.252724 297.321106,125.369553 297.833984,125.720634 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M313.861176,138.879272 
	C313.657318,138.921448 313.386353,138.717453 313.070923,138.253189 
	C313.282318,138.206314 313.538208,138.419693 313.861176,138.879272 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M311.864014,135.965881 
	C311.663422,135.994736 311.405884,135.755875 311.073120,135.260574 
	C311.267609,135.235489 311.537354,135.466843 311.864014,135.965881 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M184.698822,140.138107 
	C184.795868,140.322327 184.681488,140.632462 184.332779,141.044418 
	C184.099594,140.795456 184.229248,140.501389 184.698822,140.138107 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M194.796112,129.074310 
	C194.923065,129.315536 194.748505,129.550110 194.254242,129.737961 
	C194.144440,129.489151 194.331116,129.281296 194.796112,129.074310 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M151.999695,104.000275 
	C152.121109,104.142143 152.242813,104.283745 152.364532,104.425346 
	C152.279007,104.375832 152.193497,104.326317 152.054291,104.138092 
	C152.000610,103.999390 152.000000,104.000000 151.999695,104.000275 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M200.121582,126.160912 
	C200.126297,126.376404 199.846024,126.612885 199.289459,126.884354 
	C199.287643,126.673531 199.562134,126.427719 200.121582,126.160912 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M299.828064,126.641815 
	C299.656616,126.746590 299.335541,126.637749 298.859314,126.317253 
	C299.028931,126.213135 299.353668,126.320663 299.828064,126.641815 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M198.107422,127.155762 
	C198.111206,127.366707 197.835983,127.598305 197.289154,127.862137 
	C197.287827,127.655060 197.558121,127.415733 198.107422,127.155762 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M301.810364,127.572433 
	C301.610535,127.818344 301.299896,127.728447 300.877716,127.298424 
	C301.074066,127.066551 301.385162,127.159325 301.810364,127.572433 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M196.112335,128.171967 
	C196.127380,128.395035 195.850815,128.650970 195.291290,128.954346 
	C195.279129,128.736130 195.549927,128.470474 196.112335,128.171967 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M75.847549,273.087341 
	C76.082222,273.174347 76.143929,273.339233 76.096306,273.744263 
	C75.816254,273.817444 75.630424,273.661896 75.488190,273.473450 
	C75.461365,273.437897 75.607986,273.271393 75.847549,273.087341 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M306.859283,130.903870 
	C306.648407,130.972900 306.349884,130.780563 305.954773,130.334366 
	C306.162689,130.267838 306.467163,130.455185 306.859283,130.903870 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M308.893982,132.761658 
	C308.716736,132.819733 308.432495,132.652069 308.052246,132.248917 
	C308.300354,132.080795 308.577240,132.254959 308.893982,132.761658 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M309.900635,133.766998 
	C309.721741,133.819412 309.443115,133.649368 309.088501,133.239136 
	C309.341949,133.084518 309.604767,133.266373 309.900635,133.766998 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M310.898376,134.777100 
	C310.718048,134.828781 310.440063,134.655426 310.086243,134.240845 
	C310.342133,134.086075 310.605591,134.270233 310.898376,134.777100 
z"/>
          <path fill="#FFFFFF" opacity="1.000000" stroke="none"
            d="
M180.629547,290.827087 
	C180.809753,300.243286 180.989944,309.659485 181.083282,319.537842 
	C177.675278,320.796631 175.747894,325.344177 176.106598,330.124634 
	C176.330551,333.109314 175.673080,336.156219 175.462006,339.178772 
	C175.324509,341.147430 175.224091,343.121918 175.203125,345.094818 
	C175.165070,348.677063 175.212738,352.260132 175.195541,355.842712 
	C175.188370,357.335327 174.555283,359.110138 175.151047,360.272827 
	C178.322769,366.462860 175.364685,372.494110 175.203049,378.602325 
	C175.059860,384.013519 175.774460,389.486023 176.557846,394.866302 
	C176.933716,397.447906 176.481415,398.667725 173.884155,398.997437 
	C172.749512,399.141449 171.650085,399.676910 170.521042,399.726990 
	C168.197571,399.830017 165.106476,400.612885 163.752167,399.454590 
	C162.432907,398.326324 162.332855,395.038788 162.671814,392.850159 
	C163.510239,387.436584 162.890350,386.046570 157.592056,384.762329 
	C155.999435,384.376282 154.352402,384.133575 152.716797,384.020813 
	C147.053940,383.630432 141.383575,383.350159 135.719101,382.981354 
	C131.439514,382.702698 131.712036,378.887268 131.197937,376.296509 
	C130.446289,372.508545 131.536880,368.503448 128.420471,365.033417 
	C127.358521,363.850952 128.201004,360.897797 128.290710,358.758636 
	C128.377213,356.695831 128.615204,354.639313 128.786652,352.580048 
	C126.205528,352.600830 123.601219,352.430664 121.055107,352.739838 
	C120.085258,352.857574 119.248978,354.075439 118.289047,354.842041 
	C112.426582,352.503326 113.841896,347.518585 114.198586,343.320374 
	C114.669785,337.774170 111.997368,334.542664 106.447266,335.041840 
	C104.977791,335.173981 103.536659,335.908264 102.085617,335.898621 
	C99.061844,335.878510 96.450294,335.714844 97.698372,331.081848 
	C99.496887,324.405487 98.493843,318.226471 92.273018,313.805359 
	C91.704811,313.401520 91.867424,311.969421 91.690659,311.014801 
	C93.273758,311.014801 94.856857,311.014801 96.810287,311.014801 
	C98.995468,308.491119 97.889580,306.637817 94.733467,305.414185 
	C93.496086,304.934418 92.157127,304.075775 90.966850,304.222198 
	C84.576881,305.008392 82.133652,301.772827 82.587433,295.986023 
	C82.756996,293.823608 84.091354,291.752533 84.947510,289.278992 
	C86.723633,288.821960 88.446838,288.725708 90.639404,289.118469 
	C95.625969,290.404602 100.147316,291.908081 104.659744,291.881775 
	C129.984207,291.734070 155.306519,291.217651 180.629547,290.827087 
z"/>
          <path fill="#FFFFFF" opacity="1.000000" stroke="none"
            d="
M331.092773,291.000000 
	C348.010834,291.000000 364.443146,290.999969 380.875427,291.000000 
	C390.616333,291.000031 400.358521,291.088440 410.097351,290.946564 
	C413.022980,290.903931 415.625610,291.822083 415.543549,294.648468 
	C415.449310,297.895172 413.959351,301.110718 412.917175,304.287811 
	C412.873474,304.421082 411.230743,303.988922 410.325348,303.888153 
	C407.730774,303.599518 405.109985,303.000488 402.541077,303.166534 
	C399.370483,303.371460 398.322845,305.475952 399.376160,308.474396 
	C399.697815,309.390045 400.669952,310.268066 400.600098,311.100891 
	C399.896759,319.481964 399.038361,327.850006 398.267975,335.765991 
	C394.685822,335.531799 390.832123,335.029205 386.989716,335.104370 
	C383.644501,335.169769 381.755157,337.433685 383.490753,340.461029 
	C386.613312,345.907867 381.523010,349.815002 382.505646,355.395111 
	C380.705261,354.487823 379.933472,354.235443 379.323700,353.770508 
	C373.140472,349.056152 368.513062,350.503113 368.418182,358.254974 
	C368.341614,364.511810 365.769989,367.097931 360.622070,368.580078 
	C354.912415,370.223938 351.801422,373.871613 350.803497,379.804321 
	C349.975891,384.724487 345.259491,385.337555 341.649994,385.566071 
	C336.106079,385.917053 335.256653,386.183502 335.918976,391.435120 
	C336.617371,396.972626 332.048004,402.002441 326.429321,401.881073 
	C323.441010,401.816528 320.769592,398.456604 321.135193,395.179047 
	C321.727509,389.868774 322.354828,384.562347 322.938568,379.251160 
	C323.010376,378.597656 323.180817,377.591095 322.841278,377.311096 
	C318.057739,373.366425 322.008270,367.865631 320.331818,363.369873 
	C320.057220,362.633423 320.429871,361.290894 320.991364,360.669128 
	C323.750061,357.614227 323.699036,354.469879 321.724609,351.108490 
	C321.476501,350.686096 321.028015,350.002594 321.177643,349.780609 
	C325.142273,343.898010 320.772156,337.747681 321.770599,331.717834 
	C322.402893,327.899017 321.798676,323.882355 321.824585,319.954895 
	C321.882294,311.209595 321.897217,302.461426 322.205170,293.724121 
	C322.237946,292.793915 323.973145,291.449860 325.139832,291.145294 
	C326.857788,290.696838 328.774750,291.010803 331.092773,291.000000 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M312.845581,371.033539 
	C298.900238,371.479126 284.782715,371.683350 270.665009,371.696198 
	C243.069199,371.721252 215.473282,371.639435 187.428696,371.454163 
	C188.294540,370.872009 189.608444,370.060150 190.923721,370.057892 
	C230.408005,369.990326 269.892395,369.997040 309.376770,370.032013 
	C310.475830,370.032990 311.574463,370.471313 312.845581,371.033539 
z"/>
          <path fill="#286191" opacity="1.000000" stroke="none"
            d="
M320.624146,266.178589 
	C320.675018,260.630402 320.757416,255.258545 321.183167,249.606812 
	C321.684814,249.883621 321.843018,250.440277 321.994568,251.447189 
	C321.991516,254.598297 321.995148,257.299164 321.995361,260.466125 
	C321.997009,265.619263 322.002075,270.306274 322.000000,275.448242 
	C321.963257,286.014069 321.963257,286.010040 332.063751,286.009033 
	C358.684296,286.006439 385.305450,286.087128 411.924286,285.873260 
	C414.346802,285.853790 416.754181,283.952850 419.168610,282.925598 
	C418.656128,284.314819 418.143677,285.704010 417.307495,287.217804 
	C414.596436,287.286499 412.209106,287.230591 408.926483,287.163300 
	C403.043030,287.227783 398.054840,287.303680 393.066650,287.379578 
	C392.592041,287.331848 392.117462,287.284180 390.835083,287.205109 
	C384.378418,287.251892 378.729553,287.330078 373.080688,287.408234 
	C372.288483,287.351257 371.496246,287.294250 369.862061,287.221130 
	C364.369202,287.265900 359.718262,287.326752 355.067352,287.387634 
	C352.317902,287.345642 349.568451,287.303680 345.949036,287.244385 
	C343.080719,287.294922 341.082336,287.362762 339.083954,287.430603 
	C338.601135,287.380554 338.118317,287.330536 336.835052,287.252625 
	C332.714874,287.277283 329.395142,287.329865 326.075409,287.382446 
	C324.671661,287.267639 323.267944,287.152802 321.388672,286.675598 
	C320.636810,286.045288 320.360443,285.777435 320.084076,285.509583 
	C320.274567,279.124664 320.465088,272.739777 320.624146,266.178589 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M318.355865,328.682129 
	C318.067047,315.730499 318.058136,302.474304 318.328003,288.861450 
	C318.616425,301.795715 318.626099,315.086639 318.355865,328.682129 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M373.425415,287.618042 
	C378.729553,287.330078 384.378418,287.251892 390.481659,287.269135 
	C385.214081,287.518982 379.492126,287.673401 373.425415,287.618042 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M393.409119,287.594055 
	C398.054840,287.303680 403.043030,287.227783 408.485138,287.244446 
	C403.876556,287.494141 398.814087,287.651337 393.409119,287.594055 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M201.073822,363.332458 
	C203.509918,363.414825 205.996933,363.866119 208.792679,364.527954 
	C206.117401,366.893494 203.452484,366.594177 201.073822,363.332458 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M355.410004,287.599426 
	C359.718262,287.326752 364.369202,287.265900 369.471130,287.301880 
	C365.199005,287.536224 360.475830,287.673706 355.410004,287.599426 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M289.119995,365.055054 
	C291.671753,364.152161 294.435608,363.550781 297.597534,362.935913 
	C295.982727,366.847839 292.179749,364.402161 289.119995,365.055054 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M180.889999,334.143402 
	C181.469009,336.005951 181.744095,338.089172 181.989899,340.582733 
	C181.502426,338.783417 181.044250,336.573730 180.889999,334.143402 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M326.403442,287.594177 
	C329.395142,287.329865 332.714874,287.277283 336.476227,287.324524 
	C333.522400,287.551544 330.126953,287.678711 326.403442,287.594177 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M182.061554,342.192505 
	C183.071487,343.918396 184.042130,346.065491 185.067673,348.564453 
	C184.089142,346.815430 183.055695,344.714539 182.061554,342.192505 
z"/>
          <path fill="#4B9CD7" opacity="1.000000" stroke="none"
            d="
M191.116516,356.719208 
	C190.539337,355.919434 189.962173,355.119659 189.144196,353.951416 
	C188.903381,353.582977 188.862885,353.138550 188.862885,353.138550 
	C188.862885,353.138550 189.035065,352.965546 189.465408,352.954376 
	C190.995605,354.229980 192.095459,355.516785 193.195312,356.803558 
	C193.195312,356.803558 193.024612,356.983459 192.649704,357.002411 
	C191.888702,356.920654 191.502609,356.819916 191.116516,356.719208 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M311.994202,352.854248 
	C312.508118,351.266968 313.412537,349.690857 314.593079,347.909119 
	C314.041046,349.424133 313.212891,351.144745 311.994202,352.854248 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M191.109604,357.110046 
	C191.502609,356.819916 191.888702,356.920654 192.565414,357.093323 
	C193.550369,357.897736 194.244705,358.630157 195.108002,359.620453 
	C193.885559,359.085846 192.494125,358.293365 191.109604,357.110046 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M317.279266,341.792053 
	C317.158905,340.513550 317.297882,339.030823 317.652832,337.251556 
	C317.758698,338.499298 317.648651,340.043518 317.279266,341.792053 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M339.381958,287.621338 
	C341.082336,287.362762 343.080719,287.294922 345.497559,287.325623 
	C343.837341,287.553436 341.758667,287.682770 339.381958,287.621338 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M299.773041,362.794922 
	C300.023376,362.161285 300.656189,361.580292 301.620300,360.959839 
	C301.352905,361.562775 300.754181,362.205170 299.773041,362.794922 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M319.648010,285.481812 
	C320.360443,285.777435 320.636810,286.045288 320.967957,286.625977 
	C320.396606,287.254852 319.770508,287.570953 318.764618,287.796356 
	C318.660553,286.955139 318.936249,286.204590 319.648010,285.481812 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M302.242004,360.947083 
	C302.400818,360.597382 302.873383,360.249207 303.650238,359.892395 
	C303.488281,360.238708 303.022034,360.593658 302.242004,360.947083 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M304.139404,359.872070 
	C304.108521,359.690002 304.319794,359.441406 304.772491,359.122070 
	C304.803131,359.302765 304.592346,359.554108 304.139404,359.872070 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M196.156342,360.398254 
	C196.372711,360.169403 196.658585,360.281525 197.002502,360.750244 
	C196.793518,360.942566 196.515289,360.820007 196.156342,360.398254 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M198.163727,361.481628 
	C198.365967,361.239929 198.633682,361.328674 198.933197,361.785767 
	C198.735901,361.958191 198.490616,361.844177 198.163727,361.481628 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M200.184814,362.534668 
	C200.372025,362.257996 200.634842,362.326111 200.942810,362.782135 
	C200.763611,362.960114 200.521118,362.863251 200.184814,362.534668 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M305.253387,358.920776 
	C305.069580,358.668335 305.224548,358.400116 305.749268,358.164886 
	C305.890656,358.416809 305.715057,358.652527 305.253387,358.920776 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M316.524780,343.776154 
	C316.231262,343.602905 316.282349,343.295380 316.697449,342.859711 
	C316.937012,343.030304 316.872986,343.333771 316.524780,343.776154 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M315.380768,346.806427 
	C315.263855,346.635895 315.359894,346.300537 315.659485,345.789154 
	C315.773254,345.955933 315.683502,346.298798 315.380768,346.806427 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M185.126785,349.125061 
	C185.324646,349.088104 185.583038,349.287262 185.873474,349.737305 
	C185.583542,349.873749 185.344131,349.664734 185.126785,349.125061 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M186.169800,350.913574 
	C186.396103,350.885345 186.664017,351.150238 186.997772,351.692627 
	C186.779556,351.715607 186.495483,351.461151 186.169800,350.913574 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M188.642670,353.122162 
	C188.862885,353.138550 188.903381,353.582977 188.886276,353.805481 
	C188.585999,353.922302 188.355667,353.747131 188.079498,353.306671 
	C187.980835,353.110840 188.422455,353.105774 188.642670,353.122162 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M307.985870,356.822083 
	C307.923615,356.596039 308.158081,356.295441 308.654846,355.872040 
	C308.705597,356.082001 308.494080,356.414734 307.985870,356.822083 
z"/>
          <path fill="#4F7855" opacity="1.000000" stroke="none"
            d="
M436.220764,181.164017 
	C432.974884,182.398849 429.729004,183.633698 426.105591,184.771088 
	C427.942596,183.088531 429.940125,180.802612 432.408752,180.038269 
	C443.786530,176.515411 455.341522,175.092072 466.900116,179.408630 
	C466.238831,180.177048 465.676270,180.506134 465.113739,180.835220 
	C465.113739,180.835220 464.807831,181.004272 464.413696,181.057587 
	C463.699127,181.017029 463.378693,180.923141 462.958099,180.462372 
	C457.353271,177.655334 451.732147,177.013641 445.545349,179.006424 
	C442.290253,179.739990 439.430664,180.467224 436.478882,181.239899 
	C436.386688,181.285370 436.220764,181.164017 436.220764,181.164017 
z"/>
          <path fill="#285465" opacity="1.000000" stroke="none"
            d="
M465.469543,180.895111 
	C465.676270,180.506134 466.238831,180.177048 467.121887,179.714203 
	C467.442444,179.580444 467.799866,179.621246 467.962280,179.698944 
	C468.093872,179.992218 468.062988,180.207809 468.014374,180.750488 
	C467.272888,181.036697 466.549103,180.995850 465.469543,180.895111 
z"/>
          <path fill="#105B9E" opacity="1.000000" stroke="none"
            d="
M419.254211,282.624298 
	C416.754181,283.952850 414.346802,285.853790 411.924286,285.873260 
	C385.305450,286.087128 358.684296,286.006439 332.063751,286.009033 
	C321.963257,286.010040 321.963257,286.014069 322.360870,275.454742 
	C324.764099,274.993225 326.799316,274.980133 328.841187,275.324585 
	C329.254211,276.773132 329.660614,277.864105 329.899414,279.221130 
	C329.677216,280.205658 329.622681,280.924103 329.568115,281.642578 
	C331.706177,281.682465 333.844788,281.772034 335.982147,281.748016 
	C338.037201,281.724945 340.092285,281.485474 342.145020,281.513062 
	C349.558990,281.612732 357.199829,284.025421 364.338745,279.164093 
	C365.299622,280.080566 366.325470,281.059021 367.602722,282.277283 
	C368.538208,281.134552 369.286346,280.220673 370.315186,278.963928 
	C371.669159,283.988434 373.868408,281.324585 376.119507,280.388306 
	C377.243195,280.815552 378.162354,280.941315 379.367554,281.009644 
	C380.382202,280.374817 381.110840,279.797455 382.084747,279.459656 
	C384.668121,279.455780 387.076050,279.467407 389.313293,278.855316 
	C390.347321,278.572418 391.048279,277.072144 392.186279,275.808685 
	C396.997681,275.697418 401.523102,275.901245 406.009155,276.451233 
	C405.344330,278.044220 404.718872,279.291077 404.126801,280.471313 
	C407.929047,284.112244 411.049408,283.187592 415.168549,278.795654 
	C412.731140,277.606293 410.857544,276.692047 409.187073,275.492249 
	C412.259186,275.470276 415.128143,275.733917 417.997131,275.997559 
	C418.191498,277.535736 418.385864,279.073944 418.884521,280.851593 
	C419.239197,281.501709 419.289520,281.912354 419.254211,282.624298 
M398.316162,279.200470 
	C399.030243,279.290283 399.744324,279.380096 400.458435,279.469910 
	C400.213165,278.806610 399.967926,278.143311 399.722656,277.480042 
	C399.204773,277.837769 398.686890,278.195526 398.316162,279.200470 
z"/>
          <path fill="#F7C32B" opacity="1.000000" stroke="none"
            d="
M468.939117,192.974899 
	C469.387970,194.177490 470.467529,195.556717 470.187866,196.555313 
	C467.470947,206.257034 452.153137,213.826248 442.617920,210.364059 
	C436.403137,208.107483 431.067963,204.757706 428.066711,198.088882 
	C429.203430,197.475983 430.182068,197.295441 431.077667,197.492462 
	C442.217438,199.943344 453.250519,199.789139 464.069641,195.979340 
	C465.519989,195.468643 466.618439,193.958710 467.898621,192.918091 
	C467.917389,192.924713 467.955841,192.919357 468.200378,192.955841 
	C468.444916,192.992340 468.939117,192.974899 468.939117,192.974899 
z"/>
          <path fill="#B4C24A" opacity="1.000000" stroke="none"
            d="
M467.879852,192.911499 
	C466.618439,193.958710 465.519989,195.468643 464.069641,195.979340 
	C453.250519,199.789139 442.217438,199.943344 431.077667,197.492462 
	C430.182068,197.295441 429.203430,197.475983 427.958923,197.742737 
	C427.425964,197.075729 427.197479,196.147049 427.491425,194.986206 
	C428.981537,194.961639 429.950562,195.163040 430.916626,195.377808 
	C441.748230,197.785690 452.496124,198.217911 463.326080,193.993958 
	C464.080566,193.705368 464.558350,193.446915 465.393738,193.168091 
	C466.460815,193.068985 467.170319,192.990250 467.879852,192.911499 
z"/>
          <path fill="#4F7855" opacity="1.000000" stroke="none"
            d="
M463.049408,194.024109 
	C452.496124,198.217911 441.748230,197.785690 430.916626,195.377808 
	C429.950562,195.163040 428.981537,194.961639 427.616821,194.684998 
	C427.157990,194.226791 427.096161,193.837616 426.969910,193.157349 
	C428.335205,192.940720 429.764923,193.015167 431.654297,193.261551 
	C432.406921,193.319351 432.699921,193.205215 432.992920,193.091095 
	C432.992920,193.091080 433.261536,193.002228 433.667908,192.942993 
	C434.645996,192.902359 435.217773,192.920975 436.014832,193.287598 
	C438.072235,194.115097 439.914154,195.036591 441.734619,194.995926 
	C448.843231,194.837250 455.945068,194.374084 463.049408,194.024109 
z"/>
          <path fill="#4F7855" opacity="1.000000" stroke="none"
            d="
M467.898621,192.918106 
	C467.170319,192.990250 466.460815,193.068985 465.499695,192.851013 
	C465.904663,191.447418 466.561218,190.340546 467.217804,189.233643 
	C468.884094,188.851440 470.550385,188.469238 472.572021,188.172699 
	C471.792847,189.386688 470.658325,190.514999 469.002045,191.857056 
	C468.305481,192.353668 468.130676,192.636520 467.955841,192.919357 
	C467.955841,192.919357 467.917389,192.924713 467.898621,192.918106 
z"/>
          <path fill="#B4C24A" opacity="1.000000" stroke="none"
            d="
M468.200378,192.955841 
	C468.130676,192.636520 468.305481,192.353668 468.738007,192.021835 
	C468.992065,192.140320 468.988403,192.307800 468.961914,192.725082 
	C468.939117,192.974899 468.444916,192.992340 468.200378,192.955841 
z"/>
          <path fill="#B5DBE2" opacity="1.000000" stroke="none"
            d="
M435.789520,192.939575 
	C435.217773,192.920975 434.645996,192.902359 433.794373,192.879669 
	C434.378296,189.214218 435.242065,185.552856 436.163300,181.527740 
	C436.220764,181.164017 436.386688,181.285370 436.655334,181.592346 
	C438.627441,182.295120 440.330872,182.690918 442.012756,183.432129 
	C442.665344,186.190704 443.339447,188.603851 444.030823,191.346970 
	C441.295227,192.097824 438.542358,192.518692 435.789520,192.939575 
z"/>
          <path fill="#266C7C" opacity="1.000000" stroke="none"
            d="
M432.713379,193.074982 
	C432.699921,193.205215 432.406921,193.319351 431.995361,193.274948 
	C432.062500,193.097229 432.248169,193.078064 432.713379,193.074982 
z"/>
          <path fill="#266C7C" opacity="1.000000" stroke="none"
            d="
M436.014832,193.287598 
	C438.542358,192.518692 441.295227,192.097824 444.091858,191.003723 
	C445.366913,189.201721 446.598175,188.072937 447.897583,187.287994 
	C448.284760,188.617477 448.603790,189.603134 449.077637,191.066940 
	C450.707703,188.999252 451.961212,187.409210 453.491333,186.076355 
	C454.161804,188.098633 454.555634,189.863739 455.133270,192.452698 
	C456.518890,190.888428 457.225220,190.091019 457.730896,189.520187 
	C459.479126,190.109207 461.133301,190.666550 462.683563,191.188889 
	C463.476776,187.863068 464.108673,185.213638 464.740540,182.564209 
	C465.359253,184.609055 465.977997,186.653885 466.907288,188.966187 
	C466.561218,190.340546 465.904663,191.447418 465.142120,192.871384 
	C464.558350,193.446915 464.080566,193.705368 463.326080,193.993958 
	C455.945068,194.374084 448.843231,194.837250 441.734619,194.995926 
	C439.914154,195.036591 438.072235,194.115097 436.014832,193.287598 
z"/>
          <path fill="#B5DBE2" opacity="1.000000" stroke="none"
            d="
M464.676331,182.340134 
	C464.108673,185.213638 463.476776,187.863068 462.683563,191.188889 
	C461.133301,190.666550 459.479126,190.109207 457.730896,189.520187 
	C457.225220,190.091019 456.518890,190.888428 455.133270,192.452698 
	C454.555634,189.863739 454.161804,188.098633 453.691406,185.825775 
	C454.307037,184.213181 454.999268,183.108307 456.002991,181.730713 
	C458.562408,181.248398 460.810333,181.038818 463.058258,180.829254 
	C463.378693,180.923141 463.699127,181.017029 464.263885,181.147552 
	C464.552368,181.493744 464.586975,181.804367 464.676331,182.340134 
z"/>
          <path fill="#FCEDEC" opacity="1.000000" stroke="none"
            d="
M45.122627,182.576645 
	C46.580845,184.043106 47.675209,185.518326 48.980396,187.277740 
	C51.901993,185.227936 55.660007,182.868149 54.987686,176.367035 
	C51.999260,177.502884 49.558952,178.627945 47.004906,179.351517 
	C43.756832,180.271652 39.567516,183.696823 37.419621,178.993591 
	C35.447605,174.675446 39.536427,172.034592 43.051289,170.425888 
	C45.841797,169.148712 49.095528,167.831696 51.985863,168.119034 
	C54.903049,168.409058 58.592442,169.959625 60.205109,172.205963 
	C62.563984,175.491745 61.523743,179.898239 58.592796,182.719208 
	C55.378662,185.812729 51.590736,188.411438 47.744808,190.710449 
	C45.305523,192.168655 41.604404,193.830475 40.121315,189.907394 
	C38.804081,186.423065 40.738861,183.475052 45.122627,182.576645 
z"/>
          <path fill="#F39095" opacity="1.000000" stroke="none"
            d="
M57.541519,200.711975 
	C57.454716,201.928131 57.257694,203.111038 56.635189,203.993927 
	C55.990452,204.908310 54.933105,205.531754 54.143066,206.674561 
	C50.997322,206.531906 47.764740,205.997177 44.532154,205.462448 
	C44.620953,205.151703 44.709751,204.840958 44.798550,204.530212 
	C43.582172,204.889786 42.365791,205.249344 40.963848,204.967255 
	C41.150093,202.501129 41.521900,200.676620 41.893707,198.852127 
	C44.122684,198.197952 46.351665,197.543777 47.708717,197.145493 
	C50.268608,197.854065 52.370815,198.355804 54.413734,199.035706 
	C55.414127,199.368652 56.304325,200.032700 57.541519,200.711975 
z"/>
          <path fill="#A44138" opacity="1.000000" stroke="none"
            d="
M41.663887,198.646149 
	C41.521900,200.676620 41.150093,202.501129 40.709152,204.732849 
	C40.640015,205.140076 40.370842,205.127701 40.237343,205.115051 
	C40.807980,201.505447 39.171520,199.222824 35.893242,197.422333 
	C37.633080,197.645782 40.293430,194.966599 41.663887,198.646149 
z"/>
          <path fill="#182C20" opacity="1.000000" stroke="none"
            d="
M102.398880,246.001495 
	C103.321747,246.048965 103.789597,246.089920 104.603989,246.188721 
	C104.957031,249.186523 104.963539,252.126480 104.750816,255.627777 
	C104.251503,256.374390 104.057907,256.626160 103.636993,256.945740 
	C102.904610,256.935181 102.486038,256.923370 101.761391,256.642517 
	C97.929482,256.763306 94.403648,257.153137 90.558258,257.658264 
	C89.233345,258.252899 88.227997,258.732239 86.857864,259.308105 
	C84.611061,261.730194 83.798607,263.998199 87.033432,266.293884 
	C87.800163,267.056671 88.384430,267.583862 89.031113,268.454346 
	C90.757500,269.545166 92.421478,270.292664 94.031570,271.385254 
	C94.131371,273.403992 93.642708,275.881287 94.552727,276.608643 
	C98.024170,279.383301 101.848892,281.767883 105.737961,283.947937 
	C107.959564,285.193268 111.599899,284.179291 111.544289,288.269104 
	C106.731468,288.312775 102.370163,288.237305 98.008865,288.161804 
	C96.719444,286.777802 95.323288,284.122345 94.158249,284.220123 
	C87.552574,284.774597 85.269455,278.451569 80.618614,275.993744 
	C79.416214,275.358307 79.562737,270.917999 80.113007,268.454010 
	C81.742531,261.157440 81.259819,254.047272 78.598351,245.994980 
	C86.896927,245.994980 94.420395,245.994980 102.398880,246.001495 
z"/>
          <path fill="#FEFFFF" opacity="1.000000" stroke="none"
            d="
M97.650116,288.306519 
	C102.370163,288.237305 106.731468,288.312775 112.000168,288.393646 
	C128.998001,288.468170 145.088409,288.537323 161.560226,288.738220 
	C164.180603,288.759033 166.419586,288.648071 168.658569,288.537109 
	C171.755096,288.395538 174.851624,288.253967 178.351288,288.290833 
	C179.408386,288.946014 180.062332,289.422821 180.672913,290.363373 
	C155.306519,291.217651 129.984207,291.734070 104.659744,291.881775 
	C100.147316,291.908081 95.625969,290.404602 91.053902,289.246826 
	C93.096489,288.741180 95.193924,288.596222 97.650116,288.306519 
z"/>
          <path fill="#175C77" opacity="1.000000" stroke="none"
            d="
M264.991821,414.039093 
	C262.208832,414.463287 259.425842,414.887482 256.345642,415.556824 
	C251.671616,415.830872 247.294815,415.859772 242.369507,415.671997 
	C241.546356,415.549316 241.271698,415.643311 240.997040,415.737305 
	C239.944016,415.772186 238.891006,415.807098 237.449158,415.766418 
	C236.600983,414.825897 236.141602,413.960968 235.014633,412.991425 
	C231.235092,410.922150 228.123138,408.957458 225.416977,406.991882 
	C228.561676,407.000092 231.300583,407.009247 234.412537,407.000427 
	C235.523544,407.091125 236.261536,407.199768 237.071350,407.611176 
	C238.122559,407.882172 239.101959,407.850372 240.525116,407.842316 
	C244.951752,408.215942 248.934601,408.565887 253.304657,408.945526 
	C254.823380,409.398071 255.986267,409.752899 257.080231,410.257233 
	C259.734467,411.480865 262.356689,412.773987 264.991821,414.039093 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M187.490585,415.812683 
	C188.827011,415.569824 190.565628,415.425049 192.559631,415.486389 
	C191.174255,415.765259 189.533524,415.838013 187.490585,415.812683 
z"/>
          <path fill="#338393" opacity="1.000000" stroke="none"
            d="
M241.245392,415.831482 
	C241.271698,415.643311 241.546356,415.549316 241.908997,415.605377 
	C241.829224,415.812164 241.661499,415.868927 241.245392,415.831482 
z"/>
          <path fill="#1189A2" opacity="1.000000" stroke="none"
            d="
M265.461121,414.027344 
	C262.356689,412.773987 259.734467,411.480865 257.080231,410.257233 
	C255.986267,409.752899 254.823380,409.398071 253.274628,408.579834 
	C251.117249,407.462006 249.435974,406.475006 247.624771,406.071930 
	C243.804413,405.221771 239.916046,404.677246 236.052673,403.617188 
	C237.379822,402.662872 238.772018,401.504822 240.029205,401.636444 
	C245.708206,402.230865 251.349014,403.190186 257.279114,404.306946 
	C260.045258,404.714447 262.535828,404.833069 265.438568,404.959076 
	C267.224762,404.959473 268.598755,404.952423 270.443848,404.968628 
	C278.826233,404.677979 286.739990,404.414001 294.647675,404.025757 
	C297.826996,403.869659 298.794250,401.789093 297.511444,399.209442 
	C293.821838,391.789825 289.480255,384.874329 280.703613,381.987427 
	C280.240967,381.906158 280.057678,381.863922 280.004181,381.599060 
	C280.069794,380.594238 280.005646,379.812012 280.233582,378.778778 
	C280.932648,375.867737 282.258942,374.688721 285.154236,374.877594 
	C286.382751,374.957733 287.697784,373.711456 288.973480,373.067993 
	C296.467834,373.366089 303.962219,373.664215 312.575378,374.006805 
	C312.715576,386.213074 313.051819,397.798004 312.869659,409.374786 
	C312.807220,413.343475 309.318481,414.014618 305.926697,414.011810 
	C292.594604,414.000732 279.262482,414.012421 265.461121,414.027344 
z"/>
          <path fill="#1189A2" opacity="1.000000" stroke="none"
            d="
M225.011185,406.992798 
	C228.123138,408.957458 231.235092,410.922150 234.693481,412.951965 
	C230.413940,413.348663 225.791412,413.891968 221.161285,413.967102 
	C211.863449,414.117950 202.554886,413.804504 193.263901,414.098541 
	C188.318604,414.255035 185.609802,413.498230 185.754608,407.504333 
	C185.990051,397.758026 185.183624,387.989807 185.184219,378.230835 
	C185.184296,376.826172 187.026321,374.486267 188.367325,374.194519 
	C192.201721,373.360352 196.217056,373.357941 200.672073,373.412018 
	C203.045151,374.487335 204.907394,375.179047 207.291000,376.064392 
	C206.475937,377.173737 206.006058,378.550568 205.217148,378.767609 
	C200.021225,380.196960 198.537506,384.623199 196.584259,388.786316 
	C193.912048,394.481781 195.200500,396.648529 200.228516,396.503540 
	C202.065140,398.350555 203.361969,399.654694 204.658798,400.958862 
	C205.437439,399.630676 206.216080,398.302460 207.305328,396.991455 
	C208.030151,397.026428 208.444336,397.044250 209.116760,397.169312 
	C209.725708,397.391510 210.076401,397.506409 210.564392,397.928741 
	C213.813507,400.027466 216.925323,401.818756 220.063080,403.986511 
	C221.728363,405.238464 223.367722,406.113892 225.003616,406.993958 
	C225.000153,406.998535 225.011185,406.992798 225.011185,406.992798 
z"/>
          <path fill="#1295AA" opacity="1.000000" stroke="none"
            d="
M206.994705,396.974274 
	C206.216080,398.302460 205.437439,399.630676 204.658798,400.958862 
	C203.361969,399.654694 202.065140,398.350555 200.228516,396.503540 
	C195.200500,396.648529 193.912048,394.481781 196.584259,388.786316 
	C198.537506,384.623199 200.021225,380.196960 205.217148,378.767609 
	C206.006058,378.550568 206.475937,377.173737 207.291000,376.064392 
	C204.907394,375.179047 203.045151,374.487335 201.112701,373.431641 
	C203.398972,373.051666 205.755447,373.035675 208.853622,373.315521 
	C218.010254,373.741455 226.425049,373.939270 234.840149,373.953125 
	C236.890366,373.956512 238.941574,373.355743 240.992325,373.032745 
	C245.166855,373.171082 249.341370,373.309418 253.774536,374.154785 
	C252.297714,375.579010 250.621475,376.568146 248.815475,376.961121 
	C243.885376,378.033905 238.900558,378.855164 233.596542,379.907837 
	C232.530945,380.106812 231.806213,380.173950 230.860947,379.917297 
	C227.622086,378.708862 224.735199,378.288696 222.644257,381.879974 
	C221.905243,382.056671 221.474442,382.122925 220.864502,381.905579 
	C217.826797,383.143616 214.968216,384.665253 212.007111,386.428192 
	C211.785553,386.995514 211.666504,387.321564 211.299988,387.731903 
	C210.721069,387.911316 210.389587,388.006439 209.868134,387.827454 
	C206.072449,389.164124 205.313538,391.828461 206.994705,396.974274 
z"/>
          <path fill="#3EA1AC" opacity="1.000000" stroke="none"
            d="
M233.937408,379.776062 
	C238.900558,378.855164 243.885376,378.033905 248.815475,376.961121 
	C250.621475,376.568146 252.297714,375.579010 254.078918,374.449280 
	C254.512314,374.032074 254.899948,374.027405 255.711609,374.237030 
	C256.335236,374.731476 256.598450,374.925018 257.167786,375.303436 
	C258.604095,375.737335 259.797974,375.899811 261.086304,376.453430 
	C262.686432,377.224091 264.173370,377.801361 265.701324,377.946289 
	C270.439697,378.395782 275.193604,378.680878 279.941528,379.029755 
	C280.005646,379.812012 280.069794,380.594238 279.606079,381.627441 
	C276.057861,381.583923 273.037476,381.289429 269.550842,380.969177 
	C262.114014,380.630585 255.143402,380.317688 247.764099,379.979858 
	C242.882736,379.895325 238.410065,379.835693 233.937408,379.776062 
z"/>
          <path fill="#1295AA" opacity="1.000000" stroke="none"
            d="
M280.233551,378.778778 
	C275.193604,378.680878 270.439697,378.395782 265.701324,377.946289 
	C264.173370,377.801361 262.686432,377.224091 260.862518,376.163635 
	C259.337982,375.332458 258.131683,375.182220 256.925354,375.032013 
	C256.598450,374.925018 256.335236,374.731476 256.003693,374.239380 
	C261.272705,373.689178 266.673676,373.350922 272.810883,373.300232 
	C276.028625,373.408630 278.510132,373.229553 280.991669,373.050476 
	C283.358185,373.045288 285.724670,373.040131 288.532349,373.051483 
	C287.697784,373.711456 286.382751,374.957733 285.154236,374.877594 
	C282.258942,374.688721 280.932648,375.867737 280.233551,378.778778 
M264.019318,375.446991 
	C266.301636,375.661102 268.583954,375.875214 270.866272,376.089325 
	C270.879547,375.748108 270.892853,375.406921 270.906158,375.065704 
	C268.800110,375.065704 266.694061,375.065704 264.019318,375.446991 
z"/>
          <path fill="#1189A2" opacity="1.000000" stroke="none"
            d="
M240.524780,373.020782 
	C238.941574,373.355743 236.890366,373.956512 234.840149,373.953125 
	C226.425049,373.939270 218.010254,373.741455 209.296692,373.321442 
	C219.351120,373.023956 229.704193,373.016388 240.524780,373.020782 
z"/>
          <path fill="#1189A2" opacity="1.000000" stroke="none"
            d="
M280.549072,373.034241 
	C278.510132,373.229553 276.028625,373.408630 273.278809,373.310150 
	C275.375824,373.027710 277.741119,373.022827 280.549072,373.034241 
z"/>
          <path fill="#649BC6" opacity="1.000000" stroke="none"
            d="
M138.805634,429.187927 
	C137.095901,428.219421 135.709763,427.412872 132.905716,425.781281 
	C135.601257,423.933380 137.731598,421.394745 139.220917,421.727264 
	C141.047226,422.135010 142.337418,424.944061 143.859406,426.714966 
	C142.282684,427.593292 140.705948,428.471588 138.805634,429.187927 
z"/>
          <path fill="#B4C24A" opacity="1.000000" stroke="none"
            d="
M458.571289,288.936188 
	C458.385773,290.556458 457.818634,292.029480 456.772583,293.797363 
	C452.417786,292.959900 448.575500,293.333557 446.854431,297.179871 
	C445.890656,299.333740 446.072388,302.939423 447.320282,304.866150 
	C448.453430,306.615662 451.704803,307.708588 454.065582,307.786835 
	C458.008606,307.917603 459.424805,304.624939 460.283173,301.264343 
	C462.192902,301.158661 464.102661,301.053009 466.410339,301.057190 
	C466.445435,307.798431 461.233429,311.639893 457.876190,316.527100 
	C456.482086,318.556488 454.482300,320.158997 452.955353,322.110138 
	C449.758423,326.195312 446.192017,328.339539 440.630493,324.550323 
	C443.371674,321.146698 446.094025,317.766449 448.059113,315.326508 
	C445.043884,310.987213 441.729767,307.374329 439.901001,303.122559 
	C438.912262,300.823883 439.819580,297.138702 440.991943,294.595490 
	C443.784973,288.536652 450.392761,286.557861 458.571289,288.936188 
z"/>
          <path fill="#182C20" opacity="1.000000" stroke="none"
            d="
M460.191589,300.874573 
	C459.424805,304.624939 458.008606,307.917603 454.065582,307.786835 
	C451.704803,307.708588 448.453430,306.615662 447.320282,304.866150 
	C446.072388,302.939423 445.890656,299.333740 446.854431,297.179871 
	C448.575500,293.333557 452.417786,292.959900 456.665070,294.122650 
	C458.057648,296.263672 459.078827,298.374268 460.191589,300.874573 
M451.740814,296.853119 
	C450.800690,298.529144 449.443573,300.126709 449.106873,301.916260 
	C448.952393,302.737396 451.001373,304.917511 451.704529,304.774750 
	C453.648193,304.379974 456.534393,303.473358 457.016388,302.070892 
	C458.135406,298.814880 456.062927,296.865204 451.740814,296.853119 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M140.089890,70.282455 
	C142.160507,75.156914 141.179306,78.932968 136.799622,81.189545 
	C132.411469,83.450455 129.138885,81.111618 126.600021,77.631859 
	C123.768837,73.751465 120.991081,69.821320 118.407211,65.774200 
	C115.744003,61.602814 116.596436,57.598499 120.415619,54.923065 
	C124.164513,52.296879 127.392166,52.831047 130.633942,57.022739 
	C133.874847,61.213291 136.838531,65.618233 140.089890,70.282455 
M131.458817,61.055779 
	C130.558655,60.085308 129.529465,59.204254 128.782990,58.127262 
	C126.710266,55.136822 124.115143,55.213631 121.541771,57.060909 
	C118.759224,59.058342 117.927124,61.701149 120.168518,64.757057 
	C122.798347,68.342590 125.149879,72.178383 128.127991,75.444344 
	C129.809357,77.288231 132.779510,79.622147 134.693527,79.255104 
	C139.204834,78.389999 140.193710,74.276962 137.607880,70.304283 
	C135.722458,67.407654 133.780014,64.548119 131.458817,61.055779 
z"/>
          <path fill="#BEE6BC" opacity="1.000000" stroke="none"
            d="
M58.363258,300.262421 
	C58.886299,302.379822 59.449829,304.379913 59.200928,306.273132 
	C58.717430,309.950806 55.862686,312.370209 52.633553,311.550934 
	C49.063778,310.645294 46.251965,309.900543 43.286026,312.707153 
	C42.351177,310.366028 42.011272,308.400085 41.334381,306.558014 
	C40.374302,303.945251 39.166019,301.423706 38.063805,298.863159 
	C38.352196,297.686401 38.640583,296.509613 38.928978,295.332855 
	C40.096775,295.918884 41.711067,296.209534 42.336304,297.154572 
	C43.515839,298.937408 44.125977,301.096985 45.193016,303.620941 
	C49.569916,297.331696 52.604797,296.676544 58.363258,300.262421 
M56.744377,304.541412 
	C55.378895,303.348358 54.163952,301.618134 52.587914,301.176453 
	C51.729042,300.935730 49.213581,302.965881 49.283421,303.821869 
	C49.421722,305.516998 50.441074,307.890839 51.804688,308.577759 
	C54.571411,309.971497 55.952812,307.761566 56.744377,304.541412 
z"/>
          <path fill="#BEE6BC" opacity="1.000000" stroke="none"
            d="
M27.820669,313.750610 
	C27.334745,311.961517 27.081806,310.415131 26.763168,308.475281 
	C27.911072,305.360657 28.512369,301.813263 32.847332,303.973114 
	C32.299240,304.727051 31.487562,304.930634 31.471197,305.185303 
	C31.318548,307.560730 31.115656,309.967590 31.400288,312.307861 
	C31.459057,312.791046 33.480793,313.035461 34.597794,313.389923 
	C34.869690,311.220978 35.219154,309.057678 35.377682,306.880493 
	C35.447144,305.926422 35.126774,304.944031 34.983418,303.974487 
	C35.469322,303.554596 35.955231,303.134705 37.032784,302.954926 
	C38.736542,306.802277 39.848652,310.409546 40.960766,314.016785 
	C40.637463,316.319916 40.314159,318.623047 39.990852,320.926178 
	C39.436901,320.936523 38.882954,320.946869 38.329002,320.957214 
	C38.073597,319.198639 37.818192,317.440094 37.504375,315.279358 
	C33.982513,317.434479 30.708662,317.457520 27.820669,313.750610 
z"/>
          <path fill="#4F7855" opacity="1.000000" stroke="none"
            d="
M34.718391,303.988525 
	C35.126774,304.944031 35.447144,305.926422 35.377682,306.880493 
	C35.219154,309.057678 34.869690,311.220978 34.597794,313.389923 
	C33.480793,313.035461 31.459057,312.791046 31.400288,312.307861 
	C31.115656,309.967590 31.318548,307.560730 31.471197,305.185303 
	C31.487562,304.930634 32.299240,304.727051 33.071678,304.294342 
	C33.752037,304.060120 34.102699,304.031342 34.718391,303.988525 
z"/>
          <path fill="#4F7855" opacity="1.000000" stroke="none"
            d="
M41.252808,313.993896 
	C39.848652,310.409546 38.736542,306.802277 37.358727,302.910400 
	C37.337791,301.581573 37.582561,300.537415 37.945568,299.178223 
	C39.166019,301.423706 40.374302,303.945251 41.334381,306.558014 
	C42.011272,308.400085 42.351177,310.366028 42.892860,312.666199 
	C42.477734,313.360840 42.011288,313.665955 41.252808,313.993896 
z"/>
          <path fill="#2296F2" opacity="1.000000" stroke="none"
            d="
M193.561157,356.869812 
	C192.095459,355.516785 190.995605,354.229980 189.551666,352.868042 
	C187.415833,347.902222 184.656555,343.128052 184.050339,338.094604 
	C183.044281,329.741333 183.419388,321.221405 183.218597,312.771576 
	C183.137253,309.347473 182.931305,305.919189 183.026093,302.499542 
	C183.260559,294.040436 183.947372,285.583405 183.898605,277.128754 
	C183.724716,246.979309 183.189056,216.831741 183.065460,186.682343 
	C183.014038,174.141571 183.174728,161.559479 184.175125,149.071152 
	C184.711166,142.379471 189.006271,137.295044 194.403564,132.804794 
	C203.354874,125.357796 213.665314,125.960632 224.531982,126.361389 
	C224.998322,127.495522 224.997635,128.245270 224.533279,129.003387 
	C222.570114,129.014221 221.064896,129.104004 219.572006,129.005859 
	C201.209244,127.798698 187.956604,142.194931 187.991135,160.319885 
	C188.088898,211.636063 188.000946,262.952545 188.035339,314.268890 
	C188.040726,322.288239 187.777161,330.028046 192.740631,337.509674 
	C198.182343,345.712250 204.932373,350.704010 214.469742,350.856628 
	C236.290268,351.205902 258.121307,351.085876 279.945343,350.872620 
	C284.349854,350.829590 288.815033,349.805573 293.100433,348.664062 
	C294.594604,348.266144 295.631165,346.150269 296.946259,344.864105 
	C297.015839,344.909241 296.869446,344.840118 297.238770,344.893372 
	C304.430786,342.640656 310.770172,332.586426 310.954468,323.383911 
	C311.356445,303.313507 311.780884,283.241608 311.909271,263.168518 
	C311.966431,254.234543 311.127869,245.297852 311.051941,236.359375 
	C310.906525,219.239471 311.015564,202.117386 311.311920,184.700150 
	C311.406219,177.603638 311.207581,170.803207 311.003418,163.565063 
	C310.998535,161.084763 310.999268,159.042160 311.261749,156.788666 
	C311.330353,155.394684 311.137146,154.211594 310.670898,152.781952 
	C309.316528,149.265869 308.912384,145.520798 307.017639,142.822418 
	C302.610229,136.545654 296.988739,131.480148 289.001801,129.613525 
	C289.003052,128.459488 289.004944,127.689339 289.006836,126.919182 
	C302.102386,127.375717 314.459442,139.713425 315.071136,152.838470 
	C315.184845,155.278427 315.686066,157.700348 315.642212,160.632294 
	C313.536743,163.048752 312.919037,164.798203 315.993744,166.003403 
	C315.998322,169.694107 316.002930,173.384796 315.633240,177.611176 
	C313.324036,180.370224 312.917664,182.403473 315.987549,184.004349 
	C315.991974,189.356369 315.996429,194.708374 315.595886,200.621780 
	C314.820007,201.688263 314.130341,202.190414 314.125641,202.698868 
	C314.040283,212.006912 313.988739,221.316269 314.102081,230.623260 
	C314.111877,231.424759 315.329193,232.211533 315.985565,233.005157 
	C315.669922,239.121155 315.163879,245.235367 315.106812,251.353775 
	C315.069977,255.301804 316.012817,259.260590 315.955872,263.206482 
	C315.802582,273.819885 315.118561,284.428162 315.083221,295.039948 
	C315.052582,304.230621 316.646027,313.540680 315.609894,322.578827 
	C314.745819,330.115692 317.418488,337.984894 313.025391,345.309113 
	C306.348206,356.441315 297.112457,362.893433 283.975433,362.962555 
	C262.834717,363.073822 241.692978,362.991180 220.551666,363.004578 
	C212.043320,363.009979 203.602417,362.813416 196.347580,357.364624 
	C195.763153,356.925629 194.744095,357.065277 193.561157,356.869812 
z"/>
          <path fill="#2996EB" opacity="1.000000" stroke="none"
            d="
M288.704285,126.634567 
	C289.004944,127.689339 289.003052,128.459488 288.572571,129.618317 
	C283.390411,129.676636 278.637665,129.101120 273.883148,129.086136 
	C269.293213,129.071655 264.697937,129.939468 260.111267,129.885422 
	C248.404617,129.747482 236.701492,129.311661 224.996948,128.995010 
	C224.997635,128.245270 224.998322,127.495522 224.999207,126.370789 
	C244.653885,125.987030 264.308380,125.970055 283.962891,125.986008 
	C285.442596,125.987206 286.922089,126.223137 288.704285,126.634567 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M179.353943,285.584900 
	C178.677612,273.712006 178.487289,261.990356 178.581680,249.931351 
	C179.802475,250.250198 181.553925,250.907318 181.553162,251.562378 
	C181.540268,262.489716 181.323883,273.417145 181.071411,284.342682 
	C181.060471,284.816406 180.268250,285.272064 179.353943,285.584900 
z"/>
          <path fill="#2996EB" opacity="1.000000" stroke="none"
            d="
M315.998505,232.536163 
	C315.329193,232.211533 314.111877,231.424759 314.102081,230.623260 
	C313.988739,221.316269 314.040283,212.006912 314.125641,202.698868 
	C314.130341,202.190414 314.820007,201.688263 315.584656,201.088928 
	C315.989441,211.352173 316.000458,221.709656 315.998505,232.536163 
z"/>
          <path fill="#2996EB" opacity="1.000000" stroke="none"
            d="
M316.000305,183.575409 
	C312.917664,182.403473 313.324036,180.370224 315.624878,178.071442 
	C315.998230,179.712860 316.005646,181.429657 316.000305,183.575409 
z"/>
          <path fill="#2996EB" opacity="1.000000" stroke="none"
            d="
M316.005371,165.586243 
	C312.919037,164.798203 313.536743,163.048752 315.635437,161.065186 
	C316.002289,162.387299 316.009644,163.778198 316.005371,165.586243 
z"/>
          <path fill="#0A3A23" opacity="1.000000" stroke="none"
            d="
M166.970001,260.993408 
	C166.239456,256.195435 166.575317,250.997681 162.478119,247.237747 
	C163.093445,247.096802 164.287064,246.823410 165.700012,246.295410 
	C167.574615,246.040833 169.229889,246.040833 171.084274,246.040833 
	C172.893814,250.543671 175.002579,254.669769 173.203964,259.815247 
	C172.373566,262.190796 173.378891,265.146515 172.931000,267.731598 
	C172.243103,271.702026 171.005157,275.576111 170.030487,279.498505 
	C169.640686,281.067169 169.330261,282.655579 168.763885,284.733765 
	C168.288910,285.412476 168.121948,285.653534 168.043167,285.955688 
	C167.840134,285.979309 167.637085,286.002960 166.928314,286.043640 
	C166.422607,286.060669 166.040939,285.928772 166.016556,285.531982 
	C165.666153,282.768524 165.340149,280.401855 165.299164,277.789581 
	C165.684784,276.047058 165.785370,274.550171 166.238373,272.895935 
	C171.236237,269.322357 168.810776,265.649933 166.983536,261.747559 
	C166.981400,261.328522 166.975693,261.160980 166.970001,260.993408 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M166.040939,285.928772 
	C166.040939,285.928772 166.422607,286.060669 166.623840,286.079895 
	C167.482315,286.684875 168.139542,287.270599 168.727676,288.196747 
	C166.419586,288.648071 164.180603,288.759033 161.552734,288.407959 
	C162.789551,287.273529 164.415237,286.601166 166.040939,285.928772 
z"/>
          <path fill="#144A2E" opacity="1.000000" stroke="none"
            d="
M168.245636,285.862061 
	C168.121948,285.653534 168.288910,285.412476 168.747421,285.141083 
	C168.868851,285.349121 168.701294,285.588715 168.245636,285.862061 
z"/>
          <path fill="#26784B" opacity="1.000000" stroke="none"
            d="
M140.002899,245.997101 
	C141.169327,245.994019 142.327362,246.040253 143.482544,246.001328 
	C154.732635,245.622238 154.640976,249.148743 158.872971,257.348938 
	C159.762344,259.072266 160.518845,260.864105 161.722382,263.454895 
	C160.752380,264.774292 159.234604,266.838806 157.362610,268.955383 
	C157.008377,269.007507 156.987991,268.992065 156.819458,268.746918 
	C156.090881,268.343292 155.530838,268.184814 154.760864,267.914001 
	C154.288284,267.606140 154.102188,267.354126 153.970917,266.687317 
	C153.813904,264.592560 153.703018,262.853668 153.538986,261.119843 
	C152.908020,254.450821 146.887527,252.784897 142.706757,249.381302 
	C141.611420,248.489578 140.875565,247.156281 139.974594,246.025787 
	C139.974594,246.025787 139.993179,246.006897 140.002899,245.997101 
z"/>
          <path fill="#4F7855" opacity="1.000000" stroke="none"
            d="
M165.885956,273.053284 
	C165.785370,274.550171 165.684784,276.047058 164.966400,277.724579 
	C162.573792,277.848694 160.798996,277.792206 158.896439,277.563477 
	C158.548660,277.196106 158.296051,277.062683 157.965698,276.695129 
	C157.266052,276.591156 156.611603,276.782959 155.623077,276.994385 
	C154.502060,277.318085 153.715103,277.622131 152.705017,277.943359 
	C152.481857,277.960510 152.034225,277.956940 152.021790,277.968903 
	C152.009369,277.980865 152.038330,277.947144 152.027496,277.618134 
	C151.665649,276.559662 151.314651,275.830200 150.985535,274.815979 
	C151.013168,274.340942 151.018936,274.150635 151.276886,273.810791 
	C152.048157,273.082214 152.567200,272.503143 153.426880,272.116455 
	C155.858459,272.526825 157.949448,272.744843 160.040421,272.962830 
	C160.651535,273.374512 161.225266,273.874268 161.891174,274.156891 
	C162.179062,274.279053 162.657288,273.952820 163.399536,273.462708 
	C164.461990,273.081177 165.173981,273.067230 165.885956,273.053284 
z"/>
          <path fill="#144A2E" opacity="1.000000" stroke="none"
            d="
M166.238373,272.895935 
	C165.173981,273.067230 164.461990,273.081177 163.399261,273.167603 
	C163.695953,270.073608 164.343399,266.907135 165.264633,263.250427 
	C166.018951,262.506500 166.499451,262.252777 166.979965,261.999054 
	C168.810776,265.649933 171.236237,269.322357 166.238373,272.895935 
z"/>
          <path fill="#26784B" opacity="1.000000" stroke="none"
            d="
M159.967102,272.631989 
	C157.949448,272.744843 155.858459,272.526825 153.396576,271.759888 
	C153.018097,270.494720 153.010513,269.778503 153.336456,269.045288 
	C154.114838,269.009094 154.559677,268.989899 155.335083,268.980286 
	C156.106415,268.990601 156.547211,268.991333 156.987991,268.992065 
	C156.987991,268.992065 157.008377,269.007507 157.019165,269.017578 
	C157.984558,270.118805 158.939163,271.209991 159.967102,272.631989 
z"/>
          <path fill="#26784B" opacity="1.000000" stroke="none"
            d="
M166.983536,261.747559 
	C166.499451,262.252777 166.018951,262.506500 165.274963,262.825378 
	C165.487366,262.193024 165.963226,261.495514 166.704544,260.895691 
	C166.975693,261.160980 166.981400,261.328522 166.983536,261.747559 
z"/>
          <path fill="#035AAC" opacity="1.000000" stroke="none"
            d="
M418.002594,275.529297 
	C415.128143,275.733917 412.259186,275.470276 408.823486,275.560547 
	C407.520691,275.977997 406.784607,276.041534 406.048492,276.105103 
	C401.523102,275.901245 396.997681,275.697418 391.826019,275.750916 
	C390.449219,275.955139 389.718658,275.902008 388.901001,275.489014 
	C386.355347,273.503540 383.994720,272.833466 381.635162,275.872864 
	C380.508453,275.892883 379.755951,275.866333 378.959351,275.548004 
	C378.573181,274.595215 378.231079,273.934204 378.240021,273.951477 
	C379.699463,272.686554 380.934448,271.616180 382.416382,270.514038 
	C383.277618,270.254700 383.891937,270.027100 384.927124,269.834290 
	C387.298401,268.715942 391.097168,270.317474 391.266968,265.952606 
	C392.319580,265.524231 393.088501,265.231110 394.256958,264.959534 
	C402.751770,265.102203 395.366394,258.588043 399.411255,256.973022 
	C399.044098,256.664215 398.676941,256.355408 398.309814,256.046600 
	C396.857483,256.046600 395.405182,256.046600 393.647949,256.023468 
	C392.938904,255.951981 392.534790,255.903625 392.033386,255.522919 
	C389.614258,253.806396 387.292450,252.422211 384.815948,250.782318 
	C384.104034,250.358307 383.546783,250.190018 382.992584,250.014160 
	C382.995605,250.006622 382.975464,250.019989 382.983093,249.645264 
	C382.999756,248.521790 383.008759,247.773041 383.017761,247.024277 
	C383.455170,247.021088 383.892639,247.017883 384.712646,247.348572 
	C386.068390,247.461884 387.041565,247.241318 388.014740,247.020752 
	C396.690460,247.021820 405.366180,247.022888 414.565857,247.417053 
	C416.060272,250.206299 417.030731,252.602463 418.001221,254.998611 
	C418.003479,261.686096 418.005768,268.373566 418.002594,275.529297 
M387.968262,271.178680 
	C387.563660,271.273438 387.159088,271.368225 386.754486,271.463013 
	C386.843048,271.583344 386.947021,271.815369 387.017700,271.805634 
	C387.428497,271.748993 387.831451,271.635590 387.968262,271.178680 
z"/>
          <path fill="#035AAC" opacity="1.000000" stroke="none"
            d="
M328.834534,274.967041 
	C326.799316,274.980133 324.764099,274.993225 322.368011,274.999817 
	C322.002075,270.306274 321.997009,265.619263 322.380951,260.381042 
	C324.253296,256.734161 325.592041,253.651016 322.001221,250.996933 
	C321.843018,250.440277 321.684814,249.883621 321.290100,249.149963 
	C321.019318,248.556061 320.985046,248.139175 321.040863,247.421356 
	C331.873352,247.083160 342.615936,246.999252 353.357910,247.051422 
	C355.301208,247.060852 357.241791,247.628830 359.541138,248.311218 
	C357.281403,249.827148 354.726807,251.152908 352.034912,252.079895 
	C344.326996,254.734192 342.523407,261.313477 348.442413,266.773132 
	C350.543274,268.710938 353.782959,269.356445 356.255737,270.970367 
	C357.739807,271.938934 358.746582,273.638824 359.671417,275.028931 
	C358.979126,275.067566 358.583252,275.087311 357.801727,275.037415 
	C350.984344,274.374359 344.553925,273.765533 338.117432,273.229126 
	C337.764404,273.199707 337.361115,273.773132 336.719513,274.018005 
	C336.283539,273.936859 336.109283,273.903656 335.721954,273.628784 
	C333.603912,273.053894 331.431488,270.703613 329.878540,274.320312 
	C329.434204,274.673004 329.134369,274.820007 328.834534,274.967041 
z"/>
          <path fill="#105B9E" opacity="1.000000" stroke="none"
            d="
M359.967834,275.010040 
	C358.746582,273.638824 357.739807,271.938934 356.255737,270.970367 
	C353.782959,269.356445 350.543274,268.710938 348.442413,266.773132 
	C342.523407,261.313477 344.326996,254.734192 352.034912,252.079895 
	C354.726807,251.152908 357.281403,249.827148 359.965271,248.317810 
	C367.380157,247.634964 374.728485,247.317795 382.547272,247.012451 
	C383.008759,247.773041 382.999756,248.521790 382.625916,249.644302 
	C381.161407,250.292648 380.061798,250.567261 378.660187,250.860840 
	C377.966461,250.788055 377.574677,250.696289 377.265930,250.386932 
	C376.534119,250.124039 375.719330,250.078705 374.697357,250.024567 
	C374.490204,250.015793 374.076385,249.989563 373.907532,249.768768 
	C373.171631,249.729385 372.604523,249.910812 372.006592,250.240112 
	C371.975739,250.387970 371.941650,250.688141 371.577026,250.739227 
	C370.477356,250.834900 369.742340,250.879486 368.994354,250.750885 
	C368.981415,250.577698 368.941345,250.232681 368.891052,250.028259 
	C368.579346,249.946152 368.317932,250.068481 367.746521,250.407547 
	C363.293060,252.092377 359.149628,253.560471 354.627258,255.036041 
	C352.593353,255.740814 350.808655,256.232513 349.310272,257.177887 
	C346.106720,259.199158 345.687836,261.589294 349.104919,264.381287 
	C354.183716,266.768036 359.088287,268.846069 364.381531,270.984497 
	C365.561523,271.096222 366.352814,271.147583 367.138275,271.550140 
	C367.086700,272.602020 367.040955,273.302643 366.997620,274.001648 
	C367.000000,274.000000 367.003418,273.996399 366.595947,273.976410 
	C364.114929,274.307648 362.041382,274.658844 359.967834,275.010040 
z"/>
          <path fill="#105B9E" opacity="1.000000" stroke="none"
            d="
M418.009094,254.572693 
	C417.030731,252.602463 416.060272,250.206299 415.032959,247.424011 
	C419.685120,247.826828 417.467499,251.578613 418.009094,254.572693 
z"/>
          <path fill="#105B9E" opacity="1.000000" stroke="none"
            d="
M387.636261,247.009979 
	C387.041565,247.241318 386.068390,247.461884 385.040771,247.351074 
	C385.743469,247.012863 386.500610,247.006027 387.636261,247.009979 
z"/>
          <path fill="#13924B" opacity="1.000000" stroke="none"
            d="
M139.512772,246.021149 
	C140.875565,247.156281 141.611420,248.489578 142.706757,249.381302 
	C146.887527,252.784897 152.908020,254.450821 153.538986,261.119843 
	C153.703018,262.853668 153.813904,264.592560 153.635468,266.626678 
	C149.760895,262.958984 145.722000,259.315826 142.772400,254.938629 
	C139.767532,250.479431 135.332062,249.646805 130.978607,249.209579 
	C127.564331,248.866669 124.217499,250.570465 123.515465,255.021423 
	C121.063354,255.032364 119.044289,255.037231 117.025223,255.042114 
	C118.130379,249.683533 122.558517,246.466248 129.718140,246.047104 
	C132.819031,245.865555 135.939423,246.016556 139.512772,246.021149 
z"/>
          <path fill="#4F7855" opacity="1.000000" stroke="none"
            d="
M116.792656,255.305374 
	C119.044289,255.037231 121.063354,255.032364 123.843872,255.168823 
	C126.380936,256.217163 128.156540,257.124146 129.566956,258.072754 
	C128.590866,258.951324 127.625603,259.720459 127.449295,260.640717 
	C127.160614,262.147430 127.737976,263.854706 127.320747,265.295105 
	C126.408012,268.446014 127.974808,268.996185 130.870850,268.940491 
	C131.491119,269.010895 131.738373,269.046326 131.856567,269.283264 
	C131.504044,269.723206 131.236893,269.892822 130.717529,270.116669 
	C130.245697,270.442169 130.046738,270.695251 129.533340,271.005554 
	C126.471878,272.007263 123.789169,273.002411 121.073624,273.605469 
	C117.752731,271.804901 114.464676,270.396454 111.147720,268.700989 
	C111.090622,268.030426 111.062408,267.646820 111.416092,267.253601 
	C113.019135,267.458374 114.240273,267.672760 115.470818,267.888794 
	C116.935616,258.577850 116.694321,258.051727 111.273880,258.179199 
	C111.195732,258.089966 111.015587,257.935638 111.015587,257.935638 
	C111.015587,257.935638 111.065796,257.477692 111.109604,257.251282 
	C112.955643,256.539459 114.757866,256.054047 116.792656,255.305374 
M124.853119,264.151489 
	C123.893944,262.816803 122.934776,261.482117 121.975609,260.147430 
	C121.047028,261.366821 120.118439,262.586182 119.189857,263.805573 
	C120.957939,264.146729 122.726021,264.487915 124.853119,264.151489 
z"/>
          <path fill="#543F2E" opacity="1.000000" stroke="none"
            d="
M107.184319,257.824341 
	C108.205292,257.873138 109.226265,257.921967 110.631409,257.953217 
	C111.015587,257.935638 111.195732,258.089966 111.127426,258.495789 
	C110.185257,259.549408 109.311394,260.197235 108.437538,260.845032 
	C107.927818,259.927826 107.418106,259.010620 106.976868,258.025635 
	C107.045341,257.957825 107.184319,257.824341 107.184319,257.824341 
z"/>
          <path fill="#543F2E" opacity="1.000000" stroke="none"
            d="
M103.950806,256.944489 
	C104.057907,256.626160 104.251503,256.374390 104.744843,256.075867 
	C105.629379,256.386108 106.300682,256.809631 107.078148,257.528748 
	C107.184319,257.824341 107.045341,257.957825 106.643196,257.998962 
	C105.477631,257.674896 104.714218,257.309692 103.950806,256.944489 
z"/>
          <path fill="#105B9E" opacity="1.000000" stroke="none"
            d="
M321.994568,251.447189 
	C325.592041,253.651016 324.253296,256.734161 322.384399,259.914917 
	C321.995148,257.299164 321.991516,254.598297 321.994568,251.447189 
z"/>
          <path fill="#285465" opacity="1.000000" stroke="none"
            d="
M462.958099,180.462372 
	C460.810333,181.038818 458.562408,181.248398 455.576538,181.672165 
	C451.859314,182.117691 448.880066,182.349030 445.861389,182.471649 
	C445.821960,182.362930 445.859070,182.134644 446.058899,181.859833 
	C447.307281,181.005051 448.355835,180.425064 450.351746,179.321075 
	C448.134308,179.159714 447.037567,179.079910 445.940857,179.000092 
	C451.732147,177.013641 457.353271,177.655334 462.958099,180.462372 
z"/>
          <path fill="#1A6F8A" opacity="1.000000" stroke="none"
            d="
M445.545349,179.006439 
	C447.037567,179.079910 448.134308,179.159714 450.351746,179.321075 
	C448.355835,180.425064 447.307281,181.005051 445.716644,181.907104 
	C444.127838,182.515015 443.081055,182.800873 442.034302,183.086716 
	C440.330872,182.690918 438.627441,182.295120 436.747528,181.546875 
	C439.430664,180.467224 442.290253,179.739990 445.545349,179.006439 
z"/>
          <path fill="#457FAE" opacity="1.000000" stroke="none"
            d="
M359.671417,275.028931 
	C362.041382,274.658844 364.114929,274.307648 366.623749,274.219330 
	C367.169159,274.768585 367.279327,275.054962 367.389465,275.341309 
	C367.509064,275.242981 367.741760,275.072845 367.731598,275.056885 
	C367.502197,274.695496 367.246216,274.350983 366.995209,274.003296 
	C367.040955,273.302643 367.086700,272.602020 367.519165,271.480194 
	C368.961212,271.010101 370.016510,270.961182 371.091064,270.965027 
	C371.110321,271.017761 371.135406,271.127258 371.093750,271.475464 
	C372.716400,273.569427 374.380707,275.315216 376.054810,277.439270 
	C376.014740,278.573975 375.964874,279.330414 375.915009,280.086823 
	C373.868408,281.324585 371.669159,283.988434 370.315186,278.963928 
	C369.286346,280.220673 368.538208,281.134552 367.602722,282.277283 
	C366.325470,281.059021 365.299622,280.080566 364.338745,279.164093 
	C357.199829,284.025421 349.558990,281.612732 342.145020,281.513062 
	C340.092285,281.485474 338.037201,281.724945 335.982147,281.748016 
	C333.844788,281.772034 331.706177,281.682465 329.568115,281.642578 
	C329.622681,280.924103 329.677216,280.205658 330.282349,279.250244 
	C332.126587,278.891388 333.420288,278.769470 334.713959,278.647552 
	C334.818695,278.286255 334.923431,277.924957 335.028168,277.563629 
	C334.066254,277.257263 333.104340,276.950928 332.142395,276.644562 
	C332.475433,276.224091 332.808472,275.803650 333.141510,275.383209 
	C334.504944,275.581757 335.868378,275.780334 337.539062,276.013367 
	C341.914459,276.380798 345.982574,276.713745 350.175598,277.327759 
	C351.519257,277.751831 352.737976,277.894806 354.253998,277.999573 
	C355.763306,277.009949 356.975342,276.058502 358.187378,275.107056 
	C358.583252,275.087311 358.979126,275.067566 359.671417,275.028931 
z"/>
          <path fill="#649BC6" opacity="1.000000" stroke="none"
            d="
M406.009155,276.451233 
	C406.784607,276.041534 407.520691,275.977997 408.620392,275.846130 
	C410.857544,276.692047 412.731140,277.606293 415.168549,278.795654 
	C411.049408,283.187592 407.929047,284.112244 404.126801,280.471313 
	C404.718872,279.291077 405.344330,278.044220 406.009155,276.451233 
z"/>
          <path fill="#649BC6" opacity="1.000000" stroke="none"
            d="
M376.119507,280.388306 
	C375.964874,279.330414 376.014740,278.573975 376.220642,277.142883 
	C377.252258,276.258728 378.127838,276.049255 379.003418,275.839783 
	C379.755951,275.866333 380.508453,275.892883 382.069275,275.921448 
	C384.914429,275.898560 386.951263,275.873718 388.988129,275.848877 
	C389.718658,275.902008 390.449219,275.955139 391.539978,276.066040 
	C391.048279,277.072144 390.347321,278.572418 389.313293,278.855316 
	C387.076050,279.467407 384.668121,279.455780 382.076691,279.125916 
	C381.277191,278.254486 380.731049,277.956329 380.184875,277.658203 
	C379.817078,278.794495 379.449280,279.930786 379.081482,281.067078 
	C378.162354,280.941315 377.243195,280.815552 376.119507,280.388306 
z"/>
          <path fill="#649BC6" opacity="1.000000" stroke="none"
            d="
M337.231812,275.978882 
	C335.868378,275.780334 334.504944,275.581757 333.141510,275.383209 
	C332.808472,275.803650 332.475433,276.224091 332.142395,276.644562 
	C333.104340,276.950928 334.066254,277.257263 335.028168,277.563629 
	C334.923431,277.924957 334.818695,278.286255 334.713959,278.647552 
	C333.420288,278.769470 332.126587,278.891388 330.449982,278.984192 
	C329.660614,277.864105 329.254211,276.773132 328.841187,275.324585 
	C329.134369,274.820007 329.434204,274.673004 330.302063,274.300903 
	C332.558411,274.007324 334.246735,273.938873 335.935028,273.870422 
	C336.109283,273.903656 336.283539,273.936859 336.764984,274.336060 
	C337.125397,275.127686 337.178619,275.553284 337.231812,275.978882 
z"/>
          <path fill="#457FAE" opacity="1.000000" stroke="none"
            d="
M379.367554,281.009644 
	C379.449280,279.930786 379.817078,278.794495 380.184875,277.658203 
	C380.731049,277.956329 381.277191,278.254486 381.831421,278.886353 
	C381.110840,279.797455 380.382202,280.374817 379.367554,281.009644 
z"/>
          <path fill="#649BC6" opacity="1.000000" stroke="none"
            d="
M398.242584,278.876862 
	C398.686890,278.195526 399.204773,277.837769 399.722656,277.480042 
	C399.967926,278.143311 400.213165,278.806610 400.458435,279.469910 
	C399.744324,279.380096 399.030243,279.290283 398.242584,278.876862 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M442.012756,183.432129 
	C443.081055,182.800873 444.127838,182.515015 445.516846,182.181900 
	C445.859070,182.134644 445.821960,182.362930 445.858978,182.790131 
	C446.540497,184.459625 447.184967,185.701904 447.829468,186.944183 
	C446.598175,188.072937 445.366913,189.201721 444.074585,190.673737 
	C443.339447,188.603851 442.665344,186.190704 442.012756,183.432129 
z"/>
          <path fill="#BCBF77" opacity="1.000000" stroke="none"
            d="
M447.897583,187.287994 
	C447.184967,185.701904 446.540497,184.459625 445.898407,182.898849 
	C448.880066,182.349030 451.859314,182.117691 455.265015,181.944901 
	C454.999268,183.108307 454.307037,184.213181 453.414764,185.568604 
	C451.961212,187.409210 450.707703,188.999252 449.077637,191.066940 
	C448.603790,189.603134 448.284760,188.617477 447.897583,187.287994 
z"/>
          <path fill="#144A2E" opacity="1.000000" stroke="none"
            d="
M159.024185,277.735718 
	C160.798996,277.792206 162.573792,277.848694 164.681366,277.970215 
	C165.340149,280.401855 165.666153,282.768524 166.016556,285.531982 
	C164.415237,286.601166 162.789551,287.273529 161.171341,288.276184 
	C145.088409,288.537323 128.998001,288.468170 112.451691,288.274475 
	C111.599899,284.179291 107.959564,285.193268 105.737961,283.947937 
	C101.848892,281.767883 98.024170,279.383301 94.552727,276.608643 
	C93.642708,275.881287 94.131371,273.403992 94.482147,271.364990 
	C99.633514,270.654144 104.280418,270.308594 108.897461,270.325897 
	C107.496590,272.690247 106.125572,274.691772 104.040710,277.735443 
	C108.550667,278.286407 111.754181,278.677795 115.005455,279.418884 
	C116.960587,283.542847 119.810547,285.246277 124.245361,285.076202 
	C130.924240,284.820099 137.714432,284.524902 144.293716,285.432434 
	C151.022705,286.360565 153.428513,287.070343 159.024185,277.735718 
z"/>
          <path fill="#543F2E" opacity="1.000000" stroke="none"
            d="
M108.927330,269.963074 
	C104.280418,270.308594 99.633514,270.654144 94.536026,271.019897 
	C92.421478,270.292664 90.757500,269.545166 89.424248,268.414795 
	C90.712746,267.685150 91.670525,267.338379 92.628296,266.991608 
	C90.285957,264.167908 94.733543,260.086304 90.877815,257.542969 
	C94.403648,257.153137 97.929482,256.763306 101.780945,256.996643 
	C102.757431,259.937408 103.700203,262.220520 103.979141,264.582092 
	C104.273117,267.070953 104.990837,268.302460 107.690804,267.629333 
	C108.768692,267.360596 109.917618,267.376801 111.034195,267.263245 
	C111.062408,267.646820 111.090622,268.030426 110.823685,268.728271 
	C109.994804,269.349365 109.461067,269.656219 108.927330,269.963074 
z"/>
          <path fill="#A44138" opacity="1.000000" stroke="none"
            d="
M111.416092,267.253632 
	C109.917618,267.376801 108.768692,267.360596 107.690804,267.629333 
	C104.990837,268.302460 104.273117,267.070953 103.979141,264.582092 
	C103.700203,262.220520 102.757431,259.937408 102.087021,257.265686 
	C102.486038,256.923370 102.904610,256.935181 103.636993,256.945740 
	C104.714218,257.309692 105.477631,257.674896 106.574722,258.066772 
	C107.418106,259.010620 107.927818,259.927826 108.437538,260.845032 
	C109.311394,260.197235 110.185257,259.549408 111.205582,258.585022 
	C116.694321,258.051727 116.935616,258.577850 115.470818,267.888794 
	C114.240273,267.672760 113.019135,267.458374 111.416092,267.253632 
z"/>
          <path fill="#A44138" opacity="1.000000" stroke="none"
            d="
M90.558258,257.658264 
	C94.733543,260.086304 90.285957,264.167908 92.628296,266.991608 
	C91.670525,267.338379 90.712746,267.685150 89.361832,268.071472 
	C88.384430,267.583862 87.800163,267.056671 87.078827,265.938995 
	C87.035385,263.302887 87.129021,261.257202 87.222649,259.211548 
	C88.227997,258.732239 89.233345,258.252899 90.558258,257.658264 
z"/>
          <path fill="#543F2E" opacity="1.000000" stroke="none"
            d="
M86.857864,259.308105 
	C87.129021,261.257202 87.035385,263.302887 86.896355,265.703430 
	C83.798607,263.998199 84.611061,261.730194 86.857864,259.308105 
z"/>
          <path fill="#285465" opacity="1.000000" stroke="none"
            d="
M248.172791,380.004822 
	C255.143402,380.317688 262.114014,380.630585 269.455566,381.365906 
	C268.829163,382.190857 267.848785,382.890167 266.831696,382.948395 
	C259.408264,383.373474 251.945847,383.378265 244.567734,384.179077 
	C242.264038,384.429108 240.181747,386.718994 237.636841,388.083984 
	C236.164993,388.667542 235.054398,389.242188 233.630402,390.053741 
	C233.251526,391.853119 233.186096,393.415619 232.789215,394.996582 
	C232.016724,395.027405 231.575668,395.039764 230.830795,394.807220 
	C229.665283,392.446075 228.803589,390.329803 227.941895,388.213562 
	C225.511734,390.262604 223.081573,392.311615 220.041382,394.875031 
	C224.988495,398.361603 228.984406,401.177826 232.920898,404.344604 
	C233.254135,405.469543 233.646820,406.243958 234.039490,407.018372 
	C231.300583,407.009247 228.561676,407.000092 225.416977,406.991882 
	C225.011185,406.992798 225.000153,406.998535 225.003189,406.703247 
	C224.998276,406.020233 224.990341,405.632507 225.373505,405.178894 
	C229.541580,402.510010 225.135300,402.715210 224.510147,401.993195 
	C222.074036,399.179779 219.337646,396.626343 216.831223,393.614227 
	C218.638046,391.131805 220.326569,389.011383 222.377502,386.882874 
	C227.366577,385.593414 231.987686,384.291382 236.621719,383.037140 
	C240.464172,381.997131 244.321854,381.013428 248.172791,380.004822 
z"/>
          <path fill="#649BC6" opacity="1.000000" stroke="none"
            d="
M234.412537,407.000427 
	C233.646820,406.243958 233.254135,405.469543 233.304749,404.357422 
	C234.517212,404.015594 235.286407,404.011475 236.055588,404.007324 
	C239.916046,404.677246 243.804413,405.221771 247.624771,406.071930 
	C249.435974,406.475006 251.117249,407.462006 252.887421,408.550110 
	C248.934601,408.565887 244.951752,408.215942 240.221954,407.744629 
	C238.649841,407.518311 237.824677,407.413361 236.999512,407.308411 
	C236.261536,407.199768 235.523544,407.091125 234.412537,407.000427 
z"/>
          <path fill="#285465" opacity="1.000000" stroke="none"
            d="
M237.071350,407.611176 
	C237.824677,407.413361 238.649841,407.518311 239.778168,407.720947 
	C239.101959,407.850372 238.122559,407.882172 237.071350,407.611176 
z"/>
          <path fill="#175C77" opacity="1.000000" stroke="none"
            d="
M236.052673,403.617218 
	C235.286407,404.011475 234.517212,404.015594 233.364182,404.006897 
	C228.984406,401.177826 224.988495,398.361603 220.041382,394.875031 
	C223.081573,392.311615 225.511734,390.262604 227.941895,388.213562 
	C228.803589,390.329803 229.665283,392.446075 230.880356,395.201050 
	C231.575653,396.160706 231.882187,396.709106 232.264786,396.768616 
	C238.734390,397.775116 245.856476,394.835602 251.651337,400.004822 
	C251.835526,400.169189 252.291962,400.032867 252.621414,400.029205 
	C259.116180,399.957001 265.610962,399.883636 272.559021,399.851379 
	C277.216644,399.892273 281.421021,399.892273 285.625366,399.892273 
	C285.728699,399.393097 285.832001,398.893951 285.935333,398.394775 
	C284.950043,398.011414 283.964722,397.628082 282.959412,396.891541 
	C283.988281,395.053772 285.037170,393.569183 286.444305,392.068451 
	C289.615356,394.270874 292.428192,396.489441 295.240997,398.708008 
	C295.170502,399.296906 295.100006,399.885803 295.029510,400.474701 
	C292.595734,401.314789 290.205963,402.670959 287.718628,402.883026 
	C283.129364,403.274323 278.475739,402.852264 273.864716,403.083527 
	C272.539612,403.149994 271.268585,404.294922 269.972778,404.945374 
	C268.598755,404.952423 267.224762,404.959473 265.349762,404.586243 
	C262.233704,404.143372 259.618652,404.080719 257.003571,404.018066 
	C251.349014,403.190186 245.708206,402.230865 240.029205,401.636444 
	C238.772018,401.504822 237.379822,402.662872 236.052673,403.617218 
z"/>
          <path fill="#175C77" opacity="1.000000" stroke="none"
            d="
M237.998108,388.075104 
	C240.181747,386.718994 242.264038,384.429108 244.567734,384.179077 
	C251.945847,383.378265 259.408264,383.373474 266.831696,382.948395 
	C267.848785,382.890167 268.829163,382.190857 269.921814,381.391663 
	C273.037476,381.289429 276.057861,381.583923 279.476318,381.850037 
	C280.057678,381.863922 280.240967,381.906158 280.654419,382.336121 
	C279.562988,385.573364 278.241364,388.422913 276.592041,391.198547 
	C275.851868,390.952576 275.439453,390.780457 274.948059,390.310974 
	C273.577637,389.175385 272.288666,386.543579 270.603699,390.019165 
	C269.441437,390.046478 268.666687,390.062897 267.625519,389.828125 
	C265.905670,389.378845 264.452271,389.180756 262.744385,388.706665 
	C260.028076,388.593658 257.566254,388.756622 254.719467,388.929199 
	C252.687973,389.420105 251.041443,389.901428 249.019592,390.204498 
	C245.095535,389.375854 241.546829,388.725494 237.998108,388.075104 
z"/>
          <path fill="#1A6F8A" opacity="1.000000" stroke="none"
            d="
M276.919769,391.272430 
	C278.241364,388.422913 279.562988,385.573364 280.933777,382.375153 
	C289.480255,384.874329 293.821838,391.789825 297.511444,399.209442 
	C298.794250,401.789093 297.826996,403.869659 294.647675,404.025757 
	C286.739990,404.414001 278.826233,404.677979 270.443848,404.968628 
	C271.268585,404.294922 272.539612,403.149994 273.864716,403.083527 
	C278.475739,402.852264 283.129364,403.274323 287.718628,402.883026 
	C290.205963,402.670959 292.595734,401.314789 295.029510,400.474701 
	C295.100006,399.885803 295.170502,399.296906 295.240997,398.708008 
	C292.428192,396.489441 289.615356,394.270874 286.293365,391.776581 
	C283.478180,392.110199 281.172180,392.719543 278.604706,393.186920 
	C277.868744,392.454102 277.394257,391.863281 276.919769,391.272430 
z"/>
          <path fill="#1A6F8A" opacity="1.000000" stroke="none"
            d="
M257.279114,404.306946 
	C259.618652,404.080719 262.233704,404.143372 264.937592,404.578857 
	C262.535828,404.833069 260.045258,404.714447 257.279114,404.306946 
z"/>
          <path fill="#B5DBE2" opacity="1.000000" stroke="none"
            d="
M216.712936,393.976227 
	C219.337646,396.626343 222.074036,399.179779 224.510147,401.993195 
	C225.135300,402.715210 229.541580,402.510010 225.040695,404.999939 
	C222.890228,404.461243 221.463684,404.035645 220.037140,403.610046 
	C216.925323,401.818756 213.813507,400.027466 210.714142,397.775085 
	C210.726593,397.313995 211.003693,396.996246 211.349823,396.984070 
	C213.368286,395.973328 215.040619,394.974792 216.712936,393.976227 
z"/>
          <path fill="#3EA1AC" opacity="1.000000" stroke="none"
            d="
M207.305328,396.991455 
	C205.313538,391.828461 206.072449,389.164124 209.894562,388.051575 
	C210.110977,388.549835 209.985428,388.983368 209.640259,389.117798 
	C209.149567,391.855530 209.004044,394.458771 208.858521,397.062042 
	C208.444336,397.044250 208.030151,397.026428 207.305328,396.991455 
z"/>
          <path fill="#B5DBE2" opacity="1.000000" stroke="none"
            d="
M209.116760,397.169312 
	C209.004044,394.458771 209.149567,391.855530 209.636505,389.532166 
	C210.319839,392.206787 210.661758,394.601532 211.003693,396.996246 
	C211.003693,396.996246 210.726593,397.313995 210.576843,397.467651 
	C210.076401,397.506409 209.725708,397.391510 209.116760,397.169312 
z"/>
          <path fill="#175C77" opacity="1.000000" stroke="none"
            d="
M220.063080,403.986542 
	C221.463684,404.035645 222.890228,404.461243 224.649597,405.065796 
	C224.990341,405.632507 224.998276,406.020233 225.006653,406.698669 
	C223.367722,406.113892 221.728363,405.238464 220.063080,403.986542 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M247.764099,379.979889 
	C244.321854,381.013428 240.464172,381.997131 236.621719,383.037140 
	C231.987686,384.291382 227.366577,385.593414 222.325333,386.573975 
	C221.276550,385.848022 220.642319,385.422882 219.982117,384.697540 
	C219.924103,384.199005 219.892075,384.000641 220.029541,383.725464 
	C220.199036,383.648590 220.570938,383.633636 220.931671,383.746582 
	C222.221161,383.369476 223.149887,382.879364 224.438538,382.261963 
	C226.892807,381.503479 228.987137,380.872284 231.081482,380.241119 
	C231.806213,380.173950 232.530945,380.106812 233.596542,379.907837 
	C238.410065,379.835693 242.882736,379.895325 247.764099,379.979889 
z"/>
          <path fill="#F0FAFC" opacity="1.000000" stroke="none"
            d="
M220.008087,384.997742 
	C220.642319,385.422882 221.276550,385.848022 221.962952,386.582062 
	C220.326569,389.011383 218.638046,391.131805 216.831223,393.614227 
	C215.040619,394.974792 213.368286,395.973328 211.349823,396.984070 
	C210.661758,394.601532 210.319839,392.206787 209.981674,389.397705 
	C209.985428,388.983368 210.110977,388.549835 210.084549,388.325714 
	C210.389587,388.006439 210.721069,387.911316 211.700226,387.816803 
	C214.901291,386.877533 217.454681,385.937622 220.008087,384.997742 
M212.846741,393.305084 
	C212.939316,393.304840 213.031891,393.304596 212.846741,393.305084 
z"/>
          <path fill="#3EA1AC" opacity="1.000000" stroke="none"
            d="
M220.570923,383.633636 
	C220.570938,383.633636 220.199036,383.648590 219.659119,383.736694 
	C216.782684,384.612183 214.446152,385.399567 212.109634,386.186920 
	C214.968216,384.665253 217.826797,383.143616 220.832062,382.158752 
	C220.842804,383.008240 220.706863,383.320953 220.570923,383.633636 
z"/>
          <path fill="#3EA1AC" opacity="1.000000" stroke="none"
            d="
M230.860947,379.917297 
	C228.987137,380.872284 226.892807,381.503479 224.161438,382.098267 
	C223.333771,381.964447 223.143127,381.866974 222.952484,381.769501 
	C224.735199,378.288696 227.622086,378.708862 230.860947,379.917297 
z"/>
          <path fill="#B5DBE2" opacity="1.000000" stroke="none"
            d="
M212.007111,386.428192 
	C214.446152,385.399567 216.782684,384.612183 219.489624,383.813538 
	C219.892075,384.000641 219.924103,384.199005 219.982117,384.697510 
	C217.454681,385.937622 214.901291,386.877533 211.947678,387.732513 
	C211.666504,387.321564 211.785553,386.995514 212.007111,386.428192 
z"/>
          <path fill="#B5DBE2" opacity="1.000000" stroke="none"
            d="
M222.644257,381.879974 
	C223.143127,381.866974 223.333771,381.964447 223.801514,382.225586 
	C223.149887,382.879364 222.221161,383.369476 220.931671,383.746582 
	C220.706863,383.320953 220.842804,383.008240 221.011200,382.442383 
	C221.474442,382.122925 221.905243,382.056671 222.644257,381.879974 
z"/>
          <path fill="#1189A2" opacity="1.000000" stroke="none"
            d="
M257.167786,375.303436 
	C258.131683,375.182220 259.337982,375.332458 260.768097,375.772491 
	C259.797974,375.899811 258.604095,375.737335 257.167786,375.303436 
z"/>
          <path fill="#1189A2" opacity="1.000000" stroke="none"
            d="
M264.303650,375.256348 
	C266.694061,375.065704 268.800110,375.065704 270.906158,375.065704 
	C270.892853,375.406921 270.879547,375.748108 270.866272,376.089325 
	C268.583954,375.875214 266.301636,375.661102 264.303650,375.256348 
z"/>
          <path fill="#FCEDEC" opacity="1.000000" stroke="none"
            d="
M452.156311,296.786255 
	C456.062927,296.865204 458.135406,298.814880 457.016388,302.070892 
	C456.534393,303.473358 453.648193,304.379974 451.704529,304.774750 
	C451.001373,304.917511 448.952393,302.737396 449.106873,301.916260 
	C449.443573,300.126709 450.800690,298.529144 452.156311,296.786255 
z"/>
          <path fill="#2296F2" opacity="1.000000" stroke="none"
            d="
M131.660904,61.363914 
	C133.780014,64.548119 135.722458,67.407654 137.607880,70.304283 
	C140.193710,74.276962 139.204834,78.389999 134.693527,79.255104 
	C132.779510,79.622147 129.809357,77.288231 128.127991,75.444344 
	C125.149879,72.178383 122.798347,68.342590 120.168518,64.757057 
	C117.927124,61.701149 118.759224,59.058342 121.541771,57.060909 
	C124.115143,55.213631 126.710266,55.136822 128.782990,58.127262 
	C129.529465,59.204254 130.558655,60.085308 131.660904,61.363914 
z"/>
          <path fill="#58A250" opacity="1.000000" stroke="none"
            d="
M56.715565,304.913940 
	C55.952812,307.761566 54.571411,309.971497 51.804688,308.577759 
	C50.441074,307.890839 49.421722,305.516998 49.283421,303.821869 
	C49.213581,302.965881 51.729042,300.935730 52.587914,301.176453 
	C54.163952,301.618134 55.378895,303.348358 56.715565,304.913940 
z"/>
          <path fill="#469AD7" opacity="1.000000" stroke="none"
            d="
M224.533279,129.003387 
	C236.701492,129.311661 248.404617,129.747482 260.111267,129.885422 
	C264.697937,129.939468 269.293213,129.071655 273.883148,129.086136 
	C278.637665,129.101120 283.390411,129.676636 288.573181,130.002197 
	C296.988739,131.480148 302.610229,136.545654 307.017639,142.822418 
	C308.912384,145.520798 309.316528,149.265869 310.681763,153.178345 
	C310.977142,154.880692 310.988586,155.940125 311.000000,156.999542 
	C310.999268,159.042160 310.998535,161.084763 310.994141,164.033142 
	C310.999969,171.624680 311.009491,178.310455 311.019012,184.996216 
	C311.015564,202.117386 310.906525,219.239471 311.051941,236.359375 
	C311.127869,245.297852 311.966431,254.234543 311.909271,263.168518 
	C311.780884,283.241608 311.356445,303.313507 310.954468,323.383911 
	C310.770172,332.586426 304.430786,342.640656 297.364349,344.586456 
	C304.875641,338.017090 309.026978,329.975800 309.032745,320.013702 
	C309.063904,266.207764 309.144287,212.401443 308.957184,158.596115 
	C308.900909,142.408707 296.970703,131.100967 280.853607,131.087631 
	C260.197693,131.070526 239.541733,131.105606 218.885849,131.077652 
	C202.323456,131.055237 190.231415,142.524002 190.180511,159.037643 
	C190.015747,212.510391 189.968933,265.984039 190.083450,319.456848 
	C190.122391,337.641479 201.470627,348.681610 219.561371,348.755096 
	C236.719299,348.824768 253.879822,348.922180 271.035126,348.696716 
	C279.779602,348.581818 288.744080,349.285309 296.876678,344.818970 
	C295.631165,346.150269 294.594604,348.266144 293.100433,348.664062 
	C288.815033,349.805573 284.349854,350.829590 279.945343,350.872620 
	C258.121307,351.085876 236.290268,351.205902 214.469742,350.856628 
	C204.932373,350.704010 198.182343,345.712250 192.740631,337.509674 
	C187.777161,330.028046 188.040726,322.288239 188.035339,314.268890 
	C188.000946,262.952545 188.088898,211.636063 187.991135,160.319885 
	C187.956604,142.194931 201.209244,127.798698 219.572006,129.005859 
	C221.064896,129.104004 222.570114,129.014221 224.533279,129.003387 
z"/>
          <path fill="#2B97EB" opacity="1.000000" stroke="none"
            d="
M311.311920,184.700150 
	C311.009491,178.310455 310.999969,171.624680 310.999695,164.470840 
	C311.207581,170.803207 311.406219,177.603638 311.311920,184.700150 
z"/>
          <path fill="#2B97EB" opacity="1.000000" stroke="none"
            d="
M311.261780,156.788666 
	C310.988586,155.940125 310.977142,154.880692 310.954834,153.424896 
	C311.137146,154.211594 311.330353,155.394684 311.261780,156.788666 
z"/>
          <path fill="#1FAC5C" opacity="1.000000" stroke="none"
            d="
M129.932144,258.031158 
	C128.156540,257.124146 126.380936,256.217163 124.276917,255.162750 
	C124.217499,250.570465 127.564331,248.866669 130.978607,249.209579 
	C135.332062,249.646805 139.767532,250.479431 142.772400,254.938629 
	C145.722000,259.315826 149.760895,262.958984 153.657196,266.984924 
	C154.102188,267.354126 154.288284,267.606140 154.771271,268.149872 
	C154.991608,268.498108 155.004532,268.970703 155.004532,268.970703 
	C154.559677,268.989899 154.114838,269.009094 152.972107,269.023163 
	C150.753937,269.230743 149.233643,269.443481 147.713364,269.656250 
	C148.402237,270.850311 149.044556,272.075439 149.807449,273.220154 
	C150.046097,273.578247 150.609787,273.719696 151.024689,273.960327 
	C151.018936,274.150635 151.013168,274.340942 150.734467,274.989197 
	C148.661392,275.973846 146.861267,276.500519 144.998108,276.642395 
	C144.193680,272.337769 141.002472,272.277008 138.507843,273.168365 
	C134.624359,274.555939 132.717850,272.789062 130.926086,269.993683 
	C131.236893,269.892822 131.504044,269.723206 132.184540,269.233978 
	C133.741699,268.287109 134.841858,267.591095 136.309937,266.960754 
	C137.600342,267.231659 138.522812,267.436890 139.471542,267.647949 
	C139.962433,265.611176 140.679306,263.864929 140.721207,262.102661 
	C140.759811,260.479279 140.073090,258.838684 139.705856,257.205658 
	C138.157471,257.841919 136.609070,258.478180 134.720642,258.948181 
	C133.162018,258.583496 131.943436,258.385040 130.522675,258.168091 
	C130.320511,258.149597 129.932144,258.031158 129.932144,258.031158 
z"/>
          <path fill="#13924B" opacity="1.000000" stroke="none"
            d="
M155.335083,268.980286 
	C155.004532,268.970703 154.991608,268.498108 154.981201,268.262207 
	C155.530838,268.184814 156.090881,268.343292 156.819458,268.746918 
	C156.547211,268.991333 156.106415,268.990601 155.335083,268.980286 
z"/>
          <path fill="#21613F" opacity="1.000000" stroke="none"
            d="
M158.896439,277.563477 
	C153.428513,287.070343 151.022705,286.360565 144.293716,285.432434 
	C137.714432,284.524902 130.924240,284.820099 124.245361,285.076202 
	C119.810547,285.246277 116.960587,283.542847 115.320511,279.264160 
	C116.731773,277.853729 117.875732,276.947784 119.362518,276.164062 
	C122.103264,276.841797 124.501160,277.397308 127.188736,278.149628 
	C131.446701,279.203888 135.385666,280.545227 139.391220,280.788025 
	C143.733459,281.051208 148.546402,282.031219 152.038330,277.947144 
	C152.038330,277.947144 152.009369,277.980865 152.043549,278.240417 
	C152.431976,278.846588 152.786240,279.193237 153.140503,279.539886 
	C153.069733,279.001984 152.998947,278.464111 152.928162,277.926208 
	C153.715103,277.622131 154.502060,277.318085 155.965790,276.999786 
	C157.098663,276.987335 157.554764,276.989105 158.010864,276.990875 
	C158.296051,277.062683 158.548660,277.196106 158.896439,277.563477 
z"/>
          <path fill="#13924B" opacity="1.000000" stroke="none"
            d="
M152.027496,277.618134 
	C148.546402,282.031219 143.733459,281.051208 139.391220,280.788025 
	C135.385666,280.545227 131.446701,279.203888 127.379326,277.914459 
	C127.842163,277.287903 128.404083,277.093323 129.419037,276.952454 
	C133.899612,277.484406 137.919205,278.076294 141.960419,278.360046 
	C142.957870,278.430084 144.025665,277.498383 145.061127,277.027161 
	C146.861267,276.500519 148.661392,275.973846 150.712585,275.273987 
	C151.314651,275.830200 151.665649,276.559662 152.027496,277.618134 
z"/>
          <path fill="#13924B" opacity="1.000000" stroke="none"
            d="
M151.276886,273.810791 
	C150.609787,273.719696 150.046097,273.578247 149.807449,273.220154 
	C149.044556,272.075439 148.402237,270.850311 147.713364,269.656250 
	C149.233643,269.443481 150.753937,269.230743 152.638580,269.040131 
	C153.010513,269.778503 153.018097,270.494720 153.055969,271.567505 
	C152.567200,272.503143 152.048157,273.082214 151.276886,273.810791 
z"/>
          <path fill="#26784B" opacity="1.000000" stroke="none"
            d="
M157.965698,276.695129 
	C157.554764,276.989105 157.098663,276.987335 156.299850,276.980164 
	C156.611603,276.782959 157.266052,276.591156 157.965698,276.695129 
z"/>
          <path fill="#13924B" opacity="1.000000" stroke="none"
            d="
M152.705017,277.943359 
	C152.998947,278.464111 153.069733,279.001984 153.140503,279.539886 
	C152.786240,279.193237 152.431976,278.846588 152.055969,278.228455 
	C152.034225,277.956940 152.481857,277.960510 152.705017,277.943359 
z"/>
          <path fill="#649BC6" opacity="1.000000" stroke="none"
            d="
M384.506256,269.799561 
	C383.891937,270.027100 383.277618,270.254700 382.128113,270.341614 
	C378.107117,270.509705 374.621246,270.818481 371.135406,271.127258 
	C371.135406,271.127258 371.110321,271.017761 371.276245,270.736328 
	C372.322937,269.998444 373.203705,269.541992 374.390778,269.234070 
	C377.760315,268.538727 380.823608,267.694794 384.218140,266.771515 
	C386.488678,265.358368 388.427917,264.024628 390.499146,262.600128 
	C388.795197,262.023926 387.489594,261.582428 386.578796,261.093628 
	C389.283325,260.023956 391.593048,259.001587 394.224304,258.185211 
	C394.316345,260.573456 394.086884,262.755707 393.857422,264.937988 
	C393.088501,265.231110 392.319580,265.524231 390.932861,266.042358 
	C388.378815,267.444763 386.442535,268.622162 384.506256,269.799561 
z"/>
          <path fill="#105B9E" opacity="1.000000" stroke="none"
            d="
M394.256958,264.959564 
	C394.086884,262.755707 394.316345,260.573456 394.198792,257.999878 
	C393.851776,257.608582 393.787262,257.238220 393.840088,256.941895 
	C393.912933,256.445892 393.932892,256.246246 393.952881,256.046600 
	C395.405182,256.046600 396.857483,256.046600 398.309814,256.046600 
	C398.676941,256.355408 399.044098,256.664215 399.411255,256.973022 
	C395.366394,258.588043 402.751770,265.102203 394.256958,264.959564 
z"/>
          <path fill="#105B9E" opacity="1.000000" stroke="none"
            d="
M371.093750,271.475464 
	C374.621246,270.818481 378.107117,270.509705 381.881226,270.373352 
	C380.934448,271.616180 379.699463,272.686554 378.240021,273.951477 
	C378.231079,273.934204 378.573181,274.595215 378.959351,275.548004 
	C378.127838,276.049255 377.252258,276.258728 376.210846,276.764587 
	C374.380707,275.315216 372.716400,273.569427 371.093750,271.475464 
z"/>
          <path fill="#286191" opacity="1.000000" stroke="none"
            d="
M378.962158,250.841858 
	C380.061798,250.567261 381.161407,250.292648 382.618256,250.019012 
	C382.975464,250.019989 382.995605,250.006622 383.158752,250.257599 
	C383.871460,250.685074 384.421051,250.861542 384.970642,251.038025 
	C387.292450,252.422211 389.614258,253.806396 391.954193,255.617569 
	C391.972321,256.044556 391.818787,256.233124 391.468689,256.146912 
	C387.066467,254.321106 383.014313,252.581482 378.962158,250.841858 
z"/>
          <path fill="#105B9E" opacity="1.000000" stroke="none"
            d="
M388.901001,275.489014 
	C386.951263,275.873718 384.914429,275.898560 382.443451,275.874847 
	C383.994720,272.833466 386.355347,273.503540 388.901001,275.489014 
z"/>
          <path fill="#105B9E" opacity="1.000000" stroke="none"
            d="
M384.927124,269.834320 
	C386.442535,268.622162 388.378815,267.444763 390.649139,266.177612 
	C391.097168,270.317474 387.298401,268.715942 384.927124,269.834320 
z"/>
          <path fill="#105B9E" opacity="1.000000" stroke="none"
            d="
M388.102661,271.360016 
	C387.831451,271.635590 387.428497,271.748993 387.017700,271.805634 
	C386.947021,271.815369 386.843048,271.583344 386.754486,271.463013 
	C387.159088,271.368225 387.563660,271.273438 388.102661,271.360016 
z"/>
          <path fill="#286191" opacity="1.000000" stroke="none"
            d="
M393.647949,256.023468 
	C393.932892,256.246246 393.912933,256.445892 393.566284,256.876221 
	C392.766022,256.815643 392.292389,256.524384 391.818787,256.233124 
	C391.818787,256.233124 391.972321,256.044556 392.051514,255.949921 
	C392.534790,255.903625 392.938904,255.951981 393.647949,256.023468 
z"/>
          <path fill="#105B9E" opacity="1.000000" stroke="none"
            d="
M384.815948,250.782303 
	C384.421051,250.861542 383.871460,250.685074 383.155701,250.265152 
	C383.546783,250.190018 384.104034,250.358307 384.815948,250.782303 
z"/>
          <path fill="#105B9E" opacity="1.000000" stroke="none"
            d="
M337.539062,276.013367 
	C337.178619,275.553284 337.125397,275.127686 337.026703,274.384033 
	C337.361115,273.773132 337.764404,273.199707 338.117432,273.229126 
	C344.553925,273.765533 350.984344,274.374359 357.801727,275.037445 
	C356.975342,276.058502 355.763306,277.009949 353.949799,277.836884 
	C352.249115,277.490509 351.149933,277.268616 350.050720,277.046692 
	C345.982574,276.713745 341.914459,276.380798 337.539062,276.013367 
z"/>
          <path fill="#105B9E" opacity="1.000000" stroke="none"
            d="
M335.721954,273.628784 
	C334.246735,273.938873 332.558411,274.007324 330.446594,274.095215 
	C331.431488,270.703613 333.603912,273.053894 335.721954,273.628784 
z"/>
          <path fill="#457FAE" opacity="1.000000" stroke="none"
            d="
M348.930725,264.072540 
	C345.687836,261.589294 346.106720,259.199158 349.310272,257.177887 
	C350.808655,256.232513 352.593353,255.740814 354.861877,255.294479 
	C359.853668,254.684967 364.231812,253.824463 368.921814,253.120514 
	C369.798309,254.285416 370.362976,255.293777 370.721313,256.525391 
	C368.659668,257.857452 366.804352,258.966248 364.588074,260.013184 
	C363.142303,260.312164 362.057495,260.673004 360.555786,261.046875 
	C358.002686,261.446869 355.866577,261.833832 353.590149,262.204041 
	C353.239380,262.280090 353.171753,262.435394 353.116394,262.984344 
	C352.907715,263.539520 352.829590,263.763580 352.465302,263.871399 
	C351.055664,263.445038 349.650269,260.249054 348.930725,264.072540 
z"/>
          <path fill="#ACA698" opacity="1.000000" stroke="none"
            d="
M370.927673,256.302155 
	C370.362976,255.293777 369.798309,254.285416 369.060059,252.797501 
	C368.926727,251.853333 368.967010,251.388702 369.007294,250.924072 
	C369.742340,250.879486 370.477356,250.834900 371.948975,250.843765 
	C373.450165,250.843323 374.214783,250.789413 375.348633,250.774811 
	C376.206207,250.744247 376.694550,250.674377 377.182922,250.604492 
	C377.574677,250.696289 377.966461,250.788055 378.660187,250.860840 
	C383.014313,252.581482 387.066467,254.321106 391.468689,256.146912 
	C392.292389,256.524384 392.766022,256.815643 393.513428,257.172546 
	C393.787262,257.238220 393.851776,257.608582 393.877258,257.793915 
	C391.593048,259.001587 389.283325,260.023956 386.147156,261.053711 
	C384.193726,261.167328 383.066772,261.273529 381.630585,261.290680 
	C380.896362,261.239105 380.471375,261.276611 379.993195,261.206909 
	C379.940033,261.099731 379.928741,260.860687 380.035950,260.519043 
	C382.112122,259.434906 384.081146,258.692383 386.050140,257.949860 
	C386.100769,257.325989 386.151428,256.702118 386.202057,256.078247 
	C382.578186,255.017639 378.996857,253.710266 375.300018,253.072510 
	C374.392822,252.915970 373.108337,254.946594 371.735229,256.071716 
	C371.291321,256.215210 371.109467,256.258698 370.927673,256.302155 
z"/>
          <path fill="#BCBF77" opacity="1.000000" stroke="none"
            d="
M374.084503,269.085571 
	C373.203705,269.541992 372.322937,269.998444 371.257019,270.683563 
	C370.016510,270.961182 368.961212,271.010101 367.524994,271.128967 
	C366.352814,271.147583 365.561523,271.096222 364.342224,270.684753 
	C363.268463,269.590424 362.622711,268.856232 362.220032,267.875275 
	C365.762421,268.239990 368.787384,268.437378 368.794495,263.786011 
	C370.148346,264.359955 371.204559,264.778564 372.280548,265.510620 
	C372.895081,266.911194 373.489777,267.998383 374.084503,269.085571 
z"/>
          <path fill="#ACA698" opacity="1.000000" stroke="none"
            d="
M361.976929,268.122040 
	C362.622711,268.856232 363.268463,269.590424 363.953552,270.624359 
	C359.088287,268.846069 354.183716,266.768036 349.104919,264.381287 
	C349.650269,260.249054 351.055664,263.445038 352.852905,263.893860 
	C356.343414,265.395691 359.160187,266.758881 361.976929,268.122040 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M368.994354,250.750885 
	C368.967010,251.388702 368.926727,251.853333 368.748230,252.640961 
	C364.231812,253.824463 359.853668,254.684967 355.240845,255.287018 
	C359.149628,253.560471 363.293060,252.092377 367.968140,250.407288 
	C368.499817,250.190308 368.941345,250.232681 368.941345,250.232681 
	C368.941345,250.232681 368.981415,250.577698 368.994354,250.750885 
z"/>
          <path fill="#286191" opacity="1.000000" stroke="none"
            d="
M377.265930,250.386932 
	C376.694550,250.674377 376.206207,250.744247 375.338928,250.598907 
	C374.960052,250.383667 374.904510,250.033356 374.904510,250.033356 
	C375.719330,250.078705 376.534119,250.124039 377.265930,250.386932 
z"/>
          <path fill="#457FAE" opacity="1.000000" stroke="none"
            d="
M374.697357,250.024567 
	C374.904510,250.033356 374.960052,250.383667 374.969727,250.559586 
	C374.214783,250.789413 373.450165,250.843323 372.313599,250.792694 
	C371.941650,250.688141 371.975739,250.387970 372.345825,250.229538 
	C373.169373,250.043930 373.622894,250.016739 374.076385,249.989563 
	C374.076385,249.989563 374.490204,250.015793 374.697357,250.024567 
z"/>
          <path fill="#286191" opacity="1.000000" stroke="none"
            d="
M373.907532,249.768768 
	C373.622894,250.016739 373.169373,250.043930 372.376648,250.081665 
	C372.604523,249.910812 373.171631,249.729385 373.907532,249.768768 
z"/>
          <path fill="#286191" opacity="1.000000" stroke="none"
            d="
M368.891052,250.028259 
	C368.941345,250.232681 368.499817,250.190308 368.278198,250.190567 
	C368.317932,250.068481 368.579346,249.946152 368.891052,250.028259 
z"/>
          <path fill="#26784B" opacity="1.000000" stroke="none"
            d="
M119.019684,276.041840 
	C117.875732,276.947784 116.731773,277.853729 115.272751,278.914429 
	C111.754181,278.677795 108.550667,278.286407 104.040710,277.735443 
	C106.125572,274.691772 107.496590,272.690247 108.897461,270.325897 
	C109.461067,269.656219 109.994804,269.349365 110.852585,269.015259 
	C114.464676,270.396454 117.752731,271.804901 120.900673,273.851807 
	C120.180275,275.007446 119.599983,275.524628 119.019684,276.041840 
z"/>
          <path fill="#59B365" opacity="1.000000" stroke="none"
            d="
M144.998108,276.642365 
	C144.025665,277.498383 142.957870,278.430084 141.960419,278.360046 
	C137.919205,278.076294 133.899612,277.484406 129.416733,276.572510 
	C129.278305,274.425476 129.595200,272.712219 129.912094,270.998932 
	C130.046738,270.695251 130.245697,270.442169 130.717529,270.116669 
	C132.717850,272.789062 134.624359,274.555939 138.507843,273.168365 
	C141.002472,272.277008 144.193680,272.337769 144.998108,276.642365 
z"/>
          <path fill="#A44138" opacity="1.000000" stroke="none"
            d="
M129.566956,258.072754 
	C129.932144,258.031158 130.320511,258.149597 130.654480,258.515198 
	C133.960007,262.375793 133.707077,265.712982 130.497833,268.905518 
	C127.974808,268.996185 126.408012,268.446014 127.320747,265.295105 
	C127.737976,263.854706 127.160614,262.147430 127.449295,260.640717 
	C127.625603,259.720459 128.590866,258.951324 129.566956,258.072754 
z"/>
          <path fill="#58A250" opacity="1.000000" stroke="none"
            d="
M129.533340,271.005554 
	C129.595200,272.712219 129.278305,274.425476 128.963715,276.518738 
	C128.404083,277.093323 127.842163,277.287903 127.089645,277.717651 
	C124.501160,277.397308 122.103264,276.841797 119.362526,276.164062 
	C119.599983,275.524628 120.180275,275.007446 120.933510,274.243896 
	C123.789169,273.002411 126.471878,272.007263 129.533340,271.005554 
z"/>
          <path fill="#58A250" opacity="1.000000" stroke="none"
            d="
M130.870850,268.940491 
	C133.707077,265.712982 133.960007,262.375793 130.856659,258.533691 
	C131.943436,258.385040 133.162018,258.583496 134.718536,259.348511 
	C135.351654,262.241699 135.646835,264.568390 135.942017,266.895081 
	C134.841858,267.591095 133.741699,268.287109 132.313568,269.032410 
	C131.738373,269.046326 131.491119,269.010895 130.870850,268.940491 
z"/>
          <path fill="#1FAC5C" opacity="1.000000" stroke="none"
            d="
M124.673615,264.490295 
	C122.726021,264.487915 120.957939,264.146729 119.189857,263.805573 
	C120.118439,262.586182 121.047028,261.366821 121.975609,260.147430 
	C122.934776,261.482117 123.893944,262.816803 124.673615,264.490295 
z"/>
          <path fill="#649BC6" opacity="1.000000" stroke="none"
            d="
M350.175598,277.327759 
	C351.149933,277.268616 352.249115,277.490509 353.652527,277.875122 
	C352.737976,277.894806 351.519257,277.751831 350.175598,277.327759 
z"/>
          <path fill="#105B9E" opacity="1.000000" stroke="none"
            d="
M366.997620,274.001648 
	C367.246216,274.350983 367.502197,274.695496 367.731598,275.056885 
	C367.741760,275.072845 367.509064,275.242981 367.389465,275.341309 
	C367.279327,275.054962 367.169159,274.768585 367.031219,274.239319 
	C367.003418,273.996399 367.000000,274.000000 366.997620,274.001648 
z"/>
          <path fill="#5B808C" opacity="1.000000" stroke="none"
            d="
M278.866180,393.328918 
	C281.172180,392.719543 283.478180,392.110199 285.935120,391.792725 
	C285.037170,393.569183 283.988281,395.053772 282.642639,397.129211 
	C280.555695,397.816010 278.725494,397.713684 276.986847,398.064697 
	C275.310638,398.403137 273.728943,399.209656 272.105743,399.810516 
	C265.610962,399.883636 259.116180,399.957001 252.621414,400.029205 
	C252.291962,400.032867 251.835526,400.169189 251.651337,400.004822 
	C245.856476,394.835602 238.734390,397.775116 232.264786,396.768616 
	C231.882187,396.709106 231.575653,396.160706 231.184174,395.445953 
	C231.575668,395.039764 232.016724,395.027405 233.139694,394.982910 
	C235.986710,393.290039 237.149567,391.605438 233.943817,389.816833 
	C235.054398,389.242188 236.164993,388.667542 237.636841,388.083984 
	C241.546829,388.725494 245.095535,389.375854 248.910309,390.516388 
	C247.635086,391.461609 246.093826,391.916718 245.446701,392.107788 
	C249.091537,393.248474 253.160278,394.668091 257.322968,395.713715 
	C258.270844,395.951813 259.543701,394.896149 260.666992,394.435974 
	C259.836609,393.416809 259.035400,391.574554 258.169983,391.543915 
	C256.150818,391.472321 254.472382,391.807526 255.104446,388.919586 
	C257.566254,388.756622 260.028076,388.593658 262.725098,389.143799 
	C263.436615,392.429626 261.450317,396.878815 267.486633,395.520569 
	C267.625061,393.662384 267.758514,391.870819 267.891968,390.079285 
	C268.666687,390.062897 269.441437,390.046478 270.713562,390.220642 
	C271.416840,390.643005 271.659088,390.826843 271.976288,391.330566 
	C273.095886,392.986237 273.945038,394.680573 275.314941,395.461884 
	C277.141510,396.503571 279.223663,396.594452 278.866180,393.328918 
z"/>
          <path fill="#D8F6FD" opacity="1.000000" stroke="none"
            d="
M233.630402,390.053741 
	C237.149567,391.605438 235.986710,393.290039 233.471130,394.964447 
	C233.186096,393.415619 233.251526,391.853119 233.630402,390.053741 
z"/>
          <path fill="#649BC6" opacity="1.000000" stroke="none"
            d="
M272.559021,399.851379 
	C273.728943,399.209656 275.310638,398.403137 276.986847,398.064697 
	C278.725494,397.713684 280.555695,397.816010 282.662659,397.482391 
	C283.964722,397.628082 284.950043,398.011414 285.935333,398.394775 
	C285.832001,398.893951 285.728699,399.393097 285.625366,399.892273 
	C281.421021,399.892273 277.216644,399.892273 272.559021,399.851379 
z"/>
          <path fill="#A1C2DE" opacity="1.000000" stroke="none"
            d="
M254.719467,388.929199 
	C254.472382,391.807526 256.150818,391.472321 258.169983,391.543915 
	C259.035400,391.574554 259.836609,393.416809 260.666992,394.435974 
	C259.543701,394.896149 258.270844,395.951813 257.322968,395.713715 
	C253.160278,394.668091 249.091537,393.248474 245.446701,392.107788 
	C246.093826,391.916718 247.635086,391.461609 249.285645,390.694641 
	C251.041443,389.901428 252.687973,389.420105 254.719467,388.929199 
z"/>
          <path fill="#B5DBE2" opacity="1.000000" stroke="none"
            d="
M267.625519,389.828125 
	C267.758514,391.870819 267.625061,393.662384 267.486633,395.520569 
	C261.450317,396.878815 263.436615,392.429626 262.979614,389.419739 
	C264.452271,389.180756 265.905670,389.378845 267.625519,389.828125 
z"/>
          <path fill="#A1C2DE" opacity="1.000000" stroke="none"
            d="
M278.604706,393.186951 
	C279.223663,396.594452 277.141510,396.503571 275.314941,395.461884 
	C273.945038,394.680573 273.095886,392.986237 272.366089,391.306854 
	C273.487213,390.812958 274.257111,390.710632 275.027039,390.608307 
	C275.439453,390.780457 275.851868,390.952576 276.592041,391.198547 
	C277.394257,391.863281 277.868744,392.454102 278.604706,393.186951 
z"/>
          <path fill="#1A6F8A" opacity="1.000000" stroke="none"
            d="
M274.948059,390.310974 
	C274.257111,390.710632 273.487213,390.812958 272.327515,390.939026 
	C271.659088,390.826843 271.416840,390.643005 271.101135,390.209717 
	C272.288666,386.543579 273.577637,389.175385 274.948059,390.310974 
z"/>
          <path fill="#B5DBE2" opacity="1.000000" stroke="none"
            d="
M212.985596,393.304718 
	C213.031891,393.304596 212.939316,393.304840 212.985596,393.304718 
z"/>
          <path fill="#F9FDFE" opacity="1.000000" stroke="none"
            d="
M296.946259,344.864105 
	C288.744080,349.285309 279.779602,348.581818 271.035126,348.696716 
	C253.879822,348.922180 236.719299,348.824768 219.561371,348.755096 
	C201.470627,348.681610 190.122391,337.641479 190.083450,319.456848 
	C189.968933,265.984039 190.015747,212.510391 190.180511,159.037643 
	C190.231415,142.524002 202.323456,131.055237 218.885849,131.077652 
	C239.541733,131.105606 260.197693,131.070526 280.853607,131.087631 
	C296.970703,131.100967 308.900909,142.408707 308.957184,158.596115 
	C309.144287,212.401443 309.063904,266.207764 309.032745,320.013702 
	C309.026978,329.975800 304.875641,338.017090 296.995056,344.533203 
	C296.869446,344.840118 297.015839,344.909241 296.946259,344.864105 
M291.553284,277.130188 
	C294.052979,277.130432 296.566345,276.965393 299.049164,277.170105 
	C302.675262,277.469055 304.033539,276.153870 304.006134,272.371216 
	C303.850494,250.874969 304.115570,229.373322 303.716095,207.883209 
	C303.637115,203.633667 301.962769,199.036423 299.817535,195.290527 
	C296.547821,189.581085 290.084717,189.576233 287.090485,195.366882 
	C284.883789,199.634491 283.401520,204.765305 283.299927,209.547668 
	C282.875244,229.536575 283.085541,249.539124 283.080780,269.536682 
	C283.078979,277.155273 283.100677,277.155273 291.553284,277.130188 
M236.393677,214.797119 
	C229.202148,215.084778 223.600540,219.467026 223.383728,226.527832 
	C222.898834,242.318451 223.100204,258.131805 223.197021,273.934814 
	C223.203339,274.968048 224.664520,276.860046 225.493546,276.885223 
	C233.071854,277.115570 240.660034,277.020660 248.205246,277.020660 
	C248.205246,259.564514 248.428680,242.803329 248.086868,226.053680 
	C247.963364,220.002487 243.506516,216.088882 236.393677,214.797119 
M266.110687,204.210419 
	C259.434113,204.702026 255.069702,209.769608 254.973999,218.154388 
	C254.764893,236.477463 254.930099,254.804535 254.822632,273.129272 
	C254.804428,276.230469 256.065521,277.250977 258.989807,277.152924 
	C262.816895,277.024567 266.658630,276.998169 270.481689,277.183258 
	C273.834900,277.345642 275.259857,276.216553 275.233978,272.646301 
	C275.102203,254.488327 275.285583,236.327530 275.076050,218.170990 
	C275.007477,212.227264 272.747955,207.102676 266.110687,204.210419 
M248.620255,341.979523 
	C256.471191,342.020569 263.019836,339.258240 267.218445,332.446350 
	C271.937775,324.789612 269.173798,314.414154 261.531097,309.809174 
	C251.245804,303.612061 238.157745,305.845123 232.674942,314.732574 
	C225.239655,326.784973 232.488571,339.873962 248.620255,341.979523 
M235.884903,153.712097 
	C245.370026,153.707153 254.857590,153.831802 264.339447,153.652878 
	C270.940857,153.528275 274.838806,150.036865 274.933258,144.723053 
	C275.024170,139.604980 270.874908,136.138763 264.243134,136.076141 
	C255.258209,135.991287 246.271133,136.028381 237.286118,136.123367 
	C234.818924,136.149460 231.808075,135.772964 230.023895,137.000992 
	C227.566711,138.692230 225.211899,141.469482 224.322800,144.262085 
	C223.235962,147.675812 225.318619,150.804077 228.750107,152.265182 
	C230.683533,153.088440 232.898743,153.249893 235.884903,153.712097 
M203.370636,233.826416 
	C198.191772,235.437805 196.093369,239.327316 195.941849,244.374680 
	C195.767654,250.178329 195.879623,255.990601 195.869904,261.799133 
	C195.842880,277.951477 195.840073,277.872650 212.058777,277.180969 
	C215.024185,277.054504 216.201050,276.194244 216.167358,273.175995 
	C216.058014,263.385590 216.283127,253.589630 216.062378,243.802933 
	C215.909286,237.015274 211.321045,233.346436 203.370636,233.826416 
M206.793228,208.094955 
	C212.415970,210.006607 214.044891,209.108566 214.112015,203.591888 
	C214.237518,193.279160 214.121185,182.963684 214.188370,172.649902 
	C214.207047,169.779617 212.948883,168.226028 210.121582,168.153336 
	C207.259949,168.079788 205.885223,169.632507 205.884003,172.484192 
	C205.881332,178.639435 205.878906,184.795013 205.809982,190.949707 
	C205.792633,192.498428 205.524582,194.044327 205.375427,195.554230 
	C204.234268,195.554230 203.741013,195.482803 203.275787,195.566711 
	C201.336945,195.916397 199.406540,196.312836 197.473053,196.692139 
	C198.216263,198.521835 198.575546,200.679703 199.792999,202.104050 
	C201.603088,204.221756 204.019928,205.820831 206.793228,208.094955 
M238.816605,181.963791 
	C239.124420,180.176285 240.329071,177.582840 239.577927,176.746964 
	C236.946793,173.819000 233.849197,171.174835 230.533356,169.035797 
	C227.255524,166.921280 223.992233,168.343384 223.915375,171.827438 
	C223.670090,182.947968 223.673477,194.079575 223.939285,205.198334 
	C223.970703,206.512482 226.763046,209.062958 227.347366,208.818802 
	C229.142639,208.068726 231.570862,206.393768 231.798630,204.799118 
	C232.427597,200.396103 232.070847,195.851318 232.089890,191.362518 
	C232.102875,188.303650 232.092377,185.244675 232.092377,182.159363 
	C234.709061,182.159363 236.350494,182.159363 238.816605,181.963791 
z"/>
          <path fill="#13924B" opacity="1.000000" stroke="none"
            d="
M136.309952,266.960754 
	C135.646835,264.568390 135.351654,262.241699 135.058563,259.514709 
	C136.609070,258.478180 138.157471,257.841919 139.705856,257.205658 
	C140.073090,258.838684 140.759811,260.479279 140.721207,262.102661 
	C140.679306,263.864929 139.962433,265.611176 139.471542,267.647949 
	C138.522812,267.436890 137.600342,267.231659 136.309952,266.960754 
z"/>
          <path fill="#457FAE" opacity="1.000000" stroke="none"
            d="
M381.939789,261.379761 
	C383.066772,261.273529 384.193726,261.167328 385.752350,261.101044 
	C387.489594,261.582428 388.795197,262.023926 390.499146,262.600128 
	C388.427917,264.024628 386.488678,265.358368 384.165649,266.398895 
	C382.350281,265.723724 380.860962,265.481201 379.503906,264.919250 
	C378.242706,264.396973 377.122528,263.534088 376.139557,262.583435 
	C376.948883,261.861267 377.558899,261.376801 378.462646,260.902161 
	C379.147156,260.894836 379.537964,260.877747 379.928741,260.860657 
	C379.928741,260.860687 379.940033,261.099731 380.144501,261.374329 
	C380.879242,261.559235 381.409515,261.469513 381.939789,261.379761 
z"/>
          <path fill="#286191" opacity="1.000000" stroke="none"
            d="
M375.940277,262.821167 
	C377.122528,263.534088 378.242706,264.396973 379.503906,264.919250 
	C380.860962,265.481201 382.350281,265.723724 383.834381,266.478271 
	C380.823608,267.694794 377.760315,268.538727 374.390778,269.234100 
	C373.489777,267.998383 372.895081,266.911194 372.551025,265.352325 
	C373.847870,264.194153 374.894073,263.507660 375.940277,262.821167 
z"/>
          <path fill="#DF973F" opacity="1.000000" stroke="none"
            d="
M362.220032,267.875275 
	C359.160187,266.758881 356.343414,265.395691 353.139099,264.010071 
	C352.829590,263.763580 352.907715,263.539520 353.258423,263.018494 
	C353.748657,262.615295 353.815125,262.448364 353.730438,262.220795 
	C355.866577,261.833832 358.002686,261.446869 360.784210,261.326233 
	C362.615570,261.711395 363.801575,261.830261 365.267944,262.023071 
	C366.078644,262.330292 366.608948,262.563507 367.214539,263.048096 
	C367.601349,263.398560 367.912872,263.497681 368.293335,263.598602 
	C368.362274,263.600403 368.496826,263.630737 368.496826,263.630737 
	C368.787384,268.437378 365.762421,268.239990 362.220032,267.875275 
z"/>
          <path fill="#FCEDEC" opacity="1.000000" stroke="none"
            d="
M367.139221,262.796753 
	C366.608948,262.563507 366.078644,262.330292 365.256439,261.711060 
	C364.959351,260.908356 364.954193,260.491699 364.949036,260.075012 
	C366.804352,258.966248 368.659668,257.857452 370.721313,256.525391 
	C371.109467,256.258698 371.291321,256.215210 372.024750,256.234070 
	C374.440521,257.828430 376.304718,259.360413 378.168945,260.892365 
	C377.558899,261.376801 376.948883,261.861267 376.139557,262.583435 
	C374.894073,263.507660 373.847870,264.194153 372.531189,265.038940 
	C371.204559,264.778564 370.148346,264.359955 368.794495,263.786011 
	C368.496826,263.630737 368.362274,263.600403 368.177063,263.393677 
	C367.756165,262.951019 367.471954,262.820953 367.139221,262.796753 
z"/>
          <path fill="#ACA698" opacity="1.000000" stroke="none"
            d="
M364.588074,260.013184 
	C364.954193,260.491699 364.959351,260.908356 364.976013,261.637085 
	C363.801575,261.830261 362.615570,261.711395 361.201172,261.313202 
	C362.057495,260.673004 363.142303,260.312164 364.588074,260.013184 
z"/>
          <path fill="#ACA698" opacity="1.000000" stroke="none"
            d="
M353.590149,262.204041 
	C353.815125,262.448364 353.748657,262.615295 353.388977,262.687378 
	C353.171753,262.435394 353.239380,262.280090 353.590149,262.204041 
z"/>
          <path fill="#DF973F" opacity="1.000000" stroke="none"
            d="
M378.462646,260.902161 
	C376.304718,259.360413 374.440521,257.828430 372.286804,256.134094 
	C373.108337,254.946594 374.392822,252.915970 375.300018,253.072510 
	C378.996857,253.710266 382.578186,255.017639 386.202057,256.078247 
	C386.151428,256.702118 386.100769,257.325989 386.050140,257.949860 
	C384.081146,258.692383 382.112122,259.434906 380.035950,260.519043 
	C379.537964,260.877747 379.147156,260.894836 378.462646,260.902161 
z"/>
          <path fill="#649BC6" opacity="1.000000" stroke="none"
            d="
M381.630585,261.290680 
	C381.409515,261.469513 380.879242,261.559235 380.197662,261.481537 
	C380.471375,261.276611 380.896362,261.239105 381.630585,261.290680 
z"/>
          <path fill="#2B97EB" opacity="1.000000" stroke="none"
            d="
M291.068665,277.130829 
	C283.100677,277.155273 283.078979,277.155273 283.080780,269.536682 
	C283.085541,249.539124 282.875244,229.536575 283.299927,209.547668 
	C283.401520,204.765305 284.883789,199.634491 287.090485,195.366882 
	C290.084717,189.576233 296.547821,189.581085 299.817535,195.290527 
	C301.962769,199.036423 303.637115,203.633667 303.716095,207.883209 
	C304.115570,229.373322 303.850494,250.874969 304.006134,272.371216 
	C304.033539,276.153870 302.675262,277.469055 299.049164,277.170105 
	C296.566345,276.965393 294.052979,277.130432 291.068665,277.130829 
z"/>
          <path fill="#2C98ED" opacity="1.000000" stroke="none"
            d="
M236.817719,214.816986 
	C243.506516,216.088882 247.963364,220.002487 248.086868,226.053680 
	C248.428680,242.803329 248.205246,259.564514 248.205246,277.020660 
	C240.660034,277.020660 233.071854,277.115570 225.493546,276.885223 
	C224.664520,276.860046 223.203339,274.968048 223.197021,273.934814 
	C223.100204,258.131805 222.898834,242.318451 223.383728,226.527832 
	C223.600540,219.467026 229.202148,215.084778 236.817719,214.816986 
z"/>
          <path fill="#2996EB" opacity="1.000000" stroke="none"
            d="
M266.476349,204.309952 
	C272.747955,207.102676 275.007477,212.227264 275.076050,218.170990 
	C275.285583,236.327530 275.102203,254.488327 275.233978,272.646301 
	C275.259857,276.216553 273.834900,277.345642 270.481689,277.183258 
	C266.658630,276.998169 262.816895,277.024567 258.989807,277.152924 
	C256.065521,277.250977 254.804428,276.230469 254.822632,273.129272 
	C254.930099,254.804535 254.764893,236.477463 254.973999,218.154388 
	C255.069702,209.769608 259.434113,204.702026 266.476349,204.309952 
z"/>
          <path fill="#10A8ED" opacity="1.000000" stroke="none"
            d="
M248.184906,341.965759 
	C232.488571,339.873962 225.239655,326.784973 232.674942,314.732574 
	C238.157745,305.845123 251.245804,303.612061 261.531097,309.809174 
	C269.173798,314.414154 271.937775,324.789612 267.218445,332.446350 
	C263.019836,339.258240 256.471191,342.020569 248.184906,341.965759 
z"/>
          <path fill="#3198E9" opacity="1.000000" stroke="none"
            d="
M235.435959,153.710785 
	C232.898743,153.249893 230.683533,153.088440 228.750107,152.265182 
	C225.318619,150.804077 223.235962,147.675812 224.322800,144.262085 
	C225.211899,141.469482 227.566711,138.692230 230.023895,137.000992 
	C231.808075,135.772964 234.818924,136.149460 237.286118,136.123367 
	C246.271133,136.028381 255.258209,135.991287 264.243134,136.076141 
	C270.874908,136.138763 275.024170,139.604980 274.933258,144.723053 
	C274.838806,150.036865 270.940857,153.528275 264.339447,153.652878 
	C254.857590,153.831802 245.370026,153.707153 235.435959,153.710785 
z"/>
          <path fill="#2B97EB" opacity="1.000000" stroke="none"
            d="
M203.739014,233.747406 
	C211.321045,233.346436 215.909286,237.015274 216.062378,243.802933 
	C216.283127,253.589630 216.058014,263.385590 216.167358,273.175995 
	C216.201050,276.194244 215.024185,277.054504 212.058777,277.180969 
	C195.840073,277.872650 195.842880,277.951477 195.869904,261.799133 
	C195.879623,255.990601 195.767654,250.178329 195.941849,244.374680 
	C196.093369,239.327316 198.191772,235.437805 203.739014,233.747406 
z"/>
          <path fill="#3997E0" opacity="1.000000" stroke="none"
            d="
M206.485901,207.867798 
	C204.019928,205.820831 201.603088,204.221756 199.792999,202.104050 
	C198.575546,200.679703 198.216263,198.521835 197.473053,196.692139 
	C199.406540,196.312836 201.336945,195.916397 203.275787,195.566711 
	C203.741013,195.482803 204.234268,195.554230 205.375427,195.554230 
	C205.524582,194.044327 205.792633,192.498428 205.809982,190.949707 
	C205.878906,184.795013 205.881332,178.639435 205.884003,172.484192 
	C205.885223,169.632507 207.259949,168.079788 210.121582,168.153336 
	C212.948883,168.226028 214.207047,169.779617 214.188370,172.649902 
	C214.121185,182.963684 214.237518,193.279160 214.112015,203.591888 
	C214.044891,209.108566 212.415970,210.006607 206.485901,207.867798 
z"/>
          <path fill="#4C96D3" opacity="1.000000" stroke="none"
            d="
M238.404266,182.061584 
	C236.350494,182.159363 234.709061,182.159363 232.092377,182.159363 
	C232.092377,185.244675 232.102875,188.303650 232.089890,191.362518 
	C232.070847,195.851318 232.427597,200.396103 231.798630,204.799118 
	C231.570862,206.393768 229.142639,208.068726 227.347366,208.818802 
	C226.763046,209.062958 223.970703,206.512482 223.939285,205.198334 
	C223.673477,194.079575 223.670090,182.947968 223.915375,171.827438 
	C223.992233,168.343384 227.255524,166.921280 230.533356,169.035797 
	C233.849197,171.174835 236.946793,173.819000 239.577927,176.746964 
	C240.329071,177.582840 239.124420,180.176285 238.404266,182.061584 
M236.456741,176.248016 
	C234.687271,174.747574 233.081635,172.948639 231.088974,171.854813 
	C229.825394,171.161224 227.953796,171.299484 226.422150,171.523880 
	C225.906174,171.599487 225.258652,173.083771 225.245880,173.932877 
	C225.108841,183.042297 224.957809,192.156296 225.158920,201.261353 
	C225.193008,202.804688 226.876709,204.311615 227.798264,205.835358 
	C228.537476,204.366043 229.836044,202.925827 229.914261,201.422150 
	C230.181900,196.276337 230.065857,191.107727 230.008118,185.948807 
	C229.968216,182.383682 230.577057,179.620270 235.162292,179.960983 
	C237.568604,180.139801 238.820953,179.185287 236.456741,176.248016 
z"/>
          <path fill="#BCBF77" opacity="1.000000" stroke="none"
            d="
M367.214539,263.048096 
	C367.471954,262.820953 367.756165,262.951019 368.108154,263.391876 
	C367.912872,263.497681 367.601349,263.398560 367.214539,263.048096 
z"/>
          <path fill="#2597F0" opacity="1.000000" stroke="none"
            d="
M236.753265,176.478516 
	C238.820953,179.185287 237.568604,180.139801 235.162292,179.960983 
	C230.577057,179.620270 229.968216,182.383682 230.008118,185.948807 
	C230.065857,191.107727 230.181900,196.276337 229.914261,201.422150 
	C229.836044,202.925827 228.537476,204.366043 227.798279,205.835358 
	C226.876709,204.311615 225.193008,202.804688 225.158920,201.261353 
	C224.957809,192.156296 225.108841,183.042297 225.245880,173.932877 
	C225.258652,173.083771 225.906174,171.599487 226.422150,171.523880 
	C227.953796,171.299484 229.825394,171.161224 231.088974,171.854813 
	C233.081635,172.948639 234.687271,174.747574 236.753265,176.478516 
z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
